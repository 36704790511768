/* eslint-disable import/prefer-default-export */
import startOfDay from 'date-fns/startOfDay';
import { apiIsUserPhysician } from './api';
import { getCancerScreening } from './patientUtils';
import {
  isPreNewPatient,
  isReferral,
  isScreeningPreConsult,
  mostRecentScreeningActivity,
  testTypeName,
} from './screenings';
import { capitalize } from './string';

function descriptionName(description, screening) {
  if (description === 'new patient') {
    if (screening.upgradeToScreening) {
      return 'New screening';
    }
  }

  if (description === 'referral request') {
    if (isReferral(screening)) {
      return 'Refer to specialist';
    }
    return `Refer for ${testTypeName(screening.nextTest.type, screening)}`;
  }

  return capitalize(description);
}

function date(description, screening, patient) {
  if (description === 'new patient') {
    return screening.statusDate;
  }

  if (description === 'consult request') {
    return screening.nextTest.consultRequested;
  }

  if (description === 'chart update') {
    return screening.nextTest.chartUpdated;
  }

  if (description === 'missing result') {
    return startOfDay(mostRecentScreeningActivity(screening));
  }

  if (description === 'referral request') {
    const cancerScreening = getCancerScreening(screening, patient);
    if (cancerScreening.referral !== undefined) {
      if (cancerScreening.referral.sent) {
        return cancerScreening.referral.sent;
      }
      if (cancerScreening.referral.requested) {
        return cancerScreening.referral.requested;
      }
    }
  }

  if (description === 'referral reminder') {
    const cancerScreening = getCancerScreening(screening, patient);
    if (cancerScreening.referral !== undefined) {
      if (cancerScreening.referral.reminderDue) {
        return cancerScreening.referral.reminderDue;
      }
    }
  }

  return null;
}

function getChartUpdateOrNull(chartUpdate, patient) {
  const { description, screeningType = null } = chartUpdate;

  const screening = patient.screenings.find((item) => item.type === screeningType);

  // Not expected
  if (screening === undefined) {
    return null;
  }

  // Chek staff updates
  if (
    !apiIsUserPhysician() &&
    ['new patient', 'consult request', 'missing result', 'referral request', 'referral reminder'].includes(description)
  ) {
    // Updates shared with physician, check if chek staff already completed
    if (
      (description === 'new patient' && !isPreNewPatient(screening)) ||
      (description === 'consult request' && !isScreeningPreConsult(screening))
    ) {
      return null;
    }

    return {
      date: date(description, screening, patient),
      description: descriptionName(description, screening),
      patient,
      screeningType: screening.type,
    };
  }

  // Physician updates
  if (apiIsUserPhysician() && ['new patient', 'consult request', 'chart update'].includes(description)) {
    if (!apiIsUserPhysician()) {
      return null;
    }

    // Updates shared with chek staff, check if chek staff has not completed
    if (
      (description === 'new patient' && isPreNewPatient(screening)) ||
      (description === 'consult request' && isScreeningPreConsult(screening))
    ) {
      return null;
    }

    return {
      date: date(description, screening, patient),
      description: descriptionName(description, screening),
      patient,
      screeningType: screening.type,
    };
  }

  return null;
}

export function getChartUpdates(patient) {
  if (patient.chartUpdates === undefined || patient.screenings === undefined) {
    return [];
  }

  const allUpdates = patient.chartUpdates.reduce((accumulator, chartUpdate) => {
    const chartUpdateOrNull = getChartUpdateOrNull(chartUpdate, patient);
    return chartUpdateOrNull !== null ? accumulator.concat([chartUpdateOrNull]) : accumulator;
  }, []);

  // Filter down 'new patient' to single item
  // Note: description is 'description name'
  const newPatientUpdates = allUpdates.reduce(
    (accumulator, chartUpdate) =>
      chartUpdate.description === 'New patient' && accumulator.length === 0 ? [chartUpdate] : accumulator,
    [],
  );

  return allUpdates.filter((chartUpdate) => chartUpdate.description !== 'New patient').concat(newPatientUpdates);
}
