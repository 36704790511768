import Input from '@material-ui/core/Input';
import MuiSelect from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = (theme) => ({
  paper: {
    borderRadius: 4,
    minWidth: 230,
    padding: theme.spacing(0.25, 1),
  },
  underline: {
    '&:after': {
      borderBottomColor: theme.palette.text.primary,
    },
  },
});

function Select(props) {
  const { classes, inputProps, PaperProps, ...other } = props;

  const inputPropsClasses = inputProps ? inputProps.classes : undefined;

  return (
    <MuiSelect
      input={<Input classes={{ underline: classes.underline, ...inputPropsClasses }} />}
      MenuProps={{
        PaperProps: { className: classes.paper, ...PaperProps },
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    />
  ); // eslint-disable-line react/jsx-props-no-spreading
}

Select.propTypes = {
  classes: PropTypes.object.isRequired,
  inputProps: PropTypes.object,
  PaperProps: PropTypes.object,
};

Select.defaultProps = {
  inputProps: {},
  PaperProps: {},
};

export default withStyles(styles)(Select);
