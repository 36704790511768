import compareDesc from 'date-fns/compareDesc';
import startOfDay from 'date-fns/startOfDay';
import { findBilling, getBillingCodeOfDay } from './billing';
import { formatDateLong, isBeforeToday, isToday, now } from './date';
import {
  getLatestTest,
  getLatestTests,
  hasLatestTestNotReviewed,
  inConsultWindow,
  isAutoConsultTest,
  isNextTestQuestionnaire,
  isPatientNewPatient,
  isReferral,
  isScreeningConsult,
  screeningRiskStatus,
  specialistRecommendationName,
  testReleaseType,
  testTypeName,
  testTypeReferPatient,
  testValue2Name,
  testValueName,
} from './screenings';
import { compareDate } from './sort';
import { capitalize, uncapitalize } from './string';

// Utils

const compareChartNotesDate = (a, b) => compareDesc(a.createdDate, b.createdDate);
const compareChartNotesDescription = (a, b) => (a.description < b.description ? -1 : 1);

export const chartNoteId = (note) => `${note.createdDate}${note.description}${note.subnotes.join()}`;
export const chartAttachmentId = (attachment) => `${attachment.createdDate}`;

const createdBy = (user) => ({ userId: user.id, fullName: user.fullName });

//
// Headline
//

function headlineScreening(screening, patient) {
  if (isPatientNewPatient(patient)) {
    return 'New patient consult';
  }
  if (isScreeningConsult(screening)) {
    return 'Patient consult';
  }
  if (hasLatestTestNotReviewed(screening)) {
    return 'Test result';
  }
  return 'Chart update';
}

const headlineNote = (user) => (user.role === 'chek' ? 'Admin note' : 'Physician note');

//
// Subnotes
//

const subnoteRiskStatus = (screening, patient) => screeningRiskStatus(screening, patient).risk;

function subnoteTestSchedule(recommended, screening) {
  if (recommended.text()) {
    return recommended.text();
  }

  if (recommended.isTestNotScheduled()) {
    return null;
  }

  if (
    inConsultWindow(recommended.nextTestType(), recommended.nextTestDue(), screening) ||
    isToday(recommended.nextTestDue()) ||
    isBeforeToday(recommended.nextTestDue())
  ) {
    return `${capitalize(testTypeName(recommended.nextTestType(), screening))} due now`;
  }
  if (isNextTestQuestionnaire(screening)) {
    return `Next ${testTypeName(recommended.nextTestType(), screening)} scheduled ${formatDateLong(
      recommended.nextTestDue(),
    )}`;
  }
  return `Next ${testTypeName(recommended.nextTestType(), screening)} due ${formatDateLong(recommended.nextTestDue())}`;
}

const subnoteLatestTestResult = (screening) =>
  getLatestTests(screening)
    .sort(compareDate)
    .map((test) => {
      const valueName = `${capitalize(testTypeName(test.type, screening))} ${formatDateLong(test.date)} ${
        test.value ? capitalize(testValueName(test, screening)) : ''
      }`;
      const value2Name = test.value2 ? ` • ${testValue2Name(test.value2, screening)}` : '';
      const testRecommendationName =
        test.recommendation !== undefined && test.recommendation !== null
          ? ` (${uncapitalize(specialistRecommendationName(test.recommendation, screening))})`
          : '';
      return `${valueName}${value2Name}${testRecommendationName}`;
    });

function subnoteRequisitionReleased(screening) {
  // Reasons patient has requested a physician consult:
  // (1) New patient consult
  // (2) Patient consult for scheduled test and release requisition
  // (3) Auto consult for pap test does not go to physician
  //     and test instructions sent
  // (4) Patient consult and preform referral as booking colonoscopy
  //     or CT colonoscopy has come due
  // Note: With reflexive colonoscopy the endoscopy clinic does the booking
  // so possible to return null

  const { nextTest } = screening;

  if (testTypeReferPatient(nextTest.type, screening)) {
    return `Refer for ${testTypeName(nextTest.type, screening)}`;
  }

  const releaseType = testReleaseType(nextTest.type, screening);

  if (releaseType === 'instructions') {
    return 'Test instructions sent';
  }

  if (releaseType === 'requisition') {
    return 'Requisition released';
  }

  return null;
}

const subnoteExternalMrp = () => 'External MRP';

function subnoteOrderingPhysician(screening) {
  const latestTest = getLatestTest(screening);
  return latestTest && latestTest.orderingPhysician ? latestTest.orderingPhysician.name : '';
}

export const addRequistionReleasedSubnote = (screening, note) => {
  const subnote = subnoteRequisitionReleased(screening);
  if (subnote != null) {
    note.subnotes.push(subnote);
  }
};

//
// Attachments
// (1) Stand-alone chart note has user text as an attachment.
// (2) Physician or chek staff user attaches a subnote to one of their notes.
//

const newAttachment = (description, user) => ({
  createdDate: now(),
  createdBy: createdBy(user),
  description,
});

export const addAttachment = (description, user, note) => ({
  ...note,
  attachments: note.attachments.concat([newAttachment(description, user)]),
});

export const updateAttachment = (description, attachmentId, note) => ({
  ...note,
  attachments: note.attachments.map((attachment) =>
    attachmentId === chartAttachmentId(attachment)
      ? {
          ...attachment,
          description,
        }
      : attachment,
  ),
});

export const deleteAttachment = (attachmentId, note) => ({
  ...note,
  attachments: note.attachments.filter((attachment) => attachmentId !== chartAttachmentId(attachment)),
});

//
// Create new chart notes
//

function newScreeningNote(recommended, screening, patient, user) {
  const note = {
    createdDate: now(),
    createdBy: createdBy(user),
    description: `${capitalize(screening.type)} cancer screening:`,
    headline: headlineScreening(screening, patient),
    subnotes: [subnoteRiskStatus(screening, patient)],
    attachments: [],
  };
  // Optional: new result subnote
  if (hasLatestTestNotReviewed(screening)) {
    subnoteLatestTestResult(screening).forEach((subnote) => note.subnotes.push(subnote));
  }

  // Conditionally add subnote test schedule or reason for no test scheduled
  note.subnotes.push(subnoteTestSchedule(recommended, screening));

  if (recommended.callPatient()) {
    note.subnotes.push('Patient called');
  }

  return note;
}

export const chartNoteScreeningSetNextTestDue = (recommended, screening, patient, user) =>
  newScreeningNote(recommended, screening, patient, user);

export const chartNoteAcceptTest = (recommended, screening, patient, user) =>
  newScreeningNote(recommended, screening, patient, user);

export const chartNoteScreeningSetNoNextTest = (recommended, screening, patient, user) =>
  newScreeningNote(recommended, screening, patient, user);

export function chartNoteReferralTest(screening, patient, user) {
  const note = {
    createdDate: now(),
    createdBy: createdBy(user),
    description: `${capitalize(screening.type)} cancer screening:`,
    headline: headlineScreening(screening, patient),
    subnotes: [subnoteRiskStatus(screening, patient)],
    attachments: [],
  };

  // Note: For new patient needing to be referred by Chek physician, tests will
  // be reviewed, for new result not reviewed

  subnoteLatestTestResult(screening).forEach((subnote) => note.subnotes.push(subnote));

  note.subnotes.push('Patient called');
  note.subnotes.push('Refer to specialist');

  return note;
}

export function chartNoteReferralSent(screening, user) {
  return {
    createdDate: now(),
    createdBy: createdBy(user),
    description: `${capitalize(screening.type)} cancer screening:`,
    headline: 'Referral sent',
    subnotes: [`Referral sent to ${isReferral(screening) ? 'speciality clinic' : 'colonoscopy centre'}`],
    attachments: [],
  };
}

export function chartNoteScreeningRequistionReleased(screening, patient, user) {
  const headline = () => {
    if (isPatientNewPatient(patient)) return 'New patient consult';
    if (isAutoConsultTest(screening)) return 'Test due';
    return 'Patient consult';
  };
  return {
    createdDate: now(),
    createdBy: createdBy(user),
    description: `${capitalize(screening.type)} cancer screening:`,
    headline: headline(),
    subnotes: [
      subnoteRiskStatus(screening, patient),
      `${capitalize(testTypeName(screening.nextTest.type, screening))} due now`,
      subnoteRequisitionReleased(screening),
    ].filter((subnote) => subnote !== null),
    attachments: [],
  };
}

// User note does not have a note description as comes from attachment
export const isChartNoteUserNote = (note) => !note.description;

export const chartNoteUserNote = (description, user) => ({
  createdDate: now(),
  createdBy: createdBy(user),
  headline: headlineNote(user),
  description: null,
  subnotes: [],
  attachments: [newAttachment(description, user)],
  editable: true,
});

export function chartNoteExternalMrp(recommended, screening, patient, user) {
  const headline = () => {
    if (isPatientNewPatient(patient)) return 'New patient consult';
    if (isScreeningConsult(screening)) return 'Patient consult';
    return 'Test result';
  };
  return {
    createdDate: now(),
    createdBy: createdBy(user),
    description: `${capitalize(screening.type)} cancer screening:`,
    headline: headline(),
    subnotes: [
      subnoteRiskStatus(screening, patient),
      subnoteLatestTestResult(screening),
      recommended.referral() ? 'Referred to a specialist' : subnoteTestSchedule(recommended, screening),
      subnoteExternalMrp(),
      subnoteOrderingPhysician(screening),
    ]
      .filter((subnote) => subnote !== null)
      .flat(),
    attachments: [],
  };
}

export const chartNoteFastForwardScreeningReferral = (screening, patient, user) => ({
  createdDate: now(),
  createdBy: createdBy(user),
  description: `${capitalize(screening.type)} cancer screening:`,
  headline: 'New patient consult',
  subnotes: [
    subnoteRiskStatus(screening, patient),
    subnoteLatestTestResult(screening),
    'Referred to a specialist outside of Chek system',
  ]
    .filter((subnote) => subnote !== null)
    .flat(),
  attachments: [],
});

export const chartNoteFastForwardScreeningReleaseRequisition = (recommended, screening, patient, user) => ({
  createdDate: now(),
  createdBy: createdBy(user),
  description: `${capitalize(screening.type)} cancer screening:`,
  headline: 'New patient consult',
  subnotes: [
    subnoteRiskStatus(screening, patient),
    subnoteLatestTestResult(screening),
    subnoteTestSchedule(recommended, screening),
    'Referred outside of Chek system',
  ]
    .filter((subnote) => subnote !== null)
    .flat(),
  attachments: [],
});

//
// Support showing notification dot on chart notes tab
//

export const onNewChartNote = (patient) => {
  localStorage.setItem(`${patient.id}-unviewedChartNotes`, true);
};

export const onViewedChartNotes = (patient) => {
  localStorage.removeItem(`${patient.id}-unviewedChartNotes`);
};

export const unviewedChartNotes = (patient) => localStorage.getItem(`${patient.id}-unviewedChartNotes`);

//
// View model for use by components
//

// Group notes with same group ID and return object of note group per ID
const groupNotes = (notes, getGroupId) =>
  notes.reduce((accumulator, note) => {
    const groupId = getGroupId(note);
    if (Object.hasOwn(accumulator, groupId)) {
      accumulator[groupId].push(note);
    } else {
      accumulator[groupId] = [note];
    }
    return accumulator;
  }, {});

// Convert note group array to a view note sharing common headline
// as the title (conditionally), author and billing (conditionally)
// Note: New patient note group shares common headline title
function groupedNotesToViewNote(groupedNotes, billings) {
  const note1 = groupedNotes.at(0);
  // For physician generated notes, resolve the billing code associated
  // with grouped notes and include the billing for use with view note.
  let billing = null;
  if (!isChartNoteUserNote(note1)) {
    const noteBilling = findBilling(note1.createdDate, billings);
    if (noteBilling && noteBilling.physicianId === note1.createdBy.userId) {
      const code = getBillingCodeOfDay(noteBilling, note1.createdDate);
      if (code !== null) {
        billing = {
          ...noteBilling,
          code,
        };
      }
    }
  }

  return {
    id: chartNoteId(note1),
    title: note1.headline === 'New patient consult' ? note1.headline : null,
    editable: groupedNotes.reduce((accumulator, note) => accumulator || note.editable, false),
    createdDate: note1.createdDate,
    createdBy: note1.createdBy,
    notes: groupedNotes.sort(compareChartNotesDescription),
    billing,
  };
}

// Convert chartNotes into viewNotes and conditionally group
// chartNotes together in a single viewNote.
export function getViewNotes(patient, billings) {
  const notesUser = [];
  const notesNewPatient = [];

  const notesOther = patient.chartNotes.reduce((accumulator, note) => {
    // User note
    if (isChartNoteUserNote(note)) {
      notesUser.push(note);
      return accumulator;
    }
    // Note from new patient consult
    if (note.headline === 'New patient consult') {
      notesNewPatient.push(note);
      return accumulator;
    }
    // All other notes
    return accumulator.concat(note);
  }, []);

  // (1) Physician and chek staff user notes are not grouped (group of 1)

  const viewNotes1 = notesUser.map((note) => groupedNotesToViewNote([note], billings));

  // (2) Group together all new patient consult notes by author into one
  //     view note to present with common date and headline. Order notes
  //     by screening type. Convert to view note sharing common headline
  //     as the title (i.e. new patient consult) and author.

  const groupNewPatient = groupNotes(notesNewPatient, (note) => `${note.createdBy.userId}`);
  const viewNotes2 = Object.values(groupNewPatient).map((groupedNotes) =>
    groupedNotesToViewNote(groupedNotes, billings),
  );

  // (3) Group together non-user notes of the same day and author to
  //     present with common date. Order notes by screening type.

  const groupSameDay = groupNotes(notesOther, (note) => `${startOfDay(note.createdDate)}-${note.createdBy.userId}`);
  const viewNotes3 = Object.values(groupSameDay).map((groupedNotes) => groupedNotesToViewNote(groupedNotes, billings));

  // Sort all view notes by date
  return [viewNotes1, viewNotes2, viewNotes3].flat().sort(compareChartNotesDate);
}

export const viewNoteGetNote = (noteId, viewNote) => {
  const note = viewNote.notes.find((item) => noteId === chartNoteId(item));
  return note ? { ...note } : null;
};
