import format from 'date-fns/format';
import { apiGetBillings } from './api';
import { getBillingCodeOfDay, toBillingCodeAndModifiers } from './billing';
import { envShortName, isProdEnv } from './env';
import { genderShortName } from './gender';

function jsonToCsv(json, headers) {
  const keys = Object.keys(json.length !== 0 ? json[0] : []);
  const csv = [];
  csv.push(headers.join(','));
  const regex = /[",\r\n]/;
  for (let i = 0; i < json.length; i += 1) {
    const object = json[i];
    const values = keys.map((key) =>
      typeof object[key].match === 'function' && object[key].match(regex) ? `"${object[key]}"` : object[key],
    );
    csv.push(values.join(','));
  }
  return csv.join('\r\n');
}

function download(csv, filename) {
  const blob = new Blob([csv], { type: 'text/cvs' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', filename);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

// eslint-disable-next-line import/prefer-default-export
export function getBillings(interval) {
  const headers = [
    'Date of birth',
    'ULI',
    'Name',
    'Sex',
    'Date of service',
    'Physician name',
    'Code',
    'Modifiers',
    'DX',
    'Referring physician',
    'Admission date',
    'Facility',
    'Functional centre',
  ];

  return apiGetBillings(interval)
    .then((billings) => {
      const json = billings.map((billing) => {
        const code = getBillingCodeOfDay(billing);
        const codeModifiers = toBillingCodeAndModifiers(code);
        return {
          dateOfBirth: billing.dob,
          uli: billing.phn.replace('-', ''),
          name: billing.patientName,
          sex: genderShortName(billing.gender),
          dateOfService: format(billing.date, 'yyyy-MM-dd'),
          physicianName: billing.physicianId,
          code: codeModifiers.code || '',
          modifiers: codeModifiers.modifiers || '',
          dx: 'V65.4',
          referringPhysician: '',
          admissionDate: '',
          facility: '234420',
          functionalCentre: 'EXRM',
        };
      });
      const maxDate = json.reduce(
        (accumulator, value) =>
          accumulator === null || value.dateOfService > accumulator ? value.dateOfService : accumulator,
        null,
      );
      const csv = jsonToCsv(json, headers);
      const prefix = isProdEnv() ? '' : `${envShortName()}-`;
      download(csv, maxDate === null ? `${prefix}chek-billing (empty).csv` : `${prefix}chek-billing ${maxDate}.csv`);
    })
    .catch(() => {});
}
