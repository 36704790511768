import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  primaryContainer,
  primaryOnContainer,
  secondaryContainer,
  secondaryOnContainer,
  tertiaryContainer,
  tertiaryOnContainer,
} from '../constants/colors';
import { fontFamily2 } from '../constants/typography';

const styles = (theme) => ({
  container: {
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: 16,
    display: 'inline-flex',
    height: theme.spacing(3.25),
    paddingLeft: theme.spacing(1.25),
    paddingRight: theme.spacing(1.25),
  },
  containerIcon: {
    paddingLeft: theme.spacing(1),
  },
  defaultContainer: {
    backgroundColor: tertiaryContainer,
  },
  defaultOnContainer: {
    color: tertiaryOnContainer,
  },
  icon: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1,
    paddingRight: theme.spacing(0.5),
  },
  text: {
    ...theme.typography.caption,
    fontFamily: fontFamily2,
    fontSize: theme.typography.pxToRem(11),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: `${theme.typography.round(0.5 / 11)}em`,
    lineHeight: theme.typography.pxToRem(16),
  },
  primaryContainer: {
    backgroundColor: primaryContainer,
  },
  primaryOnContainer: {
    color: primaryOnContainer,
  },
  secondaryContainer: {
    backgroundColor: secondaryContainer,
  },
  secondaryOnContainer: {
    color: secondaryOnContainer,
  },
});

function LabelBadge(props) {
  const { classes, className: classNameProp, icon, label, variant, ...other } = props;

  return (
    <span
      className={clsx(classes.container, icon && classes.containerIcon, classes[`${variant}Container`], classNameProp)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {icon && <span className={clsx(classes.icon, classes[`${variant}OnContainer`])}>{icon}</span>}
      <span className={clsx(classes.text, classes[`${variant}OnContainer`])}>{label}</span>
    </span>
  );
}

LabelBadge.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  icon: PropTypes.node,
  label: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['default', 'primary', 'secondary']),
};

LabelBadge.defaultProps = {
  className: undefined,
  icon: null,
  variant: 'default',
};

export default withStyles(styles)(LabelBadge);
