import { createTheme } from '@material-ui/core/styles';
import {
  createColorTheme,
  createColorThemeBadge,
  onSurface08Pct,
  onSurface12Pct,
  onSurface38Pct,
  outline,
  outlineVariant,
  surface,
  tertiary,
  textPrimary,
  textSecondary,
} from './colors';
import { typography } from './typography';

const baseTheme = {
  palette: {
    primary: {
      light: surface,
      main: surface,
      dark: surface,
    },
    secondary: {
      light: surface,
      main: surface,
      dark: surface,
    },
    error: {
      light: tertiary,
      main: tertiary,
      dark: tertiary,
    },
    text: {
      primary: textPrimary,
      secondary: textSecondary,
    },
    divider: outlineVariant,
    background: {
      default: surface,
    },
    action: {
      active: textSecondary,
      hover: onSurface08Pct,
      hoverOpacity: 0.08,
      disabled: onSurface38Pct,
      disabledBackground: onSurface12Pct,
      focus: onSurface12Pct,
      selected: onSurface12Pct,
      selectedOpacity: 0.12,
    },
  },
  typography,
  overrides: {
    MuiFormLabel: {
      root: {
        color: outline,
        '&.Mui-focused': {
          color: textPrimary,
        },
      },
      filled: {
        color: outline,
      },
    },
    MuiFormHelperText: {
      root: {
        color: outline,
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottomColor: textPrimary,
          borderBottomRadius: 4,
        },
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        color: textSecondary,
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: textPrimary,
        },
      },
    },
    MuiSelect: {
      icon: {
        color: textSecondary,
      },
    },
  },
};

const theme = createTheme(baseTheme);

export default theme;

export const colorTheme = createColorTheme(baseTheme);
export const colorThemeBadge = createColorThemeBadge(baseTheme);

const breakpointUpXsAndLandscape = `${theme.breakpoints.up('xs')} and (orientation: landscape)`;
const breakpointUpSm = theme.breakpoints.up('sm');

// eslint-disable-next-line no-shadow
export function mixinGutters(theme) {
  return {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  };
}

export function mixinHeightWithToolbar(offset = 0, styles = {}) {
  const filtered = { ...styles };
  delete filtered[breakpointUpXsAndLandscape];
  delete filtered[breakpointUpSm];

  return {
    [breakpointUpXsAndLandscape]: {
      height: `calc(100% - 48px - ${offset}px)`,
      ...styles[breakpointUpXsAndLandscape],
    },
    [breakpointUpSm]: {
      height: `calc(100% - 64px - ${offset}px)`,
      ...styles[breakpointUpSm],
    },
    height: `calc(100% - 56px - ${offset}px)`,
    ...filtered,
  };
}
