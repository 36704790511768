import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { createRef, Component } from 'react';

const styles = {
  scroll: {
    overflowY: 'auto',
  },
};

class Scroll extends Component {
  constructor(props) {
    super(props);

    this.scrollRef = createRef();
    this.scrollTop = undefined;

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    const { scrollTop } = this.props;

    this.scrollRef.current.scrollTop = scrollTop;
    this.scrollRef.current.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    const { handleSave } = this.props;

    this.scrollRef.current.removeEventListener('scroll', this.handleScroll);
    handleSave(this.scrollTop);
  }

  handleScroll(event) {
    this.scrollTop = event.target.scrollTop;
  }

  render() {
    const { children, classes, className } = this.props;

    return (
      <div className={clsx(classes.scroll, className)} ref={this.scrollRef}>
        {children}
      </div>
    );
  }
}

Scroll.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
  scrollTop: PropTypes.number,
};

Scroll.defaultProps = {
  scrollTop: 0,
};

export default withStyles(styles)(Scroll);
