import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Component } from 'react';
import Button from '../components/Button';
import DelayedFade from '../components/DelayedFade';
import ExpansionPanel from '../components/ExpansionPanel';
import NewResult from '../components/NewResult';
import Separator from '../components/Separator';
import TestValue from '../components/TestValue';
import { cancerColor, neutral60 } from '../constants/colors';
import { flatShadow, noHover } from '../constants/styles';
import { distanceInYears, formatDateUiText, parseDOB } from '../utils/date';
import { genderShortName } from '../utils/gender';
import history from '../utils/history';
import notifier from '../utils/notifier';
import { getFullName } from '../utils/patient';
import { getLatestTest } from '../utils/screenings';

const styles = (theme) => ({
  button: {
    marginTop: theme.spacing(1),
  },
  buttonChart: {
    minWidth: 108,
    margin: theme.spacing(0, 1),
  },
  buttonSeeAll: {
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 1),
  },
  content: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    paddingLeft: 61,
  },
  date: {
    minWidth: 124,
  },
  empty: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: 354,
  },
  emptyText: {
    color: neutral60,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 94,
  },
  expansionPanelExpanded: {
    borderRadius: 8,
  },
  expansionPanelTop: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  expansionPanelBottom: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  flatShadow,
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  list: {
    minHeight: 354,
  },
  listItem: {
    margin: '1px 0',
    transition: theme.transitions.create('margin'),
  },
  listItemClosed: {
    margin: 0,
  },
  listItemExpanded: {
    margin: theme.spacing(1, 0),
  },
  listItemFirst: {
    marginTop: 0,
  },
  listListLast: {
    marginBottom: 0,
  },
  noHover,
  result: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginRight: theme.spacing(1),
  },
  seeAll: {
    marginTop: theme.spacing(1.5),
  },
  summary: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

class NewResults extends Component {
  constructor(props) {
    super(props);

    this.state = { newResultToClose: {}, newResultsToFilter: [] };

    this.notifier = undefined;

    this.handleAcceptClick = this.handleAcceptClick.bind(this);
    this.handleExited = this.handleExited.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { newResults } = props;
    const { newResultsToFilter } = state;

    return {
      newResultsToFilter: newResultsToFilter.filter((item) => newResults.some((value) => value.id === item.id)),
    };
  }

  handleAcceptClick(newResult) {
    return (recommended, screening) => {
      const { addWaitNotifier, handleAcceptClick: handleAcceptClickProp } = this.props;

      this.setState({ newResultToClose: newResult });

      this.notifier = notifier();
      addWaitNotifier(this.notifier);
      return handleAcceptClickProp(recommended, screening, newResult);
    };
  }

  handleExited() {
    const { newResultToClose, newResultsToFilter } = this.state;

    this.setState({
      newResultToClose: {},
      newResultsToFilter: newResultsToFilter.concat([newResultToClose]),
    });

    this.notifier.resolve();
    this.notifier = undefined;
  }

  render() {
    const { classes, expansions, handleExpandClick, handleResetChartScrollHistory, handleSeeAllClick, newResults } =
      this.props;
    const { newResultToClose, newResultsToFilter } = this.state;

    const newResultsFiltered = newResults.filter((item) => !newResultsToFilter.some((value) => value.id === item.id));

    const count = (() => {
      if (handleSeeAllClick) {
        if (newResultToClose.id) {
          return 7;
        }
        return 6;
      }
      return newResults.length;
    })();

    return (
      <div className={classes.container}>
        {newResultsFiltered.length !== 0 ? (
          <>
            <div className={classes.list}>
              {newResultsFiltered.slice(0, count).map((item, index, array) => {
                const { id, patient, screening } = item;

                const expanded = expansions.includes(id) || newResultToClose.id === id;
                const open = newResultToClose.id !== id;

                const first =
                  (index === 0 && newResultToClose.id !== item.id) ||
                  (index === 1 && array.length !== 0 && array[0].id === newResultToClose.id);
                const last =
                  (index === array.length - 1 && newResultToClose.id) ||
                  (index === array.length - 2 &&
                    array.length !== 0 &&
                    array[array.length - 1].id === newResultToClose.id);

                const latestTest = getLatestTest(screening);

                return (
                  <div
                    className={clsx(
                      classes.listItem,
                      !open && [classes.listItemClosed],
                      first && [classes.listItemFirst],
                      last && [classes.listListLast],
                      expanded && open && [classes.listItemExpanded],
                    )}
                    key={id}
                  >
                    <Collapse in={open} onExited={this.handleExited}>
                      <ExpansionPanel
                        barColor={cancerColor(screening.type)}
                        className={clsx(
                          index === 0 && [classes.expansionPanelTop],
                          index === array.length - 1 && [classes.expansionPanelBottom],
                          expanded && [classes.expansionPanelExpanded],
                        )}
                        details={
                          <div className={classes.content}>
                            <NewResult
                              handleAcceptClick={this.handleAcceptClick(item)}
                              patient={patient}
                              screening={screening}
                              test={latestTest}
                            />
                          </div>
                        }
                        expanded={expanded}
                        expandedSummary={
                          <div className={classes.summary}>
                            <Typography>
                              {`${getFullName(patient)} (${genderShortName(patient.gender)})`}
                              <span>
                                <Separator />
                                {`${distanceInYears(parseDOB(patient.dob))} yo`}
                              </span>
                            </Typography>
                            <DelayedFade in={expanded}>
                              <Button
                                className={classes.buttonChart}
                                color="primary"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleResetChartScrollHistory();
                                  history.push({
                                    expansionsChart: [screening.type],
                                    patientId: patient.id,
                                    tabIndex: 1,
                                  });
                                }}
                                variant="outlined"
                              >
                                Go to chart
                              </Button>
                            </DelayedFade>
                          </div>
                        }
                        handleClick={handleExpandClick(id)}
                        handleExpandClick={handleExpandClick(id)}
                        summary={
                          <div className={classes.summary}>
                            <div className={classes.flex}>
                              <Typography className={classes.date} noWrap>
                                {formatDateUiText(latestTest.date)}
                              </Typography>
                              <Typography noWrap>{getFullName(patient)}</Typography>
                            </div>
                            <div className={classes.result}>
                              <TestValue screening={screening} test={latestTest} variant="double-line" />
                            </div>
                          </div>
                        }
                      />
                    </Collapse>
                  </div>
                );
              })}
            </div>
            {handleSeeAllClick && newResultsFiltered.filter((item) => item.id !== newResultToClose.id).length > 6 && (
              <div className={classes.buttonSeeAll}>
                <Button
                  color="primary"
                  className={clsx(classes.button, classes.noHover, classes.seeAll)}
                  onClick={handleSeeAllClick}
                  size="large"
                >
                  See all
                </Button>
              </div>
            )}
          </>
        ) : (
          <Paper className={clsx(classes.empty, classes.flatShadow)}>
            <div className={classes.emptyText}>
              <Typography paragraph>There are no new results to accept</Typography>
              <Typography>You are all caught up!</Typography>
            </div>
          </Paper>
        )}
      </div>
    );
  }
}

NewResults.propTypes = {
  addWaitNotifier: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  expansions: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleAcceptClick: PropTypes.func.isRequired,
  handleExpandClick: PropTypes.func.isRequired,
  handleResetChartScrollHistory: PropTypes.func.isRequired,
  handleSeeAllClick: PropTypes.func,
  newResults: PropTypes.arrayOf(PropTypes.object).isRequired,
};

NewResults.defaultProps = {
  handleSeeAllClick: undefined,
};

export default withStyles(styles)(NewResults);
