import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const styles = (theme) => ({
  separator: {
    fontWeight: theme.typography.fontWeightRegular,
    margin: theme.spacing(0, `${theme.typography.round(6 / 16)}em`),
  },
});

function Separator(props) {
  const { classes, className: classNameProp } = props;

  return <span className={clsx(classes.separator, classNameProp)}>•</span>;
}

Separator.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

Separator.defaultProps = {
  className: undefined,
};
export default withStyles(styles)(Separator);
