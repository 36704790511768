import add from 'date-fns/add';
import startOfDay from 'date-fns/startOfDay';
import { historyItem as historyItemUtils, historyItemValue } from './chartHistoryUtils';
import { startOfToday } from './date';
import { getAge } from './patientUtils';
import { newRecommendationAgingIn, newRecommendationReferral, newRecommendationScheduleTest } from './recommendation';
import { dueOptionsAddNowIfNeeded } from './screeningUtils';
import { capitalize } from './string';

export function testTypeNameProstate(type) {
  if (type === 'psa') return 'PSA test';
  if (type === 'dischargeLetter') return 'discharge letter';
  return type;
}

export function testReleaseTypeProstate(type) {
  if (type === 'psa') return 'requisition';
  if (type === 'dischargeLetter') return 'other';
  return null;
}

function valueAdjusted(test) {
  if (
    test &&
    test.extras &&
    test.extras.unadjusted &&
    (test.extras.unadjusted !== 'string' || test.extras.unadjusted.length !== 0)
  ) {
    return test.extras.dutasteride || test.extras.finasteride ? 2.0 * test.extras.unadjusted : test.extras.unadjusted;
  }
  return null;
}

function valueAdjustedName(test) {
  const value = valueAdjusted(test);
  return value && `${Number(value).toFixed(1)} ng/mL`;
}

export const testValueNameProstate = (test) => test.value;

export const testUnadjustedValueNameProstate = (test) =>
  test.extras.unadjusted && `${Number(test.extras.unadjusted).toFixed(1)} ng/mL`;

export const menuOptionsTestTypeProstate = () => [
  [{ name: capitalize(testTypeNameProstate('psa')), value: 'psa' }],
  [{ name: capitalize(testTypeNameProstate('dischargeLetter')), value: 'dischargeLetter' }],
];

export const getConsultWindowProstate = (nextTestType) => (nextTestType === 'psa' ? { days: 30 } : null);

export const handleTestEditSaveClickProstate = (test) =>
  !test || !test.type || test.type !== 'psa'
    ? test
    : { ...test, extras: { ...test.extras, unadjusted: Number(test.extras.unadjusted) } };

//
// Medical history items
//

export const historyItemsProstate = [
  { type: 'personalHistory', field: 'cancer', name: 'Diagnosed prostate cancer', questionnaire: true, showChart: true },
  {
    type: 'personalHistory',
    field: 'dutasteride',
    name: 'Medication dutasteride',
    questionnaire: true,
    showChart: true,
  },
  {
    type: 'personalHistory',
    field: 'finasteride',
    name: 'Medication finasteride',
    questionnaire: true,
    showChart: true,
  },
];

const historyItem = (field) => historyItemUtils(field, historyItemsProstate);

//
// Helpers
//

export function newDefaultTestProstate(screening, patient, isReferral) {
  if (isReferral) {
    return {
      date: null,
      type: 'dischargeLetter',
    };
  }

  return {
    date: null,
    type: 'psa',
    value: null,
    extras: {
      dutasteride: historyItemValue(historyItem('dutasteride'), screening, patient).value,
      finasteride: historyItemValue(historyItem('finasteride'), screening, patient).value,
      unadjusted: null,
    },
  };
}

// Enforce PSA test result range 0-9999.9
const inputMaskPsa = /^[0-9]{1,4}(|\.|\.[0-9])$/;

export function handleTestEditChangeProstate(key, newValue, newPatient, test, screening, patient) {
  // Check PSA value range 0-9999.9 and convert to numeric type
  if (key === 'value' && test.type === 'psa') {
    if (typeof newValue === 'string' && newValue.length !== 0 && !inputMaskPsa.test(newValue)) {
      // Signal to reject change
      return null;
    }
    // User is entering the unadjusted value
    const newTest = { ...test, extras: { ...test.extras, unadjusted: newValue } };
    return { ...newTest, value: valueAdjustedName(newTest) };
  }
  // Extras
  if (['dutasteride', 'finasteride'].includes(key)) {
    // Conditionally re-calculate adjusted value
    const newTest = { ...test, extras: { ...test.extras, [key]: newValue } };
    return { ...newTest, value: newTest.value && valueAdjustedName(newTest) };
  }
  // Remove value and test extras
  if (key === 'type' && newValue === 'dischargeLetter') {
    const { value, extras, ...other } = test;
    return { ...other, [key]: newValue };
  }
  // Reset to psa defaults
  if (key === 'type' && newValue === 'psa') {
    return newDefaultTestProstate(newPatient, screening, patient);
  }
  return { ...test, [key]: newValue };
}

//
// Screening rules
//

const screeningMinAgeProstate = 50;
export const screeningMaxAgeProstate = 69;

export function getScreeningVeryHighRiskProstate(screening, patient) {
  const indicators = new Set();
  let value = false;

  // Diagnosed prostate cancer

  const cancer = historyItemValue(historyItem('cancer'), screening, patient);
  if (cancer.value) {
    indicators.add(cancer.name);
    value = true;
  }

  return {
    indicators: [...indicators],
    value,
  };
}

export function screeningRisksProstate(screening, patient) {
  return {
    veryHighRisk: getScreeningVeryHighRiskProstate(screening, patient).value,
  };
}

// Repeating PSA test within 60 days of prior test automatically releases
// requisition without consult. Repeat PSA test is the only PSA offered as
// not optional.
export const autoReleaseRequisitionProstate = (recommended) =>
  recommended.nextTestType() === 'psa' && !recommended.nextTestOptional();

//
// Recommendation
//

const defaultDueOptions = (latestTest) =>
  latestTest
    ? [
        { name: '2 years from test', duration: { months: 24 } },
        { name: '1 year from test', duration: { months: 12 } },
        { name: '6 weeks from test', duration: { weeks: 6 } },
      ]
    : [];

export function screeningNextTestDueOptionsProstate(recommendation, latestTest) {
  if (recommendation.type === 'psa') {
    if (!recommendation.agedIn) {
      const minAge = screeningMinAgeProstate;
      return [{ name: `age ${minAge}`, duration: { months: minAge * 12 } }];
    }
    return dueOptionsAddNowIfNeeded(recommendation, defaultDueOptions(latestTest));
  }
  return [];
}

// Repeat PSA test within 60 days of prior test:
//
// Note: Checking the unexpected scenario that more than 60 days
// since PSA test date (date sample taken)
const isRepeatPsa = (duration, fromDate) => {
  if (duration && fromDate) {
    const sixtyDays = add(startOfDay(fromDate), { days: 60 });
    if (add(startOfDay(fromDate), duration) <= sixtyDays) {
      return startOfToday() <= sixtyDays;
    }
  }
  return false;
};

export function screeningRecommendationProstate(latestTest, patient) {
  const { date, type } = latestTest || {};

  const age = getAge(patient, date);
  const minAge = screeningMinAgeProstate;

  if (latestTest) {
    if (type === 'dischargeLetter') {
      return newRecommendationScheduleTest({ months: 12 }, 'psa', date, true);
    }

    const value = valueAdjusted(latestTest);

    // Of age or tested younger than age and not normal result
    if (age >= minAge || value >= 2.5) {
      if (value < 2.5) {
        return newRecommendationScheduleTest({ months: 24 }, 'psa', date, true);
      }
      if (value < 4.0) {
        return newRecommendationScheduleTest({ months: 12 }, 'psa', date, true);
      }
      if (value < 7.0) {
        // Repeat PSA test within 60 days of prior test:
        // (1) Auto releases requisition
        // (2) Repeat PSA is not optional
        const duration = { weeks: 6 };
        return newRecommendationScheduleTest(duration, 'psa', date, !isRepeatPsa(duration, date));
      }
      return newRecommendationReferral();
    }
  }

  if (minAge <= age) {
    return newRecommendationScheduleTest({ months: 0 }, 'psa', date, true);
  }

  // Not agedIn
  return newRecommendationAgingIn({ months: minAge * 12 }, 'psa', patient, true);
}

export const screeningPhysicianRecommendationProstate = (newPhysicianRecommendation) => ({
  ...newPhysicianRecommendation,
  optional: !isRepeatPsa(newPhysicianRecommendation.duration, newPhysicianRecommendation.fromDate),
});

export const callPatientProstate = (recommendation, latestTest) =>
  recommendation.referral || (latestTest && latestTest.value >= 4.0) || false;

export const referPatientProstate = (recommendation) => recommendation.referral;
