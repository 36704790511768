import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { primary60 } from '../constants/colors';
import LogoStackedIcon from '../icons/LogoStacked';
import MedicalServicesIcon from '../icons/MedicalServicesOutlined';
import { apiFamilyDoctorSearch } from '../utils/api';
import cancelable from '../utils/cancelable';

const styles = (theme) => ({
  container: {
    width: '100%',
  },
  hidden: {
    visibility: 'hidden',
  },
  inputAdornment: {
    padding: theme.spacing(1.5),
  },
  list: {
    padding: theme.spacing(1, 0),
  },
  listIcon: {
    minWidth: theme.spacing(3),
    paddingLeft: theme.spacing(2),
  },
  listItem: {
    justifyContent: 'space-between',
    padding: theme.spacing(0.75, 2),
  },
  logo: {
    margin: theme.spacing(0, 1),
  },
  paper: {
    borderRadius: 4,
    width: 468,
    padding: theme.spacing(0.25, 1),
    position: 'fixed',
    zIndex: theme.zIndex.appBar + 1,
  },
  result: {
    overflow: 'hidden',
  },
  title: {
    alignItems: 'center',
    display: 'flex',
  },
  underline: {
    '&:after': {
      borderBottomColor: primary60,
    },
    '&&:hover::before': {
      borderBottomColor: primary60,
    },
  },
});

class SearchFamilyDoctor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      results: [],
    };

    this.backdropClick = false;
    this.cancelable = undefined;

    this.inputRef = createRef();

    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFamilyDoctorChange = this.handleFamilyDoctorChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.search = this.search.bind(this);
  }

  handleBlur() {
    const { familyDoctor } = this.props;

    if (!this.backdropClick) {
      if (!familyDoctor) {
        this.setState({ query: '', results: [] });
      } else {
        this.setState({ results: [] });
      }
    }
  }

  handleChange(event) {
    const { handleFamilyDoctorChange: handleFamilyDoctorChangeProp, familyDoctor } = this.props;

    const { value } = event.target;

    if (familyDoctor) {
      handleFamilyDoctorChangeProp(null);
    }

    this.search(value);
  }

  handleFamilyDoctorChange(familyDoctor) {
    const { handleFamilyDoctorChange: handleFamilyDoctorChangeProp } = this.props;
    handleFamilyDoctorChangeProp(familyDoctor);
    this.setState({ results: [] });
    this.inputRef.current.focus();
  }

  handleFocus() {
    const { query } = this.state;

    if (!this.backdropClick) {
      if (query.length !== 0) {
        this.search(query);
      }
    }

    this.backdropClick = false;
  }

  handleKeyDown(event) {
    if (['Escape', 'Esc'].includes(event.key)) {
      event.preventDefault();
      this.setState({ results: [] });
    }
  }

  handleMouseDown() {
    this.backdropClick = true;
  }

  search(query) {
    this.setState({ query });
    if (this.cancelable) this.cancelable.cancel();
    this.cancelable = cancelable(apiFamilyDoctorSearch(query, true));
    this.cancelable.promise.then((results) => this.setState({ results })).catch(() => {});
  }

  render() {
    const { classes, familyDoctor } = this.props;
    const { query, results } = this.state;

    return (
      <div className={classes.container}>
        <FormControl fullWidth>
          <InputLabel>Ordering physician</InputLabel>
          <Input
            className={classes.underline}
            endAdornment={
              <InputAdornment className={classes.inputAdornment} position="end">
                {familyDoctor && familyDoctor.chekHealth && <LogoStackedIcon className={classes.logo} />}
                <MedicalServicesIcon />
              </InputAdornment>
            }
            inputProps={{ spellCheck: false }}
            inputRef={this.inputRef}
            onBlur={this.handleBlur}
            onChange={(event) => this.handleChange(event)}
            onFocus={this.handleFocus}
            onKeyDown={this.handleKeyDown}
            value={familyDoctor ? familyDoctor.name : query}
          />
        </FormControl>
        {results.length !== 0 && (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <Paper className={classes.paper} onMouseDown={this.handleMouseDown} role="document">
            <div className={classes.list}>
              {results.slice(0, 5).map((item, index) => (
                <ListItem
                  button
                  className={classes.listItem}
                  disableGutters
                  disableRipple
                  key={`${item.name}-${index.toString()}`}
                  onClick={() => this.handleFamilyDoctorChange(item)}
                >
                  <div className={classes.result}>
                    <Typography noWrap>{item.name}</Typography>
                    <Typography color="textSecondary" noWrap variant="body2">
                      {item.address}
                    </Typography>
                  </div>
                  <ListItemIcon className={clsx(classes.listIcon, !item.chekHealth && classes.hidden)}>
                    <LogoStackedIcon />
                  </ListItemIcon>
                </ListItem>
              ))}
            </div>
          </Paper>
        )}
      </div>
    );
  }
}

SearchFamilyDoctor.propTypes = {
  classes: PropTypes.object.isRequired,
  familyDoctor: PropTypes.object,
  handleFamilyDoctorChange: PropTypes.func.isRequired,
};

SearchFamilyDoctor.defaultProps = {
  familyDoctor: null,
};
export default withStyles(styles)(SearchFamilyDoctor);
