import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Button from '../components/Button';
import SummaryRow from '../components/SummaryRow';
import { cancerColor, neutral60 } from '../constants/colors';
import { flatShadow, noHover } from '../constants/styles';
import history from '../utils/history';
import { getFullName } from '../utils/patient';

const styles = (theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3.75),
  },
  buttonSeeAll: {
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 1),
  },
  empty: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: 354,
  },
  emptyText: {
    color: neutral60,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 94,
  },
  flatShadow,
  list: {
    minHeight: 354,
    '& > :first-child': {
      marginTop: 0,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
    '& > :last-child': {
      marginBottom: 0,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
  },
  noHover,
  seeAll: {
    marginTop: theme.spacing(1.5),
  },
});

function ChartUpdates(props) {
  const { classes, chartUpdates, handleResetChartScrollHistory, handleSeeAllClick } = props;

  const count = handleSeeAllClick ? 6 : chartUpdates.length;

  return (
    <div className={classes.container}>
      {chartUpdates.length !== 0 ? (
        <>
          <div className={classes.list}>
            {chartUpdates.slice(0, count).map((item) => (
              <SummaryRow
                barColor={cancerColor(item.screeningType)}
                date={item.date}
                description={item.description}
                fullName={getFullName(item.patient)}
                handleClick={() => {
                  const identityVerified = item.patient.identity === 'verified';
                  handleResetChartScrollHistory();
                  history.push({
                    expansionsChart: [item.screeningType],
                    patientId: item.patient.id,
                    tabIndex: identityVerified ? 1 : 0,
                  });
                }}
                key={`${item.date}-${item.patient.id}-${item.description}`}
              />
            ))}
          </div>
          {handleSeeAllClick && chartUpdates.length > 6 && (
            <div className={classes.buttonSeeAll}>
              <Button
                color="primary"
                className={clsx(classes.button, classes.noHover, classes.seeAll)}
                onClick={handleSeeAllClick}
                size="large"
              >
                See all
              </Button>
            </div>
          )}
        </>
      ) : (
        <Paper className={clsx(classes.empty, classes.flatShadow)}>
          <div className={classes.emptyText}>
            <Typography paragraph>There are no charts to update</Typography>
            <Typography>You are all caught up!</Typography>
          </div>
        </Paper>
      )}
    </div>
  );
}

ChartUpdates.propTypes = {
  classes: PropTypes.object.isRequired,
  chartUpdates: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleResetChartScrollHistory: PropTypes.func.isRequired,
  handleSeeAllClick: PropTypes.func,
};

ChartUpdates.defaultProps = {
  handleSeeAllClick: undefined,
};

export default withStyles(styles)(ChartUpdates);
