import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { neutral60 } from '../constants/colors';
import { flatShadow } from '../constants/styles';
import { fontFamily1 } from '../constants/typography';
import { apiDeleteChartNote } from '../utils/api';
import { getViewNotes } from '../utils/chartNotes';
import history from '../utils/history';
import ChartNote from './ChartNote';
import { tabTitleSpacing } from './styles';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 0, 4, 3),
  },
  empty: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: 354,
  },
  emptyText: {
    color: neutral60,
    fontFamily: fontFamily1,
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: `${theme.typography.round(0.25 / 16)}em`,
    marginTop: 94,
  },
  flatShadow,
  titleSpacing: {
    ...tabTitleSpacing(theme),
  },
});

function ChartChartNotes(props) {
  const { billings, classes, patient, user } = props;

  const { tabContext } = history.getLocationState();

  useEffect(() => {
    if (tabContext) {
      history.update({ tabContext: null });
    }
  }, [tabContext]);

  // Note: head of view notes is most recent
  const viewNotes = getViewNotes(patient, billings);

  return (
    <>
      <div className={classes.titleSpacing} />
      <Divider />
      <div className={classes.container}>
        {viewNotes.length !== 0 ? (
          viewNotes.map((viewNote, index) => (
            <ChartNote
              handleDeleteNoteClick={(noteId) => apiDeleteChartNote(noteId, patient)}
              key={viewNote.id}
              openBilling={tabContext === 'openBilling' && index === 0}
              patient={patient}
              user={user}
              viewNote={viewNote}
            />
          ))
        ) : (
          <div className={classes.empty}>
            <Typography className={classes.emptyText}>Empty chart notes</Typography>
          </div>
        )}
      </div>
    </>
  );
}

ChartChartNotes.propTypes = {
  billings: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.object.isRequired,
  patient: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChartChartNotes);
