export function capitalize(string) {
  if (string) return string.charAt(0).toUpperCase() + string.slice(1);
  return string;
}

export function uncapitalize(string) {
  if (string) return string.charAt(0).toLowerCase() + string.slice(1);
  return string;
}

export const removeLineBreaks = (string) => string.replace(/(\r\n|\n|\r)/gm, '');
