import MuiCheckbox from '@material-ui/core/Checkbox';
import { ThemeProvider } from '@material-ui/styles';
import { colorTheme } from '../constants/theme';

function Checkbox(props) {
  const { ...other } = props;

  return (
    <ThemeProvider theme={colorTheme}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <MuiCheckbox size="small" {...other} />
    </ThemeProvider>
  );
}

export default Checkbox;
