import { withStyles } from '@material-ui/core/styles';
import MuiTextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

const styles = (theme) => ({
  underline: {
    lineHeight: theme.typography.pxToRem(20),
    '&:after': {
      borderBottomColor: theme.palette.text.primary,
    },
  },
});

function MultilineTextField(props) {
  const { classes, InputProps, ...other } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiTextField InputProps={{ classes: { underline: classes.underline }, ...InputProps }} multiline {...other} />
  );
}

MultilineTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  InputProps: PropTypes.object,
};

MultilineTextField.defaultProps = {
  InputProps: undefined,
};

export default withStyles(styles)(MultilineTextField);
