import MuiRadio from '@material-ui/core/Radio';
import { ThemeProvider } from '@material-ui/styles';
import { colorTheme } from '../constants/theme';

function Radio(props) {
  const { ...other } = props;

  return (
    <ThemeProvider theme={colorTheme}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <MuiRadio {...other} />
    </ThemeProvider>
  );
}

export default Radio;
