const options = ['development', 'test', 'production'];

export default function env() {
  if (process.env.REACT_APP_ENV) {
    if (options.includes(process.env.REACT_APP_ENV)) {
      return process.env.REACT_APP_ENV;
    }
  }
  return process.env.NODE_ENV;
}

export const isProdEnv = () => env() === 'production';

export const envShortName = () => (env() === 'development' ? 'dev' : env().slice(0, 4));
