import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { surface } from '../constants/colors';
import { fontFamily2 } from '../constants/typography';
import Button from './Button';
import Checkbox from './Checkbox';
import Popover from './Popover';
import PopoverTitle from './PopoverTitle';
import Radio from './Radio';

const styles = (theme) => ({
  checkbox: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.5),
    marginLeft: -1.5,
    marginTop: theme.spacing(1.5),
  },
  checkboxLabel: {
    color: theme.palette.text.primary,
    fontFamily: fontFamily2,
    fontWeight: theme.typography.fontWeightMedium,
  },
  popoverActions: {
    padding: theme.spacing(1, 2, 0.5, 2),
  },
  popoverItem: {
    padding: theme.spacing(0, 2),
    marginLeft: 0,
  },
  popoverLabel: {
    paddingLeft: theme.spacing(1),
  },
  popoverPaper: {
    backgroundColor: surface,
    minWidth: 320,
    padding: theme.spacing(0.5, 0),
  },
  radio: {
    padding: theme.spacing(1.5),
  },
});

function SetTestType(props) {
  const { anchorEl, classes, handleClose, handleSetTestTypeChanged, open, type, typeOptions } = props;

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classNamePaper={classes.popoverPaper}
      handleClose={handleClose}
      open={open}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <PopoverTitle handleClose={handleClose} title="Next test type" />
      {
        // ** Special: breast mammogram
        ['mammogram', 'mammogramAwbu'].includes(type) ? (
          <div className={classes.popoverItem}>
            <Typography className={classes.popoverLabel}>Mammogram (recommended)</Typography>
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={type === 'mammogramAwbu'}
                  color="primary"
                  onChange={() => handleSetTestTypeChanged(type === 'mammogramAwbu' ? 'mammogram' : 'mammogramAwbu')}
                  size="small"
                />
              }
              label={
                <Typography className={classes.checkboxLabel} variant="body2">
                  Include automated whole breast ultrasound (AWBU)
                </Typography>
              }
            />
          </div>
        ) : (
          <FormControl component="fieldset">
            <RadioGroup
              name="test type"
              value={type}
              onChange={(event) => handleSetTestTypeChanged(event.target.value)}
            >
              {typeOptions.map((option) => (
                <FormControlLabel
                  className={classes.popoverItem}
                  control={<Radio className={classes.radio} color="primary" size="small" />}
                  key={option.value}
                  label={
                    <Typography className={classes.popoverLabel}>
                      {option.name.concat(option.recommended ? ' (recommended)' : '')}
                    </Typography>
                  }
                  value={option.value}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )
      }
      <DialogActions className={classes.popoverActions}>
        <Button color="primary" onClick={handleClose}>
          Done
        </Button>
      </DialogActions>
    </Popover>
  );
}

SetTestType.propTypes = {
  anchorEl: PropTypes.object,
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSetTestTypeChanged: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  typeOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

SetTestType.defaultProps = {
  anchorEl: null,
};

export default withStyles(styles)(SetTestType);
