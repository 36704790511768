import format from 'date-fns/format';
import isEqual from 'date-fns/isEqual';
import isSameDay from 'date-fns/isSameDay';
import startOfDay from 'date-fns/startOfDay';
import { capitalize } from './string';
import { versionNoSupportBillingActivityCode, versionSupportBillingActivityCode } from './version';

export const billingEntryId = (date, patient) => `${format(startOfDay(date), 'yyyy-MM-dd')} ${patient.id}`;

export const billingActivityTitle = 'billing code';

export const billingCodeName = (code) => (code === '03.03CV CMGP01' ? '03.03CV • CMGP01' : code);

const defaultCode = '03.01AD';

// Activity date synced to date of chart note
export const newActivity = (description, note) => ({ code: defaultCode, date: note.createdDate, description });

export const menuOptionsBillingCode = [defaultCode, '03.03CV', '03.03CV CMGP01'].map((value) => ({
  name: billingCodeName(value),
  value,
}));

// For use by export to represent code as code and modifiers
export const toBillingCodeAndModifiers = (code) => {
  if (typeof code === 'string') {
    return code === '03.03CV CMGP01' ? { code: '03.03CV', modifiers: 'CMGP01' } : { code, modifiers: null };
  }
  return code;
};

export const snackbarTextBilling = (activity) =>
  `${capitalize(billingActivityTitle)} ${billingCodeName(activity.code)}`;

// Look for billing on day of given activity date or null if no match
// Note: Only one billing doc per day, per patient, but multiple billing activity
export function findBilling(activityDate, billings) {
  // Only consider billings of same day (billing docs dated start of day)
  const billingsOfDay = billings.filter((billing) => isSameDay(billing.date, activityDate));

  // Look for billing activity matching given activity date
  // Note: Older original version of billing documents do not have
  // activity date (or code) and not checked at this point.
  const found = billingsOfDay
    .filter((billing) => versionSupportBillingActivityCode(billing))
    .reduce(
      (accumulator, billing) =>
        !accumulator && billing.activity.find((activity) => isEqual(activity.date, activityDate))
          ? billing
          : accumulator,
      null,
    );

  if (found !== null) {
    return found;
  }

  // Did not find billing with activity with given date. Look for billing
  // in old document format of the same day as given activity date.
  return billingsOfDay
    .filter((billing) => versionNoSupportBillingActivityCode(billing))
    .reduce((accumulator, billing) => accumulator || billing, null);
}

// Resolve overall billing code given all activity of the day
export function getBillingCodeOfDay(billing) {
  const { activity = [] } = billing;
  return activity.reduce(
    // When more than one, take higher worth code (can just use string compare)
    // Note: Works with old format billing docs
    (accumulator, { code = defaultCode }) => (code > accumulator ? code : accumulator),
    defaultCode,
  );
}
