import isEqual from 'date-fns/isEqual';
import {
  EmailAuthProvider,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  fetchSignInMethodsForEmail,
  getAuth,
  multiFactor,
  onAuthStateChanged,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
} from 'firebase/auth';
import {
  FirestoreError,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { billingEntryId } from './billing';
import cancelable from './cancelable';
import { chartNoteId } from './chartNotes';
import { startOfToday } from './date';
import { multiFactorHint } from './firebase';
import { getFullName } from './patient';
import { compareStartOfDayDateThenFullName } from './sort';
import { versionNoSupportBillingActivityCode } from './version';

class Session {
  constructor() {
    this.user = undefined;
    this.userAccount = undefined;

    this.handleUserChanged = undefined;

    this.unsubscribeFromChartUpdatesSnapshot = undefined;
    this.unsubscribeFromNewResultsSnapshot = undefined;
    this.unsubscribeFromBillingsSnapshot = undefined;
    this.unsubscribeFromPatientSnapshot = undefined;
    this.unsubscribeFromUserSnapshot = undefined;
  }
}

const session = new Session();

//
// User
//

function subscribeToUserChanges() {
  if (session.unsubscribeFromUserSnapshot) {
    session.unsubscribeFromUserSnapshot();
    session.unsubscribeFromUserSnapshot = undefined;
  }

  if (session.user && session.handleUserChanged) {
    const db = getFirestore();
    session.unsubscribeFromUserSnapshot = onSnapshot(doc(db, 'users', session.user.id), (snapshot) => {
      if (snapshot.data() === undefined) {
        session.handleUserChanged(null);
      } else {
        const { fullName, roles } = snapshot.data();
        const { email, id } = session.user;
        session.user = {
          email,
          fullName,
          id,
          role: roles[0],
        };
        session.handleUserChanged(session.user);
      }
    });
  } else if (session.handleUserChanged) {
    session.handleUserChanged(session.user);
  }
}

export function dbGetUser(userId) {
  const db = getFirestore();
  return getDoc(doc(db, 'users', userId)).then((snapshot) =>
    snapshot.exists() ? snapshot.data() : Promise.reject(new Error(`Couldn't fine user: ${userId}`)),
  );
}

export const dbGetSessionUser = () => session.user && { ...session.user };

export function dbOnUserChanged(handleUserChanged) {
  session.handleUserChanged = handleUserChanged;
  subscribeToUserChanges();
}

export function dbIsUserPhysician() {
  return session.user && session.user.role === 'physician';
}

export function dbUpateUserFullName(fullName) {
  const db = getFirestore();
  return updateDoc(doc(db, 'users', session.user.id), { fullName });
}

export function dbUpdateUserPassword(passwordNew, passwordCurrent) {
  if (passwordCurrent) {
    return reauthenticateWithCredential(
      session.userAccount,
      EmailAuthProvider.credential(session.userAccount.email, passwordCurrent),
    ).then(() => updatePassword(session.userAccount, passwordNew));
  }
  return updatePassword(session.userAccount, passwordNew);
}

export function dbUserMultiFactorEnrolledFactors() {
  return multiFactor(session.userAccount).enrolledFactors;
}

function newPatientWithOutPhysician(snapshot) {
  // Reloading firestore snapshot.data() goes undefined
  if (!snapshot.exists()) {
    return null;
  }

  const {
    cancer = {},
    chartNotes = [],
    physicianId = null,
    screenings: screeningsV0 = [],
    screenings_v1: screeningsV1 = {},
    nextNotify = null,
    ...patient
  } = snapshot.data();

  const screenings = screeningsV0.concat(Object.keys(screeningsV1).map((item) => screeningsV1[item]));

  const newCancer = { ...cancer };

  // Cancer screening referral dates
  Object.keys(newCancer).forEach((key) => {
    if (newCancer[key].referral !== undefined) {
      Object.keys(newCancer[key].referral).forEach((key2) => {
        newCancer[key].referral[key2] = newCancer[key].referral[key2] && newCancer[key].referral[key2].toDate();
      });
    }
  });

  return {
    ...patient,
    id: snapshot.id,
    nextNotify: nextNotify && nextNotify.toDate(),
    physicianId,
    cancer: newCancer,
    screenings: screenings.map((item) => {
      const { nextTest: screeningNextTest, statusDate, tests: screeningTests, ...screening } = item;

      // See: firestore transform comments in screenings

      // Note: consultRequested & requisitionDownloaded mastered in firestore
      // under screenings array as that location updated by mobile (and cloud)
      const { consultRequested, requisitionDownloaded, ...cancerNextTest } =
        (newCancer[screening.type] && newCancer[screening.type].nextTest) || {};

      const cancerTests = newCancer[screening.type] && newCancer[screening.type].tests;

      const newNextTest = {
        ...screeningNextTest,
        ...cancerNextTest,
      };

      return {
        ...screening,
        nextTest: {
          ...newNextTest,
          due: newNextTest.due && newNextTest.due.toDate(),
          consultRequested: newNextTest.consultRequested && newNextTest.consultRequested.toDate(),
          chartUpdated: newNextTest.chartUpdated && newNextTest.chartUpdated.toDate(),
          requisitionReleased: newNextTest.requisitionReleased && newNextTest.requisitionReleased.toDate(),
          requisitionDownloaded: newNextTest.requisitionDownloaded && newNextTest.requisitionDownloaded.toDate(),
        },
        statusDate: statusDate.toDate(),
        tests: (cancerTests || screeningTests).map((value) => {
          const { date, ...test } = value;
          return { ...test, date: date.toDate() };
        }),
      };
    }),
    chartNotes: chartNotes.map((item) => {
      const { createdDate, ...note } = item;
      return {
        ...note,
        createdDate: createdDate.toDate(),
        attachments: note.attachments.map((value) => {
          const { createdDate: createdDate2, ...attachment } = value;
          return { ...attachment, createdDate: createdDate2.toDate() };
        }),
      };
    }),
  };
}

function newPatient(snapshot) {
  const patient = newPatientWithOutPhysician(snapshot);

  if (patient === null) {
    return patient;
  }

  // Include additional physician information from users collection
  // if have physicianId (expected)
  return patient.physicianId === null
    ? patient
    : dbGetUser(patient.physicianId)
        .then((user) => {
          const { fullName, familyDoctor } = user;
          return {
            ...patient,
            physician: { fullName, familyDoctor },
          };
        })
        .catch(() => patient);
}

export function dbOnPatientChanged(patientId, handlePatientChanged) {
  if (session.unsubscribeFromPatientSnapshot) {
    session.unsubscribeFromPatientSnapshot();
    session.unsubscribeFromPatientSnapshot = undefined;
  }

  if (handlePatientChanged) {
    const db = getFirestore();
    let snapshotCancelable = cancelable(Promise.resolve());
    session.unsubscribeFromPatientSnapshot = onSnapshot(
      doc(db, 'patients', patientId),
      (snapshot) => {
        snapshotCancelable.cancel();
        snapshotCancelable = cancelable(newPatient(snapshot));
        snapshotCancelable.promise
          .then((patient) => {
            handlePatientChanged(patient);
          })
          .catch(() => {});
      },
      (error) => {
        if (error.code === FirestoreError.FirestoreErrorCode.NOT_FOUND) {
          handlePatientChanged(null);
        }
      },
    );
  }
}

export function dbUpdateIdentity(identity, identityCheck, nextNotify, patientId) {
  const db = getFirestore();
  return updateDoc(doc(db, 'patients', patientId), { identity, identityCheck, nextNotify });
}

//
// Billing
//

function newBilling(snapshot) {
  // Original old document format
  if (versionNoSupportBillingActivityCode(snapshot.data())) {
    return { ...snapshot.data(), date: snapshot.data().date.toDate() };
  }

  const { activity, date, ...billing } = snapshot.data();
  return {
    ...billing,
    date: date.toDate(),
    activity: activity.map(({ date: activityDate, ...other }) => ({ ...other, date: activityDate.toDate() })),
  };
}

export function dbOnBillingsChanged(patientId, handleBillingsChanged) {
  if (session.unsubscribeFromBillingsSnapshot) {
    session.unsubscribeFromBillingsSnapshot();
    session.unsubscribeFromBillingsSnapshot = undefined;
  }

  if (handleBillingsChanged) {
    session.unsubscribeFromBillingsSnapshot = onSnapshot(
      query(collection(getFirestore(), 'billing'), where('patientId', '==', patientId)),
      (snapshot) => {
        handleBillingsChanged(snapshot.docs.map((item) => newBilling(item)));
      },
      (error) => {
        if (error.code === FirestoreError.FirestoreErrorCode.NOT_FOUND) {
          handleBillingsChanged();
        }
      },
    );
  }
}

export function dbSetBillingEntry(activity, patient) {
  const db = getFirestore();
  const date = startOfToday();
  const docRef = doc(db, 'billing', billingEntryId(date, patient));

  return setDoc(
    docRef,
    {
      date,
      dob: patient.dob,
      gender: patient.gender,
      patientId: patient.id,
      patientName: getFullName(patient),
      phn: patient.phn,
      physicianId: session.user.id,
      activity: arrayUnion(activity),
    },
    { merge: true },
  );
}

export function dbUpdateBillingEntry(newCode, activityDate, billingId) {
  const db = getFirestore();
  const docRef = doc(db, 'billing', billingId);

  return getDoc(docRef).then((snapshot) => {
    const billing = newBilling(snapshot);
    // Upgrades all activities of old document format
    if (versionNoSupportBillingActivityCode(billing)) {
      return updateDoc(docRef, {
        activity: billing.activity.map((item) => ({ code: newCode, date: activityDate, description: item })),
      });
    }
    return updateDoc(docRef, {
      activity: billing.activity.map((item) => (isEqual(item.date, activityDate) ? { ...item, code: newCode } : item)),
    });
  });
}

export function dbGetBillings(interval) {
  const db = getFirestore();

  return getDocs(
    query(
      collection(db, 'billing'),
      where('date', '>', interval.start),
      where('date', '<=', interval.end),
      orderBy('date'),
    ),
  ).then((snapshot) => snapshot.docs.map((item) => newBilling(item)));
}

//
// Patient screenings
//

export function dbUpdateScreening(screening, cancer, chartNotes, nextNotify, patientId) {
  const db = getFirestore();
  const docRef = doc(db, 'patients', patientId);

  return getDoc(docRef).then((snapshot) => {
    const { screenings_v1: screeningsV1 = {} } = snapshot.data();

    if (Object.hasOwn(screeningsV1, screening.type)) {
      return updateDoc(docRef, {
        cancer,
        chartNotes,
        nextNotify,
        screenings_v1: {
          ...snapshot.data().screenings_v1,
          [screening.type]: {
            ...screening,
          },
        },
      });
    }

    return updateDoc(docRef, {
      cancer,
      chartNotes,
      nextNotify,
      screenings: snapshot.data().screenings.map((item) => (item.type === screening.type ? screening : item)),
    });
  });
}

//
// Patient chart notes
//

export function dbAddChartNote(note, patientId) {
  const db = getFirestore();
  const docRef = doc(db, 'patients', patientId);

  return getDoc(docRef).then((snapshot) =>
    updateDoc(docRef, {
      chartNotes: snapshot.data().chartNotes.concat([note]),
    }),
  );
}

export function dbUpdateChartNote(note, patientId) {
  const db = getFirestore();
  const docRef = doc(db, 'patients', patientId);
  const noteId = chartNoteId(note);

  return getDoc(docRef).then((snapshot) =>
    updateDoc(docRef, {
      chartNotes: snapshot.data().chartNotes.map((item) =>
        chartNoteId({
          ...item,
          createdDate: item.createdDate.toDate(),
        }) === noteId
          ? note
          : item,
      ),
    }),
  );
}

export function dbDeleteChartNote(noteId, patientId) {
  const db = getFirestore();
  const docRef = doc(db, 'patients', patientId);

  return getDoc(docRef).then((snapshot) =>
    updateDoc(docRef, {
      chartNotes: snapshot.data().chartNotes.filter(
        (item) =>
          chartNoteId({
            ...item,
            createdDate: item.createdDate.toDate(),
          }) !== noteId,
      ),
    }),
  );
}

//
// Chart updates
//

export function dbOnChartUpdatesChanged(handleChartUpdatesChanged) {
  if (session.unsubscribeFromChartUpdatesSnapshot) {
    session.unsubscribeFromChartUpdatesSnapshot();
    session.unsubscribeFromChartUpdatesSnapshot = undefined;
  }

  if (handleChartUpdatesChanged) {
    session.unsubscribeFromChartUpdatesSnapshot = onSnapshot(
      dbIsUserPhysician()
        ? query(
            collection(getFirestore(), 'patients'),
            where('chartUpdates', '!=', false),
            where('identity', '==', 'verified'),
            where('physicianId', '==', session.user.id),
          )
        : query(
            collection(getFirestore(), 'patients'),
            where('chartUpdates', '!=', false),
            where('identity', 'in', ['submitted', 'verified']),
          ),
      (snapshot) => {
        handleChartUpdatesChanged(snapshot.docs.map((item) => newPatientWithOutPhysician(item)));
      },
    );
  }
}

//
// Chek new results
//

export function dbOnChekNewResultsChanged(handleChekNewResultsChanged) {
  if (session.unsubscribeFromNewResultsSnapshot) {
    session.unsubscribeFromNewResultsSnapshot();
    session.unsubscribeFromNewResultsSnapshot = undefined;
  }

  if (handleChekNewResultsChanged) {
    let snapshotCancelable = cancelable(Promise.resolve());
    const db = getFirestore();
    session.unsubscribeFromNewResultsSnapshot = onSnapshot(collection(db, 'newResults'), (snapshot) => {
      snapshotCancelable.cancel();
      snapshotCancelable = cancelable(
        Promise.all(
          snapshot.docs.map((item) => {
            const { patientId, screening } = item.data();
            const { tests, type } = screening;

            return getDoc(doc(db, 'patients', patientId)).then((snapshot2) => {
              const patient = newPatientWithOutPhysician(snapshot2);
              const { screenings } = patient;

              return {
                date: tests[0].date.toDate(),
                fullName: getFullName(patient),
                id: item.id,
                patient,
                screening: screenings.find((value) => value.type === type),
              };
            });
          }),
        ),
      );
      snapshotCancelable.promise
        .then((newResults) => {
          handleChekNewResultsChanged(newResults.sort(compareStartOfDayDateThenFullName));
        })
        .catch(() => {});
    });
  }
}

//
// Physician new results
//

export function dbOnPhysicianNewResultsChanged(handlePhysicianNewResultsChanged) {
  if (session.unsubscribeFromNewResultsSnapshot) {
    session.unsubscribeFromNewResultsSnapshot();
    session.unsubscribeFromNewResultsSnapshot = undefined;
  }

  if (handlePhysicianNewResultsChanged) {
    let snapshotCancelable = cancelable(Promise.resolve());
    const db = getFirestore();
    session.unsubscribeFromNewResultsSnapshot = onSnapshot(
      query(collection(db, 'newResults'), where('physicianId', '==', session.user.id)),
      (snapshot) => {
        snapshotCancelable.cancel();
        snapshotCancelable = cancelable(
          Promise.all(
            snapshot.docs.map((item) => {
              const { patientId, screening } = item.data();
              const { tests, type } = screening;

              return getDoc(doc(db, 'patients', patientId)).then((snapshot2) => {
                const patient = newPatientWithOutPhysician(snapshot2);
                const { screenings } = patient;

                return {
                  date: tests[0].date.toDate(),
                  fullName: getFullName(patient),
                  id: item.id,
                  patient,
                  screening: screenings.find((value) => value.type === type),
                };
              });
            }),
          ),
        );
        snapshotCancelable.promise
          .then((newResults) => {
            handlePhysicianNewResultsChanged(newResults.sort(compareStartOfDayDateThenFullName));
          })
          .catch(() => {});
      },
    );
  }
}

//
// Search
//

export function dbPatientSearch(searchText, identityVerified) {
  return new Promise((resolve, reject) => {
    if (!searchText) {
      resolve([]);
    }

    const tag = searchText.toLowerCase();

    const db = getFirestore();
    const collectionRef = collection(db, 'patients');

    getDocs(
      identityVerified
        ? query(
            collectionRef,
            where('search', 'array-contains', tag),
            where('identity', '==', 'verified'),
            orderBy('nameFirst'),
            orderBy('nameLast'),
            limit(5),
          )
        : query(
            collectionRef,
            where('search', 'array-contains', tag),
            orderBy('nameFirst'),
            orderBy('nameLast'),
            limit(5),
          ),
    )
      .then((snapshot) => {
        const results = snapshot.docs.map((item) => newPatientWithOutPhysician(item));
        const physicianPatients = results.filter((item) => item.physicianId === session.user.id);
        const otherPatients = results.filter((item) => item.physicianId !== session.user.id);
        resolve(physicianPatients.concat(otherPatients));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function dbFamilyDoctorSearch(searchText) {
  return new Promise((resolve, reject) => {
    if (!searchText) {
      resolve([]);
    }

    // Strip any prefix 'Dr. '
    const tag = searchText.toLowerCase().replace(/^dr. /, '');

    const db = getFirestore();
    const collectionRef = collection(db, 'familyDoctors');

    getDocs(query(collectionRef, where('search', 'array-contains', tag), orderBy('name'), limit(5)))
      .then((snapshot) => {
        const results = snapshot.docs.map((snapshot2) => {
          const { address, chekHealth, name } = snapshot2.data();
          return {
            address,
            name,
            chekHealth,
          };
        });
        resolve(results);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

//
// Authentication
//

onAuthStateChanged(getAuth(), (user) => {
  if (user) {
    session.user = { email: user.email, id: user.uid };
    session.userAccount = user;
  } else {
    if (session.unsubscribeFromPatientSnapshot) {
      session.unsubscribeFromPatientSnapshot();
      session.unsubscribeFromPatientSnapshot = undefined;
    }

    if (session.unsubscribeFromBillingsSnapshot) {
      session.unsubscribeFromBillingsSnapshot();
      session.unsubscribeFromBillingsSnapshot = undefined;
    }

    if (session.unsubscribeFromChartUpdatesSnapshot) {
      session.unsubscribeFromChartUpdatesSnapshot();
      session.unsubscribeFromChartUpdatesSnapshot = undefined;
    }

    if (session.unsubscribeFromNewResultsSnapshot) {
      session.unsubscribeFromNewResultsSnapshot();
      session.unsubscribeFromNewResultsSnapshot = undefined;
    }

    if (session.unsubscribeFromUserSnapshot) {
      session.unsubscribeFromUserSnapshot();
      session.unsubscribeFromUserSnapshot = undefined;
    }

    session.user = null;
    session.userAccount = null;
  }

  subscribeToUserChanges();
});

export function dbAccountExists(email) {
  const auth = getAuth();
  return fetchSignInMethodsForEmail(auth, email).then((signinMethods) => signinMethods.includes('password'));
}

export function dbSignIn(email, password) {
  const auth = getAuth();

  return signInWithEmailAndPassword(auth, email, password);
}

export function dbSignInVerifyPhoneNumber(resolver, recaptchaVerifier) {
  const hint = multiFactorHint(resolver);
  const auth = getAuth();
  const provider = new PhoneAuthProvider(auth);

  return provider.verifyPhoneNumber({ multiFactorHint: hint, session: resolver.session }, recaptchaVerifier);
}

export function dbSignInVerificationCode(resolver, verificationCode, verificationId) {
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(
    PhoneAuthProvider.credential(verificationId, verificationCode),
  );

  return resolver.resolveSignIn(multiFactorAssertion);
}

export function dbSignOut() {
  const auth = getAuth();

  return signOut(auth);
}
