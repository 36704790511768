import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Button from '../components/Button';
import Checkbox from '../components/Checkbox';
import Dialog from '../components/Dialog';
import { neutralVariant50, primary } from '../constants/colors';
import { selectText } from '../constants/styles';
import { fontFamily1 } from '../constants/typography';
import CloseBoxIcon from '../icons/CloseBox';
import { apiIsUserPhysician, apiUpdateIdentity } from '../utils/api';
import { distanceInYears, formatDateLong, parseDOB } from '../utils/date';
import { genderIdentityName, genderName } from '../utils/gender';
import { getFullName } from '../utils/patient';
import { tabTitleSpacing } from './styles';

const styles = (theme) => ({
  button: {
    boxShadow: 'none',
    marginLeft: theme.spacing(2),
  },
  checkbox: {
    paddingRight: theme.spacing(2),
  },
  checkboxSpacing: {
    paddingRight: theme.spacing(2.5),
  },
  checkboxReject: {
    color: theme.palette.error.main,
  },
  content: {
    padding: theme.spacing(1),
  },
  identityCheck: {
    width: 522,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  identityItem: {
    flex: 0.5,
  },
  identityLabel: {
    fontFamily: fontFamily1,
    letterSpacing: `${theme.typography.round(0.25 / 16)}em`,
    color: primary,
  },
  identityConfirm: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
  label: {
    alignItems: 'center',
    display: 'flex',
  },
  labelText: {
    width: 152,
    fontFamily: fontFamily1,
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: `${theme.typography.round(0.1 / 12)}em`,
    lineHeight: theme.typography.pxToRem(16),
    paddingTop: 1.5,
    color: neutralVariant50,
  },
  paper: {
    width: 432,
  },
  selectText,
  titleSpacing: {
    ...tabTitleSpacing(theme),
  },
});

class ChartPatientInfo extends PureComponent {
  constructor(props) {
    super(props);

    const { patient } = this.props;

    this.state = {
      identityCheck: {
        dob: patient.identityCheck !== undefined ? patient.identityCheck.dob : null,
        name: patient.identityCheck !== undefined ? patient.identityCheck.name : null,
        phn: patient.identityCheck !== undefined ? patient.identityCheck.phn : null,
      },
      rejecting: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleIdentityCheckClick = this.handleIdentityCheckClick.bind(this);
    this.handleRejectClick = this.handleRejectClick.bind(this);
    this.handleRejectConfirmClick = this.handleRejectConfirmClick.bind(this);
    this.handleVerifyClick = this.handleVerifyClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { patient: oldPatient } = prevProps;
    const { patient } = this.props;

    if (oldPatient.identity === 'rejected' && patient.identity === 'submitted') {
      this.setState({
        identityCheck: {
          dob: patient.identityCheck !== undefined ? patient.identityCheck.dob : null,
          name: patient.identityCheck !== undefined ? patient.identityCheck.name : null,
          phn: patient.identityCheck !== undefined ? patient.identityCheck.phn : null,
        },
      });
    }
  }

  handleClose() {
    this.setState({ rejecting: false });
  }

  handleIdentityCheckClick(key, value) {
    return () => {
      const { identityCheck } = this.state;
      const newIdentityCheck = {
        ...identityCheck,
      };
      newIdentityCheck[key] = value;
      this.setState({ identityCheck: newIdentityCheck });
    };
  }

  handleRejectClick() {
    this.setState({ rejecting: true });
  }

  handleRejectConfirmClick() {
    const { patient } = this.props;
    const { identityCheck } = this.state;

    apiUpdateIdentity('rejected', identityCheck, patient).then(() => {
      this.handleClose();
    });
  }

  handleVerifyClick() {
    const { handlePatientVerified, patient } = this.props;
    const { identityCheck } = this.state;

    apiUpdateIdentity('verified', identityCheck, patient);
    handlePatientVerified();
  }

  render() {
    const { classes, patient } = this.props;
    const { identityCheck, rejecting } = this.state;

    return (
      <>
        <div className={classes.titleSpacing} />
        <Divider />
        <div className={classes.content}>
          <List>
            {[
              {
                label: 'Name',
                value: getFullName(patient),
                identityKey: 'name',
                identityLabel: 'ID check - Name:',
                identityValue: identityCheck.name,
              },
              {
                label: 'PHN',
                value: patient.phn,
                identityKey: 'phn',
                identityLabel: 'ID check - PHN:',
                identityValue: identityCheck.phn,
              },
              {
                label: 'DOB',
                value: formatDateLong(parseDOB(patient.dob)),
                identityKey: 'dob',
                identityLabel: 'ID check - DOB:',
                identityValue: identityCheck.dob,
              },
              { label: 'Age', value: distanceInYears(parseDOB(patient.dob)) },
              { label: 'Sex at birth', value: genderName(patient.gender) },
              { label: 'Gender identity', value: patient.genderIdentity && genderIdentityName(patient.genderIdentity) },
              { label: 'Phone', value: patient.phone },
              { label: 'Address', value: patient.address },
              {
                label: 'Chek/Family physician',
                value: patient.physicianId === patient.familyDoctorName && patient.physicianId,
              },
              {
                label: 'Chek physician',
                value: patient.physicianId !== patient.familyDoctorName && patient.physicianId,
              },
              {
                label: 'Family physician',
                value: patient.physicianId !== patient.familyDoctorName && patient.familyDoctorName,
              },
            ]
              .filter((item) => item.value)
              .map((item) => (
                <ListItem key={item.label}>
                  <ListItemText
                    disableTypography
                    primary={
                      <div className={classes.label}>
                        <Typography className={classes.labelText} variant="caption">
                          {item.label}
                        </Typography>
                        <Typography className={classes.selectText}>{item.value}</Typography>
                      </div>
                    }
                  />
                  {!apiIsUserPhysician() &&
                    ['submitted', 'rejected'].includes(patient.identity) &&
                    'identityKey' in item && (
                      <ListItemSecondaryAction>
                        <div className={classes.identityCheck}>
                          <div className={classes.identityItem}>
                            <Typography className={classes.identityLabel}>{item.identityLabel}</Typography>
                          </div>
                          <div className={classes.checkboxSpacing}>
                            <FormControlLabel
                              className={classes.identityLabel}
                              control={
                                <Checkbox
                                  checked={item.identityValue !== null && item.identityValue}
                                  className={classes.checkbox}
                                  color="primary"
                                  onChange={this.handleIdentityCheckClick(
                                    item.identityKey,
                                    item.identityValue !== null && item.identityValue ? null : true,
                                  )}
                                />
                              }
                              label={<Typography className={classes.identityLabel}>Verify</Typography>}
                            />
                          </div>
                          <FormControlLabel
                            className={classes.identityLabel}
                            control={
                              <Checkbox
                                checked={item.identityValue !== null && !item.identityValue}
                                checkedIcon={<CloseBoxIcon />}
                                classes={{ checked: classes.checkboxReject }}
                                className={classes.checkbox}
                                color="default"
                                onChange={this.handleIdentityCheckClick(
                                  item.identityKey,
                                  item.identityValue !== null && !item.identityValue ? null : false,
                                )}
                              />
                            }
                            label={<Typography className={classes.identityLabel}>Reject</Typography>}
                          />
                        </div>
                      </ListItemSecondaryAction>
                    )}
                </ListItem>
              ))}
          </List>
        </div>
        {!apiIsUserPhysician() && ['submitted', 'rejected'].includes(patient.identity) && (
          <div className={classes.identityConfirm}>
            <Button
              className={classes.button}
              color="primary"
              onClick={this.handleVerifyClick}
              disabled={!(identityCheck.dob && identityCheck.name && identityCheck.phn)}
              variant="contained"
            >
              Verify
            </Button>
            <Button
              className={classes.button}
              color="primary"
              onClick={this.handleRejectClick}
              disabled={
                patient.identity === 'rejected' ||
                identityCheck.dob === null ||
                identityCheck.name === null ||
                identityCheck.phn === null ||
                (identityCheck.dob && identityCheck.name && identityCheck.phn)
              }
              variant="contained"
            >
              Reject
            </Button>
          </div>
        )}
        <Dialog
          contentText={['This patient will receive an email informing them their identity has been rejected.']}
          handleCancelClick={this.handleClose}
          handleOkClick={this.handleRejectConfirmClick}
          okLabel="OK, reject"
          open={rejecting}
          onClose={this.handleClose}
          PaperProps={{ className: classes.paper }}
          title="Reject patient identity?"
        />
      </>
    );
  }
}

ChartPatientInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  handlePatientVerified: PropTypes.func.isRequired,
  patient: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChartPatientInfo);
