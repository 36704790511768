import { createBrowserHistory } from 'history';

class History {
  constructor() {
    this.history = createBrowserHistory();

    this.addListener = this.addListener.bind(this);
    this.getState = this.getLocationState.bind(this);
    this.goBack = this.goBack.bind(this);
    this.goHome = this.goHome.bind(this);
    this.push = this.push.bind(this);
    this.replace = this.replace.bind(this);
    this.update = this.update.bind(this);
  }

  addListener(listener) {
    return this.history.listen((location) => {
      listener(location.state || {});
    });
  }

  getLocationState() {
    return this.history.location.state || {};
  }

  goBack() {
    this.history.goBack();
  }

  goHome() {
    const { patient } = this.history.location.state || {};
    if (patient) this.goBack();
  }

  push(state) {
    this.history.push('', state);
  }

  replace(state) {
    this.history.replace('', state);
  }

  update(state) {
    this.history.replace('', { ...this.history.location.state, ...state });
  }
}

export default new History();
