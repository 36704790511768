export const isTestReviewed = (test) => (test && test.reviewed) || false;

export const isTestNotReviewed = (test) => (test && !test.reviewed) || false;

export function isOrderingPhysicianExternal(test) {
  if (test) {
    if (test.orderingPhysician) {
      return !test.orderingPhysician.chekHealth;
    }
    // Mobile pre-v1.6 will not have orderingPhysician property
    // as external was used instead
    return test.external;
  }
  return false;
}

export const specialistRecommendationOrDefault = (test, defaultDuration) =>
  test.recommendation !== undefined && test.recommendation !== null ? { months: test.recommendation } : defaultDuration;

// Key for react
export const testKey = (test) => `${test.date.toString()}${test.type}${test.value}${test.value2}${test.recommendation}`;
