import compareAsc from 'date-fns/compareAsc';
import isAfter from 'date-fns/isAfter';
import { getFullName } from './patient';

function compareFullName(a, b) {
  return getFullName(a) < getFullName(b) ? -1 : 1;
}

export function compareDate(a, b) {
  return compareAsc(a.date, b.date);
}

export function compareDateDesc(a, b) {
  // When equal returning 'a'
  return isAfter(b.date, a.date) ? 1 : -1;
}

export function compareStartOfDayDateThenFullName(a, b) {
  const dateCompare = compareDate(a, b);
  if (dateCompare === 0) return compareFullName(a, b);
  return dateCompare;
}
