import addMinutes from 'date-fns/addMinutes';
import { now } from './date';
import { isNewPatient } from './screenings';

export const getFullName = (patient) =>
  []
    .concat(patient.nameFirst ? [patient.nameFirst] : [])
    .concat(patient.nameLast ? [patient.nameLast] : [])
    .join(' ');

const minutesToNextNotify = 30;

export function getNextNotify(newScreening, patient) {
  // Start sending notifications once all patient screenings active
  if (
    patient.screenings.reduce(
      (accumulator, screening) =>
        accumulator || isNewPatient(screening.type === newScreening.type ? newScreening : screening),
      false,
    )
  ) {
    return null;
  }

  return addMinutes(now(), minutesToNextNotify);
}

// For all tests other than colonoscopy notify patient when test result is
// missing and chek staff completed chart update without finding a result.
// Do not notifying colonoscopy given the long time period and the patient
// is likely not in a position to resolve it themself.
export const notifyPatientOfMissingResult = (nextTestType) => nextTestType !== 'colonoscopy';
