import add from 'date-fns/add';
import sub from 'date-fns/sub';
import { familyMembersLimit, historyItem as historyItemUtils, historyItemValue } from './chartHistoryUtils';
import { isAfterToday } from './date';
import { getAge } from './patientUtils';
import {
  newRecommendationAgingIn,
  newRecommendationReferral,
  newRecommendationScheduleTest,
  recommendationNextTestDue,
} from './recommendation';
import { dueOptionsAddNowIfNeeded, dueOptionsAge } from './screeningUtils';
import { capitalize } from './string';
import { specialistRecommendationOrDefault } from './tests';
import { versionNoSupportColonoscopyCt } from './version';

export function testTypeNameColon(type) {
  if (type === 'fit') return 'FIT test';
  if (type === 'colonoscopy') return 'colonoscopy';
  if (type === 'colonoscopyCt') return 'CT colonoscopy';
  if (type === 'dischargeLetter') return 'discharge letter';
  return type;
}

export function testReleaseTypeColon(type) {
  if (type === 'fit') return 'requisition';
  if (['colonoscopy', 'colonoscopyCt'].includes(type)) return 'booking';
  if (type === 'dischargeLetter') return 'other';
  return null;
}

export const testTypeReferPatientColon = (type) => ['colonoscopy', 'colonoscopyCt'].includes(type);

function valueNameColon(type, value) {
  // Added mobile app v1.6:
  //   - highRiskTubularAdenomas
  //   - highRiskSessileSerratedAdenomas
  //
  //   Plus some of the text of the display names changed
  //   so values and display names do not fully line-up.

  if (value === 'negative') return type === 'colonoscopyCt' ? 'Negative for a colon mass' : 'Negative';
  if (value === 'positive') return type === 'colonoscopyCt' ? 'Positive for a colon mass' : 'Positive';
  if (value === 'less4HyperplasticPolyps') return '<4 hyperplastic polyps';
  if (value === 'fewTubularAdenomas') return '1-2 tubular adenomas <10mm';
  if (value === 'fewSessileSerratedAdenomas') return '1-2 sessile serrated adenomas';
  if (value === 'greater4HyperplasticPolyps') return '3-4 tubular adenomas <10mm';
  if (value === 'hyperplasticPolypsGreater5mm') return 'Hyperplastic polyps >9mm';
  if (value === 'highRiskTubularAdenomas') return '5-10 tubular adenomas <10mm';
  if (value === 'highRiskSessileSerratedAdenomas') return '3-10 sessile serrated adenomas';
  if (value === 'highRiskAdenomas') return 'High risk adenomas';
  if (value === 'ssaWithDysplasia') return '>10 sessile serrated adenomas';
  if (value === 'cancer') return 'Cancer';
  if (value === 'polypsFoundNoPathologyReport') return 'Polyps found, no pathology report';
  return value;
}

export const testValueNameColon = (test) => valueNameColon(test.type, test.value);

export const menuOptionsTestTypeColon = () => [
  [
    { name: capitalize(testTypeNameColon('fit')), value: 'fit' },
    { name: capitalize(testTypeNameColon('colonoscopy')), value: 'colonoscopy' },
    { name: capitalize(testTypeNameColon('colonoscopyCt')), value: 'colonoscopyCt' },
  ],
  [{ name: capitalize(testTypeNameColon('dischargeLetter')), value: 'dischargeLetter' }],
];

export function menuOptionsTestResultColon(test) {
  if (test.type === 'fit') {
    return [
      [
        { name: valueNameColon(test.type, 'negative'), value: 'negative' },
        { name: valueNameColon(test.type, 'positive'), value: 'positive' },
      ],
    ];
  }
  if (test.type === 'colonoscopyCt') {
    return [
      [
        { name: valueNameColon(test.type, 'negative'), value: 'negative' },
        { name: valueNameColon(test.type, 'positive'), value: 'positive' },
      ],
    ];
  }
  if (test.type === 'colonoscopy') {
    return [
      [{ name: valueNameColon(test.type, 'negative'), value: 'negative' }],
      [
        { name: valueNameColon(test.type, 'less4HyperplasticPolyps'), value: 'less4HyperplasticPolyps' },
        { name: valueNameColon(test.type, 'fewTubularAdenomas'), value: 'fewTubularAdenomas' },
      ],
      [
        { name: valueNameColon(test.type, 'fewSessileSerratedAdenomas'), value: 'fewSessileSerratedAdenomas' },
        { name: valueNameColon(test.type, 'greater4HyperplasticPolyps'), value: 'greater4HyperplasticPolyps' },
        { name: valueNameColon(test.type, 'hyperplasticPolypsGreater5mm'), value: 'hyperplasticPolypsGreater5mm' },
      ],
      [
        { name: valueNameColon(test.type, 'highRiskTubularAdenomas'), value: 'highRiskTubularAdenomas' },
        {
          name: valueNameColon(test.type, 'highRiskSessileSerratedAdenomas'),
          value: 'highRiskSessileSerratedAdenomas',
        },
        { name: valueNameColon(test.type, 'highRiskAdenomas'), value: 'highRiskAdenomas' },
      ],
      [{ name: valueNameColon(test.type, 'ssaWithDysplasia'), value: 'ssaWithDysplasia' }],
      [{ name: valueNameColon(test.type, 'cancer'), value: 'cancer' }],
      [{ name: valueNameColon(test.type, 'polypsFoundNoPathologyReport'), value: 'polypsFoundNoPathologyReport' }],
    ];
  }

  return [];
}

// Note: Specialist recommendations value is in months

export const menuOptionsSpecialistRecommendationColon = (test) =>
  ['colonoscopy', 'dischargeLetter'].includes(test.type)
    ? [
        { name: 'Retest in 5 years', value: 60 },
        { name: 'Retest in 3 years', value: 36 },
        { name: 'Retest in 1 year', value: 12 },
        { name: 'Retest in 6 months', value: 6 },
        { name: 'Retest in 3 months', value: 3 },
      ]
    : [];

export function specialistRecommendationNameColon(recommendation) {
  if (recommendation === 3) return 'Retest in 3 months';
  if (recommendation === 6) return 'Retest in 6 months';
  if (recommendation === 12) return 'Retest in 1 year';
  if (recommendation === 36) return 'Retest in 3 years';
  if (recommendation === 60) return 'Retest in 5 years';
  return '';
}

export function newDefaultTestColon(screening, isReferral) {
  if (isReferral) {
    return {
      date: null,
      type: 'dischargeLetter',
      recommendation: null,
    };
  }

  return {
    date: null,
    type: screening.nextTest.type || 'fit',
    value: null,
  };
}

export function isReflexiveTestColon(nextTestType, latestTest) {
  // Endoscopy clinic auto booking repeat colonoscopy and communicating booking
  // to patient. If repeat colonoscopy is recommended in 1 year or less, no
  // referral required, automatically arranged by endoscopy clinic. When
  // discharged from cancer specialist and colonoscopy scheduled within
  // 1 year, specialist clinic makes the arrangements.

  if (nextTestType === 'colonoscopy') {
    return (
      (latestTest &&
        ['colonoscopy', 'dischargeLetter'].includes(latestTest.type) &&
        ![undefined, null].includes(latestTest.recommendation) &&
        latestTest.recommendation <= 12) ||
      false
    );
  }

  return false;
}

export function getConsultWindowColon(nextTestType) {
  if (['fit', 'colonoscopyCt'].includes(nextTestType)) {
    return { days: 30 };
  }
  if (nextTestType === 'colonoscopy') {
    return { months: 12 };
  }
  return null;
}

export function handleTestEditChangeColon(key, newValue, test) {
  // Reset value and remove specialist recommendation for fit,
  // colonoscopy and colonoscopyCt. Specialist recommendation
  // enabled for colonoscopy as result is set.
  if (key === 'type' && ['fit', 'colonoscopy', 'colonoscopyCt'].includes(newValue)) {
    const { recommendation, ...other } = test;
    return { ...other, [key]: newValue, value: null };
  }
  // Remove value and reset specialist recommendation for dischargeLetter
  if (key === 'type' && newValue === 'dischargeLetter') {
    const { value, ...other } = test;
    return { ...other, [key]: newValue, recommendation: null };
  }
  // Enable specialist recommendation for all colonoscopy
  // results other than negative when not already enabled
  if (key === 'value' && test.type === 'colonoscopy') {
    if (newValue === 'negative' && test.recommendation !== undefined) {
      const { recommendation, ...other } = test;
      return { ...other, [key]: newValue };
    }
    if (newValue !== 'negative' && test.recommendation === undefined) {
      return { ...test, [key]: newValue, recommendation: null };
    }
  }
  return { ...test, [key]: newValue };
}

const highRiskPathology = [
  'fewSessileSerratedAdenomas',
  'greater4HyperplasticPolyps',
  'hyperplasticPolypsGreater5mm',
  'highRiskTubularAdenomas',
  'highRiskSessileSerratedAdenomas',
  'highRiskAdenomas',
  'ssaWithDysplasia',
  'cancer',
  'polypsFoundNoPathologyReport',
];

//
// Medical history items
//

// ** Firestore field values but not display items

const familyHistoryItem = { type: 'familyHistory', field: 'family', questionnaire: true };

// ** Display items of chart history

const getNameFamily = (value) =>
  value ? `${capitalize(value.relation)} age ${value.age} diagnosed colon cancer or colon polyps` : '';

export const historyItemsColon = [
  familyHistoryItem,
  {
    type: 'personalHistory',
    field: 'totalColectomy',
    name: 'Total colectomy',
    questionnaire: true,
    showChart: true,
  },
  {
    type: 'personalHistory',
    field: 'bowelDisease',
    name: 'Diagnosed inflammatory bowel disease',
    questionnaire: true,
    showChart: true,
  },
  {
    type: 'personalHistory',
    field: 'polyposis',
    name: 'Diagnosed familial adenomatous polyposis or Lynch syndrome (HNPCC)',
    questionnaire: true,
    showChart: true,
  },
  {
    type: 'personalHistory',
    field: 'cancer',
    highRiskPathology,
    name: 'Diagnosed colon cancer or colon polyps',
    questionnaire: true,
    showChart: true,
  },
  {
    type: 'familyHistory',
    field: 'family',
    index: 0,
    getName: getNameFamily,
    fromArray: familyHistoryItem,
    showChart: true,
  },
  {
    type: 'familyHistory',
    field: 'family',
    index: 1,
    getName: getNameFamily,
    fromArray: familyHistoryItem,
    showChart: true,
  },
  {
    type: 'familyHistory',
    field: 'family',
    index: 2,
    getName: getNameFamily,
    fromArray: familyHistoryItem,
    showChart: true,
  },
];

const historyItem = (field) => historyItemUtils(field, historyItemsColon);

//
// Screening rules
//

export function getScreeningHighRiskColon(screening, patient) {
  let familyHistory = false;
  let pathology = false;
  let personalHistory = false;
  const indicators = new Set();

  // Diagnosed colon cancer or colon polyps

  const cancer = historyItemValue(historyItem('cancer'), screening, patient);
  if (cancer.value) {
    pathology = cancer.pathology || false;
    personalHistory = !pathology;
    indicators.add(cancer.name);
  }

  // Family history colon cancer or colon polyps

  // Firestore field value item
  const family = historyItemValue(familyHistoryItem, screening, patient);
  if ((family.value && family.value.length >= 2) || family.value.filter((item) => item.age < 60).length !== 0) {
    familyHistory = true;
    indicators.add('Family history colon cancer or colon polyps');
  }

  return {
    familyHistory,
    pathology,
    personalHistory,
    indicators: [...indicators],
    value: familyHistory || pathology || personalHistory,
  };
}

export function getScreeningVeryHighRiskColon(screening, patient) {
  const indicators = new Set();
  let value = false;

  // Diagnosed inflammatory bowel disease

  const bowelDisease = historyItemValue(historyItem('bowelDisease'), screening, patient);
  if (bowelDisease.value) {
    indicators.add(bowelDisease.name);
    value = true;
  }

  // Diagnosed familial adenomatous polyposis or Lynch syndrome (HNPCC)

  const polyposis = historyItemValue(historyItem('polyposis'), screening, patient);
  if (polyposis.value) {
    indicators.add(polyposis.name);
    value = true;
  }

  return {
    indicators: [...indicators],
    value,
  };
}

export function getScreeningNotApplicableColon(screening, patient) {
  const indicators = new Set();
  let value = false;

  // Total colectomy

  const totalColectomy = historyItemValue(historyItem('totalColectomy'), screening, patient);
  if (totalColectomy.value) {
    indicators.add(totalColectomy.name);
    value = true;
  }

  return {
    indicators: [...indicators],
    value,
  };
}

export function screeningRisksColon(screening, patient) {
  const { familyHistory, pathology, personalHistory } = getScreeningHighRiskColon(screening, patient);
  return {
    highRisk: {
      familyHistory,
      pathology,
      personalHistory,
    },
    veryHighRisk: getScreeningVeryHighRiskColon(screening, patient).value,
    notApplicable: getScreeningNotApplicableColon(screening, patient).value,
  };
}

function screeningMinAgeColon(screening, patient) {
  const isHighRisk = getScreeningHighRiskColon(screening, patient).value;
  // Firestore field value item
  const family = historyItemValue(familyHistoryItem, screening, patient);

  if (isHighRisk) {
    if (getScreeningHighRiskColon(screening, patient).familyHistory) {
      // History of multiple family members
      if (family.value.length > 1) {
        const age =
          family.value.reduce((accumulator, value) => (value.age < accumulator ? value.age : accumulator), 50) - 10;
        return age < 25 ? 25 : age;
      }
      // History of one younger family member
      if (family.value.length === 1) {
        const { age } = family.value[0];
        if (age < 60) {
          const startAge = age - 10 < 40 ? age - 10 : 40;
          return startAge < 25 ? 25 : startAge;
        }
      }
    }
    // Other high risk history
    return 40;
  }
  // History of one older member
  if (family.value.length === 1) {
    return 40;
  }
  // Default age
  return 50;
}

//
// Recommendation
//

// Includes possible specialist recommendation
const defaultDueOptions = (latestTest) =>
  latestTest
    ? [
        { name: '10 years from test', duration: { months: 120 } },
        { name: '5 years from test', duration: { months: 60 } },
        { name: '3 years from test', duration: { months: 36 } },
        { name: '1 year from test', duration: { months: 12 } },
        { name: '6 months from test', duration: { months: 6 } },
        { name: '3 months from test', duration: { months: 3 } },
      ]
    : [];

export function screeningNextTestDueOptionsColon(recommendation, latestTest, patient) {
  if (['fit', 'colonoscopy', 'colonoscopyCt'].includes(recommendation.type)) {
    const { date } = latestTest || {};
    const age = getAge(patient, date);
    if (!recommendation.agedIn) {
      return dueOptionsAge(age < 25 ? 25 : age + 1, 50);
    }
    return dueOptionsAddNowIfNeeded(recommendation, defaultDueOptions(latestTest));
  }
  return [];
}

export const screeningNextTestTypeOptionsColon = (recommendation) =>
  ['fit', 'colonoscopy', 'colonoscopyCt'].includes(recommendation.type)
    ? [{ value: 'fit' }, { value: 'colonoscopy' }, { value: 'colonoscopyCt' }]
    : [{ value: recommendation.type }];

export function screeningRecommendationColon(latestTest, otherTests, screening, patient) {
  const { date, type, value } = latestTest || {};

  const isHighRisk = getScreeningHighRiskColon(screening, patient).value;
  const age = getAge(patient, date);
  const minAge = screeningMinAgeColon(screening, patient);

  if (latestTest) {
    if (age >= minAge || isHighRisk || !['negative', 'less4HyperplasticPolyps'].includes(value)) {
      if (type === 'dischargeLetter') {
        const duration = specialistRecommendationOrDefault(latestTest, { months: 36 });
        return newRecommendationScheduleTest(duration, 'colonoscopy', date);
      }

      if (['fit', 'colonoscopyCt'].includes(type)) {
        if (isHighRisk) {
          // Look up last colonoscopy, recursive call
          const tests = otherTests.filter((item) => item.type === 'colonoscopy');
          if (tests.length !== 0) {
            return screeningRecommendationColon(
              tests[0],
              otherTests.filter((_, index) => index > 0),
              screening,
              patient,
            );
          }
          // If no colonoscopy found, schedule colonoscopy now
          return newRecommendationScheduleTest({ months: 0 }, 'colonoscopy', date);
        }
        if (value === 'negative') {
          // Next test due in 5 years after negative CT colonscopy
          return type === 'fit'
            ? newRecommendationScheduleTest({ months: 12 }, 'fit', date)
            : newRecommendationScheduleTest({ months: 60 }, 'colonoscopyCt', date);
        }
        if (value === 'positive') {
          return newRecommendationScheduleTest({ months: 0 }, 'colonoscopy', date);
        }
      }

      if (type === 'colonoscopy') {
        if (['negative', 'less4HyperplasticPolyps'].includes(value)) {
          if (isHighRisk) {
            const duration = specialistRecommendationOrDefault(latestTest, { months: 60 });
            return newRecommendationScheduleTest(duration, 'colonoscopy', date);
          }
          return newRecommendationScheduleTest({ months: 120 }, 'fit', date);
        }
        if (value === 'fewTubularAdenomas') {
          if (isHighRisk) {
            const duration = specialistRecommendationOrDefault(latestTest, { months: 60 });
            return newRecommendationScheduleTest(duration, 'colonoscopy', date);
          }
          return newRecommendationScheduleTest({ months: 60 }, 'fit', date);
        }
        if (
          ['fewSessileSerratedAdenomas', 'greater4HyperplasticPolyps', 'hyperplasticPolypsGreater5mm'].includes(value)
        ) {
          const duration = specialistRecommendationOrDefault(latestTest, { months: 60 });
          return newRecommendationScheduleTest(duration, 'colonoscopy', date);
        }
        if (
          [
            'highRiskTubularAdenomas',
            'highRiskSessileSerratedAdenomas',
            'highRiskAdenomas',
            'polypsFoundNoPathologyReport',
          ].includes(value)
        ) {
          const duration = specialistRecommendationOrDefault(latestTest, { months: 36 });
          return newRecommendationScheduleTest(duration, 'colonoscopy', date);
        }
        if (value === 'ssaWithDysplasia') {
          const duration = specialistRecommendationOrDefault(latestTest, { months: 12 });
          return newRecommendationScheduleTest(duration, 'colonoscopy', date);
        }
        if (value === 'cancer') {
          return newRecommendationReferral();
        }
      }
    } else if (type === 'fit' && getAge(patient, add(date, { months: 12 })) >= minAge) {
      // Patient had test before minAge, got negative result, next test is scheduled after minAge
      return newRecommendationScheduleTest({ months: 12 }, 'fit', date);
    } else if (type === 'colonoscopy' && getAge(patient, add(date, { months: 120 })) >= minAge) {
      // Patient had test before minAge, got negative result, next test is scheduled after minAge
      return newRecommendationScheduleTest({ months: 120 }, 'fit', date);
    }
  }

  if (age >= minAge) {
    return isHighRisk
      ? newRecommendationScheduleTest({ months: 0 }, 'colonoscopy', date)
      : newRecommendationScheduleTest({ months: 0 }, 'fit', date);
  }

  // Not agedIn
  return newRecommendationAgingIn({ months: minAge * 12 }, isHighRisk ? 'colonoscopy' : 'fit', patient);
}

export function callPatientColon(recommendation, latestTest) {
  if (recommendation.referral) {
    return true;
  }

  if (recommendation.type === 'colonoscopy') {
    // Note: Endoscopy clinic auto booking repeat colonoscopy within 1 year
    // and communicating this to the patient. These are auto accepted
    // reflexive tests which are not reviewed by Chek physician.

    const { type, value } = latestTest || {};

    // Scenarios don't call patient when their next test is colonoscopy
    if (type === 'colonoscopy') {
      if (
        [
          'negative',
          'less4HyperplasticPolyps',
          'fewSessileSerratedAdenomas',
          'greater4HyperplasticPolyps',
          'hyperplasticPolypsGreater5mm',
        ].includes(value)
      ) {
        return false;
      }
    }

    // Discharged from cancer specialist and scheduled for colonoscopy
    // do not need to call patient to info of result
    if (type === 'dischargeLetter') {
      return false;
    }

    return true;
  }

  return false;
}

export function referPatientColon(recommendation) {
  if (recommendation.referral) {
    return true;
  }

  // Chek physician needs to book colonoscopy and CT colonoscopy

  // Note: Endoscopy clinic auto booking repeat colonoscopy within 1 year
  // and communicating this to the patient. These are auto accepted
  // reflexive tests which are not reviewed by Chek physician.

  if (testTypeReferPatientColon(recommendation.type)) {
    // Referral due 1 year or less prior to colonoscopy due date or 30 days CT colonoscopy
    return !isAfterToday(sub(recommendationNextTestDue(recommendation), getConsultWindowColon(recommendation.type)));
  }

  return false;
}

//
// Chart medical history
//

const relationOptions = ['father', 'mother', 'brother', 'sister', 'son', 'daughter'];

export function getAddFamilyHistoryDialogValuesColon(screening, patient) {
  const itemValue = historyItemValue(familyHistoryItem, screening, patient);

  const filter = itemValue.value.reduce(
    (accumulator, value) =>
      ['father', 'mother'].includes(value.relation) ? accumulator.concat([value.relation]) : accumulator,
    [],
  );

  return {
    item: familyHistoryItem,
    subtitle: 'Family member colon cancer  or colon polyps',
    vacancy: itemValue.value.length < familyMembersLimit,
    values: [
      {
        label: 'Relation',
        field: 'relation',
        options: relationOptions
          .filter((option) => !filter.includes(option))
          .map((option) => ({
            name: capitalize(option),
            value: option,
          })),
      },
      {
        label: 'Age',
        field: 'age',
        options: Array.from({ length: 100 - 18 }, (_, i) => i + 18).map((option) => ({
          name: option,
          value: option,
        })),
      },
    ],
  };
}

export function setChartHistoryItemColon(item, screening, patient, value = true) {
  // Add to family history array
  if (item.field === familyHistoryItem.field) {
    const itemValue = historyItemValue(item, screening, patient);
    return [
      {
        ...itemValue,
        value: itemValue.value.concat(value),
      },
    ];
  }

  return [{ ...item, value }];
}

//
// Transform data for storage in firestore data transformation
// Note: Data under patient screening array is received by mobile app
//

export function firestoreTransformToScreeningNextTestColon(nextTest, patient) {
  if (versionNoSupportColonoscopyCt(patient)) {
    if (nextTest.type === 'colonoscopyCt') {
      return { ...nextTest, type: 'colonoscopy' };
    }
  }
  return nextTest;
}

export const firestoreTransformToScreeningTestsColon = (tests, patient) =>
  tests.map((test) => {
    if (versionNoSupportColonoscopyCt(patient)) {
      if (test.type === 'colonoscopyCt') {
        return { ...test, type: 'colonoscopy' };
      }
    }
    if (['highRiskTubularAdenomas', 'highRiskSessileSerratedAdenomas'].includes(test.value)) {
      return { ...test, value: 'highRiskAdenomas' };
    }
    return test;
  });
