import ButtonBase from '@material-ui/core/ButtonBase';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/AddOutlined';
import clsx from 'clsx';
import endOfDay from 'date-fns/endOfDay';
import endOfYesterday from 'date-fns/endOfYesterday';
import isValid from 'date-fns/isValid';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import AppBar from '../components/AppBar';
import AppBarUser from '../components/AppBarUser';
import Badge from '../components/Badge';
import Button from '../components/Button';
import DialogMultilineText from '../components/DialogMultilineText';
import DialogPaper from '../components/DialogPaper';
import DialogTitle from '../components/DialogTitle';
import FadeSlideOver from '../components/FadeSlideOver';
import FadeSlideReplace from '../components/FadeSlideReplace';
import KeyboardDatePicker from '../components/KeyboardDatePicker';
import LabelBadge from '../components/LabelBadge';
import Scroll from '../components/Scroll';
import Separator from '../components/Separator';
import Tabs from '../components/Tabs';
import { dialogActions } from '../components/styles';
import { primary } from '../constants/colors';
import { selectText } from '../constants/styles';
import { mixinGutters, mixinHeightWithToolbar } from '../constants/theme';
import { fontFamily2 } from '../constants/typography';
import FeedIcon from '../icons/FeedOutlined';
import FileDownloadIcon from '../icons/FileDownloadOutlined';
import MedicalInformationIcon from '../icons/MedicalInformationOutlined';
import {
  apiAcceptTest,
  apiAddChartNote,
  apiIsUserPhysician,
  apiOnBillingsChanged,
  apiOnChartUpdatesChanged,
  apiOnChekNewResultsChanged,
  apiOnPatientChanged,
  apiOnPhysicianNewResultsChanged,
  apiReferralTest,
  apiScreeningSetNoNextTest,
  apiSignOut,
} from '../utils/api';
import { getBillings } from '../utils/billingExport';
import { chartNoteUserNote, onViewedChartNotes, unviewedChartNotes } from '../utils/chartNotes';
import { getChartUpdates } from '../utils/chartUpdates';
import { distanceInYears, isValidKeyboardDate, parseDOB } from '../utils/date';
import { genderShortName } from '../utils/gender';
import history from '../utils/history';
import { getFullName } from '../utils/patient';
import { hasLatestTestNotReviewed } from '../utils/screenings';
import { compareDate } from '../utils/sort';
import ChartChartNotes from './ChartChartNotes';
import ChartPatientInfo from './ChartPatientInfo';
import ChartScreenings from './ChartScreenings';
import ChartUpdates from './ChartUpdates';
import NewResults from './NewResults';
import Profile from './Profile';
import Search from './Search';
import SearchAppBar from './SearchAppBar';

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  container: {
    width: 1296,
    height: '100%',
    position: 'relative',
    margin: '0 auto',
    boxSizing: 'content-box',
    ...mixinGutters(theme),
  },
  addNoteButton: {
    borderRadius: 20,
    color: theme.palette.text.secondary,
    paddingRight: 23,
  },
  main: {
    ...mixinHeightWithToolbar(0, {
      width: '100%',
      display: 'flex',
    }),
  },
  badge: {
    marginRight: theme.spacing(1),
  },
  chart: {
    ...mixinHeightWithToolbar(166, {
      width: '100%',
      display: 'flex',
    }),
  },
  content: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  contentAnchor: {
    position: 'absolute',
  },
  contentChart: {
    boxSizing: 'border-box',
    width: '100%',
    paddingBottom: theme.spacing(1),
    ...mixinGutters(theme),
  },
  column1: {
    flex: 1,
    maxWidth: 1024,
  },
  columnContent: {
    height: 'calc(100% - 28px)',
    marginTop: 28,
  },
  component: {
    width: '100%',
    height: '100%',
    '&:after': {
      content: '""',
      display: 'block',
      paddingBottom: theme.spacing(4),
    },
  },
  count: {
    marginLeft: 9,
  },
  demographics: {
    color: theme.palette.text.secondary,
  },
  demographicsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: 976,
    padding: theme.spacing(0.75),
    ...mixinGutters(theme),
  },
  dialogActions: {
    ...dialogActions(theme),
  },
  dialogBackdrop: {
    backgroundColor: 'rgba(255, 255, 255, 0.92)',
  },
  dialogContentText: {
    maxWidth: 420,
    padding: theme.spacing(1, 0),
  },
  dialogItalic: {
    fontStyle: 'italic',
    paddingTop: theme.spacing(0.5),
  },
  dialogPaper: {
    width: 560,
  },
  flexColumn: {
    flexDirection: 'column',
  },
  fontFamily2: {
    fontFamily: fontFamily2,
  },
  gutter: {
    minWidth: theme.spacing(2.5),
  },
  iconAdd: {
    height: 18,
    marginRight: 9,
    width: 18,
  },
  patient: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: `${theme.typography.round(0.1 / 22)}em`,
    marginBottom: theme.spacing(-0.5),
  },
  rail: {
    minWidth: theme.spacing(13),
  },
  railButton: {
    width: '100%',
    height: theme.spacing(12),
    padding: theme.spacing(1, 0),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  railIcon: {
    padding: theme.spacing(1, 1, 0),
    marginBottom: theme.spacing(0.5),
  },
  railLabel: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  selected: {
    color: primary,
  },
  selectDate: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2.5),
  },
  selectText,
  tabs: {
    width: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  tab: {
    fontSize: theme.typography.pxToRem(14),
    minWidth: 0,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  tabIndicator: {
    height: 3,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      width: '100%',
      backgroundColor: primary,
      borderTopLeftRadius: 3,
      borderTopRightRadius: 3,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  tabIndicator0: {
    '& > div': {
      maxWidth: 77.8,
    },
  },
  tabIndicator1: {
    '& > div': {
      maxWidth: 75,
    },
  },
  tabIndicator2: {
    '& > div': {
      maxWidth: 79.8,
    },
  },
  tabDot: {
    padding: theme.spacing(0, 1),
  },
  title: {
    marginBottom: theme.spacing(1.5),
  },
  titleBadge: {
    alignSelf: 'end',
  },
  titleContainer: {
    width: '100%',
    height: 88,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  unselected: {
    color: theme.palette.text.secondary,
  },
});

class Container extends Component {
  constructor(props) {
    super(props);

    const { expansionsChart, expansionsNewResults, index, patientId, profile, seeAll, tabIndex } =
      history.getLocationState();

    this.state = {
      billings: undefined,
      chartUpdates: undefined,
      date: null,
      expansionsChart: expansionsChart !== undefined ? expansionsChart : [],
      expansionsNewResults: expansionsNewResults !== undefined ? expansionsNewResults : [],
      index: index !== undefined ? index : 0,
      newResults: undefined,
      newResultsToFilter: [],
      openChartNote: false,
      openExport: false,
      openMenu: false,
      patient: undefined,
      patientId,
      profile: profile || false,
      seeAll: seeAll || false,
      tabDirection: 'right',
      tabIndex,
    };

    this.anchorRef = createRef(null);
    this.scrollHistory = {};
    this.notifiers = [];
    this.historyUnlisten = undefined;
    this.waitForInitialChartUpdates = seeAll === 'chartUpdates';
    this.waitForInitialBillings = patientId !== undefined;
    this.waitForIntiailNewResults = seeAll === 'newResults';
    this.waitForInitialPatient = patientId !== undefined;

    this.addNotifier = this.addNotifier.bind(this);
    this.handleAcceptClick = this.handleAcceptClick.bind(this);
    this.handleAccountIconClick = this.handleAccountIconClick.bind(this);
    this.handleAddChartNoteClick = this.handleAddChartNoteClick.bind(this);
    this.handleBillingsChanged = this.handleBillingsChanged.bind(this);
    this.handleBillingExportClick = this.handleBillingExportClick.bind(this);
    this.handleChartNoteClose = this.handleChartNoteClose.bind(this);
    this.handleChartUpdatesChanged = this.handleChartUpdatesChanged.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.handleExportClick = this.handleExportClick.bind(this);
    this.handleLocationChanged = this.handleLocationChanged.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.handleNewResultsChanged = this.handleNewResultsChanged.bind(this);
    this.handlePatientChanged = this.handlePatientChanged.bind(this);
    this.handleProfileClick = this.handleProfileClick.bind(this);
    this.handleResetScrollHistory = this.handleResetScrollHistory.bind(this);
    this.handleSaveScrollHistory = this.handleSaveScrollHistory.bind(this);
  }

  componentDidMount() {
    const { patientId } = this.state;

    this.historyUnlisten = history.addListener(this.handleLocationChanged);

    apiOnChartUpdatesChanged(this.handleChartUpdatesChanged);

    if (apiIsUserPhysician()) {
      apiOnPhysicianNewResultsChanged(this.handleNewResultsChanged);
    } else {
      apiOnChekNewResultsChanged(this.handleNewResultsChanged);
      this.setState({ index: 1 });
    }

    if (patientId !== undefined) {
      apiOnPatientChanged(patientId, this.handlePatientChanged);
      apiOnBillingsChanged(patientId, this.handleBillingsChanged);
    }
  }

  componentDidUpdate() {
    const { patient, tabIndex } = this.state;

    if (patient && tabIndex === 2) {
      if (unviewedChartNotes(patient)) {
        onViewedChartNotes(patient);
        this.forceUpdate();
      }
    }
  }

  componentWillUnmount() {
    if (this.historyUnlisten) {
      this.historyUnlisten();
    }

    apiOnChartUpdatesChanged();
    if (apiIsUserPhysician()) {
      apiOnPhysicianNewResultsChanged();
    } else {
      apiOnChekNewResultsChanged();
    }

    apiOnPatientChanged();
    apiOnBillingsChanged();
  }

  //
  // Accepting new result
  //

  handleAcceptClick(recommended, screening, newResultToFilter) {
    const { expansionsNewResults, newResults, newResultsToFilter: newResultstToFilterState } = this.state;

    const newResultsToFilter = newResultstToFilterState.concat([newResultToFilter]);
    const { patient } = newResultToFilter;

    this.setState({
      expansionsNewResults:
        expansionsNewResults.filter((item) => !newResultsToFilter.some((value) => value.id === item)).length === 0 &&
        newResults.filter((item) => !newResultsToFilter.some((value) => value.id === item.id)).length !== 0
          ? expansionsNewResults.concat([
              newResults.find((item) => !newResultsToFilter.some((value) => value.id === item.id)).id,
            ])
          : expansionsNewResults,
      newResultsToFilter,
    });

    if (recommended.referral()) {
      return apiReferralTest(recommended, screening, patient);
    }
    if (recommended.isTestNotScheduled()) {
      return apiScreeningSetNoNextTest(recommended, screening, patient);
    }

    return apiAcceptTest(recommended, screening, patient);
  }

  handleAccountIconClick() {
    const { openMenu } = this.state;

    this.setState({ openMenu: !openMenu });
  }

  handleAddChartNoteClick() {
    const { openChartNote } = this.state;

    this.setState({ openChartNote: !openChartNote });
  }

  handleBillingsChanged(billings) {
    this.waitForInitialBillings = false;
    if (this.waitForInitialPatient) {
      this.setState({ billings });
      return;
    }
    Promise.all(this.notifiers).then(() => {
      this.notifiers = [];
      this.setState({ billings });
    });
  }

  handleBillingExportClick() {
    const { openExport } = this.state;

    this.setState({ date: null, openExport: !openExport });
  }

  handleChartNoteClose() {
    this.setState({ openChartNote: false });
  }

  handleChartUpdatesChanged(patients) {
    this.waitForInitialChartUpdates = false;

    this.setState({
      chartUpdates: patients
        .reduce((accumulator, patient) => accumulator.concat(getChartUpdates(patient)), [])
        .sort(compareDate),
    });
  }

  handleClose() {
    this.setState({ openExport: false });
  }

  handleDateChange(date) {
    this.setState({ date });
  }

  handleExpandClick(id) {
    const { expansionsNewResults } = this.state;

    return () => {
      if (expansionsNewResults.includes(id)) {
        history.update({ expansionsNewResults: expansionsNewResults.filter((item) => item !== id) });
      } else {
        history.update({ expansionsNewResults: expansionsNewResults.concat([id]) });
      }
    };
  }

  handleExportClick() {
    const { date: dateState } = this.state;

    // Don't proceed unless have valid user start date (null is valid)

    const date = dateState || new Date(null);
    if (isValid(date) && date <= endOfYesterday()) {
      getBillings({ start: endOfDay(date), end: endOfYesterday() }).then(() => {
        this.setState({ openExport: false });
      });
    }
  }

  handleLocationChanged(locationState) {
    const {
      expansionsNewResults: expansionsNewResultsState,
      index: indexState,
      patientId: prevPatientId,
      newResults,
      seeAll: seeAllState,
      tabIndex: prevTabIndex,
    } = this.state;

    const { expansionsChart, expansionsNewResults, index, patientId, profile, seeAll, tabIndex } = locationState || {};

    // Update new results expansions if going home from see all
    if (
      !seeAll &&
      seeAllState === 'newResults' &&
      !patientId &&
      !prevPatientId &&
      (!expansionsNewResults || expansionsNewResults.length !== 0)
    ) {
      history.update({ expansionsNewResults: [] });
      return;
    }

    // Update new results expansions if going to see all from home
    if (seeAll === 'newResults' && !seeAllState && !patientId && !prevPatientId) {
      this.handleSaveScrollHistory('seeAll', 0);
      const newExpansionsNewResults =
        expansionsNewResultsState.length !== 0 ? expansionsNewResultsState : [newResults[0].id];
      if (
        !newExpansionsNewResults.every((item) => expansionsNewResults && expansionsNewResults.includes(item)) ||
        (expansionsNewResults && !expansionsNewResults.every((item) => newExpansionsNewResults.includes(item)))
      ) {
        history.update({ expansionsNewResults: newExpansionsNewResults });
        return;
      }
    }

    // Ignore changes to see all and new results expansions if going to chart
    let newExpansionsNewResults = expansionsNewResults || [];
    let newSeeAll = seeAll;
    if (patientId && !prevPatientId) {
      newExpansionsNewResults = expansionsNewResultsState;
      newSeeAll = seeAllState;
    }

    this.setState({
      expansionsChart,
      expansionsNewResults: newExpansionsNewResults,
      index: index !== undefined ? index : indexState,
      openChartNote: false,
      openExport: false,
      openMenu: false,
      patientId,
      profile,
      seeAll: newSeeAll,
      tabDirection: tabIndex < prevTabIndex ? 'left' : 'right',
      tabIndex,
    });

    if (patientId !== prevPatientId) {
      if (patientId) {
        apiOnPatientChanged(patientId, this.handlePatientChanged);
        apiOnBillingsChanged(patientId, this.handleBillingsChanged);
      } else {
        apiOnPatientChanged();
        this.handlePatientChanged();
        apiOnBillingsChanged();
        this.handleBillingsChanged();
      }
    }
  }

  handleMenuClose(event) {
    if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) return;

    this.setState({ openMenu: false });
  }

  handleNewResultsChanged(newResults) {
    this.waitForIntiailNewResults = false;

    Promise.all(this.notifiers).then(() => {
      const { expansionsNewResults, newResultsToFilter } = this.state;

      this.setState({
        expansionsNewResults: expansionsNewResults.filter((item) => newResults.some((value) => value.id === item)),
        newResults,
        newResultsToFilter: newResultsToFilter.filter((item) => newResults.some((value) => value.id === item.id)),
      });
    });
  }

  handlePatientChanged(patient) {
    this.waitForInitialPatient = false;
    if (this.waitForInitialBillings) {
      this.setState({ patient });
      return;
    }
    Promise.all(this.notifiers).then(() => {
      this.notifiers = [];
      this.setState({ patient });
    });
  }

  static handlePatientVerified() {
    history.update({ tabIndex: 1 });
  }

  handleProfileClick() {
    this.handleResetScrollHistory('profile')();
    history.push({ profile: true });
  }

  handleResetScrollHistory(context) {
    return () => {
      this.scrollHistory[context] = 0;
    };
  }

  handleSaveScrollHistory(context, scrollTop) {
    this.scrollHistory[context] = scrollTop;
  }

  static handleSignOutClick() {
    history.replace({});
    apiSignOut();
  }

  addNotifier(notifier) {
    this.notifiers.push(notifier);
  }

  render() {
    const { classes, user } = this.props;
    const {
      billings,
      chartUpdates,
      date,
      expansionsChart,
      expansionsNewResults,
      index,
      newResults,
      newResultsToFilter,
      openChartNote,
      openExport,
      openMenu,
      patient,
      profile,
      seeAll,
      tabDirection,
      tabIndex,
    } = this.state;

    if (
      this.waitForInitialBillings ||
      this.waitForInitialChartUpdates ||
      this.waitForIntiailNewResults ||
      this.waitForInitialPatient
    ) {
      return null;
    }

    const showChart = patient && tabIndex !== undefined;

    const newResultsFiltered = newResults
      ? newResults.filter((item) => !newResultsToFilter.some((value) => value.id === item.id))
      : [];

    if (profile) {
      return (
        <FadeSlideReplace transitionKey="profile">
          <div className={classes.root}>
            <div className={classes.container}>
              <AppBar handleBackClick={() => history.goBack()} title="Profile" />
              <Scroll
                className={clsx(classes.main, classes.flexColumn)}
                handleSave={(scrollTop) => this.handleSaveScrollHistory('profile', scrollTop)}
                scrollTop={this.scrollHistory.profile}
              >
                <div className={clsx(classes.content, classes.column1)}>
                  <Profile user={user} />
                </div>
              </Scroll>
            </div>
          </div>
        </FadeSlideReplace>
      );
    }

    if (showChart) {
      return (
        <>
          <FadeSlideReplace transitionKey="chart">
            <div className={classes.root}>
              <div className={classes.container}>
                <SearchAppBar handleResetChartScrollHistory={this.handleResetScrollHistory('chart')} />
                <AppBar handleBackClick={() => history.goBack()} title="Patient chart">
                  <AppBarUser
                    handleAccountIconClick={this.handleAccountIconClick}
                    handleMenuClose={this.handleMenuClose}
                    handleProfileClick={this.handleProfileClick}
                    handleSignOutClick={this.constructor.handleSignOutClick}
                    openMenu={openMenu}
                    user={user}
                  />
                </AppBar>
                <Tabs
                  centered
                  classes={{
                    root: clsx(classes.tabs, classes.column1),
                    indicator: clsx(classes.tabIndicator, classes[`tabIndicator${tabIndex}`]),
                  }}
                  onChange={(_, newTabIndex) => {
                    history.update({ tabIndex: newTabIndex });
                  }}
                  TabIndicatorProps={{ children: <div /> }}
                  textColor="primary"
                  value={patient.identity !== 'verified' ? 0 : tabIndex}
                >
                  <Tab
                    className={classes.tab}
                    label={
                      <Badge
                        className={classes.tabDot}
                        invisible={apiIsUserPhysician() || patient.identity !== 'submitted'}
                        variant="dot"
                      >
                        Patient info
                      </Badge>
                    }
                  />
                  {patient.identity === 'verified' && (
                    <Tab
                      className={classes.tab}
                      label={
                        <Badge
                          className={classes.tabDot}
                          invisible={
                            apiIsUserPhysician()
                              ? !patient.screenings.some((value) => hasLatestTestNotReviewed(value)) &&
                                getChartUpdates(patient).length === 0
                              : getChartUpdates(patient).length === 0
                          }
                          variant="dot"
                        >
                          Screenings
                        </Badge>
                      }
                    />
                  )}
                  {patient.identity === 'verified' && (
                    <Tab
                      className={classes.tab}
                      label={
                        <Badge className={classes.tabDot} invisible={!unviewedChartNotes(patient)} variant="dot">
                          Chart notes
                        </Badge>
                      }
                    />
                  )}
                </Tabs>
                <div className={classes.demographicsContainer}>
                  <span>
                    <Typography className={clsx(classes.patient, classes.selectText)} variant="h6">
                      {getFullName(patient)}
                    </Typography>
                    <Typography className={clsx(classes.demographics, classes.selectText)} variant="caption">
                      {`${distanceInYears(parseDOB(patient.dob))} yo (${genderShortName(patient.gender)})`}
                      <Separator />
                      {patient.phn}
                    </Typography>
                  </span>
                  {tabIndex === 0 && !apiIsUserPhysician() && (
                    <div className={classes.titleBadge}>
                      {patient.identity === 'submitted' && (
                        <LabelBadge className={classes.badge} label="Verify identity" />
                      )}
                      {patient.identity === 'rejected' && (
                        <LabelBadge
                          className={classes.badge}
                          label={`Identity ${patient.identity}`}
                          variant="secondary"
                        />
                      )}
                    </div>
                  )}
                  {tabIndex === 2 && (
                    <Button className={classes.addNoteButton} onClick={this.handleAddChartNoteClick} variant="outlined">
                      <AddIcon className={classes.iconAdd} color="primary" />
                      Add new note
                    </Button>
                  )}
                </div>
                <Scroll
                  className={clsx(classes.chart, classes.flexColumn)}
                  handleSave={(scrollTop) => this.handleSaveScrollHistory('chart', scrollTop)}
                  scrollTop={this.scrollHistory.chart}
                >
                  <div className={classes.content}>
                    <FadeSlideOver direction={tabDirection} transitionKey={tabIndex.toString()}>
                      <div className={clsx(classes.content, classes.column1, classes.contentAnchor)}>
                        <div className={classes.contentChart}>
                          {(patient.identity === 'verified' && tabIndex === 2 && (
                            <ChartChartNotes billings={billings} patient={patient} user={user} />
                          )) ||
                            (patient.identity === 'verified' && tabIndex === 1 && (
                              <ChartScreenings
                                addWaitNotifier={this.addNotifier}
                                expansions={expansionsChart}
                                patient={patient}
                              />
                            )) || (
                              <ChartPatientInfo
                                handlePatientVerified={this.constructor.handlePatientVerified}
                                patient={patient}
                              />
                            )}
                        </div>
                      </div>
                    </FadeSlideOver>
                  </div>
                </Scroll>
              </div>
            </div>
          </FadeSlideReplace>
          {
            // Unmount if not using to clear dialog description state
            openChartNote && (
              <DialogMultilineText
                handleClose={this.handleChartNoteClose}
                handleSaveClick={(description) => {
                  const note = chartNoteUserNote(description, user);
                  return apiAddChartNote(note, patient).then(this.handleChartNoteClose);
                }}
                open={openChartNote}
                placeholder="Note description"
                title="Add a new chart note"
              />
            )
          }
        </>
      );
    }

    if (seeAll) {
      return (
        <FadeSlideReplace transitionKey={seeAll}>
          <div className={classes.root}>
            <div className={classes.container}>
              <SearchAppBar handleResetChartScrollHistory={this.handleResetScrollHistory('chart')} />
              <AppBar
                handleBackClick={() => history.goBack()}
                title={
                  <>
                    {seeAll === 'chartUpdates' && 'Chart updates'}
                    {seeAll === 'newResults' && 'New results'}
                    <Typography className={classes.count} color="textSecondary" component="span" variant="h6">
                      {seeAll === 'chartUpdates' && `(${chartUpdates.length})`}
                      {seeAll === 'newResults' && `(${newResultsFiltered.length})`}
                    </Typography>
                  </>
                }
              >
                <AppBarUser
                  handleAccountIconClick={this.handleAccountIconClick}
                  handleMenuClose={this.handleMenuClose}
                  handleProfileClick={this.handleProfileClick}
                  handleSignOutClick={this.constructor.handleSignOutClick}
                  openMenu={openMenu}
                  user={user}
                />
              </AppBar>
              <Scroll
                className={classes.main}
                handleSave={(scrollTop) => this.handleSaveScrollHistory('seeAll', scrollTop)}
                scrollTop={this.scrollHistory.seeAll}
              >
                <div className={clsx(classes.content, classes.column1)}>
                  <div className={clsx(classes.content, classes.columnContent)}>
                    <div className={classes.rail} />
                    <div className={classes.gutter} />
                    <div className={classes.component}>
                      {seeAll === 'chartUpdates' && (
                        <ChartUpdates
                          chartUpdates={chartUpdates}
                          handleResetChartScrollHistory={this.handleResetScrollHistory('chart')}
                        />
                      )}
                      {seeAll === 'newResults' && (
                        <NewResults
                          addWaitNotifier={this.addNotifier}
                          expansions={expansionsNewResults}
                          handleAcceptClick={this.handleAcceptClick}
                          handleExpandClick={this.handleExpandClick}
                          handleResetChartScrollHistory={this.handleResetScrollHistory('chart')}
                          newResults={newResults}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Scroll>
            </div>
          </div>
        </FadeSlideReplace>
      );
    }

    return (
      <>
        <FadeSlideReplace transitionKey="main">
          <div className={classes.root}>
            <div className={classes.container}>
              <AppBar>
                <AppBarUser
                  handleAccountIconClick={this.handleAccountIconClick}
                  handleMenuClose={this.handleMenuClose}
                  handleProfileClick={this.handleProfileClick}
                  handleSignOutClick={this.constructor.handleSignOutClick}
                  openMenu={openMenu}
                  user={user}
                />
              </AppBar>
              <div className={classes.main}>
                <div className={clsx(classes.content, classes.column1)}>
                  <Search handleResetChartScrollHistory={this.handleResetScrollHistory('chart')} />
                  <div className={clsx(classes.content, classes.columnContent)}>
                    <div className={classes.rail}>
                      <ButtonBase
                        className={clsx(
                          classes.railButton,
                          classes.flexColumn,
                          index === 0 ? classes.selected : classes.unselected,
                        )}
                        onClick={() => {
                          history.update({ index: 0 });
                        }}
                      >
                        <div className={classes.railIcon}>
                          <Badge
                            classes={{ badge: classes.badge }}
                            badgeContent={
                              newResultsFiltered.length ? (
                                <span className={classes.fontFamily2}>{newResultsFiltered.length}</span>
                              ) : null
                            }
                            color={apiIsUserPhysician() ? 'primary' : 'secondary'}
                          >
                            <FeedIcon />
                          </Badge>
                        </div>
                        <Typography className={clsx(classes.railLabel, classes.fontFamily2)} variant="caption">
                          New results
                        </Typography>
                      </ButtonBase>
                      <ButtonBase
                        className={clsx(
                          classes.railButton,
                          classes.flexColumn,
                          index === 1 ? classes.selected : classes.unselected,
                        )}
                        onClick={() => {
                          history.update({ index: 1 });
                        }}
                      >
                        <div className={classes.railIcon}>
                          <Badge
                            badgeContent={
                              chartUpdates && chartUpdates.length !== 0 ? (
                                <span className={classes.fontFamily2}>{chartUpdates.length}</span>
                              ) : null
                            }
                          >
                            <MedicalInformationIcon />
                          </Badge>
                        </div>
                        <Typography className={clsx(classes.railLabel, classes.fontFamily2)} variant="caption">
                          Chart updates
                        </Typography>
                      </ButtonBase>
                      {!apiIsUserPhysician() && (
                        <ButtonBase
                          className={clsx(classes.railButton, classes.flexColumn)}
                          onClick={this.handleBillingExportClick}
                        >
                          <div className={classes.railIcon}>
                            <FileDownloadIcon />
                          </div>
                          <Typography className={clsx(classes.railLabel, classes.fontFamily2)} variant="caption">
                            Billing export
                          </Typography>
                        </ButtonBase>
                      )}
                    </div>
                    <div className={classes.gutter} />
                    {newResults && chartUpdates && (
                      <FadeSlideReplace transitionKey={index.toString()}>
                        <div className={classes.component}>
                          <div className={classes.titleContainer}>
                            <Typography className={classes.title} variant="h4">
                              {index === 0 ? 'New results' : 'Chart updates'}
                            </Typography>
                          </div>
                          {index === 0 ? (
                            <NewResults
                              addWaitNotifier={this.addNotifier}
                              expansions={expansionsNewResults}
                              handleAcceptClick={this.handleAcceptClick}
                              handleExpandClick={this.handleExpandClick}
                              handleResetChartScrollHistory={this.handleResetScrollHistory('chart')}
                              handleSeeAllClick={() => {
                                this.handleSaveScrollHistory('seeAll', 0);
                                history.push({
                                  seeAll: 'newResults',
                                  expansionsNewResults:
                                    expansionsNewResults.length === 0 ? [newResults[0].id] : expansionsNewResults,
                                });
                              }}
                              newResults={newResults}
                            />
                          ) : (
                            <ChartUpdates
                              chartUpdates={chartUpdates}
                              handleResetChartScrollHistory={this.handleResetScrollHistory('chart')}
                              handleSeeAllClick={() => {
                                this.handleSaveScrollHistory('seeAll', 0);
                                history.push({ seeAll: 'chartUpdates' });
                              }}
                            />
                          )}
                        </div>
                      </FadeSlideReplace>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FadeSlideReplace>
        <DialogPaper
          open={openExport}
          onClose={this.handleClose}
          BackdropProps={{ className: classes.dialogBackdrop }}
          PaperProps={{ className: classes.dialogPaper }}
        >
          <DialogTitle title="Billing export" />
          <DialogContent>
            <DialogContentText className={classes.dialogContentText}>
              Enter the date of the newest billing from your last export, or leave blank if exporting for the first
              time.
            </DialogContentText>
            <div className={classes.selectDate}>
              <KeyboardDatePicker
                autoOk
                format="yyyy-MM-dd"
                helperText={
                  isValidKeyboardDate(date) && date <= endOfYesterday() ? 'yyyy-mm-dd' : 'Date is not in the past'
                }
                inputVariant="outlined"
                InputAdornmentProps={{ position: 'end' }}
                label="Date newest billing"
                maxDate={endOfYesterday()}
                onChange={this.handleDateChange}
                value={date}
                variant="inline"
              />
            </div>
            <DialogContentText className={clsx(classes.dialogContentText, classes.dialogItalic)}>
              <sup>*</sup>Does not export any billings from today
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button color="primary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              disableRipple={!(date === null || (isValid(date) && date <= endOfYesterday()))}
              onClick={this.handleExportClick}
            >
              Export
            </Button>
          </DialogActions>
        </DialogPaper>
      </>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withStyles(styles)(Container);
