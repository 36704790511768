import SvgIcon from '@material-ui/core/SvgIcon';

function LogoStacked(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 539.28 600">
      <g id="Layer_1-2">
        <g>
          <path
            d="M22.21,362.38c0-25.47,13.27-38.2,39.81-38.2H199.53c10.99,0,19.43,2.68,25.33,8.04,5.89,5.37,8.85,12.86,8.85,22.51,0,5.1-.8,10.59-2.42,16.49-1.61,5.9-3.62,11.13-6.03,15.68H89.36v43.02h104.94c11.79,0,20.44,2.75,25.93,8.25,5.49,5.5,8.24,12.93,8.24,22.31,0,5.9-.87,11.8-2.61,17.7-1.74,5.9-3.69,10.72-5.83,14.47H89.36v44.63H231.7v62.72H22.21v-237.62Z"
            fill="#2e65ae"
          />
          <path
            d="M18.49,69.76c12.33-21.85,29.56-38.94,51.67-51.27C92.27,6.17,117.94,0,147.16,0c10.99,0,22.32,1,33.98,3.01,11.66,2.01,22.45,5.17,32.37,9.45,9.91,4.29,17.95,9.52,24.13,15.68,6.17,6.17,9.25,13.54,9.25,22.12,0,7.51-2.48,15.07-7.44,22.72-4.96,7.64-11.06,13.86-18.3,18.69-8.58-9.38-19.1-16.74-31.56-22.11-12.46-5.36-25.67-8.04-39.6-8.04-15.55,0-29.36,3.55-41.42,10.66-12.06,7.11-21.45,16.95-28.15,29.55-6.7,12.6-10.05,27.48-10.05,44.63,0,25.2,7.57,45.1,22.72,59.7,15.14,14.61,35.72,21.91,61.72,21.91,12.06,0,23.78-1.61,35.18-4.82,11.39-3.22,20.84-7.37,28.35-12.46l30.96,51.87c-7.24,5.37-16.36,10.19-27.35,14.47-10.99,4.29-22.78,7.57-35.38,9.85-12.6,2.27-25.33,3.41-38.2,3.41-29.76,0-55.76-5.96-78.01-17.88-22.25-11.93-39.54-28.68-51.87-50.26C6.16,200.57,0,175.3,0,146.36s6.16-54.75,18.49-76.6Z"
            fill="#2e65ae"
          />
          <path
            d="M287.98,352.72c0-19.03,11.66-28.54,34.98-28.54,5.63,0,11.12,.54,16.48,1.61,5.36,1.08,10.59,2.68,15.68,4.82v102.53c9.65-10.99,19.9-22.32,30.76-33.97,10.85-11.66,21.17-22.52,30.96-32.57,9.78-10.05,18.3-18.16,25.53-24.33,7.24-6.43,14.07-11.06,20.51-13.87,6.43-2.81,13.27-4.22,20.51-4.22,8.57,0,17.15,1.8,25.73,5.42,8.57,3.62,14.74,8.11,18.5,13.47-9.92,9.92-20.38,20.51-31.37,31.77-10.99,11.26-21.65,22.19-31.96,32.77-10.32,10.59-19.3,19.9-26.94,27.94-7.64,8.04-13.07,13.81-16.29,17.29,15.54,16.62,29.96,32.84,43.23,48.65,13.27,15.82,26,31.7,38.2,47.65,12.19,15.95,24.46,32.91,36.79,50.86h-82.43c-10.19-13.93-21.38-28.35-33.57-43.22-12.21-14.87-24.27-29.01-36.19-42.42-11.93-13.41-22.59-24.79-31.96-34.18v119.82h-67.15v-247.28Z"
            fill="#2e65ae"
          />
          <rect x="234.71" y="110.96" width="76.42" height="76.42" fill="#7aa9d6" />
          <rect x="311.12" y="187.38" width="76.42" height="76.42" fill="#7aa9d6" />
          <rect x="311.12" y="34.54" width="76.42" height="76.42" fill="#7aa9d6" />
          <path
            d="M515.46,8.84c-5.37-1.07-10.85-1.61-16.49-1.61-23.32,0-34.98,9.52-34.98,28.54V110.96h-76.46v76.42h76.46v95.68h67.15V13.67c-5.1-2.14-10.32-3.75-15.68-4.82Z"
            fill="#2e65ae"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default LogoStacked;
