import { getHistoryPreviousValue, historyItem as historyItemUtils, historyItemValue } from './chartHistoryUtils';
import { distanceInCalendarMonths, distanceInYears, parseDOB, startOfToday } from './date';
import { getAge } from './patientUtils';
import {
  newRecommendationAgingIn,
  newRecommendationNoScheduledTest,
  newRecommendationReferral,
  newRecommendationScheduleTest,
} from './recommendation';
import { dueOptionsAddNowIfNeeded } from './screeningUtils';
import { capitalize } from './string';
import { specialistRecommendationOrDefault } from './tests';

export function testTypeNameLung(type) {
  if (type === 'chestCt') return 'low dose CT chest';
  // Present to user as consult
  if (type === 'questionnaire') return 'patient consult';
  return type;
}

export function testReleaseTypeLung(type) {
  if (type === 'chestCt') return 'requisition';
  if (type === 'questionnaire') return 'other';
  return null;
}

function valueNameLung(value) {
  if (value === 'negative') return 'Negative';
  if (value === 'abnormalFurtherImaging') return 'Abnormal - further imaging';
  if (value === 'abnormalReferral') return 'Abnormal - referral';
  return value;
}

export const testValueNameLung = (test) => valueNameLung(test.value);

export const menuOptionsTestTypeLung = () => [[{ name: capitalize(testTypeNameLung('chestCt')), value: 'chestCt' }]];

export const menuOptionsTestResultLung = (test) =>
  test.type === 'chestCt'
    ? [
        [{ name: valueNameLung('negative'), value: 'negative' }],
        [
          { name: valueNameLung('abnormalFurtherImaging'), value: 'abnormalFurtherImaging' },
          { name: valueNameLung('abnormalReferral'), value: 'abnormalReferral' },
        ],
      ]
    : [];

// Note: Specialist recommendations value is in months

export const menuOptionsSpecialistRecommendationLung = (test) =>
  test.type === 'chestCt'
    ? [
        { name: 'Retest in 1 year', value: 12 },
        { name: 'Retest in 6 months', value: 6 },
        { name: 'Retest in 3 months', value: 3 },
      ]
    : [];

export function specialistRecommendationNameLung(recommendation) {
  if (recommendation === 3) return 'Retest in 3 months';
  if (recommendation === 6) return 'Retest in 6 months';
  if (recommendation === 12) return 'Retest in 1 year';
  return '';
}

function packsAsNumber(packs) {
  if (packs === 'twoPacks') return 2;
  if (packs === 'onePack') return 1;
  if (packs === 'halfPack') return 0.5;
  return 0;
}

const menuOptionsPacks = [
  { name: 'More than one pack', value: 'twoPacks' },
  { name: 'Half pack to one pack', value: 'onePack' },
  { name: 'Less than half a pack', value: 'halfPack' },
];

export const newDefaultTestLung = () => ({
  date: null,
  type: 'chestCt',
  value: null,
});

export const getConsultWindowLung = (nextTestType) =>
  ['chestCt', 'questionnaire'].includes(nextTestType) ? { days: 30 } : null;

export function handleTestEditChangeLung(key, newValue, test) {
  // Remove specialist recommendation from current test
  if (key === 'value' && ['negative', 'abnormalReferral'].includes(newValue) && test.type === 'chestCt') {
    const { recommendation, ...other } = test;
    return { ...other, [key]: newValue };
  }
  // Add specialist recommendation to current test
  if (key === 'value' && newValue === 'abnormalFurtherImaging' && test.type === 'chestCt') {
    return { ...test, [key]: newValue, recommendation: null };
  }
  return { ...test, [key]: newValue };
}

export function dateLastSmoked(yearQuit, patient) {
  const { dob } = patient;
  let date = startOfToday();
  if (yearQuit) {
    date = parseDOB(dob);
    date.setFullYear(yearQuit);
  }
  return date;
}

export const ageLastSmoked = (yearQuit, patient) => getAge(patient, dateLastSmoked(yearQuit, patient));

const notSmoked15Years = (yearQuit, patient) => yearQuit && distanceInYears(dateLastSmoked(yearQuit, patient)) >= 15;

const heavySmoker = (haveSmoked, startAge, packs, yearQuit, patient) => {
  if (haveSmoked) {
    if ((ageLastSmoked(yearQuit, patient) - startAge) * packsAsNumber(packs) >= 30) {
      return true;
    }
  }
  return false;
};

// Check for 3 negative chest CTs, each within 18 months of the next
function tooManyChestCts(screening) {
  const chestCts = screening.tests.filter((test) => test.type === 'chestCt' && test.value === 'negative');
  const dateDiffs = [];
  let tooMany = false;

  if (chestCts.length >= 3) {
    chestCts.reduce((previous, current) => {
      dateDiffs.push(distanceInCalendarMonths(current.date, previous.date));
      return current;
    });
  }
  if (dateDiffs.length >= 2) {
    dateDiffs.reduce((previous, current) => {
      if (previous < 18 && current < 18) tooMany = true;
      return current;
    });
  }
  return tooMany;
}

//
// Medical history items
//

// ** Firestore field values only displayed when editing social history

const haveSmokedHistoryItem = { type: 'socialHistory', field: 'haveSmoked', questionnaire: true };
const startAgeHistoryItem = { type: 'socialHistory', field: 'startAge', questionnaire: true };
const packsHistoryItem = { type: 'socialHistory', field: 'packs', questionnaire: true };
const quitSmokingHistoryItem = { type: 'socialHistory', field: 'quitSmoking', questionnaire: true };
const yearQuitHistoryItem = { type: 'socialHistory', field: 'yearQuit', questionnaire: true };

// ** Display items of chart history

function getValueSmoker(haveSmoked, startAge, packs, yearQuit, patient) {
  if (!haveSmoked) {
    return 'Non-smoker';
  }
  if (notSmoked15Years(yearQuit, patient)) {
    return 'Not smoked in last 15 years';
  }
  if (heavySmoker(haveSmoked, startAge, packs, yearQuit, patient)) {
    return 'History of heavy smoking';
  }
  return 'Smoker';
}

export const historyItemsLung = [
  haveSmokedHistoryItem,
  startAgeHistoryItem,
  packsHistoryItem,
  quitSmokingHistoryItem,
  yearQuitHistoryItem,
  { type: 'personalHistory', field: 'cancer', name: 'Diagnosed lung cancer', questionnaire: true, showChart: true },
  {
    type: 'personalHistory',
    field: 'previousChestCts',
    name: 'Previous annual chest CTs',
    showChart: true,
    readOnly: true,
    getValue: (screening) => tooManyChestCts(screening),
  },
  {
    // Not directly saved back to firestore
    type: 'socialHistory',
    field: 'smoker',
    showChart: true,
    onlyEdit: true,
    getName: (value) => value,
    getValue: (screening, patient) =>
      getValueSmoker(
        historyItemValue(haveSmokedHistoryItem, screening, patient).value,
        historyItemValue(startAgeHistoryItem, screening, patient).value,
        historyItemValue(packsHistoryItem, screening, patient).value,
        historyItemValue(yearQuitHistoryItem, screening, patient).value,
        patient,
      ),
    getChanged: (value, screening, patient) => {
      const previousHaveSmoked = getHistoryPreviousValue(haveSmokedHistoryItem, screening, patient);
      const previousStartAge = getHistoryPreviousValue(startAgeHistoryItem, screening, patient);
      const previousPacks = getHistoryPreviousValue(packsHistoryItem, screening, patient);
      const previousYearQuit = getHistoryPreviousValue(yearQuitHistoryItem, screening, patient);
      return (
        value &&
        (previousHaveSmoked === undefined ||
          previousStartAge === undefined ||
          previousPacks === undefined ||
          previousYearQuit === undefined ||
          value !== getValueSmoker(previousHaveSmoked, previousStartAge, previousPacks, previousYearQuit, patient))
      );
    },
  },
];

const historyItem = (field) => historyItemUtils(field, historyItemsLung);

//
// Screening rules
//

const screeningMinAgeLung = 55;

export function getScreeningHighRiskLung(screening, patient) {
  let personalHistory = false;
  const indicators = new Set();

  // History of heavy smoking

  const haveSmoked = historyItemValue(haveSmokedHistoryItem, screening, patient);
  const startAge = historyItemValue(startAgeHistoryItem, screening, patient);
  const packs = historyItemValue(packsHistoryItem, screening, patient);
  const yearQuit = historyItemValue(yearQuitHistoryItem, screening, patient);

  if (heavySmoker(haveSmoked.value, startAge.value, packs.value, yearQuit.value, patient)) {
    personalHistory = true;
    indicators.add('History of heavy smoking');
  }

  return {
    personalHistory,
    indicators: [...indicators],
    value: personalHistory,
  };
}

export function getScreeningVeryHighRiskLung(screening, patient) {
  const indicators = new Set();
  let value = false;

  // Diagnosed lung cancer

  const cancer = historyItemValue(historyItem('cancer'), screening, patient);
  if (cancer.value) {
    indicators.add(cancer.name);
    value = true;
  }

  return {
    indicators: [...indicators],
    value,
  };
}

export function getScreeningNotApplicableLung(screening, patient) {
  const indicators = new Set();
  let value = false;

  // Prioritize very high risk

  if (!getScreeningVeryHighRiskLung(screening, patient).value) {
    // Previous annual chest CTs (3 within 18 months)
    const previousChestCts = historyItemValue(historyItem('previousChestCts'), screening, patient);
    if (previousChestCts.value) {
      indicators.add(previousChestCts.name);
      value = true;
    }

    // Non-smoker or not smoked in last 15 years

    const haveSmoked = historyItemValue(haveSmokedHistoryItem, screening, patient);
    const yearQuit = historyItemValue(yearQuitHistoryItem, screening, patient);
    if (!haveSmoked.value || notSmoked15Years(yearQuit.value, patient)) {
      indicators.add('Non-smoker');
      value = true;
    }
  }

  return {
    indicators: [...indicators],
    value,
  };
}

export const getScreeningAverageRiskIndicatorsLung = () => ['Some history of smoking'];

export function screeningRisksLung(screening, patient) {
  const { personalHistory } = getScreeningHighRiskLung(screening, patient);
  return {
    highRisk: {
      personalHistory,
    },
    veryHighRisk: getScreeningVeryHighRiskLung(screening, patient).value,
    notApplicable: getScreeningNotApplicableLung(screening, patient).value,
  };
}

//
// Recommendation
//

// Includes possible specialist recommendation
const defaultDueOptions = (latestTest) =>
  latestTest
    ? [
        { name: '1 year from test', duration: { months: 12 } },
        { name: '6 months from test', duration: { months: 6 } },
        { name: '3 months from test', duration: { months: 3 } },
      ]
    : [];

export function screeningNextTestDueOptionsLung(recommendation, latestTest) {
  if (['chestCt', 'questionnaire'].includes(recommendation.type)) {
    if (!recommendation.agedIn) {
      const minAge = screeningMinAgeLung;
      return [{ name: `age ${minAge}`, duration: { months: minAge * 12 } }];
    }
    if (recommendation.type === 'questionnaire') {
      return [{ name: 'in 1 year', duration: { months: 12 } }];
    }
    return dueOptionsAddNowIfNeeded(recommendation, defaultDueOptions(latestTest));
  }
  return [];
}

export function screeningRecommendationLung(latestTest, screening, patient) {
  const { date, type, value } = latestTest || {};

  const isHighRisk = getScreeningHighRiskLung(screening, patient).value;
  const age = getAge(patient, date);
  const minAge = screeningMinAgeLung;

  if (latestTest && (age >= minAge || isHighRisk || value !== 'negative')) {
    if (type === 'chestCt') {
      if (value === 'negative') {
        return newRecommendationScheduleTest({ months: 12 }, 'chestCt', date);
      }
      if (value === 'abnormalFurtherImaging') {
        const duration = specialistRecommendationOrDefault(latestTest, { months: 3 });
        return newRecommendationScheduleTest(duration, 'chestCt', date);
      }
      if (value === 'abnormalReferral') {
        return newRecommendationReferral();
      }
    }
  }

  const haveSmoked = historyItemValue(haveSmokedHistoryItem, screening, patient);
  if (!haveSmoked.value) {
    return newRecommendationNoScheduledTest('Patient has never smoked and does not need screening.');
  }

  if (minAge <= age) {
    return !isHighRisk
      ? newRecommendationScheduleTest({ months: 12 }, 'questionnaire')
      : newRecommendationScheduleTest({ months: 0 }, 'chestCt', date);
  }

  // Not agedIn
  return newRecommendationAgingIn({ months: minAge * 12 }, !isHighRisk ? 'questionnaire' : 'chestCt', patient);
}

export const callPatientLung = (recommendation, latestTest) =>
  recommendation.referral ||
  (latestTest && latestTest.type === 'chestCt' && latestTest.value === 'abnormalFurtherImaging') ||
  false;

export const referPatientLung = (recommendation) => recommendation.referral;

//
// Chart medical history
//

export const getEditChartHistoryDialogValuesLung = (screening, patient) => ({
  haveSmoked: {
    label: 'Smoked more than a year?',
    item: haveSmokedHistoryItem,
    value: historyItemValue(haveSmokedHistoryItem, screening, patient).value,
  },
  startAge: {
    label: 'Age start smoking',
    item: startAgeHistoryItem,
    value: historyItemValue(startAgeHistoryItem, screening, patient).value,
    options: Array.from({ length: 75 - 13 }, (_, i) => i + 13).map((option) => ({
      name: option,
      value: option,
    })),
  },
  packs: {
    label: 'Average packs per day',
    item: packsHistoryItem,
    value: historyItemValue(packsHistoryItem, screening, patient).value,
    options: menuOptionsPacks,
  },
  quitSmoking: {
    label: 'Quit smoking?',
    item: quitSmokingHistoryItem,
    value: historyItemValue(quitSmokingHistoryItem, screening, patient).value,
  },
  yearQuit: {
    label: 'Year stop smoking',
    item: yearQuitHistoryItem,
    value: historyItemValue(yearQuitHistoryItem, screening, patient).value,
  },
});

//
// Referral
//

export const referralReminderRepeatDurationLung = () => null;
