import MuiDialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { colorTheme } from '../constants/theme';

const styles = (theme) => ({
  backdrop: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
  },
  paper: {
    borderRadius: 10,
    margin: 0,
    minWidth: 280,
    maxWidth: 460,
    padding: theme.spacing(1, 0, 0.25, 0),
  },
  paperLarge: {
    borderRadius: 10,
    margin: 0,
    width: 516,
    padding: theme.spacing(1.5, 1, 1.25),
  },
});

function DialogPaper(props) {
  const { classes, PaperProps, size, ...other } = props;

  return (
    <ThemeProvider theme={colorTheme}>
      <MuiDialog
        BackdropProps={{ className: classes.backdrop }}
        PaperProps={{
          classes: { root: size === 'large' ? classes.paperLarge : classes.paper },
          elevation: 4,
          ...PaperProps,
        }}
        {...other} // eslint-disable-line react/jsx-props-no-spreading
      />
    </ThemeProvider>
  );
}

DialogPaper.propTypes = {
  classes: PropTypes.object.isRequired,
  PaperProps: PropTypes.object,
  size: PropTypes.oneOf(['default', 'large']),
};

DialogPaper.defaultProps = {
  PaperProps: {},
  size: 'default',
};

export default withStyles(styles)(DialogPaper);
