import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { removeLineBreaks } from '../utils/string';
import Button from './Button';
import DialogPaper from './DialogPaper';
import DialogTitle from './DialogTitle';
import MultilineTextField from './MultilineTextField';
import { dialogActions } from './styles';

const styles = (theme) => ({
  dialogActions: {
    ...dialogActions(theme),
    marginTop: theme.spacing(1.5),
  },
  dialogContent: {
    padding: theme.spacing(2, 3),
  },
  paper: {
    width: 460,
  },
});

class DialogMultilineText extends Component {
  constructor(props) {
    super(props);

    const { value } = props;

    this.state = {
      value,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.valid = this.valid.bind(this);
  }

  handleChange = (event) => {
    this.setState({ value: removeLineBreaks(event.target.value) });
  };

  // Dialog close
  handleClose() {
    const { handleClose: handleCloseProp } = this.props;

    this.setState({ value: null });

    handleCloseProp();
  }

  handleSaveClick() {
    const { handleSaveClick: handleSaveClickProp } = this.props;
    const { value } = this.state;

    handleSaveClickProp(value);

    this.setState({ value: null });
  }

  valid() {
    const { value } = this.state;

    return value !== null;
  }

  render() {
    const { classes, open, placeholder, title } = this.props;
    const { value } = this.state;

    return (
      <DialogPaper open={open} onClose={this.handleClose} PaperProps={{ className: classes.paper }} size="large">
        <DialogTitle handleClose={this.handleClose} title={title} />
        <div className={classes.dialogContent}>
          <MultilineTextField
            autoFocus
            fullWidth
            onChange={this.handleChange}
            placeholder={placeholder}
            type="text"
            value={value || ''}
          />
        </div>
        <DialogActions className={classes.dialogActions}>
          <Button color="primary" disabled={!this.valid()} onClick={this.handleSaveClick} variant="contained">
            Save
          </Button>
        </DialogActions>
      </DialogPaper>
    );
  }
}

DialogMultilineText.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
};

DialogMultilineText.defaultProps = {
  value: null,
};

export default withStyles(styles)(DialogMultilineText);
