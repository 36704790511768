import MuiTabs from '@material-ui/core/Tabs';
import { ThemeProvider } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { colorTheme } from '../constants/theme';

function Tabs(props) {
  const { children, ...other } = props;

  return (
    <ThemeProvider theme={colorTheme}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <MuiTabs {...other}>{children}</MuiTabs>
    </ThemeProvider>
  );
}

Tabs.propTypes = {
  children: PropTypes.node,
};

Tabs.defaultProps = {
  children: null,
};

export default Tabs;
