import DialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import Tab from '@material-ui/core/Tab';
import { Typography } from '@material-ui/core/index';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { primary, primary60 } from '../constants/colors';
import { setChartHistoryItem } from '../utils/screenings';
import { capitalize } from '../utils/string';
import Button from './Button';
import DialogPaper from './DialogPaper';
import DialogTitle from './DialogTitle';
import Tabs from './Tabs';
import TextField from './TextField';
import { dialogActions, selectPaper } from './styles';

const styles = (theme) => ({
  dialogActions: {
    ...dialogActions(theme),
    marginTop: theme.spacing(1.5),
  },
  dialogContent: {
    padding: theme.spacing(0, 1),
  },
  paper: {
    width: 460,
  },
  selectPaper: {
    ...selectPaper(theme),
    maxHeight: 240,
  },
  subtitle: {
    padding: theme.spacing(3, 2, 0.5),
  },
  tab0: {
    minWidth: 88,
    padding: theme.spacing(0.75, 2),
  },
  tab1: {
    minWidth: 72,
    padding: theme.spacing(0.75, 2),
  },
  tabIndicator: {
    height: 2,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      width: '100%',
      backgroundColor: primary,
      borderTopLeftRadius: 2,
      borderTopRightRadius: 2,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  tabIndicator0: {
    '& > div': {
      maxWidth: 88,
    },
  },
  tabIndicator1: {
    '& > div': {
      maxWidth: 72,
    },
  },
  title: {
    marginTop: theme.spacing(1),
  },
  underline: {
    '&:after': {
      borderBottomColor: primary60,
    },
    '&&:hover::before': {
      borderBottomColor: primary60,
    },
  },
  value1: {
    marginLeft: theme.spacing(2),
    width: 172,
  },
});

class ChartAddFamilyHistoryDialogBreast extends Component {
  constructor(props) {
    super(props);

    const { getValues, patient, screening } = props;

    const { values } = getValues(screening, patient, 'familyCancer');

    this.state = {
      tabIndex: 0,
      value0: values.length > 0 ? null : undefined,
      value1: values.length > 1 ? null : undefined,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);

    this.getValues = this.getValues.bind(this);
    this.valid = this.valid.bind(this);
  }

  handleChange = (index) => (event) => {
    this.setState({ [`value${index}`]: event.target.value });
  };

  // Dialog close
  handleClose() {
    const { handleClose: handleCloseProp } = this.props;

    this.setState({ tabIndex: 0, value0: undefined, value1: undefined });

    handleCloseProp();
  }

  handleSaveClick() {
    const { handleSaveClick: handleSaveClickProp, screening, patient } = this.props;
    const { value0, value1 } = this.state;

    const { item, values } = this.getValues();

    const newValue = values.reduce(
      (accumulator, value, index) => ({
        ...accumulator,
        [value.field]: index === 0 ? value0 : value1,
      }),
      {},
    );

    const items = setChartHistoryItem(item, screening, patient, newValue);

    handleSaveClickProp(items);

    this.setState({ tabIndex: 0, value0: undefined, value1: undefined });
  }

  handleTabChange(newTabIndex) {
    const { values } = this.getValues(newTabIndex);

    this.setState({
      tabIndex: newTabIndex,
      value0: values.length > 0 ? null : undefined,
      value1: values.length > 1 ? null : undefined,
    });
  }

  getValues(tabIndex = null) {
    const { getValues, screening, patient } = this.props;
    const { tabIndex: tabIndexState } = this.state;

    return getValues(
      screening,
      patient,
      (tabIndex !== null ? tabIndex : tabIndexState) === 0 ? 'familyCancer' : 'familyBrca',
    );
  }

  valid() {
    const { value0, value1 } = this.state;

    return value0 !== null && (value1 === undefined || value1 !== null);
  }

  render() {
    const { classes, open, title } = this.props;
    const { tabIndex, value0, value1 } = this.state;

    const { subtitle, vacancy, values } = this.getValues();

    return (
      <DialogPaper open={open} onClose={this.handleClose} PaperProps={{ className: classes.paper }} size="large">
        <DialogTitle className={classes.title} handleClose={this.handleClose} title={title} />
        <div className={classes.dialogContent}>
          <Tabs
            classes={{
              indicator: clsx(classes.tabIndicator, classes[`tabIndicator${tabIndex}`]),
            }}
            onChange={(_, newTabIndex) => this.handleTabChange(newTabIndex)}
            TabIndicatorProps={{ children: <div /> }}
            textColor="primary"
            value={tabIndex}
          >
            <Tab
              classes={{
                root: classes.tab0,
              }}
              label={<Typography variant="subtitle2">Breast cancer</Typography>}
            />
            <Tab
              classes={{
                root: classes.tab1,
              }}
              label={<Typography variant="subtitle2">BRCA gene</Typography>}
            />
          </Tabs>
          <div className={classes.subtitle}>
            <Typography color="textSecondary">
              {vacancy ? subtitle : 'Maximum family history count reached.'}
            </Typography>
          </div>
          {vacancy && (
            <List>
              <ListItem>
                {values.map((value, index) => (
                  <TextField
                    autoFocus={index === 0}
                    className={clsx(index === 1 && classes.value1)}
                    fullWidth
                    InputProps={{ classes: { underline: classes.underline } }}
                    key={value.label}
                    label={capitalize(value.label)}
                    onChange={this.handleChange(index)}
                    select
                    SelectProps={
                      index === 1
                        ? {
                            MenuProps: {
                              PaperProps: { className: classes.selectPaper },
                            },
                          }
                        : {}
                    }
                    value={index === 0 ? value0 || '' : value1 || ''}
                  >
                    {value.options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                ))}
              </ListItem>
            </List>
          )}
        </div>
        <DialogActions className={classes.dialogActions}>
          {vacancy ? (
            <Button color="primary" disabled={!this.valid()} onClick={this.handleSaveClick} variant="contained">
              Save
            </Button>
          ) : (
            <Button color="primary" onClick={this.handleClose} variant="contained">
              Close
            </Button>
          )}
        </DialogActions>
      </DialogPaper>
    );
  }
}

ChartAddFamilyHistoryDialogBreast.propTypes = {
  classes: PropTypes.object.isRequired,
  getValues: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  patient: PropTypes.object.isRequired,
  screening: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(ChartAddFamilyHistoryDialogBreast);
