import {
  familyMembersLimit,
  getHistoryPreviousValue,
  historyItem as historyItemUtils,
  historyItemValue,
} from './chartHistoryUtils';
import { getAge } from './patientUtils';
import { newRecommendationAgingIn, newRecommendationReferral, newRecommendationScheduleTest } from './recommendation';
import { dueOptionsAddNowIfNeeded, dueOptionsAge } from './screeningUtils';
import { capitalize } from './string';

export function testTypeNameBreast(type) {
  if (type === 'mammogram') return 'mammogram';
  if (type === 'mammogramAwbu') return 'mammogram AWBU';
  if (type === 'breastImaging') return 'breast imaging';
  if (type === 'breastBiopsy') return 'breast biopsy';
  if (type === 'dischargeLetter') return 'discharge letter';
  return type;
}

export function testReleaseTypeBreast(type) {
  if (['mammogram', 'mammogramAwbu'].includes(type)) return 'requisition';
  if (['breastImaging', 'breastBiopsy'].includes(type)) return 'booking';
  if (type === 'dischargeLetter') return 'other';
  return null;
}

function valueNameBreast(value) {
  if (value === 'negative') return 'Negative';
  if (value === 'abnormalFurtherImaging') return 'Abnormal - further imaging';
  if (value === 'abnormalBiopsy') return 'Abnormal - biopsy';
  if (value === 'abnormal') return 'Abnormal';
  if (value === 'atypicalHyperplasia') return 'Atypical hyperplasia';
  if (value === 'lobularCarcinomaInSitu') return 'Lobular carcinoma in situ';
  if (value === 'cancer') return 'Cancer';
  return value;
}

export const testValueNameBreast = (test) => valueNameBreast(test.value);

export const testValue2NameBreast = (value2) => (value2 === 'volparaD' ? 'Volpara D' : '');

export const menuOptionsTestTypeBreast = () => [
  [
    { name: capitalize(testTypeNameBreast('mammogram')), value: 'mammogram' },
    { name: capitalize(testTypeNameBreast('mammogramAwbu')), value: 'mammogramAwbu' },
    { name: capitalize(testTypeNameBreast('breastImaging')), value: 'breastImaging' },
    { name: capitalize(testTypeNameBreast('breastBiopsy')), value: 'breastBiopsy' },
  ],
  [{ name: capitalize(testTypeNameBreast('dischargeLetter')), value: 'dischargeLetter' }],
];

export function menuOptionsTestResultBreast(test) {
  if (['mammogram', 'mammogramAwbu'].includes(test.type)) {
    return [
      [{ name: valueNameBreast('negative'), value: 'negative' }],
      [
        { name: valueNameBreast('abnormalFurtherImaging'), value: 'abnormalFurtherImaging' },
        { name: valueNameBreast('abnormalBiopsy'), value: 'abnormalBiopsy' },
      ],
    ];
  }
  if (test.type === 'breastImaging') {
    return [
      [
        { name: valueNameBreast('negative'), value: 'negative' },
        { name: valueNameBreast('abnormal'), value: 'abnormal' },
      ],
    ];
  }
  if (test.type === 'breastBiopsy') {
    return [
      [{ name: valueNameBreast('negative'), value: 'negative' }],
      [
        { name: valueNameBreast('atypicalHyperplasia'), value: 'atypicalHyperplasia' },
        { name: valueNameBreast('lobularCarcinomaInSitu'), value: 'lobularCarcinomaInSitu' },
        { name: valueNameBreast('cancer'), value: 'cancer' },
      ],
    ];
  }

  return [];
}

export function newDefaultTestBreast(screening, isReferral) {
  if (isReferral) {
    return {
      date: null,
      type: 'dischargeLetter',
    };
  }

  const test = {
    date: null,
    type: screening.nextTest.type || 'mammogram',
    value: null,
  };

  if (['mammogram', 'mammogramAwbu'].includes(test.type)) {
    return {
      ...test,
      value2: null,
    };
  }

  return test;
}

export const getConsultWindowBreast = (nextTestType) =>
  ['mammogram', 'mammogramAwbu'].includes(nextTestType) ? { days: 30 } : null;

export const isReflexiveTestBreast = (nextTestType) => ['breastImaging', 'breastBiopsy'].includes(nextTestType);

export function handleTestEditChangeBreast(key, newValue, test) {
  // Remove value and value2 (Volpara D) for dischargeLetter
  if (key === 'type' && newValue === 'dischargeLetter') {
    const { value, value2, ...other } = test;
    return { ...other, [key]: newValue };
  }
  // Reset value and value2 (Volpara D) for mammogram
  if (key === 'type' && ['mammogram', 'mammogramAwbu'].includes(newValue)) {
    return { ...test, [key]: newValue, value: null, value2: null };
  }
  // Reset value and remove value2 (Volpara D) if test type changes
  if (key === 'type' && newValue !== test.type) {
    const { value2, ...other } = test;
    return { ...other, [key]: newValue, value: null };
  }
  // Value2 of true becomes 'volparaD'
  if (key === 'value2' && newValue && ['mammogram', 'mammogramAwbu'].includes(test.type)) {
    return { ...test, [key]: 'volparaD' };
  }
  return { ...test, [key]: newValue };
}

const highRiskPathology = ['cancer', 'atypicalHyperplasia', 'lobularCarcinomaInSitu'];

//
// Medical history items
//

// ** Firestore field values but not display items

const brcaDiagnosedHistoryItem = {
  type: 'personalHistory',
  field: 'brcaDiagnosed',
  questionnaire: true,
};

const brcaTestedHistoryItem = {
  type: 'personalHistory',
  field: 'brcaTested',
  questionnaire: true,
};

const familyBrcaHistoryItem = { type: 'familyHistory', field: 'familyBrca', questionnaire: true };

const familyCancerHistoryItem = { type: 'familyHistory', field: 'familyCancer', questionnaire: true };

// ** Display items of chart history

const getNameFamilyBrca = (value) => (value ? `${capitalize(value.relation)} diagnosed BRCA gene` : '');

const getNameFamilyCancer = (value) =>
  value ? `${capitalize(value.relation)} age ${value.age} diagnosed breast cancer` : '';

export const historyItemsBreast = [
  brcaDiagnosedHistoryItem,
  brcaTestedHistoryItem,
  familyBrcaHistoryItem,
  familyCancerHistoryItem,
  {
    type: 'personalHistory',
    // 'show' pre-fix avoid find name collision
    field: 'showBrcaDiagnosed',
    name: 'Diagnosed BRCA gene',
    showChart: true,
    onlyEdit: true,
    getValue: (screening, patient) => historyItemValue(brcaDiagnosedHistoryItem, screening, patient).value,
    getChanged: (value, screening, patient) =>
      value && value !== getHistoryPreviousValue(brcaDiagnosedHistoryItem, screening, patient),
  },
  {
    type: 'personalHistory',
    field: 'brcaNegative',
    name: 'Tested for BRCA gene, marker not found',
    showChart: true,
    onlyEdit: true,
    getValue: (screening, patient) =>
      historyItemValue(brcaTestedHistoryItem, screening, patient).value &&
      !historyItemValue(brcaDiagnosedHistoryItem, screening, patient).value,
    getChanged: (value, screening, patient) =>
      value &&
      value !==
        (getHistoryPreviousValue(brcaTestedHistoryItem, screening, patient) &&
          !getHistoryPreviousValue(brcaDiagnosedHistoryItem, screening, patient)),
  },
  {
    type: 'personalHistory',
    field: 'brcaNeverTested',
    name: 'Never tested BRCA gene',
    showChart: true,
    onlyEdit: true,
    getValue: (screening, patient) =>
      !historyItemValue(brcaTestedHistoryItem, screening, patient).value &&
      !historyItemValue(brcaDiagnosedHistoryItem, screening, patient).value,
    getChanged: (value, screening, patient) =>
      value &&
      value !==
        (getHistoryPreviousValue(brcaTestedHistoryItem, screening, patient) === false &&
          getHistoryPreviousValue(brcaDiagnosedHistoryItem, screening, patient) === null),
  },
  {
    type: 'personalHistory',
    field: 'chestWallRadiation',
    name: 'Chest wall radiation when 10-30 years old',
    questionnaire: true,
    showChart: true,
  },
  {
    type: 'personalHistory',
    field: 'cancer',
    highRiskPathology: ['cancer'],
    name: 'Diagnosed breast cancer',
    questionnaire: true,
    showChart: true,
  },
  {
    type: 'personalHistory',
    field: 'atypicalHyperplasia',
    highRiskPathology: ['atypicalHyperplasia'],
    name: 'Atypical hyperplasia',
    firestore: true,
    showChart: true,
  },
  {
    type: 'personalHistory',
    field: 'lobularCarcinomaInSitu',
    highRiskPathology: ['lobularCarcinomaInSitu'],
    name: 'Lobular carcinoma in situ',
    firestore: true,
    showChart: true,
  },
  {
    type: 'personalHistory',
    field: 'doubleMastectomy',
    name: 'Bilateral mastectomy',
    questionnaire: true,
    showChart: true,
  },
  { type: 'personalHistory', field: 'leftMastectomy', name: 'Left mastectomy', questionnaire: true, showChart: true },
  { type: 'personalHistory', field: 'rightMastectomy', name: 'Right mastectomy', questionnaire: true, showChart: true },
  { type: 'personalHistory', field: 'breastImplants', name: 'Breast implants', questionnaire: true, showChart: true },
  {
    type: 'familyHistory',
    field: 'familyBrca',
    index: 0,
    getName: getNameFamilyBrca,
    fromArray: familyBrcaHistoryItem,
    showChart: true,
  },
  {
    type: 'familyHistory',
    field: 'familyBrca',
    index: 1,
    getName: getNameFamilyBrca,
    fromArray: familyBrcaHistoryItem,
    showChart: true,
  },
  {
    type: 'familyHistory',
    field: 'familyBrca',
    index: 2,
    getName: getNameFamilyBrca,
    fromArray: familyBrcaHistoryItem,
    showChart: true,
  },
  {
    type: 'familyHistory',
    field: 'familyCancer',
    index: 0,
    getName: getNameFamilyCancer,
    fromArray: familyCancerHistoryItem,
    showChart: true,
  },
  {
    type: 'familyHistory',
    field: 'familyCancer',
    index: 1,
    getName: getNameFamilyCancer,
    fromArray: familyCancerHistoryItem,
    showChart: true,
  },
  {
    type: 'familyHistory',
    field: 'familyCancer',
    index: 2,
    getName: getNameFamilyCancer,
    fromArray: familyCancerHistoryItem,
    showChart: true,
  },
];

const historyItem = (field) => historyItemUtils(field, historyItemsBreast);

//
// Screening rules
//

export function getScreeningHighRiskBreast(screening, patient) {
  let familyHistory = false;
  let pathology = false;
  let personalHistory = false;
  const indicators = new Set();

  // Diagnosed breast cancer

  const cancer = historyItemValue(historyItem('cancer'), screening, patient);
  if (cancer.value) {
    pathology = cancer.pathology || false;
    personalHistory = !pathology;
    indicators.add(cancer.name);
  }

  // Family history breast cancer

  // Firestore field value item
  const familyCancer = historyItemValue(familyCancerHistoryItem, screening, patient);
  if (familyCancer.value && familyCancer.value.length !== 0) {
    familyHistory = true;
    indicators.add('Family history breast cancer');
  }

  // Atypical hyperplasia, Lobular carcinoma in situ

  ['atypicalHyperplasia', 'lobularCarcinomaInSitu'].forEach((field) => {
    const item = historyItemValue(historyItem(field), screening, patient);
    if (item.value) {
      pathology = item.pathology || false;
      personalHistory = !pathology;
      indicators.add(item.name);
    }
  });

  return {
    familyHistory,
    pathology,
    personalHistory,
    indicators: [...indicators],
    value: familyHistory || pathology || personalHistory,
  };
}

export function getScreeningVeryHighRiskBreast(screening, patient) {
  const indicators = new Set();
  let value = false;

  // Diagnosed BRCA gene

  const brcaDiagnosed = historyItemValue(historyItem('showBrcaDiagnosed'), screening, patient);
  if (brcaDiagnosed.value) {
    indicators.add(brcaDiagnosed.name);
    value = true;
  }

  // Family history of BRCA gene and not tested

  const brcaNeverTested = historyItemValue(historyItem('brcaNeverTested'), screening, patient);
  if (brcaNeverTested.value) {
    // Firestore field value item
    const familyBrca = historyItemValue(familyBrcaHistoryItem, screening, patient);
    if (familyBrca.value && familyBrca.value.length !== 0) {
      indicators.add(brcaNeverTested.name);
      value = true;
    }
  }

  // Chest wall radiation when 10-30 years old

  const chestWallRadiation = historyItemValue(historyItem('chestWallRadiation'), screening, patient);
  if (chestWallRadiation.value) {
    indicators.add(chestWallRadiation.name);
    value = true;
  }

  return {
    indicators: [...indicators],
    value,
  };
}

export function getScreeningNotApplicableBreast(screening, patient) {
  const indicators = new Set();
  let value = false;

  // Double mastectomy or right mastectomy and left mastectomy

  const doubleMastectomy = historyItemValue(historyItem('doubleMastectomy'), screening, patient);
  const leftMastectomy = historyItemValue(historyItem('leftMastectomy'), screening, patient);
  const rightMastectomy = historyItemValue(historyItem('rightMastectomy'), screening, patient);
  if (doubleMastectomy.value || (leftMastectomy.value && rightMastectomy.value)) {
    indicators.add(doubleMastectomy.name);
    value = true;
  }

  return {
    indicators: [...indicators],
    value,
  };
}

// Volpara D is value2 for the latest non-reflexive test
const isVolparaD = (tests) =>
  tests.reduce((accumulator, test) => {
    if (accumulator === null) {
      if (!isReflexiveTestBreast(test)) {
        return test.value2 === 'volparaD';
      }
    }
    return accumulator;
  }, null) || false;

export const getScreeningAverageRiskIndicatorsBreast = (screening) =>
  isVolparaD(screening.tests) ? [testValue2NameBreast('volparaD')] : [];

export function screeningRisksBreast(screening, patient) {
  const { familyHistory, pathology, personalHistory } = getScreeningHighRiskBreast(screening, patient);
  return {
    highRisk: {
      familyHistory,
      pathology,
      personalHistory,
    },
    veryHighRisk: getScreeningVeryHighRiskBreast(screening, patient).value,
    notApplicable: getScreeningNotApplicableBreast(screening, patient).value,
  };
}

const screeningStartAgeBreast = 45;

function screeningMinAgeBreast(screening, patient) {
  if (getScreeningHighRiskBreast(screening, patient).familyHistory) {
    // Firestore field value item
    const familyCancer = historyItemValue(familyCancerHistoryItem, screening, patient);
    const age =
      familyCancer.value.reduce((accumulator, value) => (value.age < accumulator ? value.age : accumulator), 50) - 10;
    return age < 25 ? 25 : age;
  }
  // Default age
  return 40;
}

// Mammogram offered as early optional screening if not already
// qualified for regular screening
export function isOfferedOptionallyTestBreast(screening, patient) {
  if (['mammogram', 'mammogramAwbu'].includes(screening.nextTest.type)) {
    const isHighRisk = getScreeningHighRiskBreast(screening, patient).value;
    if (!isHighRisk) {
      return getAge(patient) < screeningStartAgeBreast;
    }
  }
  return false;
}

//
// Recommendation
//

const defaultDueOptions = (latestTest) =>
  latestTest
    ? [
        { name: '2 years from test', duration: { months: 24 } },
        { name: '1 year from test', duration: { months: 12 } },
      ]
    : [];

export function screeningNextTestDueOptionsBreast(recommendation, latestTest, patient) {
  if (['mammogram', 'mammogramAwbu'].includes(recommendation.type)) {
    if (!recommendation.agedIn) {
      const { date } = latestTest || {};
      const age = getAge(patient, date);
      return dueOptionsAge(age < 25 ? 25 : age + 1, 40);
    }
    return dueOptionsAddNowIfNeeded(recommendation, defaultDueOptions(latestTest));
  }
  return [];
}

// Magic to hide mammogram and mammogramAwbu being nearly equivalent
export const screeningIsEquivalentTestTypeBreast = (testType, otherType) =>
  testType === otherType ||
  (['mammogram', 'mammogramAwbu'].includes(testType) && ['mammogram', 'mammogramAwbu'].includes(otherType));

export const screeningNextTestTypeOptionsBreast = (recommendation) =>
  ['mammogram', 'mammogramAwbu'].includes(recommendation.type)
    ? ['mammogram', 'mammogramAwbu'].map((type) => ({
        // Override with multiple types recommended
        recommended: true,
        value: type,
      }))
    : [{ value: recommendation.type }];

export function screeningRecommendationBreast(latestTest, screening, patient) {
  const { date, type, value } = latestTest || {};

  const isHighRisk = getScreeningHighRiskBreast(screening, patient).value;
  const age = getAge(patient, date);
  const minAge = screeningMinAgeBreast(screening, patient);
  const volparaD = isVolparaD(screening.tests);

  if (latestTest && (age >= minAge || isHighRisk || value !== 'negative')) {
    if (['mammogram', 'mammogramAwbu'].includes(type)) {
      if (value === 'negative') {
        return newRecommendationScheduleTest({ months: isHighRisk || volparaD ? 12 : 24 }, 'mammogram', date);
      }
      if (value === 'abnormalFurtherImaging') {
        return newRecommendationScheduleTest({ months: 0 }, 'breastImaging');
      }
      if (value === 'abnormalBiopsy') {
        return newRecommendationScheduleTest({ months: 0 }, 'breastBiopsy');
      }
    }

    if (type === 'breastImaging') {
      if (value === 'negative') {
        return newRecommendationScheduleTest({ months: isHighRisk || volparaD ? 12 : 24 }, 'mammogram', date);
      }
      if (value === 'abnormal') {
        return newRecommendationScheduleTest({ months: 0 }, 'breastBiopsy');
      }
    }

    if (type === 'breastBiopsy') {
      if (value === 'negative') {
        return newRecommendationScheduleTest({ months: isHighRisk || volparaD ? 12 : 24 }, 'mammogram', date);
      }

      if (highRiskPathology.includes(value)) {
        return newRecommendationReferral();
      }
    }

    if (type === 'dischargeLetter') {
      return newRecommendationScheduleTest({ months: 12 }, 'mammogram', date);
    }
  }

  if (minAge <= age) {
    return newRecommendationScheduleTest({ months: 0 }, 'mammogram', date);
  }

  // Not agedIn
  return newRecommendationAgingIn({ months: minAge * 12 }, 'mammogram', patient);
}

export const callPatientBreast = (recommendation) => recommendation.referral;
export const referPatientBreast = (recommendation) => recommendation.referral;

//
// Chart medical history
//

const relationOptions = ['mother', 'sister', 'daughter'];

export function getAddFamilyHistoryDialogValuesBreast(screening, patient, optionProp) {
  if (optionProp === 'familyBrca') {
    const itemValue = historyItemValue(familyBrcaHistoryItem, screening, patient);
    const filter = itemValue.value.reduce(
      (accumulator, value) => (value.relation === 'mother' ? value.relation : accumulator),
      null,
    );

    return {
      item: familyBrcaHistoryItem,
      subtitle: 'Family member BRCA gene',
      vacancy: itemValue.value.length < familyMembersLimit,
      values: [
        {
          label: 'Family member',
          field: 'relation',
          options: relationOptions
            .filter((option) => option !== filter)
            .map((option) => ({
              name: capitalize(option),
              value: option,
            })),
        },
      ],
    };
  }

  const itemValue = historyItemValue(familyCancerHistoryItem, screening, patient);
  const filter = itemValue.value.reduce(
    (accumulator, value) => (value.relation === 'mother' ? value.relation : accumulator),
    null,
  );

  // Default
  if (!optionProp || optionProp === 'familyCancer') {
    return {
      item: familyCancerHistoryItem,
      subtitle: 'Family member breast cancer',
      vacancy: itemValue.value.length < familyMembersLimit,
      values: [
        {
          label: 'Family member',
          field: 'relation',
          options: relationOptions
            .filter((option) => option !== filter)
            .map((option) => ({
              name: capitalize(option),
              value: option,
            })),
        },
        {
          label: 'Age',
          field: 'age',
          options: Array.from({ length: 100 - 18 }, (_, i) => i + 18).map((option) => ({
            name: option,
            value: option,
          })),
        },
      ],
    };
  }

  return null;
}

// Left and right mastectomy equals double mastectomy
export function chartPersonalHistoryToShowFilterBreast(items) {
  const doubleMastectomy = historyItemUtils('doubleMastectomy', items);
  if (doubleMastectomy !== undefined) {
    return items.filter((item) => !['leftMastectomy', 'rightMastectomy'].includes(item.field));
  }

  const leftMastectomy = historyItemUtils('leftMastectomy', items);
  const rightMastectomy = historyItemUtils('rightMastectomy', items);
  if (leftMastectomy && rightMastectomy) {
    return items
      .filter((item) => !['leftMastectomy', 'rightMastectomy'].includes(item.field))
      .concat({ ...historyItem('doubleMastectomy'), value: true });
  }

  return items;
}

export function setChartHistoryItemBreast(item, screening, patient, value = true) {
  // Add to family history array
  if ([familyCancerHistoryItem.field, familyBrcaHistoryItem.field].includes(item.field)) {
    const itemValue = historyItemValue(item, screening, patient);
    return [
      {
        ...itemValue,
        value: itemValue.value.concat(value),
      },
    ];
  }

  // Special display BRCA
  if (item.field === 'showBrcaDiagnosed') {
    return [
      {
        // Firestore field value item
        ...brcaDiagnosedHistoryItem,
        value: true,
      },
    ];
  }
  if (item.field === 'brcaNegative') {
    return [
      {
        ...brcaTestedHistoryItem,
        value: true,
      },
      {
        // Firestore field value item
        ...brcaDiagnosedHistoryItem,
        value: false,
      },
    ];
  }
  if (item.field === 'brcaNeverTested') {
    return [
      {
        ...brcaTestedHistoryItem,
        value: false,
      },
      {
        // Firestore field value item
        ...brcaDiagnosedHistoryItem,
        value: null,
      },
    ];
  }

  // Left and right mastectomy equals double mastectomy
  if (['leftMastectomy', 'rightMastectomy'].includes(item.field)) {
    const otherMastectomy = historyItemValue(
      historyItem(item.field === 'leftMastectomy' ? 'rightMastectomy' : 'leftMastectomy'),
      screening,
      patient,
    );
    if (otherMastectomy.value) {
      return [
        { ...item, value: false },
        {
          ...historyItem(otherMastectomy.field),
          value: false,
        },
        {
          ...historyItem('doubleMastectomy'),
          value: true,
        },
      ];
    }
  }

  return [{ ...item, value }];
}

export function unsetChartHistoryItemBreast(itemProp) {
  const item = { ...itemProp, value: false };

  // Left and right mastectomy equals double mastectomy
  if (itemProp.field === 'doubleMastectomy') {
    return [
      item,
      {
        ...historyItem('leftMastectomy'),
        value: false,
      },
      {
        ...historyItem('rightMastectomy'),
        value: false,
      },
    ];
  }

  return [item];
}

// Special display BRCA and left and right mastectomy equals double mastectomy
export function menuOptionsAddPersonalHistoryFilterBreast(menuOptions, screening, patient) {
  // Only allow BRCA to be added if none of the BRCA items
  // shown in chart (not expected)
  const newMenuOptions =
    historyItemValue(historyItem('showBrcaDiagnosed'), screening, patient).value ||
    historyItemValue(historyItem('brcaNegative'), screening, patient).value ||
    historyItemValue(historyItem('brcaNeverTested'), screening, patient).value
      ? menuOptions.filter((item) => !['showBrcaDiagnosed', 'brcaNegative', 'brcaNeverTested'].includes(item.field))
      : menuOptions;

  if (!newMenuOptions.find((item) => item.field === 'doubleMastectomy')) {
    return newMenuOptions.filter((item) => !['leftMastectomy', 'rightMastectomy'].includes(item.field));
  }
  if (
    !newMenuOptions.find((item) => item.field === 'leftMastectomy') ||
    !newMenuOptions.find((item) => item.field === 'rightMastectomy')
  ) {
    return newMenuOptions.filter((item) => item.field !== 'doubleMastectomy');
  }
  return newMenuOptions;
}

export const menuOptionsEditPersonalHistoryBreast = [
  // Only needs to support BRCA
  historyItem('showBrcaDiagnosed'),
  historyItem('brcaNegative'),
  historyItem('brcaNeverTested'),
];

//
// Transform data for storage in firestore data transformation
// Note: Data under patient screening array is received by mobile app
//

export function firestoreTransformToScreeningNextTestBreast(nextTest) {
  if (nextTest.type === 'mammogramAwbu') {
    return { ...nextTest, type: 'mammogram' };
  }
  return nextTest;
}

export const firestoreTransformToScreeningTestsBreast = (tests) =>
  tests.map((test) => (test.type === 'mammogramAwbu' ? { ...test, type: 'mammogram' } : test));
