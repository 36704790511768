import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import { DatePicker as MuiDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import startOfDay from 'date-fns/startOfDay';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { colorTheme } from '../constants/theme';
import DateFnsUtils from '../utils/dateFnsUtils';

const styles = (theme) => ({
  focused: {},
  root: {
    '&$focused': {
      color: theme.palette.text.primary,
    },
  },
  underline: {
    '&:after': {
      borderBottomColor: theme.palette.text.primary,
    },
  },
});

class DatePicker extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    const { onChange } = this.props;

    onChange(startOfDay(date));
  }

  render() {
    const { classes, InputProps, onChange, value, ...other } = this.props;

    const inputPropsClasses = InputProps ? InputProps.classes : undefined;

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={colorTheme}>
          <MuiDatePicker
            InputLabelProps={{
              classes: { root: classes.root, focused: classes.focused },
              shrink: value !== null,
            }}
            InputProps={{ classes: { underline: classes.underline, ...inputPropsClasses } }}
            onChange={this.handleChange}
            value={value}
            {...other} // eslint-disable-line react/jsx-props-no-spreading
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    );
  }
}

DatePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  InputProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
};

DatePicker.defaultProps = {
  InputProps: undefined,
  value: null,
};

export default withStyles(styles)(DatePicker);
