import add from 'date-fns/add';
import isAfter from 'date-fns/isAfter';
import sub from 'date-fns/sub';
import { distanceInYears, now, parseDOB } from './date';

// ** File for use by screenings & breast, cervical, colon, lung and prostate **
//
// Cannot include './screenings' (directly or indirectly)

export const getAge = (patient, fromDate = now()) => distanceInYears(parseDOB(patient.dob), fromDate);

export const maxNextTestDue = (screeningMaxAge, patient) =>
  sub(add(parseDOB(patient.dob), { years: screeningMaxAge + 1 }), { days: 1 });

export function isAgingOut(screeningMaxAge, patient, nextTestDue) {
  if (getAge(patient) > screeningMaxAge) {
    return true;
  }

  const maxDate = maxNextTestDue(screeningMaxAge, patient);
  if (nextTestDue && isAfter(nextTestDue, maxDate)) {
    return true;
  }

  return false;
}

export function getCancerScreening(screening, patient) {
  if (Object.prototype.hasOwnProperty.call(patient.cancer, screening.type)) {
    return patient.cancer[screening.type];
  }
  return undefined;
}
