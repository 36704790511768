import MuiBadge from '@material-ui/core/Badge';
import { ThemeProvider } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { colorThemeBadge } from '../constants/theme';

function Badge(props) {
  const { color, ...other } = props;

  return (
    <ThemeProvider theme={colorThemeBadge}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <MuiBadge color={color} {...other} overlap="rectangular" />
    </ThemeProvider>
  );
}

Badge.propTypes = {
  color: PropTypes.string,
};

Badge.defaultProps = {
  color: 'primary',
};

export default Badge;
