import PropTypes from 'prop-types';
import React from 'react';
import Recommended from '../utils/recommended';
import NewResultChart from './NewResultChart';

function NewResult(props) {
  const { handleAcceptClick: handleAcceptClickProp, patient, screening, test } = props;

  const [recommended, setRecommended] = React.useState(new Recommended(screening, patient));

  const handleAcceptClick = () => handleAcceptClickProp(recommended, screening);

  const handleRecommendedChanged = (newRecommended) => {
    setRecommended(newRecommended);
  };

  return (
    <NewResultChart
      handleAcceptClick={handleAcceptClick}
      handleRecommendedChanged={handleRecommendedChanged}
      patient={patient}
      recommended={recommended}
      screening={screening}
      test={test}
      variant="new result"
    />
  );
}

NewResult.propTypes = {
  handleAcceptClick: PropTypes.func.isRequired,
  patient: PropTypes.object.isRequired,
  screening: PropTypes.object.isRequired,
  test: PropTypes.object.isRequired,
};

export default NewResult;
