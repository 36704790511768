export const isObjectEmpty = (object) =>
  (object && Object.keys(object).length === 0 && object.constructor === Object) || false;

export const removeProperties = (properties, object) =>
  Object.keys(object)
    .filter((key) => !properties.includes(key))
    .reduce(
      (obj, key) => ({
        ...obj,
        [key]: object[key],
      }),
      {},
    );

export const copyObjectInstance = (object) => Object.assign(Object.create(Object.getPrototypeOf(object)), object);
