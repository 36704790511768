import { primary } from '../constants/colors';
import { fontFamily2 } from '../constants/typography';

export const chartTitle = (theme) => ({
  ...theme.typography.subtitle1,
  color: primary,
  fontFamily: fontFamily2,
  letterSpacing: `${theme.typography.round(0.15 / 16)}em`,
  lineHeight: theme.typography.pxToRem(24),
  marginBottom: theme.spacing(2),
});

export const dialogActions = (theme) => ({
  padding: theme.spacing(1, 3, 1.25),
});

export const selectPaper = (theme) => ({
  borderRadius: 4,
  minWidth: 230,
  padding: theme.spacing(0.25, 1),
});
