import MuiTextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { selectPaper } from './styles';

const styles = (theme) => ({
  paper: { ...selectPaper(theme) },
});

function TextField(props) {
  const { classes, SelectProps, ...other } = props;

  return (
    <MuiTextField
      SelectProps={{
        MenuProps: {
          PaperProps: { className: classes.paper },
        },
        ...SelectProps,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    />
  );
}

TextField.propTypes = {
  classes: PropTypes.object.isRequired,
  SelectProps: PropTypes.object,
};

TextField.defaultProps = {
  SelectProps: {},
};

export default withStyles(styles)(TextField);
