const find = (description, screening, patient) =>
  Boolean(
    patient.chartUpdates &&
      patient.chartUpdates.find(
        (chartUpdate) => chartUpdate.description === description && chartUpdate.screeningType === screening.type,
      ),
  );

export const isMissingResult = (screening, patient) => find('missing result', screening, patient);
export const isReferralReminderDue = (screening, patient) => find('referral reminder', screening, patient);
