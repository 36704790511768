import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { Component } from 'react';
import IdleTimer from 'react-idle-timer';
import { userInactivityTimeout } from '../constants/firebase';
import theme from '../constants/theme';
import { apiOnUserChanged, apiSignOut } from '../utils/api';
import history from '../utils/history';
import Container from './Container';
import SignIn from './SignIn';

class App extends Component {
  static handleUserAction() {
    localStorage.setItem('lastActiveTime', Date.now());
  }

  static handleUserIdle() {
    if (Date.now() - localStorage.getItem('lastActiveTime') > userInactivityTimeout) {
      history.replace({});
      apiSignOut();
    }
  }

  constructor(props) {
    super(props);

    this.state = { user: undefined };

    this.handleUserChange = this.handleUserChange.bind(this);
  }

  componentDidMount() {
    apiOnUserChanged(this.handleUserChange);
  }

  componentWillUnmount() {
    apiOnUserChanged();
  }

  handleUserChange(user) {
    if (!user) {
      history.replace({});
    }
    this.setState({ user });
  }

  render() {
    const { user } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {user !== undefined &&
          (user ? (
            <>
              <Container user={user} />
              <IdleTimer
                onAction={this.constructor.handleUserAction}
                onIdle={this.constructor.handleUserIdle}
                throttle={5000}
                timeout={userInactivityTimeout}
              />
            </>
          ) : (
            <SignIn />
          ))}
      </ThemeProvider>
    );
  }
}

export default App;
