import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import notifier from '../utils/notifier';
import CSSTransition from './CSSTransition';

const styles = (theme) => ({
  inEnter: {
    opacity: 0,
    transform: 'translateY(-4px)',
  },
  inEnterActive: {
    opacity: 1,
    transform: 'translateY(0)',
    transition: theme.transitions.create(['opacity', 'transform']),
  },
  inEnterDone: {
    opacity: 1,
    transform: 'translateY(0)',
  },
  inExit: {
    opacity: 1,
    transform: 'translateY(0)',
  },
  inExitActive: {
    opacity: 0,
    transform: 'translateY(-4px)',
    transition: theme.transitions.create(['opacity', 'transform']),
  },
  inExitDone: {
    opacity: 0,
  },
  outEnter: {
    opacity: 0,
    transform: 'translateY(4px)',
  },
  outEnterActive: {
    opacity: 1,
    transform: 'translateY(0)',
    transition: theme.transitions.create(['opacity', 'transform']),
  },
  outEnterDone: {
    opacity: 1,
    transform: 'translateY(0)',
  },
  outExit: {
    opacity: 1,
    transform: 'translateY(0)',
  },
  outExitActive: {
    opacity: 0,
    transform: 'translateY(4px)',
    transition: theme.transitions.create(['opacity', 'transform']),
  },
  outExitDone: {
    opacity: 0,
  },
});

function FadeSlideBetween(props) {
  const { classes, componentIn, componentOut, in: inProp, onTransitionEnd } = props;

  let firstOnTransitionEnd;
  let secondOnTransitionEnd;

  if (onTransitionEnd) {
    const firstPromise = notifier();
    const secondPromise = notifier();

    firstOnTransitionEnd = () => firstPromise.resolve();
    secondOnTransitionEnd = () => secondPromise.resolve();

    Promise.all([firstPromise, secondPromise]).then(() => onTransitionEnd());
  }

  return (
    <>
      <CSSTransition
        classNames={{
          enter: classes.outEnter,
          enterActive: classes.outEnterActive,
          enterDone: classes.outEnterDone,
          exit: classes.outExit,
          exitActive: classes.outExitActive,
          exitDone: classes.outExitDone,
        }}
        in={!inProp}
        mountOnEnter
        onTransitionEnd={firstOnTransitionEnd}
        unmountOnExit
      >
        {componentOut}
      </CSSTransition>
      <CSSTransition
        classNames={{
          enter: classes.inEnter,
          enterActive: classes.inEnterActive,
          enterDone: classes.inEnterDone,
          exit: classes.inExit,
          exitActive: classes.inExitActive,
          exitDone: classes.inExitDone,
        }}
        in={inProp}
        mountOnEnter
        onTransitionEnd={secondOnTransitionEnd}
        unmountOnExit
      >
        {componentIn}
      </CSSTransition>
    </>
  );
}

FadeSlideBetween.propTypes = {
  classes: PropTypes.object.isRequired,
  componentIn: PropTypes.node.isRequired,
  componentOut: PropTypes.node.isRequired,
  in: PropTypes.bool.isRequired,
  onTransitionEnd: PropTypes.func,
};

FadeSlideBetween.defaultProps = {
  onTransitionEnd: undefined,
};

export default withStyles(styles)(FadeSlideBetween);
