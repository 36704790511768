import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import Button from '../components/Button';
import { selectText } from '../constants/styles';
import { mixinGutters } from '../constants/theme';
import {
  apiSignInVerificationCode,
  apiSignInVerifyPhoneNumber,
  apiUpateUserFullName,
  apiUpdateUserPassword,
  apiUserMultiFactorEnrolledFactors,
} from '../utils/api';
import {
  containsPhoneMultiFactor,
  multiFactorHint,
  multiFactorResolver,
  newRecaptchaVerifier,
} from '../utils/firebase';
import { passwordInstruction, validPassword } from '../utils/password';

const styles = (theme) => ({
  buttonContainer: {
    padding: theme.spacing(2, 0, 1.5),
  },
  buttonError: {
    paddingTop: theme.spacing(0.5),
  },
  button: {
    boxShadow: 'none',
  },
  buttonCancel: {
    marginLeft: 16,
  },
  buttonEdit: {
    marginLeft: 12,
  },
  buttonSave: {
    marginLeft: 20,
  },
  buttonText: {
    minWidth: 0,
    padding: 0,
    marginTop: 2,
    fontSize: theme.typography.pxToRem(12),
    letterSpacing: `${theme.typography.round(0.4 / 12)}em`,
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'transparent',
    },
  },
  container: {
    boxSizing: 'border-box',
    width: '100%',
    marginTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1),
    ...mixinGutters(theme),
  },
  content: {
    padding: theme.spacing(2.5, 1),
  },
  flex: {
    display: 'flex',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  helperTextPassword: {
    lineHeight: theme.typography.pxToRem(20),
    paddingTop: theme.spacing(0.5),
  },
  input: {
    width: 180,
  },
  instructionTextPassword: {
    maxWidth: 300,
    paddingTop: 5,
    paddingBottom: theme.spacing(2.5),
  },
  selectText,
  spacing: {
    marginLeft: theme.spacing(2.5),
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 32,
  },
  title: {
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: theme.typography.pxToRem(28),
    padding: theme.spacing(1.5, 0, 1.5),
  },
  verification: {
    marginTop: theme.spacing(3),
  },
});

class Profile extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: undefined,
      nameEdit: false,
      nameError: null,
      passwordCode: undefined,
      passwordCodeError: null,
      passwordCodeShow: false,
      passwordCurrent: undefined,
      passwordCurrentError: null,
      passwordCurrentShow: false,
      passwordEdit: false,
      passwordFormError: null,
      passwordNew: undefined,
      passwordNewAgain: undefined,
      passwordNewShow: false,
    };

    this.inputRef = createRef();

    this.handleNameCancelClick = this.handleNameCancelClick.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleNameEditClick = this.handleNameEditClick.bind(this);
    this.handleNameSaveClick = this.handleNameSaveClick.bind(this);
    this.handlePasswordCancelClick = this.handlePasswordCancelClick.bind(this);
    this.handlePasswordCodeChange = this.handlePasswordCodeChange.bind(this);
    this.handlePasswordCodeSaveClick = this.handlePasswordCodeSaveClick.bind(this);
    this.handlePasswordCurrentChange = this.handlePasswordCurrentChange.bind(this);
    this.handlePasswordEditClick = this.handlePasswordEditClick.bind(this);
    this.handlePasswordNewChange = this.handlePasswordNewChange.bind(this);
    this.handlePasswordNewAgainChange = this.handlePasswordNewAgainChange.bind(this);
    this.handlePasswordSaveClick = this.handlePasswordSaveClick.bind(this);
    this.handleToggleClick = this.handleToggleClick.bind(this);
  }

  handleNameCancelClick() {
    this.setState({
      name: undefined,
      nameEdit: false,
      nameError: null,
    });
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value ? event.target.value : '', nameError: null });
  }

  handleNameEditClick(name) {
    return () => {
      this.setState({ name, nameEdit: true, nameError: null });
    };
  }

  handleNameSaveClick() {
    const { name } = this.state;

    if (!name) {
      this.setState({ nameError: 'Enter full name' });
      return;
    }

    apiUpateUserFullName(name);

    this.setState({ name: undefined, nameEdit: false, nameError: null });
  }

  handlePasswordCancelClick() {
    this.setState({
      passwordCurrent: undefined,
      passwordCurrentError: null,
      passwordCurrentShow: false,
      passwordEdit: false,
      passwordFormError: null,
      passwordNew: undefined,
      passwordNewAgain: undefined,
      passwordNewShow: false,
    });
  }

  handlePasswordCodeChange(event) {
    this.setState({ passwordCode: event.target.value ? event.target.value : '', passwordCodeError: null });
  }

  handlePasswordCodeSaveClick() {
    const { resolver, passwordCode, passwordNew, verificationId } = this.state;

    apiSignInVerificationCode(resolver, passwordCode, verificationId)
      .then(() => apiUpdateUserPassword(passwordNew))
      .then(() => {
        this.handlePasswordCancelClick();
      })
      .catch((error) => {
        const { code } = error;

        if (code === 'auth/invalid-verification-code') {
          this.setState({ passwordCodeError: 'Invaid code. Try entering the code again.' });
          this.inputRef.current.focus();
        } else {
          this.setState({ passwordCodeError: 'Error occurred. Try again.' });
        }
      });
  }

  handlePasswordCurrentChange(event) {
    this.setState({ passwordCurrent: event.target.value ? event.target.value : '', passwordCurrentError: null });
  }

  handlePasswordEditClick() {
    this.setState({
      passwordCode: '',
      passwordCodeError: null,
      passwordCodeShow: false,
      passwordCurrent: '',
      passwordCurrentError: null,
      passwordCurrentShow: false,
      passwordEdit: true,
      passwordFormError: null,
      passwordNew: '',
      passwordNewAgain: '',
      passwordNewShow: false,
      userEnrolledInPhone2FA: containsPhoneMultiFactor(apiUserMultiFactorEnrolledFactors()),
    });
  }

  handlePasswordNewChange(event) {
    this.setState({ passwordNew: event.target.value ? event.target.value : '' });
  }

  handlePasswordNewAgainChange(event) {
    this.setState({ passwordNewAgain: event.target.value ? event.target.value : '' });
  }

  handlePasswordSaveClick() {
    const { passwordNew, passwordNewAgain, passwordCurrent } = this.state;

    if (!passwordCurrent) {
      this.setState({ passwordCurrentError: 'Enter current password' });
    }

    if (!passwordNew) {
      this.setState({ passwordFormError: 'Enter a password' });
      return;
    }

    if (!passwordCurrent) {
      return;
    }

    // Check new password is valid
    const passwordFormError = validPassword(passwordNew);
    if (passwordFormError !== null) {
      this.setState({ passwordFormError });
      return;
    }

    // Check new password different than current
    if (passwordNew === passwordCurrent) {
      this.setState({ passwordFormError: 'New password must be different than current password' });
      return;
    }

    // Check new passwords match
    if (passwordNew !== passwordNewAgain) {
      this.setState({ passwordFormError: 'Passwords do not match' });
      return;
    }

    this.setState({ passwordFormError: null });

    apiUpdateUserPassword(passwordNew, passwordCurrent)
      .then(() => {
        this.handlePasswordCancelClick();
      })
      .catch((error) => {
        const { code } = error;

        if (code === 'auth/multi-factor-auth-required') {
          const resolver = multiFactorResolver(error);
          const recaptchaVerifier = newRecaptchaVerifier('save-password-click');

          apiSignInVerifyPhoneNumber(resolver, recaptchaVerifier)
            .then((verificationId) => {
              this.setState({
                passwordFormError: null,
                passwordCodeShow: true,
                resolver,
                verificationId,
              });
              this.inputRef.current.focus();
            })
            .catch((error2) => {
              const { code: code2 } = error2;

              if (code2 === 'auth/too-many-requests') {
                this.setState({ passwordFormError: 'Too many attempts. Try again later.' });
              } else {
                // eslint-disable-next-line no-console
                console.error(error2);
                this.setState({ passwordFormError: 'Error occurred. Try again.' });
              }
            });
        } else {
          this.setState({ passwordFormError: 'Unable to change password, check current password is correct' });
        }
      });
  }

  handleToggleClick(param) {
    return () => {
      const { [param]: value } = this.state;
      this.setState({ [param]: !value });
    };
  }

  render() {
    const { classes, user } = this.props;
    const {
      name,
      nameEdit,
      nameError,
      passwordCode,
      passwordCodeError,
      passwordCodeShow,
      passwordCurrent,
      passwordCurrentError,
      passwordCurrentShow,
      passwordEdit,
      passwordFormError,
      passwordNew,
      passwordNewAgain,
      passwordNewShow,
      resolver,
      userEnrolledInPhone2FA,
    } = this.state;

    return (
      <div className={classes.container}>
        <Typography className={classes.title}>Personal info</Typography>
        <Divider />
        <div className={classes.content}>
          <List>
            <ListItem>
              <ListItemText
                disableTypography
                primary={
                  <>
                    <Typography color="textSecondary" component="div" variant="caption">
                      Email
                    </Typography>
                    <Typography className={clsx(classes.text, classes.selectText)}>{user.email}</Typography>
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                disableTypography
                primary={
                  <>
                    <Typography color="textSecondary" component="div" variant="caption">
                      Name
                    </Typography>
                    {nameEdit === false ? (
                      <Typography className={clsx(classes.text, classes.selectText)}>
                        {user.fullName}
                        <Button
                          className={clsx(classes.button, classes.buttonText, classes.buttonEdit)}
                          color="primary"
                          onClick={this.handleNameEditClick(user.fullName)}
                        >
                          Edit
                        </Button>
                      </Typography>
                    ) : (
                      <>
                        <Input
                          autoFocus
                          className={classes.input}
                          inputProps={{
                            autoCapitalize: 'off',
                            autoCorrect: 'off',
                            spellCheck: false,
                          }}
                          onChange={this.handleNameChange}
                          value={name}
                        />
                        {nameError && <FormHelperText>{nameError}</FormHelperText>}
                        <span>
                          <Button
                            className={clsx(classes.button, classes.buttonText, classes.buttonSave)}
                            color="primary"
                            onClick={this.handleNameSaveClick}
                          >
                            Save
                          </Button>
                          <Button
                            className={clsx(classes.button, classes.buttonText, classes.buttonCancel)}
                            color="primary"
                            onClick={this.handleNameCancelClick}
                          >
                            Cancel
                          </Button>
                        </span>
                      </>
                    )}
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                disableTypography
                primary={
                  <>
                    <Typography color="textSecondary" component="div" variant="caption">
                      Password
                    </Typography>
                    {passwordEdit === false ? (
                      <Typography className={clsx(classes.text, classes.selectText)}>
                        ••••••••
                        <Button
                          className={clsx(classes.button, classes.buttonText, classes.buttonEdit)}
                          color="primary"
                          onClick={this.handlePasswordEditClick}
                        >
                          Edit
                        </Button>
                      </Typography>
                    ) : (
                      <>
                        <Input
                          autoFocus
                          className={classes.input}
                          disabled={passwordCodeShow}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton onClick={this.handleToggleClick('passwordCurrentShow')}>
                                {passwordCurrentShow ? (
                                  <VisibilityIcon fontSize="small" />
                                ) : (
                                  <VisibilityOffIcon fontSize="small" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          inputProps={passwordCurrentShow ? { spellCheck: false } : {}}
                          onChange={this.handlePasswordCurrentChange}
                          type={passwordCurrentShow ? 'text' : 'password'}
                          value={passwordCurrent}
                        />
                        {passwordCurrentError && <FormHelperText error>{passwordCurrentError}</FormHelperText>}
                      </>
                    )}
                  </>
                }
              />
            </ListItem>
            {passwordEdit && (
              <>
                <ListItem>
                  <div className={classes.flexColumn}>
                    <FormHelperText className={classes.instructionTextPassword}>{passwordInstruction}</FormHelperText>
                    <div className={classes.flex}>
                      <div>
                        <Typography color="textSecondary" component="div" variant="caption">
                          New password
                        </Typography>
                        <Input
                          className={classes.input}
                          disabled={passwordCodeShow}
                          inputProps={passwordNewShow ? { spellCheck: false } : {}}
                          onChange={this.handlePasswordNewChange}
                          type={passwordNewShow ? 'text' : 'password'}
                          value={passwordNew}
                        />
                      </div>
                      <div className={classes.spacing}>
                        <Typography color="textSecondary" component="div" variant="caption">
                          New password (again)
                        </Typography>
                        <Input
                          className={classes.input}
                          disabled={passwordCodeShow}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton component="span" onClick={this.handleToggleClick('passwordNewShow')}>
                                {passwordNewShow ? (
                                  <VisibilityIcon fontSize="small" />
                                ) : (
                                  <VisibilityOffIcon fontSize="small" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          inputProps={passwordCurrentShow ? { spellCheck: false } : {}}
                          onChange={this.handlePasswordNewAgainChange}
                          type={passwordNewShow ? 'text' : 'password'}
                          value={passwordNewAgain}
                        />
                      </div>
                    </div>
                    {passwordFormError && (
                      <FormHelperText className={classes.helperTextPassword} error>
                        {passwordFormError}
                      </FormHelperText>
                    )}
                  </div>
                </ListItem>
                <ListItem>
                  <div className={clsx(classes.buttonContainer, passwordFormError && classes.buttonError)}>
                    <Button
                      className={classes.button}
                      color="primary"
                      disabled={passwordCodeShow}
                      id="save-password-click"
                      onClick={this.handlePasswordSaveClick}
                      size="small"
                      variant="contained"
                    >
                      {userEnrolledInPhone2FA ? 'Continue' : 'Change password'}
                    </Button>
                    <Button
                      className={clsx(classes.button, classes.buttonCancel)}
                      color="primary"
                      disabled={passwordCodeShow}
                      onClick={this.handlePasswordCancelClick}
                      size="small"
                    >
                      Cancel
                    </Button>
                  </div>
                </ListItem>
                <Collapse in={passwordCodeShow}>
                  <ListItem className={classes.verification}>
                    <div className={classes.flexColumn}>
                      <Typography color="textSecondary" component="div" variant="caption">
                        Verification code
                      </Typography>
                      <Input
                        className={classes.input}
                        inputProps={{ spellCheck: false }}
                        inputRef={this.inputRef}
                        onChange={this.handlePasswordCodeChange}
                        type="text"
                        value={passwordCode}
                      />
                      {passwordCodeError ? (
                        <FormHelperText className={classes.helperTextPassword} error>
                          {passwordCodeError}
                        </FormHelperText>
                      ) : (
                        <FormHelperText className={classes.helperTextPassword}>
                          {resolver &&
                            `Enter the 6-digit code sent to you at (***) *** - ${multiFactorHint(
                              resolver,
                            ).phoneNumber.slice(-4)}`}
                        </FormHelperText>
                      )}
                    </div>
                  </ListItem>
                  <ListItem>
                    <Button
                      className={classes.button}
                      color="primary"
                      onClick={this.handlePasswordCodeSaveClick}
                      size="small"
                      variant="contained"
                    >
                      Change password
                    </Button>
                    <Button
                      className={clsx(classes.button, classes.buttonCancel)}
                      color="primary"
                      onClick={this.handlePasswordCancelClick}
                      size="small"
                    >
                      Cancel
                    </Button>
                  </ListItem>
                </Collapse>
              </>
            )}
          </List>
        </div>
      </div>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withStyles(styles)(Profile);
