import { surfaceVariant, textPrimary } from './colors';

export const flatShadow = {
  borderColor: textPrimary,
  boxShadow: `0 0 0 1px ${surfaceVariant}`,
};

export const shadow1 = {
  borderColor: textPrimary,
  boxShadow: '0 1px 2px 0 rgba(67, 71, 78,.3),0 1px 3px 1px rgba(67, 71, 78,.15)',
};

export const noHover = {
  '&:hover': {
    backgroundColor: 'transparent',
    '@media (hover: none)': {
      backgroundColor: 'transparent',
    },
  },
};

export const selectText = {
  '-webkit-user-select': 'text',
  '-khtml-user-select': 'text',
  '-moz-user-select': 'text',
  '-ms-user-select': 'text',
  userSelect: 'text',
};
