import { isObjectEmpty } from './object';
import { getCancerScreening } from './patientUtils';
import { isTestReviewed } from './tests';

// ** File for use by screenings & breast, cervical, colon, lung and prostate **
//
// Cannot include './screenings' (directly or indirectly)

//
// Chart medical history
//

function isItemValueTestPathology(item, tests) {
  const { highRiskPathology = [] } = item;
  if (highRiskPathology.length === 0) {
    return false;
  }
  return tests.reduce((accumulator, test) => accumulator || highRiskPathology.includes(test.value), false);
}

const getChangedTestPathology = (item, screening) =>
  isItemValueTestPathology(item, screening.tests) !==
  isItemValueTestPathology(
    item,
    screening.tests.filter((test) => isTestReviewed(test)),
  );

function itemValue(item, screening, cancerScreening, patient) {
  // Derived value or special use
  if (item.getValue) {
    return {
      ...item,
      value: item.getValue(screening, patient),
    };
  }

  // Conditionally value may at times derive from test pathology
  if (isItemValueTestPathology(item, screening.tests)) {
    return {
      ...item,
      readOnly: true,
      pathology: true,
      value: true,
      getChanged: () => getChangedTestPathology(item, screening),
    };
  }

  const { notReviewed = {} } = cancerScreening[item.type];

  if (item.questionnaire) {
    return {
      ...item,
      value: notReviewed[item.field] !== undefined ? notReviewed[item.field] : screening.questionnaire[item.field],
    };
  }

  const { autoReviewed = {} } = cancerScreening[item.type];

  const value = [notReviewed, autoReviewed, cancerScreening[item.type]].reduce(
    (accumulator, _value) => (accumulator === undefined ? _value[item.field] : accumulator),
    undefined,
  );

  return {
    ...item,
    value,
  };
}

const getPreviousValue = (item, cancerScreening) => cancerScreening[item.type][item.field];

// Default history item getChanged implementation (not array member item history)
const getChanged = (item, previousValue) => (item.value || previousValue) && item.value !== previousValue;

// Get value item of an individual chart history value which is an
// element within underlying value array (e.g. family history)
// Note: History items with fromArray must have getName and index defined
// Future: Support non-questionnaire arrays
function itemValueFromArray(item, screening, cancerScreening, patient) {
  // Item value could be a current value (e.g. notReviewed or questionnaire)
  // or in the process of being deleted (physician accept pending) in which
  // only previous value is available in cancer screening.

  const { value: fromArray = [] } = itemValue(item.fromArray, screening, cancerScreening, patient) || {};
  const previousArray = getPreviousValue(item.fromArray, cancerScreening) || [];

  const itemValues = [];

  for (let index = 0; index < Math.max(fromArray.length, previousArray.length); index += 1) {
    if (fromArray.length >= previousArray.length) {
      const name = item.getName(fromArray[index]);
      const changed = !previousArray.find((previous) => name === item.getName(previous));
      itemValues.push({
        ...item,
        name,
        changed,
        value: fromArray[index],
      });
    } else {
      const name = item.getName(previousArray[index]);
      const value = fromArray.find((current) => name === item.getName(current));
      itemValues.push({
        ...item,
        name,
        changed: value === undefined,
        // value undefined history been removed
        value,
      });
    }
  }

  if (item.index !== undefined && item.index < itemValues.length) {
    return itemValues[item.index];
  }

  return { ...item, value: undefined };
}

//
// Exported functions
//

export const historyItem = (field, historyItems) => historyItems.find((item) => item.field === field);

export function historyTypeName(type) {
  if (type === 'personalHistory') return 'personal history';
  if (type === 'socialHistory') return 'social history';
  if (type === 'familyHistory') return 'family history';
  return '';
}

export function historyItemValue(item, screening, patient) {
  const cancerScreening = getCancerScreening(screening, patient);

  // Item value is element within underlying value array (e.g. family history)
  if (item.fromArray) {
    return itemValueFromArray(item, screening, cancerScreening, patient);
  }

  const newItem = itemValue(item, screening, cancerScreening, patient);

  // Set changed property
  const changed = newItem.getChanged
    ? newItem.getChanged(newItem.value, screening, patient)
    : getChanged(newItem, getPreviousValue(newItem, cancerScreening));

  // Set name property
  const name = newItem.getName ? newItem.getName(newItem.value) : newItem.name;

  return {
    ...newItem,
    name,
    changed,
  };
}

export function getHistoryPreviousValue(item, screening, patient) {
  const cancerScreening = getCancerScreening(screening, patient);
  return getPreviousValue(item, cancerScreening);
}

// Did history value change from last time physician accepted screening
export function historyValueChanged(item, screening, patient) {
  const previousValue = getHistoryPreviousValue(item, screening, patient);
  return getChanged(item, previousValue);
}

// May the user edit history item
// Note: shown chart history items have a value of true, or have been removed
// by the user and have value of false but are shown as changes as shown.
export const historyItemUserEditable = (item) => !item.readOnly && (item.value || item.onlyEdit);

//
// ** Used by screening
//

export function hasHistoryType(historyType, screening, patient) {
  const cancerScreening = getCancerScreening(screening, patient);
  return Object.hasOwn(cancerScreening, historyType);
}

// Note: historyType of null, all items considered
export function getHistoryItemsWithOrWithoutValue(historyType, historyItems, screening, patient, filter = null) {
  const items = historyItems
    .filter((item) => !historyType || item.type === historyType)
    .map((item) => historyItemValue(item, screening, patient));
  return filter ? filter(items, screening, patient) : items;
}

// Return patient cancer history and screening questionnaire in form to save
// directly into firestore. Non-firestore and undefined values filtered out.

export const getHistoryItemsCancerHistory = (historyType, historyItems, screening, patient) =>
  getHistoryItemsWithOrWithoutValue(historyType, historyItems, screening, patient)
    // Item stored in firestore
    .filter((item) => item.questionnaire || item.firestore)
    .filter((item) => item.value !== undefined);

export const getHistoryItemsScreeningQuestionnaire = (historyItems, screening, patient) =>
  getHistoryItemsWithOrWithoutValue(null, historyItems, screening, patient)
    .filter((item) => item.questionnaire && !item.pathology)
    .filter((item) => item.value !== undefined);

// Get chart history items to show on patient chart returning items with
// value of true or changed since physician last accepting screening plan.
// Note: Returns null is history type not supported.
export function getHistoryItemsToShow(historyType, historyItems, screening, patient, filter = null) {
  if (!hasHistoryType(historyType, screening, patient)) {
    return null;
  }
  const items = getHistoryItemsWithOrWithoutValue(historyType, historyItems, screening, patient)
    .filter((item) => item.showChart)
    .filter((item) => item.value || item.changed);
  return filter ? filter(items, screening, patient) : items;
}

export function hasHistoryNotReviewed(historyType, screening, patient) {
  const cancerScreening = getCancerScreening(screening, patient);
  return (
    hasHistoryType(historyType, screening, patient) &&
    Object.hasOwn(cancerScreening[historyType], 'notReviewed') &&
    !isObjectEmpty(cancerScreening[historyType].notReviewed)
  );
}

// Remove history item to array and return updated fromArray item
export function removeHistoryFromArray(item, screening, patient) {
  // Remove history element from fromArray
  if (item.fromArray) {
    const { value: fromArray = [] } = historyItemValue(item.fromArray, screening, patient) || {};
    return [
      {
        ...item.fromArray,
        value: fromArray.filter((value) => item.name !== item.getName(value)),
      },
    ];
  }

  throw new Error('Invalid usage.');
}

// Items available to be edited by the user are already shown on the
// screening chart to the user and not avilable to be added. Read-only
// items are excluded.
function getItemsAvailableToAdd(historyType, historyItems, screening, patient, filter = null) {
  const items = getHistoryItemsWithOrWithoutValue(historyType, historyItems, screening, patient)
    .filter((item) => !item.readOnly)
    .filter((item) => !historyItemUserEditable(item));
  return filter ? filter(items, screening, patient) : items;
}

export const getAddHistoryDialogValue = (historyType, historyItems, screening, patient, filterOptions = null) => ({
  label: historyTypeName(historyType),
  options: getItemsAvailableToAdd(historyType, historyItems, screening, patient, filterOptions).map((option) => ({
    name: option.name,
    value: option.field,
  })),
});

export const getEditHistoryDialogValue = (historyType, options) => ({
  label: historyTypeName(historyType),
  options: options.map((option) => ({
    name: option.name,
    value: option.field,
  })),
});

export const familyMembersLimit = 3;

// Helper - test dialog
export function getPersonalHistoryValue(field, historyItems, screening, patient) {
  const item = historyItem(
    field,
    getHistoryItemsWithOrWithoutValue('personalHistory', historyItems, screening, patient),
  );
  return item !== undefined ? item.value : undefined;
}
