import isAfter from 'date-fns/isAfter';
import isEqual from 'date-fns/isEqual';
import max from 'date-fns/max';
import startOfDay from 'date-fns/startOfDay';
import sub from 'date-fns/sub';
import {
  callPatientBreast,
  chartPersonalHistoryToShowFilterBreast,
  firestoreTransformToScreeningNextTestBreast,
  firestoreTransformToScreeningTestsBreast,
  getAddFamilyHistoryDialogValuesBreast,
  getConsultWindowBreast,
  getScreeningAverageRiskIndicatorsBreast,
  getScreeningHighRiskBreast,
  getScreeningNotApplicableBreast,
  getScreeningVeryHighRiskBreast,
  handleTestEditChangeBreast,
  historyItemsBreast,
  isOfferedOptionallyTestBreast,
  isReflexiveTestBreast,
  menuOptionsAddPersonalHistoryFilterBreast,
  menuOptionsEditPersonalHistoryBreast,
  menuOptionsTestResultBreast,
  menuOptionsTestTypeBreast,
  newDefaultTestBreast,
  referPatientBreast,
  screeningIsEquivalentTestTypeBreast,
  screeningNextTestDueOptionsBreast,
  screeningNextTestTypeOptionsBreast,
  screeningRecommendationBreast,
  screeningRisksBreast,
  setChartHistoryItemBreast,
  testReleaseTypeBreast,
  testTypeNameBreast,
  testValue2NameBreast,
  testValueNameBreast,
  unsetChartHistoryItemBreast,
} from './breast';
import {
  callPatientCervical,
  getConsultWindowCervical,
  getScreeningHighRiskCervical,
  getScreeningLowRiskCervical,
  getScreeningNotApplicableCervical,
  handleTestEditChangeCervical,
  historyItemsCervical,
  isAutoConsultTestCervical,
  menuOptionsEditSocialHistoryCervical,
  menuOptionsTestResult2Cervical,
  menuOptionsTestResultCervical,
  menuOptionsTestTypeCervical,
  newDefaultTestCervical,
  referPatientCervical,
  screeningMaxAgeCervical,
  screeningNextTestDueOptionsCervical,
  screeningRecommendationCervical,
  screeningRisksCervical,
  setChartHistoryItemCervical,
  testReleaseTypeCervical,
  testTypeNameCervical,
  testValue2NameCervical,
  testValueNameCervical,
} from './cervical';
import {
  getAddHistoryDialogValue,
  getEditHistoryDialogValue,
  getHistoryItemsCancerHistory,
  getHistoryItemsScreeningQuestionnaire,
  getHistoryItemsToShow,
  getPersonalHistoryValue,
  hasHistoryNotReviewed,
  hasHistoryType,
  historyItem,
  historyItemUserEditable,
  historyTypeName,
  removeHistoryFromArray,
} from './chartHistoryUtils';
import {
  callPatientColon,
  firestoreTransformToScreeningNextTestColon,
  firestoreTransformToScreeningTestsColon,
  getAddFamilyHistoryDialogValuesColon,
  getConsultWindowColon,
  getScreeningHighRiskColon,
  getScreeningNotApplicableColon,
  getScreeningVeryHighRiskColon,
  handleTestEditChangeColon,
  historyItemsColon,
  isReflexiveTestColon,
  menuOptionsSpecialistRecommendationColon,
  menuOptionsTestResultColon,
  menuOptionsTestTypeColon,
  newDefaultTestColon,
  referPatientColon,
  screeningNextTestDueOptionsColon,
  screeningNextTestTypeOptionsColon,
  screeningRecommendationColon,
  screeningRisksColon,
  setChartHistoryItemColon,
  specialistRecommendationNameColon,
  testReleaseTypeColon,
  testTypeNameColon,
  testTypeReferPatientColon,
  testValueNameColon,
} from './colon';
import { now } from './date';
import {
  callPatientLung,
  getConsultWindowLung,
  getEditChartHistoryDialogValuesLung,
  getScreeningAverageRiskIndicatorsLung,
  getScreeningHighRiskLung,
  getScreeningNotApplicableLung,
  getScreeningVeryHighRiskLung,
  handleTestEditChangeLung,
  historyItemsLung,
  menuOptionsSpecialistRecommendationLung,
  menuOptionsTestResultLung,
  menuOptionsTestTypeLung,
  newDefaultTestLung,
  referPatientLung,
  referralReminderRepeatDurationLung,
  screeningNextTestDueOptionsLung,
  screeningRecommendationLung,
  screeningRisksLung,
  specialistRecommendationNameLung,
  testReleaseTypeLung,
  testTypeNameLung,
  testValueNameLung,
} from './lung';
import { copyObjectInstance } from './object';
import { getCancerScreening } from './patientUtils';
import {
  autoReleaseRequisitionProstate,
  callPatientProstate,
  getConsultWindowProstate,
  getScreeningVeryHighRiskProstate,
  handleTestEditChangeProstate,
  handleTestEditSaveClickProstate,
  historyItemsProstate,
  menuOptionsTestTypeProstate,
  newDefaultTestProstate,
  referPatientProstate,
  screeningMaxAgeProstate,
  screeningNextTestDueOptionsProstate,
  screeningPhysicianRecommendationProstate,
  screeningRecommendationProstate,
  screeningRisksProstate,
  testReleaseTypeProstate,
  testTypeNameProstate,
  testUnadjustedValueNameProstate,
  testValueNameProstate,
} from './prostate';
import { capitalize } from './string';
import { isOrderingPhysicianExternal, isTestNotReviewed, isTestReviewed } from './tests';

export const isPreNewPatient = (screening) => screening.status === 'preNewPatient';
export const isNewPatient = (screening) => screening.status === 'newPatient';
export const isActive = (screening) => screening.status === 'active';
export const isReferral = (screening) => screening.status === 'referral';

export const isPreNewOrNewPatient = (screening) => isPreNewPatient(screening) || isNewPatient(screening);

export const isActiveOrBecomingActivePatient = (patient) =>
  patient.screenings.reduce(
    (accumulator, screening) => (isPreNewOrNewPatient(screening) ? accumulator + 1 : accumulator),
    0,
  ) <= 1;

// Patient in orignal initial onboarding stage and at least 1 screening preNewPatient
// Note: Patient upgrade to screening may have screening status of preNewPatient
// but is not in the initial onboarding state
export const isPatientPreNewPatient = (patient) =>
  patient.screenings !== undefined &&
  patient.screenings.some((screening) => screening.upgradeToScreening !== true && isPreNewPatient(screening));

// Patient in orignal initial onboarding stage and at least 1 screening preNewPatient or newPatient
// Note: Patient upgrade to screening may have screening status of preNewPatient or newPatient
// but is not in the initial onboarding state
export const isPatientNewPatient = (patient) =>
  patient.screenings !== undefined &&
  patient.screenings.some((screening) => screening.upgradeToScreening !== true && isPreNewOrNewPatient(screening));

export const isScreeningPreConsult = (screening) =>
  screening.nextTest.consultRequested &&
  !screening.nextTest.chartUpdated &&
  !screening.nextTest.requisitionReleased &&
  !screening.nextTest.requisitionDownloaded;

export const isScreeningConsult = (screening) =>
  screening.nextTest.consultRequested &&
  screening.nextTest.chartUpdated &&
  !screening.nextTest.requisitionReleased &&
  !screening.nextTest.requisitionDownloaded;

export const mostRecentScreeningActivity = (screening) =>
  max(
    [
      screening.nextTest.due,
      screening.nextTest.chartUpdated,
      screening.nextTest.requisitionReleased,
      screening.nextTest.requisitionDownloaded,
    ].filter((item) => item),
  );

export const isNextTestQuestionnaire = (screening) => screening.nextTest.type === 'questionnaire';

export function getLatestTest(screening) {
  return screening.tests.length !== 0 ? screening.tests[0] : null;
}

// Filters old version of test when editing test (testIndex != null)
// rather than adding a new one.
export function isLatestTestIndex(testIndex, test, screening) {
  return screening.tests
    .filter((_, index) => index !== testIndex)
    .reduce(
      (accumulator, item) => accumulator && (isEqual(test.date, item.date) || isAfter(test.date, item.date)),
      true,
    );
}

export const isLatestTest = (newTest, screening) => isLatestTestIndex(null, newTest, screening);

export function isReflexiveTest(nextTestType, screening, newTest = null) {
  if (screening.type === 'breast') {
    return isReflexiveTestBreast(nextTestType);
  }
  if (screening.type === 'colon') {
    const latestTest = newTest || getLatestTest(screening);
    return isReflexiveTestColon(nextTestType, latestTest);
  }
  return false;
}

// Return latest test as well as associated reflexive tests resulting from
// original screening. Tests ordered newest to oldest.
export function getLatestTests(screening) {
  return screening.tests.reduce((accumulator, test) => {
    if (accumulator.length !== 0 && !isReflexiveTest(accumulator.at(-1).type, screening, test)) {
      return accumulator;
    }
    return accumulator.concat([test]);
  }, []);
}

export function testTypeName(type, screening) {
  if (screening.type === 'breast') return testTypeNameBreast(type);
  if (screening.type === 'cervical') return testTypeNameCervical(type);
  if (screening.type === 'colon') return testTypeNameColon(type);
  if (screening.type === 'lung') return testTypeNameLung(type);
  if (screening.type === 'prostate') return testTypeNameProstate(type);
  return '';
}

export function testValueName(test, screening) {
  if (screening.type === 'breast') return testValueNameBreast(test);
  if (screening.type === 'cervical') return testValueNameCervical(test);
  if (screening.type === 'colon') return testValueNameColon(test);
  if (screening.type === 'lung') return testValueNameLung(test);
  if (screening.type === 'prostate') return testValueNameProstate(test);
  return '';
}

export function testUnadjustedValueName(test, screening) {
  if (screening.type === 'prostate') return testUnadjustedValueNameProstate(test);
  return null;
}

export function testValue2Name(value2, screening) {
  if (screening.type === 'breast') return testValue2NameBreast(value2);
  if (screening.type === 'cervical') return testValue2NameCervical(value2);
  return '';
}

export function getReviewedTests(screening) {
  return screening.tests.reduce((accumulator, item, index) => {
    if (isTestReviewed(item)) return accumulator.concat([{ index, test: item }]);
    return accumulator;
  }, []);
}

export function hasLatestTestNotReviewed(screening) {
  const latestTest = getLatestTest(screening);
  return isTestNotReviewed(latestTest);
}

function getConsultWindow(nextTestType, screening) {
  if (screening.type === 'breast') return getConsultWindowBreast(nextTestType);
  if (screening.type === 'cervical') return getConsultWindowCervical(nextTestType);
  if (screening.type === 'colon') return getConsultWindowColon(nextTestType);
  if (screening.type === 'lung') return getConsultWindowLung(nextTestType);
  if (screening.type === 'prostate') return getConsultWindowProstate(nextTestType);
  return null;
}

export function inConsultWindow(nextTestType, nextTestDue, screening) {
  if (!nextTestType || !nextTestDue) {
    return false;
  }

  const consultWindow = getConsultWindow(nextTestType, screening);
  if (consultWindow === null) {
    return false;
  }

  return now() >= sub(startOfDay(nextTestDue), consultWindow);
}

export function isAutoConsultTest(screening) {
  if (screening.type === 'cervical') return isAutoConsultTestCervical(screening);
  return false;
}

// Is screening test being offered as an optional test
export function isOfferedOptionallyTest(screening, patient) {
  if (screening.type === 'breast') {
    return isOfferedOptionallyTestBreast(screening, patient);
  }
  return screening.nextTest.optional || false;
}

export function testReleaseType(type, screening) {
  if (screening.type === 'breast') return testReleaseTypeBreast(type);
  if (screening.type === 'cervical') return testReleaseTypeCervical(type);
  if (screening.type === 'colon') return testReleaseTypeColon(type);
  if (screening.type === 'lung') return testReleaseTypeLung(type);
  if (screening.type === 'prostate') return testReleaseTypeProstate(type);
  return null;
}

// Does require patient referral to complete test
export function testTypeReferPatient(type, screening) {
  if (screening.type === 'colon') return testTypeReferPatientColon(type);
  return false;
}

//
// Is the screening currently the responsibility of an external physician
// as resolved from most recent test.
//
export function isScreeningExternalMrp(screening, newTest = null) {
  const latestTest = newTest || getLatestTest(screening);
  return latestTest && latestTest.external;
}

// Is the patient screening consult ongoing either explicitly
// or implicitly.
//
// There are situations when a patient for whom Chek is the MRP receives
// a requisition directly, in person, from their family doctor who is
// also a Chek physician and the new result enter in the Chek system with the
// Chek physician listed as the referring physician -- all without a consult.
// Treat this case also as an ongoing consult.

export function isScreeningConsultOngoing(screening) {
  if (screening.nextTest.consultRequested) {
    return true;
  }

  const hasNewResult = hasLatestTestNotReviewed(screening);
  if (hasNewResult && !isScreeningExternalMrp(screening)) {
    return true;
  }

  return false;
}

//
// Special rules to determine if setting test schedule next test date
// or test type triggers requisition to be released.
//
export function autoReleaseRequisition(recommended, screening) {
  // Release a requistion if the next test due falls within the consult
  // window and consult is ongoing (i.e. new patient or screening consult)

  if (isScreeningConsultOngoing(screening)) {
    // Special case prostate may require test be repeated
    if (screening.type === 'prostate') {
      if (autoReleaseRequisitionProstate(recommended)) {
        return true;
      }
    }
    return inConsultWindow(recommended.nextTestType(), recommended.nextTestDue(), screening);
  }

  return false;
}

//
// Special rules to determine if requisition released should be set to when
// updating patient with next external MRP suggestion. Part of processing
// new test result which is external MRP.
//
// Release requisition if ongoing testing (e.g. breast reflexive imaging/biopsy,
// repeat colonoscopy (e.g. FIT positive, reflexive, discharge), repeat PSA).
//
// Patient sees 'Test Pending'
//
export function autoReleaseRequisitionExternalMrpSuggestion(recommended, screening) {
  const latestTest = getLatestTest(screening);

  // Patient consult required after discharge
  if (latestTest && latestTest.type === 'dischargeLetter') {
    // Special case colon
    if (screening.type === 'colon') {
      // Specialist will make arrangements when colonoscopy scheduled
      // within 1 year of discharge
      return isReflexiveTest(recommended.nextTestType(), screening);
    }
    return false;
  }

  // Repeat colonoscopy, breast reflexive imaging/biopsy
  if (isReflexiveTest(recommended.nextTestType(), screening)) {
    return true;
  }

  // Release a requistion if the next test due falls within the consult window

  // Special case prostate
  if (screening.type === 'prostate') {
    if (autoReleaseRequisitionProstate(recommended)) {
      return true;
    }
  }

  return inConsultWindow(recommended.nextTestType(), recommended.nextTestDue(), screening);
}

export function menuOptionsTestType(screening) {
  if (screening.type === 'breast') return menuOptionsTestTypeBreast();
  if (screening.type === 'cervical') return menuOptionsTestTypeCervical();
  if (screening.type === 'colon') return menuOptionsTestTypeColon();
  if (screening.type === 'lung') return menuOptionsTestTypeLung();
  if (screening.type === 'prostate') return menuOptionsTestTypeProstate();
  return [];
}

export function menuOptionsTestResult(test, screening) {
  if (screening.type === 'breast') return menuOptionsTestResultBreast(test);
  if (screening.type === 'cervical') return menuOptionsTestResultCervical(test);
  if (screening.type === 'colon') return menuOptionsTestResultColon(test);
  if (screening.type === 'lung') return menuOptionsTestResultLung(test);
  return [];
}

export function menuOptionsTestResult2(test, screening) {
  if (screening.type === 'cervical') return menuOptionsTestResult2Cervical(test);
  return { label: '', items: [] };
}

export function menuOptionsSpecialistRecommendation(test, screening) {
  if (screening.type === 'colon') return menuOptionsSpecialistRecommendationColon(test);
  if (screening.type === 'lung') return menuOptionsSpecialistRecommendationLung(test);
  return [];
}

export function specialistRecommendationName(recommendation, screening) {
  if (screening.type === 'colon') return specialistRecommendationNameColon(recommendation);
  if (screening.type === 'lung') return specialistRecommendationNameLung(recommendation);
  return '';
}

export const flattenMenuOptions = (items) =>
  items.reduce((accumulator, value, index, array) => {
    if (index + 1 < array.length) {
      return accumulator.concat(value).concat(null);
    }
    return accumulator.concat(value);
  }, []);

export function newDefaultTest(screening, patient) {
  let newTest = { date: null, type: null, value: null };
  if (screening.type === 'breast') newTest = newDefaultTestBreast(screening, isReferral(screening));
  if (screening.type === 'cervical') newTest = newDefaultTestCervical(isReferral(screening));
  if (screening.type === 'colon') newTest = newDefaultTestColon(screening, isReferral(screening));
  if (screening.type === 'lung') newTest = newDefaultTestLung();
  if (screening.type === 'prostate') newTest = newDefaultTestProstate(screening, patient, isReferral(screening));

  // Default test ordering physician
  if (patient.physician !== undefined) {
    const { familyDoctor } = patient.physician;
    return {
      ...newTest,
      orderingPhysician: { name: familyDoctor.name, chekHealth: familyDoctor.chekHealth || false },
    };
  }

  return newTest;
}

// Return updated test or null to signal no change to test
export function handleTestEditChange(key, newValue, test, screening, patient) {
  if (screening.type === 'breast') return handleTestEditChangeBreast(key, newValue, test);
  if (screening.type === 'cervical') return handleTestEditChangeCervical(key, newValue, test);
  if (screening.type === 'colon') return handleTestEditChangeColon(key, newValue, test);
  if (screening.type === 'lung') return handleTestEditChangeLung(key, newValue, test);
  if (screening.type === 'prostate') {
    const newPatient = isPreNewOrNewPatient(screening);
    return handleTestEditChangeProstate(key, newValue, newPatient, test, screening, patient);
  }
  return { ...test, [key]: newValue };
}

// Return test object to be saved
export function handleTestEditSaveClick(test, screening) {
  if (screening.type === 'prostate') return handleTestEditSaveClickProstate(test);
  return test;
}

export function patientScreenedFor(patient) {
  const screeningFor = [];
  if (patient && patient.screenings) {
    ['breast', 'cervical', 'colon', 'lung', 'prostate'].forEach((value) => {
      if (patient.screenings.find((item) => item.type === value)) {
        screeningFor.push(value);
      }
    });
  }
  return screeningFor;
}

// Get new nextText intialized with recommendation
export const screeningNewNextTest = (recommended) => ({
  due: recommended.nextTestDue(),
  type: recommended.nextTestType(),
  consultRequested: null,
  chartUpdated: null,
  requisitionReleased: null,
  requisitionDownloaded: null,
  optional: recommended.nextTestOptional(),
  agingOut: recommended.nextTestAgingOut(),
});

// Perform any necessary data transformations as part of updating
// Firestore.
//
// Patient cancer nextTest and tests and screenings array nextTest and tests
// are used to support backwards compatibility support for older versions
// of mobile.
//
// When creating patient object, nextTest and tests moved under patient
// screenings in memory as abstraction for clinic code (legacy).
// Before updating firestore, in memory nextTest and test transformed from
// the memory representation used by clinic to a version compatible for
// mobile app code.

export function firestoreTransformToScreeningNextTest(screening, patient) {
  const { nextTest, type } = screening;
  if (type === 'breast') return firestoreTransformToScreeningNextTestBreast(nextTest);
  if (type === 'colon') return firestoreTransformToScreeningNextTestColon(nextTest, patient);
  return nextTest;
}

export function firestoreTransformToScreeningTests(screening, patient) {
  const { tests, type } = screening;
  if (type === 'breast') return firestoreTransformToScreeningTestsBreast(tests);
  if (type === 'colon') return firestoreTransformToScreeningTestsColon(tests, patient);
  return tests;
}

export function getScreeningHighRisk(screening, patient) {
  if (screening.type === 'breast') return getScreeningHighRiskBreast(screening, patient);
  if (screening.type === 'cervical') return getScreeningHighRiskCervical(screening, patient);
  if (screening.type === 'colon') return getScreeningHighRiskColon(screening, patient);
  if (screening.type === 'lung') return getScreeningHighRiskLung(screening, patient);
  return { indicators: [], value: false };
}

export function getScreeningVeryHighRisk(screening, patient) {
  if (screening.type === 'breast') return getScreeningVeryHighRiskBreast(screening, patient);
  if (screening.type === 'colon') return getScreeningVeryHighRiskColon(screening, patient);
  if (screening.type === 'lung') return getScreeningVeryHighRiskLung(screening, patient);
  if (screening.type === 'prostate') return getScreeningVeryHighRiskProstate(screening, patient);
  return { indicators: [], value: false };
}

function getScreeningLowRisk(screening, patient) {
  if (screening.type === 'cervical') return getScreeningLowRiskCervical(screening, patient);
  return { indicators: [], value: false };
}

function getScreeningAverageRiskIndicators(screening, patient) {
  if (screening.type === 'breast') return getScreeningAverageRiskIndicatorsBreast(screening, patient);
  if (screening.type === 'lung') return getScreeningAverageRiskIndicatorsLung(screening, patient);
  return [];
}

export function getScreeningNotApplicable(screening, patient) {
  if (screening.type === 'breast') return getScreeningNotApplicableBreast(screening, patient);
  if (screening.type === 'cervical') return getScreeningNotApplicableCervical(screening, patient);
  if (screening.type === 'colon') return getScreeningNotApplicableColon(screening, patient);
  if (screening.type === 'lung') return getScreeningNotApplicableLung(screening, patient);
  return { indicators: [], value: false };
}

// Screening not applicable master switch. Cannot be set through product UI,
// but directly under-to-hood in firestore.
export function getScreeningNotApplicableByPhysician(screening, patient) {
  const cancerScreening = getCancerScreening(screening, patient);
  return (cancerScreening && cancerScreening.notApplicable) || false;
}

export function screeningRisks(screening, patient) {
  if (screening.type === 'breast') return screeningRisksBreast(screening, patient);
  if (screening.type === 'cervical') return screeningRisksCervical(screening, patient);
  if (screening.type === 'colon') return screeningRisksColon(screening, patient);
  if (screening.type === 'lung') return screeningRisksLung(screening, patient);
  if (screening.type === 'prostate') return screeningRisksProstate(screening, patient);
  return {};
}

export function screeningMaxAge(screening) {
  if (screening.type === 'cervical') return screeningMaxAgeCervical;
  if (screening.type === 'prostate') return screeningMaxAgeProstate;
  return 74;
}

//
// Is the screening currently the responsibility of an external physician.
// resolved test in the process of being added but not yet appended to
// test history.
//
// New test in process of being configured. External property maybe set by user
// at time the test added. A test in the process of being newly added will not
// have external defined and call this function to set it.
//
// Note: Only used by TestDialog
export function isTestExternalMrp(newTest, recommended, screening) {
  if (isOrderingPhysicianExternal(newTest)) {
    // (1) Not active consult, externally ordered test remains with external MRP
    if (!isScreeningPreConsult(screening) && !isScreeningConsult(screening)) {
      return true;
    }

    // (2) Patient requested consult, only externally ordered ongoing test
    // remains with external MRP

    // Auto consult is not a patient requested consult unless onboarding
    // as new patient
    if (isAutoConsultTest(screening) && !isPreNewOrNewPatient(screening)) {
      return true;
    }

    if (recommended && recommended.isTestOngoing()) {
      return true;
    }
  }
  return false;
}

export const screeningStatusProps = (newStatus, screening) =>
  newStatus !== screening.status
    ? {
        status: newStatus,
        statusDate: now(),
      }
    : // No update, returning originals
      {
        status: screening.status,
        statusDate: screening.statusDate,
      };

export function screeningRiskStatus(screening, patient) {
  if (getScreeningNotApplicableByPhysician(screening, patient)) {
    return { risk: 'Screening not applicable', indicators: ['Indicated by physician'] };
  }

  const notApplicable = getScreeningNotApplicable(screening, patient);
  if (notApplicable.value) {
    return { risk: 'Screening not applicable', indicators: notApplicable.indicators };
  }

  const veryHighRisk = getScreeningVeryHighRisk(screening, patient);
  if (veryHighRisk.value) {
    return { risk: 'Very high risk', indicators: veryHighRisk.indicators };
  }

  const highRisk = getScreeningHighRisk(screening, patient);
  if (highRisk.value) {
    return { risk: 'High risk', indicators: highRisk.indicators };
  }

  const lowRisk = getScreeningLowRisk(screening, patient);
  if (lowRisk.value) {
    return { risk: 'Low risk', indicators: lowRisk.indicators };
  }

  return { risk: 'Average risk', indicators: getScreeningAverageRiskIndicators(screening, patient) };
}

//
// Screening chart medical history
//

function screeningHistoryItems(screening) {
  if (screening.type === 'breast') return historyItemsBreast;
  if (screening.type === 'cervical') return historyItemsCervical;
  if (screening.type === 'colon') return historyItemsColon;
  if (screening.type === 'lung') return historyItemsLung;
  if (screening.type === 'prostate') return historyItemsProstate;
  return [];
}

//
// Chart medical history
//

export const chartHistoryItem = (field, screening) => historyItem(field, screeningHistoryItems(screening));

export const chartHistoryTypeName = (type) => historyTypeName(type);
export const isChartHistoryItemUserEditable = (item) => historyItemUserEditable(item);

export const hasPersonalHistory = (screening, patient) => hasHistoryType('personalHistory', screening, patient);
export const hasSocialHistory = (screening, patient) => hasHistoryType('socialHistory', screening, patient);
export const hasFamilyHistory = (screening, patient) => hasHistoryType('familyHistory', screening, patient);

export const hasChartHistoryNotReviewed = (screening, patient) =>
  hasHistoryNotReviewed('personalHistory', screening, patient) ||
  hasHistoryNotReviewed('socialHistory', screening, patient) ||
  hasHistoryNotReviewed('familyHistory', screening, patient);

// Update history item to represent it has been included in the
// chart history (user action) and return the equivalent
// firestore items to be stored
export function setChartHistoryItem(item, screening, patient, value = true) {
  // Special non-family history handling
  if (screening.type === 'breast') {
    return setChartHistoryItemBreast(item, screening, patient, value);
  }
  if (screening.type === 'cervical') {
    return setChartHistoryItemCervical(item, value);
  }
  if (screening.type === 'colon') {
    return setChartHistoryItemColon(item, screening, patient, value);
  }

  return [
    {
      ...item,
      value,
    },
  ];
}

// Update history item to represent it has been removed from the
// chart history (user action) and return the equivalent
// firestore items to be stored
export function unsetChartHistoryItem(item, screening, patient) {
  // Remove history element from array
  if (item.fromArray) {
    return removeHistoryFromArray(item, screening, patient);
  }

  if (screening.type === 'breast') {
    return unsetChartHistoryItemBreast(item);
  }

  return [
    {
      ...item,
      value: false,
    },
  ];
}

// Get chart history items to show on patient chart returning items with
// value of true or changed since physician last accepting screening plan.
// Note: Returns null is history type not supported.

export const chartPersonalHistoryToShow = (screening, patient) =>
  getHistoryItemsToShow(
    'personalHistory',
    screeningHistoryItems(screening),
    screening,
    patient,
    // Special: breast filtering
    screening.type === 'breast' ? chartPersonalHistoryToShowFilterBreast : null,
  );

export const chartSocialHistoryToShow = (screening, patient) =>
  getHistoryItemsToShow('socialHistory', screeningHistoryItems(screening), screening, patient);

export const chartFamilyHistoryToShow = (screening, patient) =>
  getHistoryItemsToShow('familyHistory', screeningHistoryItems(screening), screening, patient);

export function menuOptionsEditChartHistory(screening) {
  if (screening.type === 'breast') return menuOptionsEditPersonalHistoryBreast;
  if (screening.type === 'cervical') return menuOptionsEditSocialHistoryCervical;
  return [];
}

//
// Specific support chart history user dialog interaction
//

export const getAddChartHistoryDialogValue = (historyType, screening, patient) =>
  getAddHistoryDialogValue(
    historyType,
    screeningHistoryItems(screening),
    screening,
    patient,
    screening.type === 'breast' ? menuOptionsAddPersonalHistoryFilterBreast : null,
  );

export const getEditChartHistoryDialogValue = (historyType, screening) =>
  getEditHistoryDialogValue(historyType, menuOptionsEditChartHistory(screening));

export function getEditChartHistoryDialogValues(screening, patient) {
  if (screening.type === 'lung') return getEditChartHistoryDialogValuesLung(screening, patient);
  return [];
}

export function getAddFamilyHistoryDialogValues(screening, patient, option) {
  if (screening.type === 'breast') return getAddFamilyHistoryDialogValuesBreast(screening, patient, option);
  if (screening.type === 'colon') return getAddFamilyHistoryDialogValuesColon(screening, patient);
  return [];
}

// Return patient chart history and screening questionnaire in form to save
// directly into firestore. Non-firestore and undefined values filtered out.

export const fromPersonalHistoryToCancerPersonalHistory = (screening, patient) =>
  getHistoryItemsCancerHistory('personalHistory', screeningHistoryItems(screening), screening, patient).reduce(
    (accumulator, item) => ({
      ...accumulator,
      [item.field]: item.value,
    }),
    {},
  );

export const fromSocialHistoryToCancerSocialHistory = (screening, patient) =>
  getHistoryItemsCancerHistory('socialHistory', screeningHistoryItems(screening), screening, patient).reduce(
    (accumulator, item) => ({
      ...accumulator,
      [item.field]: item.value,
    }),
    {},
  );

export const fromFamilyHistoryToCancerFamilyHistory = (screening, patient) =>
  getHistoryItemsCancerHistory('familyHistory', screeningHistoryItems(screening), screening, patient).reduce(
    (accumulator, item) => ({
      ...accumulator,
      [item.field]: item.value,
    }),
    {},
  );

export const fromChartHistoryToScreeningQuestionnaire = (screening, patient) =>
  getHistoryItemsScreeningQuestionnaire(screeningHistoryItems(screening), screening, patient).reduce(
    (accumulator, item) => ({
      ...accumulator,
      [item.field]: item.value,
    }),
    {},
  );

// Helper - test dialog
export const chartPersonalHistoryValue = (field, screening, patient) =>
  getPersonalHistoryValue(field, screeningHistoryItems(screening), screening, patient);

//
// Recommendation
//

export function screeningNextTestDueOptions(recommendation, latestTest, screening, patient) {
  if (screening.type === 'breast') return screeningNextTestDueOptionsBreast(recommendation, latestTest, patient);
  if (screening.type === 'cervical') return screeningNextTestDueOptionsCervical(recommendation, latestTest);
  if (screening.type === 'colon') return screeningNextTestDueOptionsColon(recommendation, latestTest, patient);
  if (screening.type === 'lung') return screeningNextTestDueOptionsLung(recommendation, latestTest);
  if (screening.type === 'prostate') return screeningNextTestDueOptionsProstate(recommendation, latestTest);
  return [];
}

export function screeningIsEquivalentTestType(testType, otherType, screening) {
  if (screening.type === 'breast') return screeningIsEquivalentTestTypeBreast(testType, otherType);
  return testType === otherType;
}

export const screeningNextTestTypeOptions = (recommendation, screening) =>
  (
    (screening.type === 'breast' && screeningNextTestTypeOptionsBreast(recommendation)) ||
    (screening.type === 'colon' && screeningNextTestTypeOptionsColon(recommendation)) || [
      { value: recommendation.type },
    ]
  ).map((option) => ({
    isEquivalent: (otherType) => screeningIsEquivalentTestType(option.value, otherType, screening),
    name: capitalize(testTypeName(option.value, screening)),
    ...option,
  }));

export function screeningRecommendation(latestTest, screening, patient) {
  if (screening.type === 'breast') {
    return screeningRecommendationBreast(latestTest, screening, patient);
  }
  if (screening.type === 'cervical') {
    const otherTests = screening.tests.filter((item, index) => isTestReviewed(item) && index > 0);
    return screeningRecommendationCervical(latestTest, otherTests, screening, patient);
  }
  if (screening.type === 'colon') {
    const otherTests = screening.tests.filter((item, index) => isTestReviewed(item) && index > 0);
    return screeningRecommendationColon(latestTest, otherTests, screening, patient);
  }
  if (screening.type === 'lung') {
    return screeningRecommendationLung(latestTest, screening, patient);
  }
  if (screening.type === 'prostate') {
    return screeningRecommendationProstate(latestTest, patient);
  }
  return {};
}

export function callPatient(recommendation, latestTest, screening) {
  if (screening.type === 'breast') return callPatientBreast(recommendation);
  if (screening.type === 'cervical') return callPatientCervical(recommendation, latestTest);
  if (screening.type === 'colon') return callPatientColon(recommendation, latestTest);
  if (screening.type === 'lung') return callPatientLung(recommendation, latestTest);
  if (screening.type === 'prostate') return callPatientProstate(recommendation, latestTest);
  return false;
}

export function referPatient(recommendation, screening) {
  if (screening.type === 'breast') return referPatientBreast(recommendation);
  if (screening.type === 'cervical') return referPatientCervical(recommendation);
  if (screening.type === 'colon') return referPatientColon(recommendation);
  if (screening.type === 'lung') return referPatientLung(recommendation);
  if (screening.type === 'prostate') return referPatientProstate(recommendation);
  return false;
}

export function screeningPhysicianRecommendation(
  options,
  screeningRecommendationProp,
  physicianRecommendationProp,
  screening,
) {
  if (!options) {
    return null;
  }

  const newPhysicianRecommendation = {
    ...copyObjectInstance(physicianRecommendationProp || screeningRecommendationProp),
    ...options,
  };

  if (screening.type === 'prostate') {
    return screeningPhysicianRecommendationProstate(newPhysicianRecommendation);
  }

  return newPhysicianRecommendation;
}

//
// Referral
//

export function referralReminderRepeatDuration(screening) {
  if (screening.type === 'lung') return referralReminderRepeatDurationLung();
  return { years: 1 };
}
