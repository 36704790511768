/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
import add from 'date-fns/add';
import isEqual from 'date-fns/isEqual';
import { isBeforeToday, isEqualDuration, parseDOB, startOfToday } from './date';

class Recommendation {
  constructor(duration = null, type = null, fromDate = null, optional = false) {
    this.duration = duration;
    this.type = type;
    this.optional = optional;
    this.fromDate = fromDate;

    this.text = null;
    this.referral = false;
    this.agedIn = true;
  }
}

//
// Builder functions
//

export function newRecommendationScheduleTest(duration, type, latestTestDate = null, optional = false) {
  // Scheduling test due using fromDate
  // (1) Next test type questionnaire - today
  // (2) Never tested - today
  // (3) Latest test date
  const fromDate = type !== 'questionnaire' && latestTestDate ? latestTestDate : startOfToday();

  return new Recommendation(duration, type, fromDate, optional);
}

export function newRecommendationAgingIn(duration, type, patient, optional = false) {
  // Scheduling test set fromDate to DOB
  const fromDate = parseDOB(patient.dob);

  const recommendation = new Recommendation(duration, type, fromDate, optional);
  recommendation.agedIn = false;
  return recommendation;
}

export function newRecommendationNoScheduledTest(text) {
  const recommendation = new Recommendation();
  recommendation.text = text;
  return recommendation;
}

export function newRecommendationReferral() {
  const recommendation = new Recommendation();
  recommendation.referral = true;
  return recommendation;
}

//
// Helpers
//

export const isEqualRecommendation = (recommendation1, recommendation2) =>
  ((recommendation1.duration === null && recommendation2.duration === null) ||
    (recommendation1.duration &&
      recommendation2.duration &&
      isEqualDuration(recommendation1.duration, recommendation2.duration))) &&
  recommendation1.type === recommendation2.type &&
  recommendation1.optional === recommendation2.optional &&
  ((recommendation1.fromDate === null && recommendation2.fromDate === null) ||
    (recommendation1.fromDate &&
      recommendation2.fromDate &&
      isEqual(recommendation1.fromDate, recommendation2.fromDate))) &&
  recommendation1.text === recommendation2.text &&
  recommendation1.referral === recommendation2.referral &&
  recommendation1.agedIn === recommendation2.agedIn;

// Given recommendation, get the corresponding nextTest.due
export function recommendationNextTestDue(recommendation) {
  if (recommendation.fromDate === null || recommendation.duration === null) {
    return null;
  }
  const due = add(recommendation.fromDate, recommendation.duration);
  // Due date ultimately cannot be in the past
  return isBeforeToday(due) ? startOfToday() : due;
}
