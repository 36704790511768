import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider';
import InputBase from '@material-ui/core/InputBase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Component } from 'react';
import SearchExpansionPanel from '../components/SearchExpansionPanel';
import { flatShadow, shadow1 } from '../constants/styles';
import MedicalInformationIcon from '../icons/MedicalInformationOutlined';
import StickyNote2Icon from '../icons/StickyNote2Outlined';
import { apiPatientSearch } from '../utils/api';
import cancelable from '../utils/cancelable';
import history from '../utils/history';
import { getFullName } from '../utils/patient';

const styles = (theme) => ({
  button: {
    borderTopLeftRadius: 32,
    borderBottomLeftRadius: 32,
  },
  container: {
    width: 396,
    minHeight: 52,
    marginTop: -26,
    marginLeft: -138,
    borderRadius: 30,
    position: 'absolute',
    left: '50%',
    zIndex: theme.zIndex.appBar + 1,
    transition: 'border-radius 100ms ease',
  },
  containerFocused: {
    borderRadius: 20,
    ...shadow1,
  },
  contentInput: {
    height: 52,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  details: {
    marginLeft: 52,
    '& > :last-child': {
      marginBottom: theme.spacing(1),
    },
  },
  flatShadow,
  icon: {
    marginRight: theme.spacing(2),
  },
  input: {
    width: 352,
    height: 24,
    display: 'flex',
  },
  inputBase: {
    flex: 1,
    marginLeft: theme.spacing(1.5),
  },
  list: {
    '& > :first-child': {
      marginTop: theme.spacing(1.5),
    },
    paddingBottom: theme.spacing(2.5),
  },
  summary: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(1, 0, 1, 3.5),
    minWidth: 0,
    flex: '1 1 0',
  },
  summaryColumn: {
    marginLeft: 38,
    minWidth: 0,
    textAlign: 'left',
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
  },
});

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      query: '',
      results: [],
    };

    this.backdropClick = false;
    this.cancelable = undefined;

    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
  }

  handleBlur() {
    const { focused } = this.state;

    if (focused) {
      this.setState(this.backdropClick ? { focused: false } : { focused: false, query: '', results: [] });
    }
  }

  handleChange(event) {
    const { value } = event.target;

    this.setState({ query: value });
    if (this.cancelable) this.cancelable.cancel();
    this.cancelable = cancelable(apiPatientSearch(value));
    this.cancelable.promise.then((results) => this.setState({ results })).catch(() => {});
  }

  handleFocus() {
    const { focused } = this.state;

    this.setState(!focused ? { focused: true } : null);
    this.backdropClick = false;
  }

  handleKeyDown(event) {
    if (['Escape', 'Esc'].includes(event.key)) {
      event.preventDefault();
      this.setState({ results: [] });
    }
  }

  handleMouseDown() {
    this.backdropClick = true;
  }

  render() {
    const { classes, handleResetChartScrollHistory } = this.props;
    const { focused, query, results } = this.state;

    return (
      <Paper
        className={clsx(
          classes.container,
          focused || results.length !== 0 ? classes.containerFocused : classes.flatShadow,
        )}
      >
        <div className={classes.contentInput}>
          <div className={classes.input}>
            <SearchIcon className={classes.textSecondary} />
            <InputBase
              className={clsx(classes.inputBase, classes.textPrimary)}
              inputProps={{ spellCheck: false }}
              onBlur={this.handleBlur}
              onChange={(event) => this.handleChange(event)}
              onFocus={this.handleFocus}
              onKeyDown={this.handleKeyDown}
              placeholder="Search for patients"
              value={query}
            />
          </div>
        </div>
        {results.length !== 0 && (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <div onMouseDown={this.handleMouseDown} role="document">
            <Divider />
            <div className={classes.list}>
              {results.slice(0, 5).map((item, index) => (
                <SearchExpansionPanel
                  details={
                    <List className={classes.details} disablePadding>
                      <ListItem
                        className={classes.button}
                        button
                        disableRipple
                        onClick={() => {
                          handleResetChartScrollHistory();
                          history.push({ expansionsChart: [], patientId: item.id, tabIndex: 0 });
                        }}
                      >
                        <InfoIcon className={clsx(classes.icon, classes.textSecondary)} />
                        <Typography color="textPrimary">Patient info</Typography>
                      </ListItem>
                      {item.identity === 'verified' && (
                        <>
                          <ListItem
                            className={classes.button}
                            button
                            disableRipple
                            onClick={() => {
                              handleResetChartScrollHistory();
                              history.push({ expansionsChart: [], patientId: item.id, tabIndex: 1 });
                            }}
                          >
                            <MedicalInformationIcon className={clsx(classes.icon, classes.textSecondary)} />
                            <Typography color="textPrimary">Screenings</Typography>
                          </ListItem>
                          <ListItem
                            className={classes.button}
                            button
                            disableRipple
                            onClick={() => {
                              handleResetChartScrollHistory();
                              history.push({ expansionsChart: [], patientId: item.id, tabIndex: 2 });
                            }}
                          >
                            <StickyNote2Icon className={clsx(classes.icon, classes.textSecondary)} />
                            <Typography color="textPrimary">Chart notes</Typography>
                          </ListItem>
                        </>
                      )}
                    </List>
                  }
                  expanded={index === 0}
                  key={item.id}
                  summary={
                    <ButtonBase
                      className={classes.summary}
                      disableRipple
                      focusRipple={false}
                      onClick={() => {
                        handleResetChartScrollHistory();
                        history.push({ expansionsChart: [], patientId: item.id, tabIndex: 1 });
                      }}
                    >
                      <div className={classes.summaryColumn}>
                        <div className={clsx(classes.title)}>
                          <Typography noWrap>{getFullName(item)}</Typography>
                          <Typography color="textSecondary" variant="body2">
                            {item.phn}
                          </Typography>
                        </div>
                      </div>
                    </ButtonBase>
                  }
                />
              ))}
            </div>
          </div>
        )}
      </Paper>
    );
  }
}

Search.propTypes = {
  classes: PropTypes.object.isRequired,
  handleResetChartScrollHistory: PropTypes.func.isRequired,
};

export default withStyles(styles)(Search);
