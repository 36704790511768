// eslint-disable-next-line import/no-extraneous-dependencies
import { gte } from 'semver';

// ** Version compatibility queries

// CT colonoscopy started mobile app v1.6.2
const versionSupportColonoscopyCt = (patient) =>
  patient.settings !== undefined &&
  patient.settings.supportedAppVersion &&
  gte(patient.settings.supportedAppVersion, '1.6.2');

export const versionNoSupportColonoscopyCt = (patient) => !versionSupportColonoscopyCt(patient);

// Firestore billing document format support for
// activity billing code and date (started summer 2023)
export const versionSupportBillingActivityCode = (billing) =>
  billing.activity !== undefined && billing.activity.length !== 0 && Object.hasOwn(billing.activity.at(0), 'date');

export const versionNoSupportBillingActivityCode = (billing) => !versionSupportBillingActivityCode(billing);
