import { isNowDuration } from './date';

// ** File for use by screenings & breast, cervical, colon, lung and prostate **
//
// Cannot include './screenings'

//
// Recommendations
//

export const dueOptionsAge = (startAge, endAge) => {
  const options = [];
  for (let i = endAge; i >= startAge; i -= 1) options.push({ name: `age ${i}`, duration: { months: i * 12 } });
  return options;
};

// Add due option 'now' when recommending due now
export const dueOptionsAddNowIfNeeded = (recommendation, dueOptions) =>
  recommendation.duration && isNowDuration(recommendation.duration)
    ? dueOptions.concat([{ name: 'Now', duration: { months: 0 } }])
    : dueOptions;
