import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { isBefore } from 'date-fns';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { primary60 } from '../constants/colors';
import { now } from '../utils/date';
import { ageLastSmoked, dateLastSmoked } from '../utils/lung';
import { capitalize } from '../utils/string';
import Button from './Button';
import Checkbox from './Checkbox';
import Collapse from './Collapse';
import DialogPaper from './DialogPaper';
import DialogTitle from './DialogTitle';
import TextField from './TextField';
import { dialogActions, selectPaper } from './styles';

const styles = (theme) => ({
  dialogActions: {
    ...dialogActions(theme),
    marginTop: theme.spacing(1.5),
  },
  dialogContent: {
    padding: theme.spacing(0, 1),
  },
  paper: {
    width: 460,
  },
  selectPaper: {
    ...selectPaper(theme),
    maxHeight: 240,
  },
  subtitle: {
    padding: theme.spacing(1, 2, 0),
  },
  underline: {
    '&:after': {
      borderBottomColor: primary60,
    },
    '&&:hover::before': {
      borderBottomColor: primary60,
    },
  },
});

class ChartSocialHistoryDialogLung extends Component {
  constructor(props) {
    super(props);

    const { getValues, patient, screening } = props;

    const { haveSmoked, startAge, packs, quitSmoking, yearQuit } = getValues(screening, patient);

    this.state = {
      haveSmoked: haveSmoked.value,
      startAge: startAge.value,
      packs: packs.value,
      quitSmoking: quitSmoking.value,
      yearQuit: yearQuit.value,
      error: null,
    };

    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.valid = this.valid.bind(this);
    this.validYearQuit = this.validYearQuit.bind(this);
  }

  // Blur year quit
  handleBlur() {
    this.setState({
      error: this.validYearQuit() ? null : 'Invalid year',
    });
  }

  handleChange(key, newValue, event) {
    if (key === 'haveSmoked') {
      this.setState({
        haveSmoked: newValue,
        startAge: null,
        packs: null,
        quitSmoking: null,
        yearQuit: null,
        error: null,
      });
      return;
    }

    if (key === 'startAge') {
      this.setState({
        startAge: newValue,
      });
      return;
    }

    if (key === 'packs') {
      this.setState({
        packs: newValue,
      });
      return;
    }

    if (key === 'quitSmoking') {
      this.setState({
        quitSmoking: newValue,
        yearQuit: null,
        error: null,
      });
      return;
    }

    if (key === 'yearQuit') {
      // Year quit type number in firestore
      const newInt = Number.parseInt(newValue, 10);
      if (newInt < 0 || newInt > 9999) {
        // Input rejected, effectively no change
        if (event) {
          event.stopPropagation();
        }
        return;
      }
      this.setState({ yearQuit: Number.isNaN(newInt) ? null : newInt });
      return;
    }

    this.setState({ [key]: newValue });
  }

  static handleCheckboxChange = (key, handler) => (event) => {
    handler(key, event.target.checked, event);
  };

  static handleValueChange = (key, handler) => (event) => {
    handler(key, event.target.value, event);
  };

  // Dialog close
  handleClose() {
    const { handleClose: handleCloseProp } = this.props;

    this.setState({
      haveSmoked: null,
      startAge: null,
      packs: null,
      quitSmoking: null,
      yearQuit: null,
      error: null,
    });

    handleCloseProp();
  }

  handleSaveClick() {
    const { getValues, handleSaveClick: handleSaveClickProp, screening, patient } = this.props;
    const {
      haveSmoked: haveSmokedState,
      startAge: startAgeState,
      packs: packsState,
      quitSmoking: quitSmokingState,
      yearQuit: yearQuitState,
    } = this.state;

    const { haveSmoked, startAge, packs, quitSmoking, yearQuit } = getValues(screening, patient);

    const items = [
      { ...haveSmoked.item, value: haveSmokedState },
      { ...startAge.item, value: startAgeState },
      { ...packs.item, value: packsState },
      { ...quitSmoking.item, value: quitSmokingState },
      { ...yearQuit.item, value: yearQuitState },
    ];

    handleSaveClickProp(items);

    this.setState({
      haveSmoked: null,
      startAge: null,
      packs: null,
      quitSmoking: null,
      yearQuit: null,
      error: null,
    });
  }

  valid() {
    const { haveSmoked, startAge, packs, quitSmoking, error } = this.state;

    // Non-smoker
    if (haveSmoked === false && error === null) {
      return true;
    }

    // Smoker
    if (haveSmoked === true && startAge !== null && packs !== null && error === null) {
      if (quitSmoking === false) {
        return true;
      }

      return this.validYearQuit();
    }

    return false;
  }

  validYearQuit() {
    const { patient } = this.props;
    const { quitSmoking, startAge, yearQuit } = this.state;

    if (!quitSmoking) {
      return true;
    }

    // Smoker, must have smoked at least 1 year
    return (
      yearQuit !== null &&
      ageLastSmoked(yearQuit, patient) > startAge &&
      isBefore(dateLastSmoked(yearQuit, patient), now())
    );
  }

  render() {
    const { classes, getValues, open, patient, screening, title } = this.props;
    const {
      error,
      haveSmoked: haveSmokedState,
      startAge: startAgeState,
      packs: packsState,
      quitSmoking: quitSmokingState,
      yearQuit: yearQuitState,
    } = this.state;

    const { haveSmoked, startAge, packs, quitSmoking, yearQuit } = getValues(screening, patient);

    return (
      <DialogPaper open={open} onClose={this.handleClose} PaperProps={{ className: classes.paper }} size="large">
        <DialogTitle handleClose={this.handleClose} title={title} />
        <div className={classes.dialogContent}>
          <div className={classes.subtitle}>
            <Typography color="textSecondary">Smoking</Typography>
          </div>
          <List>
            <ListItem key={haveSmoked.label}>
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox
                    checked={haveSmokedState || false}
                    color="primary"
                    onChange={this.constructor.handleCheckboxChange(haveSmoked.item.field, this.handleChange)}
                  />
                }
                label={haveSmoked.label}
              />
            </ListItem>
            <Collapse key="haveSmoked-true" in={haveSmokedState || false}>
              {[
                { value: startAge, valueState: startAgeState },
                { value: packs, valueState: packsState },
              ].map(({ value, valueState }) => (
                <ListItem key={value.label}>
                  <TextField
                    fullWidth
                    InputProps={{ classes: { underline: classes.underline } }}
                    label={capitalize(value.label)}
                    onChange={this.constructor.handleValueChange(value.item.field, this.handleChange)}
                    select
                    SelectProps={
                      value === startAge
                        ? {
                            MenuProps: {
                              PaperProps: { className: classes.selectPaper },
                            },
                          }
                        : {}
                    }
                    value={valueState || ''}
                  >
                    {value.options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </ListItem>
              ))}
              <ListItem key={quitSmoking.label}>
                <FormControlLabel
                  className={classes.checkbox}
                  control={
                    <Checkbox
                      checked={quitSmokingState || false}
                      color="primary"
                      onChange={this.constructor.handleCheckboxChange(quitSmoking.item.field, this.handleChange)}
                    />
                  }
                  label={quitSmoking.label}
                />
              </ListItem>
            </Collapse>
            <Collapse key={yearQuit.label} in={quitSmokingState || false}>
              <ListItem>
                <TextField
                  error={error !== null}
                  fullWidth
                  helperText={error}
                  InputProps={{
                    classes: { underline: classes.underline },
                  }}
                  label={yearQuit.label}
                  onBlur={this.handleBlur}
                  onChange={this.constructor.handleValueChange(yearQuit.item.field, this.handleChange)}
                  placeholder="yyyy"
                  value={yearQuitState || ''}
                />
              </ListItem>
            </Collapse>
          </List>
        </div>
        <DialogActions className={classes.dialogActions}>
          <Button color="primary" disabled={!this.valid()} onClick={this.handleSaveClick} variant="contained">
            Save
          </Button>
        </DialogActions>
      </DialogPaper>
    );
  }
}

ChartSocialHistoryDialogLung.propTypes = {
  classes: PropTypes.object.isRequired,
  getValues: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  patient: PropTypes.object.isRequired,
  screening: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(ChartSocialHistoryDialogLung);
