import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { apiIsUserPhysician } from '../utils/api';
import { formatDateUiText } from '../utils/date';
import { testTypeName } from '../utils/screenings';
import { capitalize } from '../utils/string';
import LabelBadge from './LabelBadge';
import NextTestDue from './NextTestDue';
import TestValue from './TestValue';
import Tooltip from './Tooltip';
import { chartTitle } from './styles';

const styles = (theme) => ({
  action: {
    position: 'absolute',
    top: '50%',
    right: 16,
    transform: 'translateY(-50%)',
    opacity: 0,
    '&:hover': {
      opacity: 1,
    },
  },
  badge: {
    marginLeft: theme.spacing(3),
  },
  badgeSecondary: {
    marginLeft: theme.spacing(1),
  },
  chartTitle: chartTitle(theme),
  item: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    marginBottom: theme.spacing(2),
    position: 'relative',
  },
  itemAction: {
    '&:hover > $action': {
      opacity: 1,
    },
  },
  itemElement: {
    minWidth: 132,
  },
  itemElementWide: {
    minWidth: 164,
  },
  referral: {
    paddingBottom: 6,
  },
});

function NewResultChart(props) {
  const {
    classes,
    handleAcceptClick,
    handleDeleteClick,
    handleEditClick,
    handleRecommendedChanged,
    patient,
    recommended,
    screening,
    test,
    variant,
  } = props;

  return (
    <>
      {variant === 'new result' && <Typography className={classes.chartTitle}>Test schedule</Typography>}
      <div className={clsx(classes.item, classes.itemAction, recommended.referral() && classes.referral)}>
        <Typography className={classes.itemElement}>{formatDateUiText(test.date)}</Typography>
        <Typography className={classes.itemElementWide}>{capitalize(testTypeName(test.type, screening))}</Typography>
        <TestValue screening={screening} test={test} />
        {apiIsUserPhysician() && recommended.referral() && (
          <LabelBadge
            className={classes.badge}
            label={
              (recommended.callPatient() && recommended.referPatient() && 'Call and refer') ||
              (recommended.callPatient() && 'Call patient') ||
              'Refer patient'
            }
          />
        )}
        <LabelBadge
          className={clsx(classes.badge, apiIsUserPhysician() && recommended.referral() && classes.badgeSecondary)}
          label="New result"
          variant="secondary"
        />
        {variant === 'chart' && (
          <div className={classes.action}>
            <Tooltip title="Edit">
              <IconButton onClick={handleEditClick}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton onClick={handleDeleteClick}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
      <NextTestDue
        handleAcceptClick={handleAcceptClick}
        handleRecommendedChanged={handleRecommendedChanged}
        patient={patient}
        recommended={recommended}
        screening={screening}
      />
    </>
  );
}

NewResultChart.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAcceptClick: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func,
  handleEditClick: PropTypes.func,
  handleRecommendedChanged: PropTypes.func.isRequired,
  patient: PropTypes.object.isRequired,
  recommended: PropTypes.object.isRequired,
  screening: PropTypes.object.isRequired,
  test: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['chart', 'new result']).isRequired,
};

NewResultChart.defaultProps = {
  handleDeleteClick: undefined,
  handleEditClick: undefined,
};

export default withStyles(styles)(NewResultChart);
