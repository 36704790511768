import { withStyles } from '@material-ui/core/styles';
import EventIcon from '@material-ui/icons/EventOutlined';
import { KeyboardDatePicker as MuiKeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import DateFnsUtils from '../utils/dateFnsUtils';

const styles = (theme) => ({
  focused: {},
  root: {
    '&$focused': {
      color: theme.palette.text.primary,
    },
  },
});

function KeyboardDatePicker(props) {
  const { classes, InputLabelProps, InputProps, ...other } = props;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MuiKeyboardDatePicker
        keyboardIcon={<EventIcon className={classes.textSecondary} />}
        InputLabelProps={{ classes: { root: classes.root, focused: classes.focused }, ...InputLabelProps }}
        InputProps={{ ...InputProps }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
      />
    </MuiPickersUtilsProvider>
  );
}

KeyboardDatePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  InputLabelProps: PropTypes.object,
  InputProps: PropTypes.object,
};

KeyboardDatePicker.defaultProps = {
  InputLabelProps: undefined,
  InputProps: undefined,
};

export default withStyles(styles)(KeyboardDatePicker);
