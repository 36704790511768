import add from 'date-fns/add';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths';
import differenceInCalendarYears from 'date-fns/differenceInCalendarYears';
import differenceInYears from 'date-fns/differenceInYears';
import format from 'date-fns/format';
import isEqual from 'date-fns/isEqual';
import isFuture from 'date-fns/isFuture';
import isPast from 'date-fns/isPast';
import isValid from 'date-fns/isValid';
import parse from 'date-fns/parse';
import startOfDay from 'date-fns/startOfDay';
import toDate from 'date-fns/toDate';

const dateFormatString = 'LLL d';
const dateLongFormatString = 'LLL d, yyyy';

// Return clone of date
export const now = () => toDate(Date.now());
export const startOfToday = () => startOfDay(now());

export function distanceInCalendarDays(date, fromDate = now()) {
  return Math.abs(differenceInCalendarDays(fromDate, date));
}

export function distanceInCalendarMonths(date, fromDate = now()) {
  return Math.abs(differenceInCalendarMonths(fromDate, date));
}

export function distanceInYears(birthDate, fromDate = now()) {
  return Math.abs(differenceInYears(fromDate, birthDate));
}

export function isToday(date) {
  return Math.abs(differenceInCalendarDays(now(), date)) < 1;
}

export function isInAnotherYear(date) {
  return differenceInCalendarYears(now(), date) !== 0;
}

export function isAfterToday(date) {
  return isFuture(date) && !isToday(date);
}

export function isBeforeToday(date) {
  return isPast(date) && !isToday(date);
}

export function isEqualDuration(duration1, duration2) {
  return isEqual(add(new Date(0), duration1), add(new Date(0), duration2));
}

export function isNowDuration(duration) {
  return isToday(add(now(), duration));
}

export function formatDate(date) {
  return format(date, dateFormatString);
}

export function formatDateLong(date) {
  return format(date, dateLongFormatString);
}

export function formatDateUiText(date, fromDate = now()) {
  const days = differenceInCalendarDays(fromDate, date);
  if (Math.abs(days) < 1) return 'Today';
  if (days > 0 && days < 2) return 'Yesterday';
  if (days < 0 && days > -2) return 'Tomorrow';
  return isInAnotherYear(date) ? formatDateLong(date) : formatDate(date);
}

export const formatDateLongUiText = (date) =>
  distanceInCalendarDays(date) >= 2 ? `on ${formatDateUiText(date)}` : formatDateUiText(date).toLowerCase();

export function parseDOB(dateString) {
  const date = parse(dateString, 'yyyy-MM-dd', now());
  return isValid(date) ? date : undefined;
}

export const isValidKeyboardDate = (date) => date && isValid(date) && date >= new Date(1899, 11, 31);
