import { initializeApp } from 'firebase/app';
import { getAuth, getMultiFactorResolver, PhoneMultiFactorGenerator, RecaptchaVerifier } from 'firebase/auth';
import config from '../constants/firebase';

initializeApp(config);

export function containsPhoneMultiFactor(enrolledFactors) {
  return enrolledFactors.some((item) => item.factorId === PhoneMultiFactorGenerator.FACTOR_ID);
}

export function multiFactorResolver(error) {
  const auth = getAuth();
  return getMultiFactorResolver(auth, error);
}

export function multiFactorHint(resolver) {
  return resolver.hints.find((item) => item.factorId === PhoneMultiFactorGenerator.FACTOR_ID);
}

export function newRecaptchaVerifier(recaptchaContainer) {
  const auth = getAuth();
  return new RecaptchaVerifier(auth, recaptchaContainer, {
    size: 'invisible',
  });
}
