export default function notifier() {
  let resolver;

  const promise = new Promise((resolve) => {
    resolver = resolve;
  });

  promise.resolve = resolver;

  return promise;
}
