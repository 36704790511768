import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/DoneOutlined';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import InfoIcon from '../icons/InfoOutlined';
import {
  specialistRecommendationName,
  testTypeName,
  testUnadjustedValueName,
  testValue2Name,
  testValueName,
} from '../utils/screenings';
import { capitalize } from '../utils/string';
import Popover from './Popover';
import Separator from './Separator';

const styles = (theme) => ({
  adjusted: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(12),
    letterSpacing: `${theme.typography.round(0.3 / 12)}em`,
    lineHeight: theme.typography.pxToRem(12),
    marginLeft: 4,
    position: 'relative',
    top: -6,
  },
  clickable: {
    cursor: 'pointer',
  },
  divider: {
    marginBottom: theme.spacing(1.25),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  infoIcon: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: `${theme.typography.round(0.2 / 14)}em`,
    lineHeight: theme.typography.pxToRem(20),
    marginLeft: 2,
    position: 'relative',
    top: -6,
  },
  popoverIcon: {
    fontSize: '1.1429em',
  },
  popoverItem: {
    display: 'flex',
    alignItems: 'center',
    letterSpacing: `${theme.typography.round(0.4 / 14)}em`,
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  textSecondary: {
    lineHeight: theme.typography.pxToRem(16),
  },
});

function TestValue(props) {
  const { classes, screening, test, variant } = props;

  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (variant === 'double-line') {
    // No test value, include test type (e.g. discharge letter)
    if (test.value === undefined || test.value === null) {
      return (
        <>
          <Typography className={classes.flex} noWrap>
            {capitalize(testTypeName(test.type, screening))}
          </Typography>
          {test.recommendation !== undefined && test.recommendation !== null && (
            <Typography className={classes.textSecondary} color="textSecondary" noWrap variant="caption">
              {specialistRecommendationName(test.recommendation, screening)}
            </Typography>
          )}
        </>
      );
    }

    // Include test type as caption but not recommendation
    return (
      <>
        <Typography className={classes.flex} noWrap>
          {testValueName(test, screening)}
          {test.value2 && (
            <>
              <Separator />
              {testValue2Name(test.value2, screening)}
            </>
          )}
        </Typography>
        <Typography className={classes.textSecondary} color="textSecondary" noWrap variant="caption">
          {capitalize(testTypeName(test.type, screening))}
        </Typography>
      </>
    );
  }

  // No test value (e.g. discharge letter)
  if (test.value === undefined || test.value === null) {
    return <div />;
  }

  // ** Special: PSA

  return test.type === 'psa' && (test.extras.dutasteride || test.extras.finasteride) ? (
    <>
      <div ref={anchorRef}>
        <Typography className={clsx(classes.flex, classes.clickable)} component="span" onClick={handleClick} noWrap>
          {testValueName(test, screening)}
          <span className={classes.adjusted}>adjusted</span>
          <InfoIcon className={classes.infoIcon} />
        </Typography>
      </div>
      <Popover anchorEl={anchorRef.current} handleClose={handleClose} open={open}>
        {test.extras.unadjusted && (
          <Typography className={classes.popoverItem} variant="body2">
            Unadjusted PSA level
            <span>{testUnadjustedValueName(test, screening)}</span>
          </Typography>
        )}
        {test.extras.finasteride && (
          <Typography className={classes.popoverItem} variant="body2">
            Finasteride (Proscar)
            <DoneIcon className={classes.popoverIcon} />
          </Typography>
        )}
        {test.extras.dutasteride && (
          <Typography className={classes.popoverItem} variant="body2">
            Dutasteride (Avodart)
            <DoneIcon className={classes.popoverIcon} />
          </Typography>
        )}
        <Divider className={classes.divider} />
        {test.value && (
          <Typography className={classes.popoverItem} variant="subtitle2">
            Adjusted PSA level
            <span>{test.value}</span>
          </Typography>
        )}
      </Popover>
    </>
  ) : (
    <Typography className={classes.flex} noWrap>
      {testValueName(test, screening)}
      {test.value2 && (
        <>
          <Separator />
          {testValue2Name(test.value2, screening)}
        </>
      )}
      {test.recommendation !== undefined && test.recommendation !== null && (
        <>
          {test.value && <Separator />}
          {specialistRecommendationName(test.recommendation, screening)}
        </>
      )}
    </Typography>
  );
}

TestValue.propTypes = {
  classes: PropTypes.object.isRequired,
  screening: PropTypes.object.isRequired,
  test: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['default', 'double-line']),
};

TestValue.defaultProps = {
  variant: 'default',
};

export default withStyles(styles)(TestValue);
