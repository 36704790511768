import MuiCollapse from '@material-ui/core/Collapse';
import PropTypes from 'prop-types';
import theme from '../constants/theme';

function Collapse(props) {
  const { in: inProp, onTransitionEnd, ...other } = props;

  const addEndListener = (node, done) => {
    const eventListener = (event) => {
      if (event.propertyName === 'height') {
        node.removeEventListener('transitionend', eventListener, false);
        done(event);
        if (onTransitionEnd) onTransitionEnd();
      }
    };
    node.addEventListener('transitionend', eventListener, false);
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiCollapse addEndListener={addEndListener} in={inProp} timeout={theme.transitions.duration.standard} {...other} />
  );
}

Collapse.propTypes = {
  in: PropTypes.bool.isRequired,
  onTransitionEnd: PropTypes.func,
};

Collapse.defaultProps = {
  onTransitionEnd: undefined,
};

export default Collapse;
