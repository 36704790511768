import ButtonBase from '@material-ui/core/ButtonBase';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { flatShadow } from '../constants/styles';
import { mixinGutters } from '../constants/theme';
import { formatDateUiText } from '../utils/date';
import Bar from './Bar';

const styles = (theme) => ({
  button: {
    height: 70,
    display: 'flex',
    flex: '1 0 auto',
    justifyContent: 'space-between',
    alignItems: 'center',
    ...mixinGutters(theme),
  },
  container: {
    display: 'flex',
    flex: 1,
  },
  date: {
    minWidth: 124,
  },
  description: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 80,
  },
  flatShadow,
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  paper: {
    margin: '1px 0',
    '&:first-child': {
      marginTop: 0,
      marginBottom: 1,
    },
    '&:last-child': {
      marginTop: 1,
      marginBottom: 0,
    },
  },
  reasonContainer: {
    justifyContain: 'flex-end',
  },
});

function SummaryRow(props) {
  const { barColor, classes, date, description, fullName, handleClick } = props;

  return (
    <Paper className={clsx(classes.paper, classes.flatShadow)} square>
      <ButtonBase className={classes.button} component="div" disableRipple onClick={handleClick}>
        <div className={classes.flex}>
          <Bar color={barColor} />
          <Typography className={classes.date} noWrap>
            {formatDateUiText(date)}
          </Typography>
          <Typography noWrap>{fullName}</Typography>
        </div>
        <Typography className={classes.description} noWrap>
          {description}
        </Typography>
      </ButtonBase>
    </Paper>
  );
}

SummaryRow.propTypes = {
  barColor: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(SummaryRow);
