import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grow from '@material-ui/core/Grow';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core/index';
import { withStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import add from 'date-fns/add';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { apiIsUserPhysician } from '../utils/api';
import { distanceInCalendarDays, formatDateLong, isNowDuration, startOfToday } from '../utils/date';
import Recommended from '../utils/recommended';
import { testTypeName } from '../utils/screenings';
import { capitalize } from '../utils/string';
import DatePicker from './DatePicker';
import Select from './Select';

const styles = (theme) => ({
  arrow: {
    color: theme.palette.text.secondary,
    position: 'absolute',
    right: 10,
    pointerEvents: 'none',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  form: {
    minWidth: 280,
    marginLeft: theme.spacing(2),
  },
  grow: {
    transformOrigin: '0 0 0',
  },
  item: {
    display: 'flex',
    alignItems: 'baseline',
  },
  popper: {
    zIndex: theme.zIndex.modal,
  },
  recommended: {
    marginLeft: theme.spacing(0.5),
  },
});

class NextTestDueSelect extends Component {
  constructor(props) {
    super(props);

    const { recommended } = props;

    const dueOptions = Recommended.nextTestDueOptions(recommended);

    this.state = {
      customDuration: null,
      dueOption: dueOptions.find((option) => option.recommended),
      dueOptions,
      error: undefined,
      popperOpen: false,
      recommended,
      selectOpen: false,
      typeOption: Recommended.nextTestTypeOptions(recommended).find((option) => option.recommended),
    };

    this.menuItemRef = createRef();

    this.handleCustomChange = this.handleCustomChange.bind(this);
    this.handlePopperClose = this.handlePopperClose.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSelectClose = this.handleSelectClose.bind(this);
    this.handleSelectOpen = this.handleSelectOpen.bind(this);
    this.menuOptions = this.menuOptions.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { recommended: recommendedProp } = props;
    const { recommended } = state;

    // Re-initialize due options if underlying screening recommendation
    // has changed e.g. new patient latest test added

    if (!recommended.isScreeningRecommendationsEqual(recommendedProp)) {
      const dueOption = Recommended.getNextTestDueOption(recommendedProp);
      const customDuration = dueOption === null ? recommendedProp.nextTestDuration() : null;

      return {
        customDuration,
        dueOption,
        dueOptions: Recommended.nextTestDueOptions(recommendedProp),
        error: undefined,
        popperOpen: false,
        recommended: recommendedProp,
        selectOpen: false,
        typeOption: Recommended.nextTestTypeOptions(recommendedProp).find((option) => option.recommended),
      };
    }

    return null;
  }

  handleCustomChange(date) {
    const { handleRecommendedChanged, recommended } = this.props;

    const customDuration = { days: distanceInCalendarDays(date, recommended.nextTestFromDate()) };

    // User free-hand due date
    const newRecommended = Recommended.copy(recommended);
    newRecommended.setPhysicianRecommendation({ duration: customDuration });
    handleRecommendedChanged(newRecommended);
    this.setState({ customDuration, dueOption: null });
  }

  handlePopperClose() {
    this.setState({ popperOpen: false, selectOpen: false });
  }

  handleSelectChange(event) {
    const { handleRecommendedChanged, recommended } = this.props;
    const { dueOptions } = this.state;

    const { value: optionName } = event.target;

    // Use selected one of fixed menu options
    if (optionName !== 'Custom date') {
      const dueOption = dueOptions.find((option) => option.name === optionName);
      if (dueOption) {
        const newRecommended = Recommended.copy(recommended);
        newRecommended.setPhysicianRecommendation({ duration: dueOption.duration });
        handleRecommendedChanged(newRecommended);
        this.setState({ customDuration: null, dueOption });
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleSelectClose(event) {
    const { innerText } = event.target;

    this.setState((state) => {
      const { customDuration, popperOpen } = state;

      if (innerText === 'Custom date') {
        if (!popperOpen) {
          return { popperOpen: true };
        }
        if (popperOpen && customDuration !== null) {
          return { popperOpen: false, selectOpen: false };
        }
        return null;
      }
      return { popperOpen: false, selectOpen: false };
    });
  }

  handleSelectOpen() {
    this.setState({ selectOpen: true });
  }

  menuOptions() {
    const { customDuration, dueOptions } = this.state;

    // (1) Add null item for divider between aging out and aged in
    // (use of null required for select and menu items to work)
    // (2) Add custom date option
    return dueOptions
      .reduce((accumulator, option, index, array) => {
        if (index !== 0 && !option.agingOut && array[index - 1].agingOut) {
          return accumulator.concat([null, option]);
        }
        return accumulator.concat([option]);
      }, [])
      .concat([{ name: 'Custom date', duration: customDuration }]);
  }

  render() {
    const { classes, recommended, screening } = this.props;
    const { dueOption: dueOptionState, dueOptions, error, popperOpen, selectOpen, typeOption } = this.state;

    function renderValue(optionName) {
      if (optionName === 'Custom date') {
        return <Typography>{formatDateLong(recommended.nextTestDue())}</Typography>;
      }

      const dueOption = dueOptions.find((option) => option.name === optionName);

      return (
        <Typography>
          {(dueOption && dueOption.name) || ''}
          {/* Allows mammogram and mammogramAwbu to be nearly equivalent
              Note: physician selection is recommended and screeningRecommendation is typeOption */}
          {dueOption && dueOption.recommended && typeOption.isEquivalent(recommended.nextTestType()) && (
            <Typography className={classes.recommended} component="span">
              (recommended)
            </Typography>
          )}
        </Typography>
      );
    }

    return (
      <div className={classes.item}>
        <Typography>{`${capitalize(testTypeName(recommended.nextTestType(), screening))}:`}</Typography>
        <FormControl className={classes.form} disabled={!apiIsUserPhysician()} error={error !== undefined}>
          <Select
            onChange={this.handleSelectChange}
            onClose={this.handleSelectClose}
            onOpen={this.handleSelectOpen}
            open={selectOpen}
            // eslint-disable-next-line react/jsx-no-bind
            renderValue={renderValue}
            value={(dueOptionState && dueOptionState.name) || 'Custom date'}
          >
            {this.menuOptions().map((menuOption) =>
              menuOption === null ? (
                <Divider className={classes.divider} key="null" variant="middle" />
              ) : (
                <MenuItem
                  key={menuOption.name}
                  ref={menuOption.name === 'Custom date' ? this.menuItemRef : undefined}
                  value={menuOption.name}
                >
                  <ListItemText
                    primary={menuOption.name === 'Custom date' ? menuOption.name : renderValue(menuOption.name)}
                    secondary={
                      (menuOption.duration &&
                        `due ${formatDateLong(
                          isNowDuration(menuOption.duration)
                            ? startOfToday()
                            : add(recommended.nextTestFromDate(), menuOption.duration),
                        )}`) ||
                      ''
                    }
                  />
                  {menuOption.name === 'Custom date' && <ChevronRightIcon className={classes.arrow} fontSize="small" />}
                </MenuItem>
              ),
            )}
          </Select>
          {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
        {this.menuItemRef.current && (
          <Popper
            className={classes.popper}
            anchorEl={this.menuItemRef.current}
            modifiers={{
              preventOverflow: {
                enabled: true,
                boundariesElement: 'viewport',
              },
            }}
            open={popperOpen}
            placement="right-start"
            transition
          >
            {({ TransitionProps }) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <Grow className={classes.grow} {...TransitionProps}>
                <Paper elevation={8}>
                  <DatePicker
                    autoOk
                    minDate={startOfToday()}
                    onChange={this.handleCustomChange}
                    onClose={this.handlePopperClose}
                    value={recommended.nextTestDue()}
                    variant="static"
                  />
                </Paper>
              </Grow>
            )}
          </Popper>
        )}
      </div>
    );
  }
}

NextTestDueSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  handleRecommendedChanged: PropTypes.func.isRequired,
  recommended: PropTypes.object.isRequired,
  screening: PropTypes.object.isRequired,
};

export default withStyles(styles)(NextTestDueSelect);
