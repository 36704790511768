import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CSSTransition from './CSSTransition';

const styles = (theme) => ({
  enter: {
    opacity: 0,
    transform: 'translateY(-8.5px)',
  },
  enterActive: {
    opacity: 1,
    transform: 'translateY(0)',
    transition: theme.transitions.create(['opacity', 'transform']),
  },
  enterDone: {
    opacity: 1,
    transform: 'translateY(0)',
  },
  exit: {
    opacity: 1,
    transform: 'translateY(0)',
  },
  exitActive: {
    opacity: 0,
    transform: 'translateY(-8.5px)',
    transition: theme.transitions.create(['opacity', 'transform']),
  },
  exitDone: {
    opacity: 0,
  },
});

function FadeSlide(props) {
  const { children, classes, in: inProp, onTransitionEnd } = props;

  return (
    <CSSTransition
      classNames={{
        enter: classes.enter,
        enterActive: classes.enterActive,
        enterDone: classes.enterDone,
        exit: classes.exit,
        exitActive: classes.exitActive,
        exitDone: classes.exitDone,
      }}
      in={inProp}
      onTransitionEnd={onTransitionEnd}
    >
      <div>{children}</div>
    </CSSTransition>
  );
}

FadeSlide.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  in: PropTypes.bool.isRequired,
  onTransitionEnd: PropTypes.func,
};

FadeSlide.defaultProps = {
  onTransitionEnd: undefined,
};

export default withStyles(styles)(FadeSlide);
