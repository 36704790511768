import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from './Button';
import DialogPaper from './DialogPaper';
import DialogTitle from './DialogTitle';
import { dialogActions } from './styles';

const styles = (theme) => ({
  dialogActions: {
    ...dialogActions(theme),
  },
});

function Dialog(props) {
  const { cancelLabel, classes, contentText, handleClose, handleCancelClick, handleOkClick, okLabel, title, ...other } =
    props;

  return (
    <DialogPaper
      {...other} // eslint-disable-line react/jsx-props-no-spreading
    >
      {title && <DialogTitle title={title} handleClose={handleClose} />}
      {contentText && contentText.length !== 0 && (
        <DialogContent>
          {contentText.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <DialogContentText key={index.toString()}>{item}</DialogContentText>
          ))}
        </DialogContent>
      )}
      <DialogActions className={classes.dialogActions}>
        {handleCancelClick && (
          <Button color="primary" onClick={handleCancelClick}>
            {cancelLabel}
          </Button>
        )}
        <Button color="primary" onClick={handleOkClick}>
          {okLabel}
        </Button>
      </DialogActions>
    </DialogPaper>
  );
}

Dialog.propTypes = {
  cancelLabel: PropTypes.string,
  classes: PropTypes.object.isRequired,
  contentText: PropTypes.arrayOf(PropTypes.string),
  handleCancelClick: PropTypes.func,
  handleClose: PropTypes.func,
  handleOkClick: PropTypes.func.isRequired,
  okLabel: PropTypes.string,
  PaperProps: PropTypes.object,
  size: PropTypes.oneOf(['default', 'large']),
  title: PropTypes.string,
};

Dialog.defaultProps = {
  cancelLabel: 'Cancel',
  contentText: [],
  handleCancelClick: null,
  handleClose: null,
  okLabel: 'OK',
  PaperProps: {},
  size: 'default',
  title: null,
};

export default withStyles(styles)(Dialog);
