import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { colorTheme } from '../constants/theme';

const styles = (theme) => ({
  button: {
    lineHeight: theme.typography.pxToRem(24),
    minWidth: 84,
  },
  contained: {
    boxShadow: '0 1px 1px rgba(0, 0, 0, 0.16)',
    '&:active': {
      boxShadow: '0 1px 1px rgba(0, 0, 0, 0.16)',
    },
    '&:hover': {
      boxShadow: '0 1px 1px rgba(0, 0, 0, 0.16)',
    },
    '&.Mui-focusVisible': {
      boxShadow: '0 1px 1px rgba(0, 0, 0, 0.16)',
    },
  },
  outlined: {
    border: `1px solid ${theme.palette.divider}`,
    '&:active': {
      border: `1px solid ${theme.palette.divider}`,
    },
    '&:hover': {
      border: `1px solid ${theme.palette.divider}`,
    },
  },
});

function Button(props) {
  const { classes, className: classNameProp, MuiButtonClasses, variant, ...other } = props;

  return (
    <ThemeProvider theme={colorTheme}>
      <MuiButton
        classes={MuiButtonClasses}
        className={clsx(
          classes.button,
          variant === 'contained' && classes.contained,
          variant === 'outlined' && classes.outlined,
          classNameProp,
        )}
        variant={variant}
        {...other} // eslint-disable-line react/jsx-props-no-spreading
      />
    </ThemeProvider>
  );
}

Button.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  MuiButtonClasses: PropTypes.object,
  variant: PropTypes.string,
};

Button.defaultProps = {
  className: undefined,
  MuiButtonClasses: {},
  variant: undefined,
};

export default withStyles(styles)(Button);
