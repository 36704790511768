import PropTypes from 'prop-types';
import { Component } from 'react';
import { CSSTransition as RtgCSSTranstion } from 'react-transition-group';

class CSSTransition extends Component {
  componentDidMount() {
    const { onTransitionEnd } = this.props;

    if (onTransitionEnd) onTransitionEnd();
  }

  render() {
    const { in: inProp, onTransitionEnd, ...other } = this.props;

    const addEndListener = (node, done) => {
      const eventListener = (event) => {
        node.removeEventListener('transitionend', eventListener, false);
        done(event);
        if (onTransitionEnd) onTransitionEnd();
      };
      node.addEventListener('transitionend', eventListener, false);
    };

    return <RtgCSSTranstion addEndListener={addEndListener} in={inProp} timeout={null} {...other} />; // eslint-disable-line react/jsx-props-no-spreading
  }
}

CSSTransition.propTypes = {
  in: PropTypes.bool.isRequired,
  onTransitionEnd: PropTypes.func,
};

CSSTransition.defaultProps = {
  onTransitionEnd: undefined,
};

export default CSSTransition;
