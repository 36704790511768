import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/AddOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';
import Dialog from '../components/Dialog';
import DialogMultilineText from '../components/DialogMultilineText';
import DialogSelect from '../components/DialogSelect';
import Separator from '../components/Separator';
import Tooltip from '../components/Tooltip';
import { lung40, primary, secondary } from '../constants/colors';
import { apiUpdateBillingEntry, apiUpdateChartNote } from '../utils/api';
import { billingActivityTitle, billingCodeName, billingEntryId, menuOptionsBillingCode } from '../utils/billing';
import {
  addAttachment,
  chartAttachmentId,
  chartNoteId,
  deleteAttachment,
  isChartNoteUserNote,
  updateAttachment,
  viewNoteGetNote,
} from '../utils/chartNotes';
import { formatDateLong } from '../utils/date';

const styles = (theme) => ({
  action: {
    position: 'absolute',
    right: -16,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  actionHover: {
    opacity: 0,
    '&:hover': {
      opacity: 1,
    },
  },
  container: {
    position: 'relative',
  },
  content: {
    padding: theme.spacing(1.5, 3, 1.5, 0),
  },
  item: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  itemAction: {
    '&:hover > $action': {
      opacity: 1,
    },
  },
  paper: {
    width: 352,
  },
  colorSecondary: {
    color: secondary,
  },
  createdBy: {
    fontStyle: 'italic',
    letterSpacing: `${theme.typography.round(0.1 / 14)}em`,
    paddingTop: theme.spacing(0.5),
  },
  billingCode: {
    color: lung40,
    marginTop: theme.spacing(0.25),
  },
  title: {
    color: primary,
    paddingBottom: theme.spacing(1),
  },
  headline: {
    color: primary,
    paddingTop: theme.spacing(0.5),
  },
  headlineUserNote: {
    color: secondary,
  },
  description: {
    paddingBottom: theme.spacing(0.25),
  },
  subnote: {
    lineHeight: theme.typography.pxToRem(22),
    maxWidth: 650,
  },
  subnotes: {
    paddingBottom: theme.spacing(0.5),
  },
  attachments: {
    padding: theme.spacing(0.5, 0, 1),
  },
  attachment: {
    lineHeight: theme.typography.pxToRem(22),
    maxWidth: 488,
  },
  attachmentNonUserNote: {
    color: secondary,
  },
  separator: {
    marginRight: 14,
  },
  subtitle2: {
    lineHeight: theme.typography.pxToRem(20),
  },
});

class ChartNote extends Component {
  constructor(props) {
    super(props);

    const { openBilling } = props;

    this.state = {
      action: openBilling ? 'edit billing' : null,
      description: null,
      dialogTitle: null,
      attachmentId: null,
      noteId: null,
    };

    this.handleAddAttachmentClick = this.handleAddAttachmentClick.bind(this);
    this.handleEditBillingClick = this.handleEditBillingClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDeleteAttachmentClick = this.handleDeleteAttachmentClick.bind(this);
    this.handleDeleteNoteClick = this.handleDeleteNoteClick.bind(this);
    this.handleEditAttachmentClick = this.handleEditAttachmentClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  handleAddAttachmentClick(noteId) {
    return () => {
      this.setState({
        action: 'add attachment',
        dialogTitle: 'Attach note',
        noteId,
      });
    };
  }

  handleClose() {
    this.setState({
      action: null,
      description: null,
      dialogTitle: null,
      attachmentId: null,
      noteId: null,
    });
  }

  handleDeleteAttachmentClick(attachmentId, noteId) {
    return () => {
      this.setState({
        action: 'delete attachment',
        dialogTitle: 'Delete attached note?',
        attachmentId,
        noteId,
      });
    };
  }

  handleDeleteNoteClick() {
    const { viewNote } = this.props;

    this.setState({
      action: 'delete note',
      dialogTitle: 'Delete chart note?',
      noteId: viewNote.id,
    });
  }

  handleEditAttachmentClick(description, attachmentId, noteId) {
    return () => {
      this.setState({
        action: 'edit attachment',
        description,
        dialogTitle: 'Edit attached note',
        attachmentId,
        noteId,
      });
    };
  }

  handleEditBillingClick() {
    this.setState({
      action: 'edit billing',
    });
  }

  handleSaveClick(value) {
    const { viewNote, patient, handleDeleteNoteClick: handleDeleteNoteClickProp, user } = this.props;
    const { action, attachmentId, noteId } = this.state;

    this.setState({ action: null });

    if (action === 'add attachment') {
      const note = viewNoteGetNote(noteId, viewNote);
      if (note) {
        apiUpdateChartNote(addAttachment(value, user, note), patient).then(this.handleClose);
      }
      return;
    }

    if (action === 'edit attachment') {
      const note = viewNoteGetNote(noteId, viewNote);
      if (note) {
        apiUpdateChartNote(updateAttachment(value, attachmentId, note), patient).then(this.handleClose);
      }
      return;
    }
    if (action === 'edit billing') {
      const billingId = billingEntryId(viewNote.createdDate, patient);
      apiUpdateBillingEntry(value, viewNote.createdDate, billingId).then(this.handleClose);
      return;
    }
    // Note: Deleting attachment, deletes user note
    if (action === 'delete note' || (action === 'delete attachment' && viewNote.editable)) {
      handleDeleteNoteClickProp(noteId);
      return;
    }

    if (action === 'delete attachment') {
      const note = viewNoteGetNote(noteId, viewNote);
      if (note) {
        apiUpdateChartNote(deleteAttachment(attachmentId, note), patient).then(this.handleClose);
      }
      return;
    }

    this.handleClose();
  }

  render() {
    const { classes, user, viewNote } = this.props;
    const { action, description, dialogTitle } = this.state;

    const allowEdit = viewNote.createdBy.userId === user.id;
    const openBilling = action === 'edit billing';
    const openDialog = ['delete note', 'delete attachment'].includes(action);
    const openChartNote = ['add attachment', 'edit attachment'].includes(action);

    return (
      <>
        <div className={classes.container}>
          <div className={classes.content}>
            <div className={clsx(classes.item, allowEdit && classes.itemAction)}>
              <Typography className={classes.subtitle2} variant="subtitle2">
                {formatDateLong(viewNote.createdDate)}
              </Typography>
              {allowEdit && viewNote.editable && (
                <div className={clsx(classes.action, classes.actionHover)}>
                  <Tooltip title="Delete note">
                    <IconButton onClick={this.handleDeleteNoteClick}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
            {viewNote.title && (
              <Typography className={clsx(classes.subtitle2, classes.title)} variant="subtitle2">
                {viewNote.title}
              </Typography>
            )}
            {viewNote.notes.map((note, index) => {
              const noteId = chartNoteId(note);
              return (
                <Fragment key={`${noteId}-${index.toString()}`}>
                  {
                    // Normally use the note headline unless view note title
                    // set and all notes use a common headline (aka. title)
                    note.headline && !viewNote.title && (
                      <Typography
                        className={clsx(
                          classes.subtitle2,
                          isChartNoteUserNote(note) && classes.headlineUserNote,
                          classes.headline,
                        )}
                        variant="subtitle2"
                      >
                        {note.headline}
                      </Typography>
                    )
                  }
                  {
                    // Attach note to non-user chart notes
                    !isChartNoteUserNote(note) && (
                      <div className={classes.description}>
                        <div className={clsx(classes.item, allowEdit && classes.itemAction)}>
                          <Typography className={classes.subtitle2} variant="subtitle2">
                            {note.description}
                          </Typography>
                          {allowEdit && (
                            <div className={clsx(classes.action, classes.actionHover)}>
                              <Tooltip title="Attach note">
                                <IconButton onClick={this.handleAddAttachmentClick(noteId)}>
                                  <AddIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  }
                  {note.subnotes.length !== 0 && (
                    <div className={classes.subnotes}>
                      {note.subnotes.map((subnote, subnoteIndex) => (
                        <Typography
                          className={classes.subnote}
                          key={`${subnote}-${subnoteIndex.toString()}`}
                          variant="body2"
                        >
                          <Separator className={classes.separator} />
                          {subnote}
                        </Typography>
                      ))}
                    </div>
                  )}
                  {note.attachments.length !== 0 && (
                    <div className={classes.attachments}>
                      {note.attachments.map((attachment) => {
                        const attachmentId = chartAttachmentId(attachment);
                        return (
                          <div className={clsx(classes.item, allowEdit && classes.itemAction)} key={attachmentId}>
                            <Typography
                              className={clsx(
                                classes.attachment,
                                !isChartNoteUserNote(note) && classes.attachmentNonUserNote,
                              )}
                              variant="body2"
                            >
                              {attachment.description}
                            </Typography>
                            {allowEdit && (
                              <div className={clsx(classes.action, classes.actionHover)}>
                                <Tooltip title="Edit note">
                                  <IconButton
                                    onClick={this.handleEditAttachmentClick(
                                      attachment.description,
                                      attachmentId,
                                      noteId,
                                    )}
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                                {!viewNote.editable && (
                                  <Tooltip title="Delete note">
                                    <IconButton onClick={this.handleDeleteAttachmentClick(attachmentId, noteId)}>
                                      <DeleteIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </Fragment>
              );
            })}
            <Typography className={classes.createdBy} variant="body2">
              {viewNote.createdBy.fullName}
            </Typography>
            {viewNote.billing && (
              <div className={clsx(classes.item, allowEdit && classes.itemAction)}>
                <Typography className={clsx(classes.subtitle2, classes.billingCode)} variant="subtitle2">
                  {billingCodeName(viewNote.billing.code)}
                </Typography>
                {allowEdit && (
                  <div className={clsx(classes.action, classes.actionHover)}>
                    <Tooltip title="Edit code">
                      <IconButton onClick={this.handleEditBillingClick}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </div>
            )}
          </div>
          <Divider />
        </div>
        {openBilling && (
          <DialogSelect
            handleClose={this.handleClose}
            handleSaveClick={this.handleSaveClick}
            label={billingActivityTitle}
            open
            options={menuOptionsBillingCode}
            title={`Edit ${billingActivityTitle}`}
            value={viewNote.billing.code}
          />
        )}
        {openDialog && (
          <Dialog
            handleCancelClick={this.handleClose}
            handleOkClick={this.handleSaveClick}
            okLabel="OK, delete"
            open
            onClose={this.handleClose}
            PaperProps={{ className: classes.paper }}
            title={dialogTitle}
          />
        )}
        {openChartNote && (
          <DialogMultilineText
            handleClose={this.handleClose}
            handleSaveClick={this.handleSaveClick}
            open
            placeholder="Description"
            title={dialogTitle}
            value={description}
          />
        )}
      </>
    );
  }
}

ChartNote.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDeleteNoteClick: PropTypes.func.isRequired,
  openBilling: PropTypes.bool,
  patient: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  viewNote: PropTypes.object.isRequired,
};

ChartNote.defaultProps = {
  openBilling: false,
};

export default withStyles(styles)(ChartNote);
