import { neutral20 } from './colors';

export const fontFamily1 = 'Roboto, Helvetica, Arial, sans-serif';
export const fontFamily2 = 'GoogleSans, Roboto, Helvetica, Arial, sans-serif';

const headerPrimaryColor = neutral20;

const round = (value) => Math.round(value * 1e5) / 1e5;

export const typography = {
  h1: {
    color: headerPrimaryColor,
    fontFamily: fontFamily2,
  },
  h2: {
    color: headerPrimaryColor,
    fontFamily: fontFamily2,
  },
  h3: {
    color: headerPrimaryColor,
    fontFamily: fontFamily2,
  },
  h4: {
    color: headerPrimaryColor,
    fontFamily: fontFamily2,
    fontSize: `${round(28 / 16)}rem`,
    letterSpacing: 'normal',
    lineHeight: `${round(36 / 16)}rem`,
  },
  h5: {
    color: headerPrimaryColor,
    fontFamily: fontFamily2,
  },
  h6: {
    color: headerPrimaryColor,
    fontFamily: fontFamily2,
    fontSize: `${round(22 / 16)}rem`,
    lineHeight: `${round(28 / 16)}rem`,
  },
  subtitle1: {
    fontWeight: 500,
  },
  subtitle2: {
    lineHeight: `${round(20 / 14)}em`,
  },
  body1: {
    fontFamily: fontFamily2,
    fontWeight: 500,
  },
  body2: {
    letterSpacing: `${round(0.25 / 14)}em`,
  },
  button: {
    fontFamily: fontFamily2,
    textTransform: 'none',
  },
  caption: {
    letterSpacing: `${round(0.4 / 12)}em`,
  },
  round,
};
