export default function cancelable(promise) {
  let hasCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      value => (hasCanceled ? reject(new Error('Cancelled')) : resolve(value)),
      error => (hasCanceled ? reject(new Error('Cancelled')) : reject(error)),
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    },
  };
}
