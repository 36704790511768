import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CSSTransition from './CSSTransition';

const styles = (theme) => ({
  enter: {
    opacity: 0,
  },
  enterActive: {
    opacity: 1,
    transition: theme.transitions.create('opacity', {
      delay: 100,
      duration: theme.transitions.duration.standard - 100,
    }),
  },
  enterDone: {
    opacity: 1,
  },
  exit: {
    opacity: 1,
  },
  exitActive: {
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.standard - 100,
    }),
  },
  exitDone: {
    opacity: 0,
  },
});

function DelayedFade(props) {
  const { children, classes, in: inProp, onTransitionEnd } = props;

  return (
    <CSSTransition
      appear
      classNames={{
        appear: classes.enter,
        appearActive: classes.enterActive,
        appearDone: classes.enterDone,
        enter: classes.enter,
        enterActive: classes.enterActive,
        enterDone: classes.enterDone,
        exit: classes.exit,
        exitActive: classes.exitActive,
        exitDone: classes.exitDone,
      }}
      in={inProp}
      onTransitionEnd={onTransitionEnd}
    >
      {children}
    </CSSTransition>
  );
}

DelayedFade.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  in: PropTypes.bool.isRequired,
  onTransitionEnd: PropTypes.func,
};

DelayedFade.defaultProps = {
  onTransitionEnd: undefined,
};

export default withStyles(styles)(DelayedFade);
