import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Dialog from './Dialog';

const styles = {
  paper: {
    width: 388,
  },
};

function ReferralDialog(props) {
  const { classes, handleClose, handleOkClick, open, recommended } = props;
  if (recommended !== null && !recommended.referPatient()) {
    return (
      <Dialog
        cancelLabel="No"
        handleCancelClick={handleClose}
        handleOkClick={handleOkClick}
        okLabel={open ? 'Yes, patient called' : ''}
        open={open}
        onClose={handleClose}
        PaperProps={{ className: classes.paper }}
        title={open ? 'Has the patient been called?' : ''}
      />
    );
  }

  return (
    <Dialog
      contentText={
        open
          ? ['A request will be made to Chek clinic staff to send the referral.'].concat(
              recommended !== null && recommended.callPatient()
                ? ['The patient should have been called prior to continuing.']
                : [],
            )
          : null
      }
      handleCancelClick={handleClose}
      handleOkClick={handleOkClick}
      okLabel={open ? 'Yes, make request' : ''}
      open={open}
      onClose={handleClose}
      title={open ? 'Request referral be sent?' : ''}
    />
  );
}

ReferralDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleOkClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  recommended: PropTypes.object,
};

ReferralDialog.defaultProps = {
  recommended: null,
};

export default withStyles(styles)(ReferralDialog);
