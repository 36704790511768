function checkCharacters(password) {
  let upper = 0;
  let lower = 0;
  let number = 0;
  let special = 0;
  for (let i = 0; i < password.length; i += 1) {
    if (password[i] >= 'A' && password[i] <= 'Z') {
      upper += 1;
    } else if (password[i] >= 'a' && password[i] <= 'z') {
      lower += 1;
    } else if (password[i] >= '0' && password[i] <= '9') {
      number += 1;
    } else {
      special += 1;
    }
  }
  return (
    [upper, lower, number, special].reduce((accumulator, value) => (value !== 0 ? accumulator + 1 : accumulator), 0) >=
    3
  );
}

export const passwordInstruction =
  'Use at least 8 characters and include 3 of upper case, lower case, numeric or special characters.';

export function validPassword(password) {
  if (password === null || password.length < 8) {
    return 'Use password of at least 8 characters';
  }
  if (!checkCharacters(password)) {
    return 'Use 3 of upper case, lower case, numeric or special characters';
  }
  return null;
}
