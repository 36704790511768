import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const styles = {
  logoDefault: {
    height: 42,
  },
  logoAppBar: {
    width: 104,
  },
};

function Logo(props) {
  const { className, classes, variant } = props;

  return variant === 'appbar' ? (
    <Box className={clsx(classes.logoAppBar, className)} component="img" src="images/logo.svg" alt="chek logo" />
  ) : (
    <Box className={clsx(classes.logoDefault, className)} component="img" src="images/logo.svg" alt="chek logo" />
  );
}

Logo.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['default', 'appbar']),
};

Logo.defaultProps = {
  className: undefined,
  variant: 'default',
};

export default withStyles(styles)(Logo);
