import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { flatShadow, noHover } from '../constants/styles';
import { mixinGutters } from '../constants/theme';
import notifier from '../utils/notifier';
import Bar from './Bar';
import Collapse from './Collapse';
import FadeSlide from './FadeSlide';
import FadeSlideBetween from './FadeSlideBetween';

const styles = (theme) => ({
  expandButton: {
    color: theme.palette.text.secondary,
    padding: 8,
    margin: '15px 16px',
  },
  flatShadow,
  noHover,
  icon: {
    transition: theme.transitions.create('transform'),
  },
  iconExpanded: {
    transform: 'rotate(180deg)',
  },
  summary: {
    display: 'flex',
  },
  summaryButton: {
    height: 70,
    flex: 1,
    ...mixinGutters(theme),
  },
  transitionContainer: {
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  transitionComponent: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
  },
});

function ExpansionPanel(props) {
  const {
    barColor,
    classes,
    className,
    details,
    expanded,
    expandedSummary,
    handleClick,
    handleExpandClick,
    onTransitionEnd,
    summary,
  } = props;

  let fadeSlideBetweenOnTransitionEnd;
  let collapseOnTransitionEnd;
  let fadeSlideOnTransitionEnd;

  if (onTransitionEnd) {
    const fadeSlideBetweenPromise = notifier();
    const collapsePromise = notifier();
    const fadeSlidePromise = notifier();

    fadeSlideBetweenOnTransitionEnd = () => fadeSlideBetweenPromise.resolve();
    collapseOnTransitionEnd = () => collapsePromise.resolve();
    fadeSlideOnTransitionEnd = () => fadeSlidePromise.resolve();

    Promise.all([fadeSlideBetweenPromise, collapsePromise, fadeSlidePromise]).then(onTransitionEnd);
  }

  return (
    <Paper className={clsx(classes.flatShadow, className)} square>
      <div className={classes.summary}>
        <ButtonBase className={classes.summaryButton} component="div" disableRipple onClick={handleClick}>
          <Bar color={barColor} />
          <div className={classes.transitionContainer}>
            <FadeSlideBetween
              componentIn={<div className={classes.transitionComponent}>{expandedSummary}</div>}
              componentOut={<div className={classes.transitionComponent}>{summary}</div>}
              in={expanded}
              onTransitionEnd={fadeSlideBetweenOnTransitionEnd}
            />
          </div>
        </ButtonBase>
        <IconButton className={clsx(classes.expandButton, classes.noHover)} onClick={handleExpandClick}>
          <ExpandMoreIcon className={clsx(classes.icon, expanded && [classes.iconExpanded])} />
        </IconButton>
      </div>
      {details && (
        <Collapse in={expanded} onTransitionEnd={collapseOnTransitionEnd}>
          <div>
            <Divider />
            <FadeSlide in={expanded} onTransitionEnd={fadeSlideOnTransitionEnd}>
              {details}
            </FadeSlide>
          </div>
        </Collapse>
      )}
    </Paper>
  );
}

ExpansionPanel.propTypes = {
  barColor: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  details: PropTypes.element,
  expanded: PropTypes.bool.isRequired,
  expandedSummary: PropTypes.element,
  handleClick: PropTypes.func,
  handleExpandClick: PropTypes.func,
  onTransitionEnd: PropTypes.func,
  summary: PropTypes.element.isRequired,
};

ExpansionPanel.defaultProps = {
  className: undefined,
  details: undefined,
  expandedSummary: undefined,
  handleClick: undefined,
  handleExpandClick: undefined,
  onTransitionEnd: undefined,
};

export default withStyles(styles)(ExpansionPanel);
