import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = {
  container: {
    marginRight: 33,
  },
  image: {
    width: 4,
    height: 32,
    opacity: 0.87,
  },
};

function Bar(props) {
  const { classes, color, ...other } = props;

  return (
    <div className={classes.container}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <svg className={classes.image} fill={color} viewBox="0 0 4 32" {...other}>
        <path d="M 0,0 L 0,32 L 4,32 L 4,0 L 0,0 z" />
      </svg>
    </div>
  );
}

Bar.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
};

export default withStyles(styles)(Bar);
