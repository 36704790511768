import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import Logo from '../components/Logo';
import { primary } from '../constants/colors';
import { fontFamily1 } from '../constants/typography';
import {
  apiAccountExists,
  apiGetUser,
  apiSignIn,
  apiSignInVerificationCode,
  apiSignInVerifyPhoneNumber,
} from '../utils/api';
import { validEmail } from '../utils/email';
import EventListener from '../utils/eventListener';
import { multiFactorHint, multiFactorResolver, newRecaptchaVerifier } from '../utils/firebase';

const styles = (theme) => ({
  container: {
    height: '100%',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    width: 384,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingTop: 120,
  },
  formCode: {
    paddingTop: 36,
  },
  textField: {
    fontSize: theme.typography.pxToRem(24),
  },
  textSpacing: {
    lineHeight: theme.typography.pxToRem(48),
  },
  button: {
    borderRadius: 80,
    color: primary,
    marginTop: 44,
    padding: '12px 32px',
  },
  buttonText: {
    color: '#000000',
    fontFamily: fontFamily1,
    fontSize: theme.typography.pxToRem(13),
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: `${theme.typography.round(0.25 / 13)}em`,
    lineHeight: theme.typography.pxToRem(20),
    marginTop: theme.spacing(5),
    opacity: 0.54,
  },
  placeHolder: {
    marginTop: 40,
    height: 16,
  },
  primary: {
    color: primary,
  },
  textAssistance: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 112,
    paddingBottom: 62.25,
    width: '100%',
  },
  textCode: {
    paddingBottom: 36,
    width: '100%',
  },
});

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      error: undefined,
      password: '',
      resolver: undefined,
      showPassword: false,
      step: 0,
      verificationCode: '',
      verificationId: undefined,
    };

    this.inputRef = createRef();
    this.selection = null;

    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleForgotPasswordClick = this.handleForgotPasswordClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSignInClick = this.handleSignInClick.bind(this);
    this.handleVisibilityClick = this.handleVisibilityClick.bind(this);
  }

  componentDidUpdate() {
    const { step } = this.state;

    if (step === 1) {
      if (this.selection) {
        const { selectionStart, selectionEnd, selectionDirection } = this.selection;
        this.inputRef.current.focus();
        this.inputRef.current.setSelectionRange(selectionStart, selectionEnd, selectionDirection);
        this.selection = null;
      } else {
        this.inputRef.current.focus();
      }
    }
  }

  handleChange(param) {
    return (event) => {
      if (param === 'email') {
        this.handleEmailChange(event.target.value);
      } else {
        this.setState({ [param]: event.target.value });
      }
    };
  }

  handleClick() {
    const { email, password, step } = this.state;

    if (step === 0) {
      if (email === '') {
        this.setState({ error: 'Enter an email' });
        this.inputRef.current.focus();
        return;
      }

      if (!validEmail(email)) {
        this.setState({ error: 'Enter a valid email' });
        this.inputRef.current.focus();
        return;
      }

      apiAccountExists(email)
        .then((exists) =>
          this.setState({
            step: exists ? 1 : 0,
            error: exists ? undefined : "Couldn't find your Chek account",
          }),
        )
        .catch(() => {
          this.setState({ error: undefined });
        });
    } else if (step === 1) {
      if (password === '') {
        this.setState({ error: 'Enter a password' });
        this.inputRef.current.focus();
        return;
      }

      apiSignIn(email, password)
        .then((credentials) => {
          apiGetUser(credentials.user.uid).catch(() => {
            this.setState({
              step: 0,
              password: '',
              error: "Couldn't find your Chek account",
            });
          });
        })
        .catch((error) => {
          const { code } = error;

          if (code === 'auth/multi-factor-auth-required') {
            const resolver = multiFactorResolver(error);
            const recaptchaVerifier = newRecaptchaVerifier('sign-in-click');

            apiSignInVerifyPhoneNumber(resolver, recaptchaVerifier)
              .then((verificationId) => {
                this.setState({
                  error: undefined,
                  resolver,
                  step: 3,
                  verificationId,
                });
              })
              .catch((error2) => {
                const { code: code2 } = error2;

                if (code2 === 'auth/too-many-requests') {
                  this.setState({ error: 'Too many attempts. Try again later.' });
                } else {
                  // eslint-disable-next-line no-console
                  console.error(error2);
                  this.setState({ error: 'Error occurred. Sign-in again.' });
                }
              });
          } else if (code === 'auth/wrong-password') {
            this.setState({ error: 'Incorrect password. Try again or click forgot password to reset it.' });
            this.inputRef.current.focus();
          }
        });
    } else if (step === 3) {
      const { resolver, verificationCode, verificationId } = this.state;

      apiSignInVerificationCode(resolver, verificationCode, verificationId).catch((error) => {
        const { code } = error;

        if (code === 'auth/invalid-verification-code') {
          this.setState({ error: 'Invaid code. Try entering the code again.' });
          this.inputRef.current.focus();
        } else {
          this.setState({ error: 'Error occurred. Sign-in again.' });
        }
      });
    }
  }

  handleEmailChange(email) {
    this.setState({ email: email.toLowerCase() });
  }

  handleForgotPasswordClick() {
    this.setState({ step: 2 });
  }

  handleKeyDown(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.handleClick();
    }
  }

  handleSignInClick() {
    this.setState({
      email: '',
      error: undefined,
      password: '',
      resolver: undefined,
      step: 0,
      verificationCode: undefined,
      verificationId: undefined,
    });
  }

  handleVisibilityClick() {
    const { showPassword } = this.state;

    const { selectionStart, selectionEnd, selectionDirection } = this.inputRef.current;
    this.selection = { selectionStart, selectionEnd, selectionDirection };
    this.setState({ showPassword: !showPassword });
  }

  render() {
    const { classes } = this.props;
    const { email, error, password, resolver, showPassword, step, verificationCode } = this.state;

    return (
      <div className={classes.container}>
        <EventListener target="window" onKeyDown={this.handleKeyDown} />
        <div className={classes.content}>
          <Logo />
          {step === 0 && (
            <div className={classes.form}>
              <TextField
                autoComplete="email"
                autoFocus
                className={classes.textField}
                error={error !== undefined}
                fullWidth
                helperText={error}
                InputProps={{
                  inputProps: {
                    autoCapitalize: 'off',
                    autoCorrect: 'off',
                    spellCheck: false,
                  },
                }}
                inputRef={this.inputRef}
                onChange={this.handleChange('email')}
                placeholder="Enter email"
                type="email"
                value={email}
              />
              <Button
                className={classes.button}
                color="primary"
                onClick={this.handleClick}
                size="large"
                type="submit"
                variant="contained"
                disableRipple
                fullWidth
              >
                Next
              </Button>
              <div className={classes.placeHolder} />
            </div>
          )}
          {step === 1 && (
            <div className={classes.form}>
              <TextField
                autoComplete="current-password"
                className={classes.textField}
                error={error !== undefined}
                fullWidth
                helperText={error}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={this.handleVisibilityClick}>
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  inputProps: {
                    autoCapitalize: 'off',
                    autoCorrect: 'off',
                    spellCheck: false,
                  },
                }}
                inputRef={this.inputRef}
                onChange={this.handleChange('password')}
                placeholder="Enter password"
                type={showPassword ? 'text' : 'password'}
                value={password}
              />
              <Button
                className={classes.button}
                color="primary"
                id="sign-in-click"
                onClick={this.handleClick}
                size="large"
                variant="contained"
                disableRipple
                fullWidth
              >
                Next
              </Button>
              <ButtonBase className={classes.buttonText} disableRipple onClick={this.handleForgotPasswordClick}>
                Forgot password?
              </ButtonBase>
            </div>
          )}
          {step === 2 && (
            <div className={classes.textAssistance}>
              <Typography className={classes.textSpacing}>Please contact us to have your password reset.</Typography>
              <Link
                aria-label="Phone Chek Health"
                className={classes.primary}
                href="tel:+18882527915"
                rel="noopener"
                title="Phone Chek Health"
              >
                <Typography variant="subtitle1">1-888-252-7915</Typography>
              </Link>
              <div className={classes.placeHolder} />
            </div>
          )}
          {step === 3 && (
            <div className={clsx(classes.form, classes.formCode)}>
              <Typography
                className={classes.textCode}
              >{`Enter the 6-digit code sent to you at (***) *** - ${multiFactorHint(resolver).phoneNumber.slice(
                -4,
              )}`}</Typography>
              <TextField
                autoFocus
                className={classes.textField}
                error={error !== undefined}
                fullWidth
                helperText={error}
                id="sign-in-code"
                InputProps={{
                  inputProps: {
                    autoCapitalize: 'off',
                    autoCorrect: 'off',
                    spellCheck: false,
                  },
                }}
                inputRef={this.inputRef}
                onChange={this.handleChange('verificationCode')}
                placeholder="000000"
                type="text"
                value={verificationCode}
              />
              <Button
                className={classes.button}
                color="primary"
                onClick={this.handleClick}
                size="large"
                type="submit"
                variant="contained"
                disableRipple
                fullWidth
              >
                Next
              </Button>
              <div className={classes.placeHolder} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);
