import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/EditOutlined';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { apiIsUserPhysician } from '../utils/api';
import Recommended from '../utils/recommended';
import {
  autoReleaseRequisition,
  hasChartHistoryNotReviewed,
  hasLatestTestNotReviewed,
  isNewPatient,
  isScreeningConsult,
  isScreeningConsultOngoing,
  isScreeningPreConsult,
  testReleaseType,
} from '../utils/screenings';
import Button from './Button';
import LabelBadge from './LabelBadge';
import NextTestDueSelect from './NextTestDueSelect';
import ReferralDialog from './ReferralDialog';
import SetTestType from './SetTestType';

const styles = (theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2.5),
    paddingRight: 30,
  },
  badge: {
    marginLeft: theme.spacing(3),
  },
  badgeSecondary: {
    marginLeft: theme.spacing(1),
  },
  button: {
    boxShadow: 'none',
  },
  buttonSpacing: {
    marginRight: theme.spacing(1),
  },
  recommendedText: {
    paddingTop: 9,
  },
  testScheduled: {
    display: 'flex',
    alignItems: 'baseline',
    flex: 1,
    paddingTop: theme.spacing(0.75),
  },
});

class NextTestDue extends Component {
  constructor(props) {
    super(props);

    const { recommended } = props;

    this.state = {
      dueOptions: Recommended.nextTestDueOptions(recommended),
      openReferral: false,
      openType: false,
      recommended,
      typeOptions: Recommended.nextTestTypeOptions(recommended),
    };

    this.anchorRef = createRef(null);
    this.handleAcceptClick = this.handleAcceptClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleReferralCompleteClicked = this.handleReferralCompleteClicked.bind(this);
    this.handleSetTestDateClicked = this.handleSetTestDateClicked.bind(this);
    this.handleSetTestTypeChanged = this.handleSetTestTypeChanged.bind(this);
    this.handleSetTestTypeClicked = this.handleSetTestTypeClicked.bind(this);
    this.getButtonLabel = this.getButtonLabel.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { recommended: recommendedProp } = props;
    const { recommended } = state;

    // Re-initialize recommended if underlying screening recommendation
    // has changed e.g. new patient latest test added

    if (!recommended.isScreeningRecommendationsEqual(recommendedProp)) {
      return {
        dueOptions: Recommended.nextTestDueOptions(recommendedProp),
        recommended: recommendedProp,
        typeOptions: Recommended.nextTestTypeOptions(recommendedProp),
      };
    }

    return null;
  }

  handleClose() {
    this.setState({ openReferral: false, openType: false });
  }

  handleReferralCompleteClicked() {
    const { openType } = this.state;

    if (openType) {
      return;
    }

    this.setState({ openReferral: true });
  }

  handleSetTestDateClicked() {
    const { handleRecommendedChanged, recommended: recommendedProp } = this.props;
    const { dueOptions, openReferral, openType } = this.state;

    if (openReferral || openType) {
      return;
    }

    // (1) Clearing earlier physician recommendation which aged out
    // patient early, so need to clear prior physician recommendation
    // and return to original screening recommendation.
    //
    // (2) Physician is looking to age back in patient by screening
    // test earlier than recommendation test due.

    const newRecommended = Recommended.copy(recommendedProp);

    // Clear earlier recommendation
    newRecommended.setPhysicianRecommendation(null);

    if (newRecommended.nextTestAgingOut()) {
      const option = dueOptions.find((item) => !item.agingOut);
      if (option !== undefined) {
        newRecommended.setPhysicianRecommendation({ duration: option.duration });
      }
    }

    handleRecommendedChanged(newRecommended);
  }

  handleSetTestTypeChanged(type) {
    const { handleRecommendedChanged, recommended: recommendedProp } = this.props;
    const { typeOptions } = this.state;

    const typeOption = typeOptions.find((option) => option.value === type);

    if (typeOption !== undefined) {
      // Optionally clear earlier recommendation when returning to screening
      // recommended test type and new test type is distinct from previous
      // type (i.e. not clearing recommended due date when flipping between
      // mammogram and mammogramAwbu). Test types mammogram and mammogramAwbu
      // is the only case where there is more than one recommended test type
      // for a screening.
      // Notes:
      // (1) This also resets due date to recommended date
      // (2) The previous test type option is recommendedProp

      const newRecommended = Recommended.copy(recommendedProp);

      newRecommended.setPhysicianRecommendation(
        typeOption.recommended && !typeOption.isEquivalent(recommendedProp.nextTestType())
          ? null
          : { type: typeOption.value },
      );

      handleRecommendedChanged(newRecommended);
    }
  }

  handleSetTestTypeClicked() {
    const { openReferral, openType } = this.state;

    if (openReferral) {
      return;
    }

    this.setState({ openType: !openType });
  }

  handleAcceptClick() {
    const { handleAcceptClick } = this.props;

    this.setState({ openReferral: false });

    handleAcceptClick();
  }

  getButtonLabel() {
    const { editingTestSchedule, recommended, screening } = this.props;

    // Additional label parts when not editing and screening consult is ongoing

    if (editingTestSchedule && !isScreeningConsultOngoing(screening)) {
      return 'Accept';
    }

    const part =
      ['requisition', 'instructions'].includes(testReleaseType(recommended.nextTestType(), screening)) &&
      autoReleaseRequisition(recommended, screening)
        ? ' & release requisition'
        : '';

    if (recommended.referPatient()) {
      return 'Request referral'.concat(part);
    }

    if (recommended.callPatient()) {
      return 'Call complete'.concat(part);
    }

    return 'Accept'.concat(part);
  }

  render() {
    const {
      classes,
      editingTestSchedule,
      handleAcceptClick,
      handleRecommendedChanged,
      patient,
      screening,
      recommended,
    } = this.props;
    const { dueOptions, openReferral, openType, typeOptions } = this.state;

    const screeningPreConsult = isScreeningPreConsult(screening);
    const screeningConsult = isScreeningConsult(screening);
    const newPatient = isNewPatient(screening);
    const hasNewResult = hasLatestTestNotReviewed(screening);
    const hasNewHistory = hasChartHistoryNotReviewed(screening, patient);

    return (
      <>
        {
          // Either review recommended text or select nextTest, not both and
          // is neither when patient referral.
          //
          // The one case patient referral: onboarding new patient and needs
          // to be referred to specialist by Chek physician.
          //
          // Recommended text when aged out, very high risk, or not applicable.
          //
        }
        {((recommended.text() || (newPatient && recommended.referral())) && (
          <div className={clsx(hasNewResult && classes.recommendedText)}>
            <Typography>
              {recommended.text() || 'Patient due for referral to a specialist.'}
              {apiIsUserPhysician() && recommended.isTestNotScheduled() && (
                <>
                  <LabelBadge className={classes.badge} label="Review" />
                  {(newPatient && (
                    <LabelBadge
                      className={classes.badgeSecondary}
                      label={screening.upgradeToScreening ? 'New screening' : 'New patient'}
                      variant="secondary"
                    />
                  )) ||
                    (screeningConsult && (
                      <LabelBadge className={classes.badgeSecondary} label="Consult request" variant="secondary" />
                    )) ||
                    (hasNewHistory && (
                      <LabelBadge className={classes.badgeSecondary} label="Chart update" variant="secondary" />
                    ))}
                </>
              )}
            </Typography>
          </div>
        )) ||
          (recommended.isTestScheduled() && (
            <div className={classes.testScheduled}>
              <NextTestDueSelect
                handleRecommendedChanged={handleRecommendedChanged}
                recommended={recommended}
                screening={screening}
              />
              {(editingTestSchedule && (
                <LabelBadge
                  className={classes.badge}
                  icon={<EditIcon fontSize="inherit" />}
                  label="Editing"
                  variant="primary"
                />
              )) ||
                (apiIsUserPhysician() && (
                  <>
                    {/* Colonoscopy patient refer and call */}
                    <LabelBadge
                      className={classes.badge}
                      label={
                        (recommended.callPatient() && recommended.referPatient() && 'Call and refer') ||
                        (recommended.callPatient() && 'Call patient') ||
                        (recommended.referPatient() && 'Refer patient') ||
                        'Set test date'
                      }
                    />
                    {(newPatient && (
                      <LabelBadge
                        className={classes.badgeSecondary}
                        label={screening.upgradeToScreening ? 'New screening' : 'New patient'}
                        variant="secondary"
                      />
                    )) ||
                      (screeningConsult && (
                        <LabelBadge className={classes.badgeSecondary} label="Consult request" variant="secondary" />
                      )) ||
                      (!hasNewResult && hasNewHistory && (
                        <LabelBadge className={classes.badgeSecondary} label="Chart update" variant="secondary" />
                      ))}
                  </>
                )) ||
                (screeningPreConsult && <LabelBadge className={classes.badge} label="Consult request" />)}
            </div>
          ))}
        <div className={classes.actions}>
          {recommended.isTestScheduled() && typeOptions.length > 1 && (
            <>
              <Button
                className={clsx(classes.button, classes.buttonSpacing)}
                color="primary"
                disabled={!apiIsUserPhysician()}
                onClick={this.handleSetTestTypeClicked}
                variant="outlined"
              >
                Set test type
              </Button>
              <SetTestType
                anchorEl={this.anchorRef.current}
                handleClose={this.handleClose}
                handleSetTestTypeChanged={this.handleSetTestTypeChanged}
                open={openType}
                type={recommended.nextTestType()}
                typeOptions={typeOptions}
              />
            </>
          )}
          {recommended.nextTestAgingOut() && dueOptions.filter((option) => !option.agingOut).length !== 0 && (
            <Button
              className={clsx(classes.button, classes.buttonSpacing)}
              color="primary"
              disabled={!apiIsUserPhysician()}
              onClick={this.handleSetTestDateClicked}
              variant="outlined"
            >
              Set test date
            </Button>
          )}
          <div ref={this.anchorRef}>
            <Button
              className={classes.button}
              color="primary"
              disabled={!apiIsUserPhysician()}
              onClick={
                isScreeningConsultOngoing(screening) && (recommended.referPatient() || recommended.callPatient())
                  ? () => this.handleReferralCompleteClicked()
                  : () => handleAcceptClick()
              }
              variant="contained"
            >
              {this.getButtonLabel()}
            </Button>
          </div>
        </div>
        <ReferralDialog
          handleClose={this.handleClose}
          handleOkClick={this.handleAcceptClick}
          open={openReferral}
          recommended={recommended}
        />
      </>
    );
  }
}

NextTestDue.propTypes = {
  classes: PropTypes.object.isRequired,
  editingTestSchedule: PropTypes.bool,
  handleAcceptClick: PropTypes.func.isRequired,
  handleRecommendedChanged: PropTypes.func.isRequired,
  patient: PropTypes.object.isRequired,
  recommended: PropTypes.object.isRequired,
  screening: PropTypes.object.isRequired,
};

NextTestDue.defaultProps = {
  editingTestSchedule: false,
};

export default withStyles(styles)(NextTestDue);
