import MuiAppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBackOutlined';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { primary } from '../constants/colors';
import Logo from './Logo';

const styles = (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  navButton: {
    color: theme.palette.text.secondary,
  },
  title: {
    width: 1044,
  },
  titleText: {
    color: primary,
    marginLeft: theme.spacing(3.5),
  },
});

function AppBar(props) {
  const { children, classes, handleBackClick, title } = props;

  return (
    <MuiAppBar elevation={0} position="relative">
      <Toolbar className={classes.toolbar} disableGutters>
        <div className={clsx(classes.container, classes.title)}>
          {handleBackClick && (
            <IconButton className={classes.navButton} onClick={handleBackClick}>
              <ArrowBackIcon />
            </IconButton>
          )}
          {!handleBackClick && <Logo variant="appbar" />}
          {title && (
            <Typography className={classes.titleText} variant="h6">
              {title}
            </Typography>
          )}
        </div>
        <div className={classes.container}>{children}</div>
      </Toolbar>
    </MuiAppBar>
  );
}

AppBar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  handleBackClick: PropTypes.func,
  title: PropTypes.node,
};

AppBar.defaultProps = {
  children: undefined,
  handleBackClick: undefined,
  title: undefined,
};

export default withStyles(styles)(AppBar);
