import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { startOfDay } from 'date-fns';
import PropTypes from 'prop-types';
import { Component } from 'react';
import Button from '../components/Button';
import Checkbox from '../components/Checkbox';
import Collapse from '../components/Collapse';
import Dialog from '../components/Dialog';
import DialogPaper from '../components/DialogPaper';
import DialogTitle from '../components/DialogTitle';
import KeyboardDatePicker from '../components/KeyboardDatePicker';
import TextField from '../components/TextField';
import { dialogActions } from '../components/styles';
import { error20, error95, primary60, secondary20, secondary95, tertiary20, tertiary95 } from '../constants/colors';
import { fontFamily1 } from '../constants/typography';
import InfoIcon from '../icons/InfoOutlined';
import { isValidKeyboardDate } from '../utils/date';
import Recommended from '../utils/recommended';
import {
  chartPersonalHistoryValue,
  flattenMenuOptions,
  handleTestEditChange,
  handleTestEditSaveClick,
  isLatestTestIndex,
  isTestExternalMrp,
  menuOptionsSpecialistRecommendation,
  menuOptionsTestResult,
  menuOptionsTestResult2,
  menuOptionsTestType,
} from '../utils/screenings';
import SearchFamilyDoctor from './SearchFamilyDoctor';

const styles = (theme) => ({
  banner: {
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    fontFamily: fontFamily1,
    justifyContent: 'center',
    margin: theme.spacing(0.5, 2, 1),
    padding: theme.spacing(2.25, 3),
  },
  bannerChekHealth: {
    backgroundColor: secondary95,
    color: secondary20,
    height: 48,
    padding: theme.spacing(1, 3),
  },
  bannerExternal: {
    backgroundColor: tertiary95,
    color: tertiary20,
  },
  bannerOverridden: {
    backgroundColor: error95,
    color: error20,
  },
  bannerTitle: {
    letterSpacing: `${theme.typography.round(0.2 / 14)}em`,
    lineHeight: theme.typography.pxToRem(24),
  },
  bannerTitleContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  bannerText: {
    lineHeight: theme.typography.pxToRem(16),
    marginTop: theme.spacing(1),
  },
  checkbox: {
    marginRight: theme.spacing(1),
  },
  dialogActions: {
    ...dialogActions(theme),
    marginTop: theme.spacing(1.5),
  },
  dialogContent: {
    padding: theme.spacing(0, 1),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    padding: theme.spacing(3, 0, 0.5),
  },
  formGroup: {
    margin: theme.spacing(1.5, 0, 0.25),
  },
  infoIcon: {
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: 10,
  },
  underline: {
    '&:after': {
      borderBottomColor: primary60,
    },
    '&&:hover::before': {
      borderBottomColor: primary60,
    },
  },
  unit: {
    fontSize: theme.typography.pxToRem(11),
  },
  value2Checkbox: {
    marginTop: theme.spacing(0.25),
  },
});

class TestDialog extends Component {
  static valid = (test) =>
    (test.type &&
      test.orderingPhysician &&
      isValidKeyboardDate(test.date) &&
      (test.value === undefined || test.value !== null)) ||
    false;

  static isTestExternalMrp(test, screening, patient) {
    if (TestDialog.valid(test)) {
      const recommended = new Recommended(screening, patient, test);
      return isTestExternalMrp(test, recommended, screening);
    }
    return false;
  }

  constructor(props) {
    super(props);

    const { test, screening, patient } = props;

    const overriddenMrp = test.external === false && TestDialog.isTestExternalMrp(test, screening, patient);

    this.state = {
      dialogOkClick: null,
      dialogOkLabel: null,
      dialogTitle: null,
      dialogText: null,
      overriddenMrp,
      showingBanner: null,
      showMore: overriddenMrp,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOkClick = this.handleOkClick.bind(this);
    this.handleMoreOptionsClick = this.handleMoreOptionsClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { patient, screening, test, testIndex } = props;
    const { overriddenMrp: overriddenMrpState, showingBanner: showingBannerState, showMore } = state;

    const newValid = TestDialog.valid(test);

    if (newValid) {
      let showingBanner = null;
      const externalMrp = TestDialog.isTestExternalMrp(test, screening, patient);
      const overriddenMrp = externalMrp && overriddenMrpState;
      if (isLatestTestIndex(testIndex, test, screening)) {
        if (overriddenMrp) {
          showingBanner = 'overridden';
        } else if (externalMrp) {
          showingBanner = 'external';
        } else {
          showingBanner = 'chek health';
        }
      }
      if (showingBanner !== showingBannerState) {
        return showMore
          ? { overriddenMrp, showingBanner, showMore: externalMrp || overriddenMrp }
          : { overriddenMrp, showingBanner };
      }

      return null;
    }

    return overriddenMrpState ? { overriddenMrp: false } : null;
  }

  handleChange(key, newValue, event) {
    const { handleTestChange: handleTestChangeProp, patient, screening, test } = this.props;

    const newTest = handleTestEditChange(key, newValue, test, screening, patient);
    if (newTest == null) {
      // Input rejected, effectively no change
      if (event) {
        event.stopPropagation();
      }
      return;
    }

    handleTestChangeProp(newTest);
  }

  handleCheckboxChange = (key, handler) => (event) => {
    const { newPatient, screening, patient } = this.props;

    // ** Special: PSA - warn if test result configuration may contradict
    // patient chart
    if (!newPatient) {
      if (['dutasteride', 'finasteride'].includes(key)) {
        if (event.target.checked) {
          if (chartPersonalHistoryValue(key, screening, patient) !== true) {
            this.setState({
              dialogText: [
                `Are you sure you want to check ${key} and indicate that it be applied to the test result?`,
                `The patient's chart does not include ${key} as current medication.`,
              ],
              dialogOkClick: () => handler(key, true),
              dialogOkLabel: `Yes, check ${key}`,
              dialogTitle: 'Check medication?',
            });
            return;
          }
        } else if (chartPersonalHistoryValue(key, screening, patient) === true) {
          this.setState({
            dialogText: [
              `Are you sure you want to uncheck ${key} and indicate that it not be applied to the test result?`,
              `The patient's chart indicates ${key} is current medication.`,
            ],
            dialogOkClick: () => handler(key, false),
            dialogOkLabel: `Yes, uncheck ${key}`,
            dialogTitle: 'Uncheck medication?',
          });
          return;
        }
      }
    }

    if (key === 'value2') {
      // Breast value2 of true becomes 'volparaD'
      handler(key, event.target.checked, event);
    }

    if (key === 'overriddenMrp') {
      this.setState({ overriddenMrp: event.target.checked });
    }
  };

  // Dialog close
  handleClose() {
    this.setState({ dialogOkClick: null, dialogOkLabel: null, dialogTitle: null, dialogText: null });
  }

  handleOkClick() {
    const { dialogOkClick } = this.state;

    if (dialogOkClick !== null) {
      dialogOkClick();
    }

    this.handleClose();
  }

  handleMoreOptionsClick() {
    this.setState({ showMore: true });
  }

  handleSaveClick() {
    const { handleSaveClick: handleSaveClickProp, patient, screening, test } = this.props;
    const { overriddenMrp } = this.state;

    // Set externalMrp onto test
    const newTest = {
      ...test,
      external: overriddenMrp ? false : TestDialog.isTestExternalMrp(test, screening, patient),
    };

    handleSaveClickProp(handleTestEditSaveClick(newTest, screening));
  }

  static handleDateChange = (handler) => (date) => {
    // Setting only the date so adjust to start of day
    if (!Number.isNaN(date.getTime())) {
      handler('date', startOfDay(date));
    }
  };

  static handleTestChange = (key, handler) => (event) => {
    handler(key, event.target.value, event);
  };

  render() {
    const {
      classes,
      handleClose: handleCloseProp,
      handleTestChange: handleTestChangeProp,
      newPatient,
      open,
      patient,
      screening,
      test,
      title,
    } = this.props;
    const { dialogOkClick, dialogOkLabel, dialogTitle, dialogText, overriddenMrp, showingBanner, showMore } =
      this.state;

    const optionsTestResult2 = menuOptionsTestResult2(test, screening);
    const externalMrp = TestDialog.isTestExternalMrp(test, screening, patient);

    return (
      <>
        <DialogPaper open={open} onClose={handleCloseProp} size="large">
          <DialogTitle handleClose={handleCloseProp} title={title} />
          <div className={classes.dialogContent}>
            <Collapse in={showingBanner === 'chek health'}>
              <div className={clsx(classes.banner, classes.bannerChekHealth)}>
                <div className={classes.bannerTitleContainer}>
                  <InfoIcon className={classes.infoIcon} fontSize="small" />
                  <Typography className={classes.bannerTitle} component="span" variant="subtitle2">
                    Most responsible physician Chek Health
                  </Typography>
                </div>
              </div>
            </Collapse>
            <Collapse in={showingBanner === 'external'}>
              <div className={clsx(classes.banner, classes.bannerExternal)}>
                <div className={classes.bannerTitleContainer}>
                  <InfoIcon className={classes.infoIcon} fontSize="small" />
                  <Typography className={classes.bannerTitle} component="span" variant="subtitle2">
                    External most responsible physician
                  </Typography>
                </div>
                <Typography className={classes.bannerText} component="div" variant="caption">
                  {/* eslint-disable-next-line max-len */}
                  {`The ordering physician is external and responsbile for the ongoing ${screening.type} cancer screening care and management at this time.`}
                </Typography>
                <Typography className={classes.bannerText} component="div" variant="caption">
                  This test result will not be forwarded to the Chek Health physician.
                </Typography>
                <Typography className={classes.bannerText} component="div" variant="caption">
                  General guidance regarding their cancer screening care will be automatically provided to the patient
                  through the Chek mobile app.
                </Typography>
              </div>
            </Collapse>
            <Collapse in={showingBanner === 'overridden'}>
              <div className={clsx(classes.banner, classes.bannerOverridden)}>
                <div className={classes.bannerTitleContainer}>
                  <InfoIcon className={classes.infoIcon} fontSize="small" />
                  <Typography className={classes.bannerTitle} component="span" variant="subtitle2">
                    Chek Health to become MRP
                  </Typography>
                </div>
                <Typography className={classes.bannerText} component="div" variant="caption">
                  {/* eslint-disable-next-line max-len */}
                  The ordering physician is <u>external</u> and typically responsbile for the ongoing care and
                  follow-up.
                </Typography>
                <Typography className={classes.bannerText} component="div" variant="caption">
                  {/* eslint-disable-next-line max-len */}
                  {`By assigning Chek Health as the most responsible physician, we now take over responsibility for the patient's ${screening.type} cancer
                      screening care and follow-up on any currently outstanding screening tasks.`}
                </Typography>
                <Typography className={classes.bannerText} component="div" variant="caption">
                  This test result will be forwarded to the Chek Health physician for review.
                </Typography>
              </div>
            </Collapse>
            <List>
              <ListItem>
                <TextField
                  autoFocus
                  fullWidth
                  InputProps={{ classes: { underline: classes.underline } }}
                  label="Test type"
                  onChange={TestDialog.handleTestChange('type', this.handleChange)}
                  select
                  value={test.type || ''}
                >
                  {flattenMenuOptions(menuOptionsTestType(screening)).map((option, index) => {
                    if (option === null) {
                      return <Divider className={classes.divider} key={`type-${index.toString()}`} variant="middle" />;
                    }
                    return (
                      <MenuItem key={`type-${index.toString()}`} value={option.value}>
                        {option.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </ListItem>
              <ListItem>
                <SearchFamilyDoctor
                  familyDoctor={test.orderingPhysician}
                  handleFamilyDoctorChange={(familyDoctor) =>
                    handleTestChangeProp({
                      ...test,
                      orderingPhysician: familyDoctor && {
                        name: familyDoctor.name,
                        chekHealth: familyDoctor.chekHealth || false,
                      },
                    })
                  }
                />
              </ListItem>
              <Collapse in={showMore && TestDialog.valid(test)}>
                <ListItem>
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        checked={overriddenMrp || !externalMrp}
                        color="primary"
                        onChange={this.handleCheckboxChange('overriddenMrp', this.handleChange)}
                      />
                    }
                    label="Most responsible physician Chek Health"
                  />
                </ListItem>
              </Collapse>
              {
                // ** Special: PSA (numeric entry test result)
                // Future: Make screening generalized
              }
              {test.extras !== undefined && (
                <Collapse in={test.extras.dutasteride !== undefined || test.extras.finasteride !== undefined}>
                  <ListItem dense>
                    <FormControl className={classes.formControl} component="fieldset">
                      <FormLabel>Patient medication at time of test</FormLabel>
                      <FormGroup className={classes.formGroup}>
                        {test.extras.finasteride !== undefined && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={test.extras.finasteride}
                                color="primary"
                                onChange={this.handleCheckboxChange('finasteride', this.handleChange)}
                              />
                            }
                            label="Finasteride (Proscar)"
                          />
                        )}
                        {test.extras.dutasteride !== undefined && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={test.extras.dutasteride}
                                color="primary"
                                onChange={this.handleCheckboxChange('dutasteride', this.handleChange)}
                              />
                            }
                            label="Dutasteride (Avodart)"
                          />
                        )}
                      </FormGroup>
                      {!newPatient &&
                        ((test.extras.finasteride !== undefined &&
                          chartPersonalHistoryValue('finasteride', screening, patient) &&
                          test.extras.dutasteride !== undefined &&
                          chartPersonalHistoryValue('dutasteride', screening, patient) && (
                            <FormHelperText>
                              <sup>*</sup>Current medication finasteride and dutasteride indicated in patient chart
                            </FormHelperText>
                          )) ||
                          (test.extras.finasteride !== undefined &&
                            chartPersonalHistoryValue('finasteride', screening, patient) && (
                              <FormHelperText>
                                <sup>*</sup>Current medication finasteride indicated in patient chart
                              </FormHelperText>
                            )) ||
                          (test.extras.dutasteride !== undefined &&
                            chartPersonalHistoryValue('dutasteride', screening, patient) && (
                              <FormHelperText>
                                <sup>*</sup>Current medication dutasteride indicated in patient chart
                              </FormHelperText>
                            )))}
                    </FormControl>
                  </ListItem>
                </Collapse>
              )}
              <Collapse in={test.value !== undefined}>
                <ListItem dense={test.extras !== undefined}>
                  {
                    // ** Special: PSA (numeric entry test result)
                  }
                  {test.type === 'psa' ? (
                    <TextField
                      fullWidth
                      helperText="Unadjusted PSA level"
                      InputProps={{
                        classes: { underline: classes.underline },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="button">ng/mL</Typography>
                          </InputAdornment>
                        ),
                      }}
                      label="Result"
                      onChange={TestDialog.handleTestChange('value', this.handleChange)}
                      placeholder="0-9999.9"
                      value={test.extras.unadjusted || ''}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{ classes: { underline: classes.underline } }}
                      label="Result"
                      onChange={TestDialog.handleTestChange('value', this.handleChange)}
                      select
                      value={test.value || ''}
                    >
                      {flattenMenuOptions(menuOptionsTestResult(test, screening)).map((option, index) => {
                        if (option === null) {
                          return (
                            <Divider className={classes.divider} key={`value-${index.toString()}`} variant="middle" />
                          );
                        }
                        return (
                          <MenuItem key={`value-${index.toString()}`} value={option.value}>
                            {option.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  )}
                </ListItem>
              </Collapse>
              <Collapse in={test.value2 !== undefined}>
                <ListItem dense={['mammogram', 'mammogramAwbu'].includes(test.type)}>
                  {
                    // ** Special: Mammogram value2 as checkbox (Volpara D)
                  }
                  {['mammogram', 'mammogramAwbu'].includes(test.type) ? (
                    <FormControlLabel
                      className={classes.value2Checkbox}
                      control={
                        <Checkbox
                          checked={test.value2 === 'volparaD'}
                          color="primary"
                          onChange={this.handleCheckboxChange('value2', this.handleChange)}
                        />
                      }
                      label="Volpara D (optional result)"
                    />
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{ classes: { underline: classes.underline } }}
                      label={optionsTestResult2.label}
                      onChange={TestDialog.handleTestChange('value2', this.handleChange)}
                      select
                      value={test.value2 || ''}
                    >
                      <MenuItem value={null}>
                        <em>None</em>
                      </MenuItem>
                      <Divider className={classes.divider} variant="middle" />
                      {optionsTestResult2.items.map((option, index) => {
                        if (option === null) {
                          return (
                            <Divider className={classes.divider} key={`value2-${index.toString()}`} variant="middle" />
                          );
                        }
                        return (
                          <MenuItem key={`value2-${index.toString()}`} value={option.value}>
                            {option.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  )}
                </ListItem>
              </Collapse>
              <Collapse in={test.recommendation !== undefined}>
                <ListItem>
                  <TextField
                    fullWidth
                    InputProps={{ classes: { underline: classes.underline } }}
                    label="Specialist recommends (optional)"
                    onChange={TestDialog.handleTestChange('recommendation', this.handleChange)}
                    select
                    value={test.recommendation !== undefined && test.recommendation !== null ? test.recommendation : ''}
                  >
                    <MenuItem value={null}>
                      <em>None</em>
                    </MenuItem>
                    <Divider className={classes.divider} variant="middle" />
                    {menuOptionsSpecialistRecommendation(test, screening).map((option, index) => {
                      if (option === null) {
                        return (
                          <Divider
                            className={classes.divider}
                            key={`recommendation-${index.toString()}`}
                            variant="middle"
                          />
                        );
                      }
                      return (
                        <MenuItem key={`recommendation-${index.toString()}`} value={option.value}>
                          {option.name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </ListItem>
              </Collapse>
              <ListItem>
                <FormControl fullWidth>
                  <KeyboardDatePicker
                    autoOk
                    format="yyyy-MM-dd"
                    helperText="yyyy-mm-dd"
                    InputProps={{ classes: { underline: classes.underline } }}
                    inputVariant="standard"
                    label="Date"
                    onChange={TestDialog.handleDateChange(this.handleChange)}
                    value={test.date}
                    variant="inline"
                  />
                </FormControl>
              </ListItem>
            </List>
          </div>
          <DialogActions className={classes.dialogActions}>
            <Button
              color="primary"
              disabled={!TestDialog.valid(test) || !externalMrp || showMore}
              onClick={this.handleMoreOptionsClick}
            >
              More options
            </Button>
            <Button
              color="primary"
              disabled={!TestDialog.valid(test)}
              onClick={this.handleSaveClick}
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </DialogPaper>
        <Dialog
          contentText={dialogText}
          handleCancelClick={this.handleClose}
          handleOkClick={this.handleOkClick}
          okLabel={dialogOkLabel}
          open={dialogOkClick !== null}
          onClose={this.handleClose}
          size="large"
          title={dialogTitle}
        />
      </>
    );
  }
}

TestDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  handleTestChange: PropTypes.func.isRequired,
  newPatient: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  patient: PropTypes.object.isRequired,
  screening: PropTypes.object.isRequired,
  test: PropTypes.object.isRequired,
  testIndex: PropTypes.number,
  title: PropTypes.string.isRequired,
};

TestDialog.defaultProps = {
  testIndex: null,
};

export default withStyles(styles)(TestDialog);
