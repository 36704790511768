import add from 'date-fns/add';
import addMinutes from 'date-fns/addMinutes';
import { newActivity } from './billing';
import {
  addRequistionReleasedSubnote,
  chartNoteAcceptTest,
  chartNoteExternalMrp,
  chartNoteFastForwardScreeningReferral,
  chartNoteFastForwardScreeningReleaseRequisition,
  chartNoteReferralSent,
  chartNoteReferralTest,
  chartNoteScreeningRequistionReleased,
  chartNoteScreeningSetNextTestDue,
  chartNoteScreeningSetNoNextTest,
  onNewChartNote,
} from './chartNotes';
import { now } from './date';
import {
  dbAccountExists,
  dbAddChartNote,
  dbDeleteChartNote,
  dbFamilyDoctorSearch,
  dbGetBillings,
  dbGetSessionUser,
  dbGetUser,
  dbIsUserPhysician,
  dbOnBillingsChanged,
  dbOnChartUpdatesChanged,
  dbOnChekNewResultsChanged,
  dbOnPatientChanged,
  dbOnPhysicianNewResultsChanged,
  dbOnUserChanged,
  dbPatientSearch,
  dbSetBillingEntry,
  dbSignIn,
  dbSignInVerificationCode,
  dbSignInVerifyPhoneNumber,
  dbSignOut,
  dbUpateUserFullName,
  dbUpdateBillingEntry,
  dbUpdateChartNote,
  dbUpdateIdentity,
  dbUpdateScreening,
  dbUpdateUserPassword,
  dbUserMultiFactorEnrolledFactors,
} from './db';
import { isObjectEmpty, removeProperties } from './object';
import { getNextNotify, notifyPatientOfMissingResult } from './patient';
import { getCancerScreening } from './patientUtils';
import Recommended from './recommended';
import { hasReferralBeenRequested } from './referral';
import {
  autoReleaseRequisition,
  autoReleaseRequisitionExternalMrpSuggestion,
  chartHistoryItem,
  firestoreTransformToScreeningNextTest,
  firestoreTransformToScreeningTests,
  fromChartHistoryToScreeningQuestionnaire,
  fromFamilyHistoryToCancerFamilyHistory,
  fromPersonalHistoryToCancerPersonalHistory,
  fromSocialHistoryToCancerSocialHistory,
  hasChartHistoryNotReviewed,
  hasFamilyHistory,
  hasLatestTestNotReviewed,
  hasPersonalHistory,
  hasSocialHistory,
  inConsultWindow,
  isActive,
  isActiveOrBecomingActivePatient,
  isAutoConsultTest,
  isLatestTest,
  isLatestTestIndex,
  isPatientPreNewPatient,
  isPreNewOrNewPatient,
  isPreNewPatient,
  isReflexiveTest,
  isScreeningConsult,
  isScreeningExternalMrp,
  isScreeningPreConsult,
  referralReminderRepeatDuration,
  screeningNewNextTest,
  screeningRisks,
  screeningStatusProps,
  testTypeReferPatient,
} from './screenings';
import { showSnackbar } from './snackbar';
import { compareDateDesc } from './sort';
import { isTestNotReviewed } from './tests';

export const apiOnUserChanged = (handleUserChanged) => dbOnUserChanged(handleUserChanged);

export const apiIsUserPhysician = () => dbIsUserPhysician();

export const apiUpateUserFullName = (fullName) => dbUpateUserFullName(fullName);

export const apiUpdateUserPassword = (passwordNew, passwordCurrent) =>
  dbUpdateUserPassword(passwordNew, passwordCurrent);

export const apiUserMultiFactorEnrolledFactors = () => dbUserMultiFactorEnrolledFactors();

export const apiOnPatientChanged = (patientId, handlePatientChanged) =>
  dbOnPatientChanged(patientId, handlePatientChanged);

export const apiUpdateIdentity = (identity, identityCheck, patient) =>
  dbUpdateIdentity(identity, identityCheck, identity === 'rejected' ? addMinutes(now(), 60) : null, patient.id);

export const apiOnBillingsChanged = (patientId, handleBillingsChanged) =>
  dbOnBillingsChanged(patientId, handleBillingsChanged);

function apiSetBillingEntry(activity, patient) {
  return dbSetBillingEntry(activity, patient)
    .then(() => {
      // User snackbar showing billing code for active patient
      // or accepting last screening of new patient
      if (isActiveOrBecomingActivePatient(patient)) {
        showSnackbar({ activity: { ...activity } });
      }
    })
    .catch(() => {});
}

export const apiUpdateBillingEntry = (newCode, activityDate, billingId) =>
  dbUpdateBillingEntry(newCode, activityDate, billingId);

export const apiGetBillings = (interval) => dbGetBillings(interval);

function apiUpdateCancerScreening(
  screening,
  cancerScreening,
  patient,
  newNote = null,
  nextNotify = patient.nextNotify,
) {
  const newCancerScreening = {
    ...cancerScreening,
    nextTest: {
      ...screening.nextTest,
    },
    tests: [...screening.tests],
  };

  const newCancer = {
    ...patient.cancer,
    [screening.type]: newCancerScreening,
  };

  // So screening risks apply new incoming cancer screening
  const newPatient = {
    ...patient,
    cancer: newCancer,
  };

  // See: firestore transform comments in screenings

  const newScreening = {
    ...screening,
    ...screeningRisks(screening, newPatient),
    nextTest: {
      ...firestoreTransformToScreeningNextTest(screening, newPatient),
    },
    tests: [...firestoreTransformToScreeningTests(screening, newPatient)],
  };

  return dbUpdateScreening(
    newScreening,
    newCancer,
    newNote ? newPatient.chartNotes.concat([newNote]) : newPatient.chartNotes,
    nextNotify,
    newPatient.id,
  ).then(() => {
    if (newNote) {
      onNewChartNote(newPatient);
    }
  });
}

function apiUpdateScreening(screening, patient, newNote, nextNotify = patient.nextNotify) {
  const cancerScreening = getCancerScreening(screening, patient);
  return apiUpdateCancerScreening(screening, cancerScreening, patient, newNote, nextNotify);
}

export function apiScreeningRequistionReleasedComplete(screening, patient) {
  const note = chartNoteScreeningRequistionReleased(screening, patient, dbGetSessionUser());

  if (apiIsUserPhysician()) {
    apiSetBillingEntry(newActivity('requisition released', note), patient);
  }

  const newScreening = {
    ...screening,
    nextTest: { ...screening.nextTest, requisitionReleased: now() },
  };

  const cancerScreening = getCancerScreening(screening, patient);

  const newCancerScreening = { ...cancerScreening };

  // Referral when releasing requistion when colonoscopy or CT colonoscopy as
  // has come due
  if (testTypeReferPatient(newScreening.nextTest.type, newScreening)) {
    newCancerScreening.referral = {
      requested: newScreening.nextTest.requisitionReleased,
      sent: null,
      reminderDue: null,
    };
  } else {
    delete newCancerScreening.referral;
  }

  return apiUpdateCancerScreening(newScreening, newCancerScreening, patient, note, getNextNotify(screening, patient));
}

//
// Physician setting new test plan so accept outstanding
// screening history edits as part of the update.
//
function apiScreeningSetTestPlan(recommended, screening, patient, note) {
  // (1) Save effective patient history used by physician to set test plan
  // to cancer screening personalHistory, socialHistory & familyHistory

  // (2) Empty autoReviewed and notReviewed for
  // cancer screening personalHistory, socialHistory & familyHistory

  const personalHistory = hasPersonalHistory(screening, patient)
    ? {
        ...fromPersonalHistoryToCancerPersonalHistory(screening, patient),
        autoReviewed: {},
        notReviewed: {},
      }
    : null;

  const socialHistory = hasSocialHistory(screening, patient)
    ? {
        ...fromSocialHistoryToCancerSocialHistory(screening, patient),
        autoReviewed: {},
        notReviewed: {},
      }
    : null;

  const familyHistory = hasFamilyHistory(screening, patient)
    ? {
        ...fromFamilyHistoryToCancerFamilyHistory(screening, patient),
        autoReviewed: {},
        notReviewed: {},
      }
    : null;

  // (3) Copy questionnaire back to screening questionniare. This
  // picks up questionnaire values overridden thru clinic chart updates
  // and will present them to patient on next questionnaire.

  const newScreening = {
    ...screening,
    questionnaire: { ...screening.questionnaire, ...fromChartHistoryToScreeningQuestionnaire(screening, patient) },
    viewed: {
      ...screening.viewed,
      message: null,
    },
  };

  const cancerScreening = getCancerScreening(screening, patient);

  const newCancerScreening = [
    { field: 'personalHistory', item: personalHistory },
    { field: 'socialHistory', item: socialHistory },
    { field: 'familyHistory', item: familyHistory },
  ]
    .filter(({ item }) => !isObjectEmpty(item))
    .reduce(
      (accumulator, { field, item }) =>
        item !== null
          ? {
              ...accumulator,
              [field]: item,
            }
          : accumulator,
      cancerScreening,
    );

  // Screening upgradeToScreening is a temporary field which is
  // removed at this point (not usual field present)
  delete newScreening.upgradeToScreening;

  // Cancer screening referral either adding or removing
  // (1) Referral for colonoscopy or CT colonoscopy
  // (2) Referral to specialist
  // Note: Check that requisition has been released when referring for
  // colonoscopy. When editing test type without active patient consult,
  // possible to recommend referral and requisition has not been released
  // as patient consult is required.

  if (recommended.referral()) {
    newCancerScreening.referral = {
      requested: newScreening.statusDate,
      sent: null,
      reminderDue: null,
    };
  } else if (recommended.referPatient() && newScreening.nextTest.requisitionReleased) {
    newCancerScreening.referral = {
      requested: newScreening.nextTest.requisitionReleased,
      sent: null,
      reminderDue: null,
    };
  } else {
    delete newCancerScreening.referral;
  }

  return apiUpdateCancerScreening(
    newScreening,
    newCancerScreening,
    patient,
    note,
    getNextNotify(newScreening, patient),
  );
}

export function apiAcceptTest(recommended, screening, patient) {
  const note = chartNoteAcceptTest(recommended, screening, patient, dbGetSessionUser());

  if (apiIsUserPhysician()) {
    apiSetBillingEntry(newActivity('reviewed results', note), patient);
  }

  const newScreening = {
    ...screening,
    ...screeningStatusProps('active', screening, patient),
    nextTest: {
      ...screeningNewNextTest(recommended),
    },
    tests: screening.tests.map((test, index) => (index === 0 ? { ...test, reviewed: true } : test)),
  };

  // Note: Using original screening object for this check
  if (autoReleaseRequisition(recommended, screening)) {
    newScreening.nextTest.requisitionReleased = now();
    addRequistionReleasedSubnote(newScreening, note);
  }

  return apiScreeningSetTestPlan(recommended, newScreening, patient, note);
}

export function apiReferralTest(recommended, screening, patient) {
  const note = chartNoteReferralTest(screening, patient, dbGetSessionUser());

  if (apiIsUserPhysician()) {
    apiSetBillingEntry(newActivity(`referral ${screening.type}`, note), patient);
  }

  const newScreening = {
    ...screening,
    ...screeningStatusProps('referral', screening, patient),
    nextTest: {
      ...screeningNewNextTest(recommended),
    },
    tests: screening.tests.map((test, index) => (index === 0 ? { ...test, reviewed: true } : test)),
  };

  return apiScreeningSetTestPlan(recommended, newScreening, patient, note);
}

//
// Setting no next test when medically not applicable, aging out, very high risk
// and referral (handled function above)
//
export function apiScreeningSetNoNextTest(recommended, screening, patient) {
  const note = chartNoteScreeningSetNoNextTest(recommended, screening, patient, dbGetSessionUser());

  if (apiIsUserPhysician() && isScreeningConsult(screening)) {
    apiSetBillingEntry(newActivity('test plan set', note), patient);
  }

  const newScreening = {
    ...screening,
    ...screeningStatusProps('active', screening, patient),
    nextTest: {
      ...screeningNewNextTest(recommended),
    },
    tests: screening.tests.map((test, index) => (index === 0 ? { ...test, reviewed: true } : test)),
  };

  return apiScreeningSetTestPlan(recommended, newScreening, patient, note);
}

export function apiScreeningSetNextTestDue(recommended, screening, patient) {
  const note = chartNoteScreeningSetNextTestDue(recommended, screening, patient, dbGetSessionUser());

  if (apiIsUserPhysician() && isScreeningConsult(screening)) {
    apiSetBillingEntry(newActivity('test plan set', note), patient);
  }

  const newScreening = {
    ...screening,
    ...screeningStatusProps('active', screening, patient),
    nextTest: {
      ...screening.nextTest,
      due: recommended.nextTestDue(),
      type: recommended.nextTestType(),
      optional: recommended.nextTestOptional(),
      agingOut: recommended.nextTestAgingOut(),
    },
  };

  // Note: Using original screening object for this check
  if (autoReleaseRequisition(recommended, screening)) {
    newScreening.nextTest.requisitionReleased = now();
    addRequistionReleasedSubnote(newScreening, note);
  }

  // Ends the consult if due date is no longer in the consult window.
  // Uncommon and may occur when physician directly edits the next
  // test due date into the future during an active consult or post
  // requisition release.
  if (!inConsultWindow(recommended.nextTestType(), recommended.nextTestDue(), newScreening)) {
    newScreening.nextTest.consultRequested = null;
    newScreening.nextTest.chartUpdated = null;
    newScreening.nextTest.requisitionReleased = null;
    newScreening.nextTest.requisitionDownloaded = null;
  }

  return apiScreeningSetTestPlan(recommended, newScreening, patient, note);
}

// Add and update screening tests

const setTestsReviewed = (reviewed, tests, screening) => ({
  ...screening,
  tests: tests.sort(compareDateDesc).map((test, index) => ({
    ...test,
    reviewed: reviewed || index !== 0,
  })),
});

// Add test into screening and return new screening object
const addTest = (reviewed, newTest, screening) =>
  setTestsReviewed(reviewed, screening.tests.concat([newTest]), screening);

const addReviewedTest = (newTest, screening) => addTest(true, newTest, screening);
const addNotReviewedTest = (newTest, screening) => addTest(false, newTest, screening);

// Updates screening tests with edited test return new screening object
function addEditedTest(newTest, testIndex, screening) {
  const newTests = screening.tests.map((test, index) => (index === testIndex ? newTest : test));
  return setTestsReviewed(
    // Determine if all tests reviewed
    newTests.reduce((accumulator, test) => !accumulator || !test.reviewed, true),
    newTests,
    screening,
  );
}

//
// ** External MRP
//
// Update patient with medical suggestion:
//  - new test added or after chek staff chart update complete
//  - automatically apply default next test recommendation
//  - not physician reviewed
//  - update patient with a medical suggestion (not physician signed)
//
// Releases requisition if ongoing testing (e.g. breast reflexive imaging/biopsy,
// repeat colonoscopy (e.g. FIT positive, reflexive), repeat PSA).
//
function apiScreeningExternalMrpSuggestion(recommended, screening, patient, note) {
  const newScreening = {
    ...screening,
    ...screeningStatusProps(recommended.referral() ? 'referral' : 'active', screening, patient),
    nextTest: {
      ...screeningNewNextTest(recommended),
    },
  };

  if (autoReleaseRequisitionExternalMrpSuggestion(recommended, screening)) {
    newScreening.nextTest.requisitionReleased = now();
  }

  // Remove outstanding referral if present
  const cancerScreening = getCancerScreening(screening, patient);

  const newCancerScreening = { ...cancerScreening };
  delete newCancerScreening.referral;

  return apiUpdateCancerScreening(
    newScreening,
    newCancerScreening,
    patient,
    note,
    getNextNotify(newScreening, patient),
  );
}

//
// ** Chek MRP
//
// Update patient with medical notice (not physician signed) which is
// provided without physician review and non-billable.
//
// Update on reflexive test progress (i.e. breast imaging/biopsy)
//
// Note: Chart note with reflexive test information resolved when
// test is complete.
//
function apiScreeningReleaseNotice(recommended, screening, patient) {
  const newScreening = {
    ...screening,
    ...screeningStatusProps('active', screening, patient),
    nextTest: {
      ...screening.nextTest,
      due: recommended.nextTestDue(),
      type: recommended.nextTestType(),
      chartUpdated: now(),
      requisitionReleased: now(),
      optional: recommended.nextTestOptional(),
      agingOut: recommended.nextTestAgingOut(),
    },
    viewed: {
      ...screening.viewed,
      message: null,
    },
  };

  // Remove outstanding referral if present
  const cancerScreening = getCancerScreening(screening, patient);

  const newCancerScreening = { ...cancerScreening };
  delete newCancerScreening.referral;

  return apiUpdateCancerScreening(
    newScreening,
    newCancerScreening,
    patient,
    null,
    getNextNotify(newScreening, patient),
  );
}

//
// ** Chek MRP
//
// Send to physician for review
//
function apiSendToPhysican(screening, patient) {
  const newScreening = {
    ...screening,
    nextTest: {
      ...screening.nextTest,
      chartUpdated: now(),
    },
  };

  // Chek staff has completed chart update of new patient. All tests are
  // marked as reviewed. Switch status to newPatient to mark for physician
  // to set test plan.
  if (isPreNewPatient(screening)) {
    return apiUpdateScreening({ ...newScreening, ...screeningStatusProps('newPatient', screening, patient) }, patient);
  }

  // At this point, latest test will not have been reviewed and automatically
  // received by physician once saved to Firestore.

  // It is possible for Chek staff to update the chart due to a referral reminder
  // and add the latest test result without first clicking on 'record as done'.
  // The 'record as done' will no longer be available to clear the reminder.

  // Set next referral reminder for 1 month
  // Note: Reminder does not apply to lung
  const cancerScreening = getCancerScreening(screening, patient);
  if (Object.hasOwn(cancerScreening, 'referral') && cancerScreening.referral.reminderDue) {
    const newCancerScreening = {
      ...cancerScreening,
      referral: {
        ...cancerScreening.referral,
        reminderDue: referralReminderRepeatDuration(screening) ? add(now(), { months: 1 }) : null,
      },
    };

    return apiUpdateCancerScreening(newScreening, newCancerScreening, patient);
  }

  return apiUpdateScreening(newScreening, patient);
}

//
// Called when adding new test or after chek staff chart update complete.
//
// Forward screening to physician review or, in some circumstances, issue an
// update to patient without the need of physician review.
//
//
// Exceptions when do not send to physician:
// (1) Send patient medical suggestion when external MRP
// (2) Latest test is reflexive test in progress (e.g. breast imaging/biopsy)
// (3) Auto consult test (i.e. pap test) releases requisition without
//     physician review
//
// Note: When newTest is null, the latest test is taken from the screening.
// This occurs when called at the end of chek staff chart update complete.
//
function apiScreeningForReview(screening, patient, newTest = null) {
  // New test will be null when called by chart update complete but
  // represents a new test
  const recommended = new Recommended(screening, patient, newTest);

  // (1) Most responsible physician is external so can only provide patient
  // with medical suggestion. Default recommendation used and does not
  // require physician review.

  if (isScreeningExternalMrp(screening, newTest)) {
    // External MRP is based on adding a new test directly or as part of a
    // new patient consult and chart update complete. When editing an existing
    // test, ensure reviewed set.
    const newScreening = newTest
      ? addReviewedTest(newTest, screening)
      : setTestsReviewed(true, screening.tests, screening);
    const note = chartNoteExternalMrp(recommended, newScreening, patient, dbGetSessionUser());
    return apiScreeningExternalMrpSuggestion(recommended, newScreening, patient, note);
  }

  // (2) Reflexive testing in progress (breast imaging/biopsy/colonoscopy specialist
  // recommendation <= 1y). Provide patient with an update as being directed
  // externally. Does not require physician review.

  if (isReflexiveTest(recommended.nextTestType(), screening, newTest)) {
    // When editing an existing test, ensure reviewed set
    const newScreening = newTest
      ? addReviewedTest(newTest, screening)
      : setTestsReviewed(true, screening.tests, screening);
    return apiScreeningReleaseNotice(recommended, newScreening, patient);
  }

  // (3) Auto consult test provide medical opinion automatically without physician
  // review if there is no other screening updates needing review.
  //
  // In rare case of adding most recent test and during auto consult,
  // forward to physician which will release requisition (i.e. test instructions).

  if (
    isAutoConsultTest(screening) &&
    isScreeningPreConsult(screening) &&
    !newTest &&
    !hasLatestTestNotReviewed(screening) &&
    !hasChartHistoryNotReviewed(screening, patient) &&
    !screening.notApplicable
  ) {
    return apiScreeningRequistionReleasedComplete(screening, patient);
  }

  if (!isScreeningPreConsult(screening) && !hasLatestTestNotReviewed(screening) && !newTest) {
    // Editing recent test history (e.g. change test ordering). Check if
    // recommended test plan change due to the edit and send to physician
    // for review.
    if (!recommended.isEqualToScreening(screening)) {
      // Sends to physician
      const newScreening = setTestsReviewed(false, screening.tests, screening);
      return apiSendToPhysican(newScreening, patient);
    }
  }

  // Submit to physician for review (e.g. newly added test)
  // Note: When editing an existing test, ensure reviewed use
  const newScreening = newTest ? addNotReviewedTest(newTest, screening, patient) : screening;
  return apiSendToPhysican(newScreening, patient);
}

//
// Called after chek staff chart update complete for new patients.
//
// Onboarding new patient with Chek MRP and ongoing screening tasks
// which may already be complete (i.e. referral,  colonoscopy booked)
// and next screening result not yet available, need to be able to
// fast forward the patient to next step without presenting the
// physician with a referral task.
//
function apiFastForwardScreening(option, screening, patient) {
  if (option === 'fast forward referral') {
    const newScreening = {
      ...screening,
      ...screeningStatusProps('referral', screening, patient),
      nextTest: {
        due: null,
        type: null,
        consultRequested: null,
        chartUpdated: now(),
        requisitionReleased: null,
        requisitionDownloaded: null,
      },
    };

    const note = chartNoteFastForwardScreeningReferral(screening, patient, dbGetSessionUser());

    return apiUpdateScreening(newScreening, patient, note);
  }

  if (option === 'fast forward screening') {
    const recommended = new Recommended(screening, patient);

    const newScreening = {
      ...screening,
      ...screeningStatusProps('active', screening, patient),
      nextTest: {
        ...screening.nextTest,
        due: recommended.nextTestDue(),
        type: recommended.nextTestType(),
        chartUpdated: now(),
        requisitionReleased: now(),
        optional: recommended.nextTestOptional(),
        agingOut: recommended.nextTestAgingOut(),
      },
    };

    const note = chartNoteFastForwardScreeningReleaseRequisition(recommended, screening, patient, dbGetSessionUser());

    return apiUpdateScreening(newScreening, patient, note);
  }

  throw new Error('Invalid usage.');
}

// Chek staff complete update operation on patient screening chart
export function apiScreeningChartUpdateComplete(screening, patient, option = null) {
  if (option === 'missing result') {
    const newScreening = {
      ...screening,
      nextTest: { ...screening.nextTest, chartUpdated: now() },
    };

    return apiUpdateScreening(
      newScreening,
      patient,
      null,
      notifyPatientOfMissingResult(newScreening.nextTest.type)
        ? getNextNotify(newScreening, patient)
        : patient.nextNotify,
    );
  }

  if (['fast forward screening', 'fast forward referral'].includes(option)) {
    return apiFastForwardScreening(option, screening, patient);
  }

  if (option === 'referral request') {
    if (hasReferralBeenRequested(screening, patient)) {
      const cancerScreening = getCancerScreening(screening, patient);

      // Chek staff sent referral to specialist or referral for colonoscopy
      // or CT colonoscopy as requested by physician. Set inital referral
      // reminder for 1 month.

      const newCancerScreening = {
        ...cancerScreening,
        referral: {
          ...cancerScreening.referral,
          sent: now(),
          reminderDue: add(now(), { months: 1 }),
        },
      };

      const note = chartNoteReferralSent(screening, dbGetSessionUser());

      return apiUpdateCancerScreening(screening, newCancerScreening, patient, note);
    }
  }

  if (option === 'referral reminder') {
    const cancerScreening = getCancerScreening(screening, patient);

    if (Object.hasOwn(cancerScreening, 'referral') && cancerScreening.referral.reminderDue) {
      // Set next referral reminder for 1 year
      // Note: 1 year reminder does not apply to lung
      const duration = referralReminderRepeatDuration(screening);

      const newCancerScreening = {
        ...cancerScreening,
        referral: {
          ...cancerScreening.referral,
          reminderDue: duration ? add(now(), duration) : null,
        },
      };

      return apiUpdateCancerScreening(screening, newCancerScreening, patient);
    }
  }

  return apiScreeningForReview(screening, patient);
}

//
// Forwarding test on for review or add directly to
// screening tests as reviewed.
//
export function apiAddTest(newTest, screening, patient) {
  // Add test screening tests directly without physician review when adding
  // adding test will not directly result in update to patient test plan

  // (1) New patient
  if (isPreNewOrNewPatient(screening)) {
    const newScreening = addReviewedTest(newTest, screening);
    return apiUpdateScreening(newScreening, patient);
  }

  // (2) Adding past test
  if (!isLatestTest(newTest, screening)) {
    const newScreening = addReviewedTest(newTest, screening);
    return apiUpdateScreening(newScreening, patient);
  }

  return apiScreeningForReview(screening, patient, newTest);
}

export function apiUpdateTest(test, testIndex, screening, patient) {
  // Updating most recent test (e.g. new result) and test date is pushed into
  // the past such that the test is no longer the most recent nd screening
  // nextTest due is set, update without physician review (like adding past test).
  if (!test.reviewed && screening.nextTest.due) {
    if (!isLatestTestIndex(testIndex, test, screening)) {
      const newScreening = setTestsReviewed(
        true,
        screening.tests.map((item, index) => (index === testIndex ? test : item)),
        screening,
      );
      return apiUpdateScreening(newScreening, patient);
    }
  }

  const newScreening = addEditedTest(test, testIndex, screening);
  return apiScreeningForReview(newScreening, patient);
}

export function apiDeleteTest(testIndex, screening, patient) {
  const deletedTest = screening.tests.find((_, index) => index === testIndex);

  // Unexpected usage error
  if (deletedTest === undefined) {
    return Promise.resolve();
  }

  const newScreening = {
    ...screening,
    tests: screening.tests.filter((_, index) => index !== testIndex),
  };

  // Test not reviewed (e.g. new result) and screening nextTest due
  // is set, just throw test away
  if (isTestNotReviewed(deletedTest) && newScreening.nextTest.due) {
    return apiUpdateScreening(newScreening, patient);
  }

  return apiScreeningForReview(newScreening, patient);
}

export const apiAddChartNote = (note, patient) => dbAddChartNote(note, patient.id);
export const apiUpdateChartNote = (note, patient) => dbUpdateChartNote(note, patient.id);
export const apiDeleteChartNote = (noteId, patient) => dbDeleteChartNote(noteId, patient.id);

export function apiUpdateChartHistory(newItems, screening, patient) {
  if (newItems.length === 0) {
    return Promise.resolve();
  }

  if (newItems.filter((newItem) => newItem.value === undefined).length !== 0) {
    return Promise.resolve();
  }

  const item1 = newItems[0];
  const historyType = item1.type;

  // Check api sane use - only support all fields with equal
  // history type and questionnaire setting
  if (
    newItems.filter((newItem) => newItem.type !== historyType || newItem.questionnaire !== item1.questionnaire)
      .length !== 0
  ) {
    return Promise.resolve();
  }

  const values = newItems.reduce(
    (accumulator, newItem) => ({
      ...accumulator,
      [newItem.field]: newItem.value,
    }),
    {},
  );

  const fields = Object.keys(values);
  const cancerScreening = getCancerScreening(screening, patient);

  const { autoReviewed = {} } = cancerScreening[historyType];
  const { notReviewed = {} } = cancerScreening[historyType];

  // Depending on whether changing the history item changes the screening
  // recommendation determines how the history is stored and if physician
  // needs to review the chart. New patients have history items auto reviewed
  // and will be picked up by the initial consult. Auto review all changes
  // for referrals.

  if (isActive(screening)) {
    const recommended = new Recommended(screening, patient);

    // Inject getValue to return the new history to evaluate the screening
    // without committing
    for (let i = 0; i < newItems.length; i += 1) {
      const item = chartHistoryItem(newItems[i].field, screening);
      // Item stored in firestore
      if (item.questionnaire || item.firestore) {
        item.getValue = () => newItems[i].value;
      }
    }

    const newRecommended = new Recommended(screening, patient);

    // Revert test injection
    for (let i = 0; i < newItems.length; i += 1) {
      const item = chartHistoryItem(newItems[i].field, screening);
      // Item stored in firestore
      if (item.questionnaire || item.firestore) {
        delete item.getValue;
      }
    }

    // A new screening recommendation needs to be physician reviewed prior
    // to showing the changed history item or updated test plan to patient
    if (recommended.willNextTestChange(newRecommended)) {
      // ** Cancer screening - not reviewed
      return apiUpdateCancerScreening(
        screening.nextTest.chartUpdated === null && !isScreeningPreConsult(screening)
          ? { ...screening, nextTest: { ...screening.nextTest, chartUpdated: now() } }
          : screening,
        {
          ...cancerScreening,
          [historyType]: {
            ...cancerScreening[historyType],
            autoReviewed: {
              ...removeProperties(fields, autoReviewed),
            },
            notReviewed: { ...notReviewed, ...values },
          },
        },
        patient,
      );
    }
  }

  // Screening recommendation has not changed or new patient, so changed
  // medical history can be safely shown to patient and applied immediately

  if (item1.questionnaire) {
    // ** Screening questionnaire (auto reviewed)

    const questionnaire = { ...screening.questionnaire, ...values };

    return apiUpdateCancerScreening(
      { ...screening, questionnaire },
      {
        ...cancerScreening,
        [historyType]: {
          ...cancerScreening[historyType],
          autoReviewed: {
            ...removeProperties(fields, autoReviewed),
          },
          notReviewed: {
            ...removeProperties(fields, notReviewed),
          },
        },
      },
      patient,
    );
  }

  // ** Cancer screening - auto reviewed (clinic chart updates)

  return apiUpdateCancerScreening(
    screening,
    {
      ...cancerScreening,
      [historyType]: {
        ...cancerScreening[historyType],
        autoReviewed: { ...autoReviewed, ...values },
        notReviewed: {
          ...removeProperties(fields, notReviewed),
        },
      },
    },
    patient,
  );
}

export const apiOnChartUpdatesChanged = (handleChartUpdatesChanged) =>
  dbOnChartUpdatesChanged(handleChartUpdatesChanged);

export const apiOnChekNewResultsChanged = (handleChekNewResultsChanged) =>
  dbOnChekNewResultsChanged(handleChekNewResultsChanged);

export const apiOnPhysicianNewResultsChanged = (handlePhysicianNewResultsChanged) =>
  dbOnPhysicianNewResultsChanged(handlePhysicianNewResultsChanged);

export const apiPatientSearch = async (searchText, identityVerified) => {
  // Physician only see patient not in the original initial onboarding
  // state of preNewPatient
  if (apiIsUserPhysician()) {
    const patients = await dbPatientSearch(searchText, true);
    return patients.filter((patient) => !isPatientPreNewPatient(patient));
  }
  return dbPatientSearch(searchText, identityVerified);
};

export const apiFamilyDoctorSearch = async (searchText) => dbFamilyDoctorSearch(searchText);

export const apiAccountExists = (email) => dbAccountExists(email);

export const apiSignIn = (email, password) => dbSignIn(email, password);

export const apiSignInVerifyPhoneNumber = (resolver, recaptchaVerifier) =>
  dbSignInVerifyPhoneNumber(resolver, recaptchaVerifier);

export const apiSignInVerificationCode = (resolver, verificationCode, verificationId) =>
  dbSignInVerificationCode(resolver, verificationCode, verificationId);

export const apiGetUser = (userId) => dbGetUser(userId);

export const apiSignOut = dbSignOut;
