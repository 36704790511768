import DialogActions from '@material-ui/core/DialogActions';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { primary60 } from '../constants/colors';
import { capitalize } from '../utils/string';
import Button from './Button';
import DialogPaper from './DialogPaper';
import DialogTitle from './DialogTitle';
import TextField from './TextField';
import { dialogActions } from './styles';

const styles = (theme) => ({
  dialogActions: {
    ...dialogActions(theme),
    marginTop: theme.spacing(1.5),
  },
  dialogContent: {
    padding: theme.spacing(2, 3),
  },
  paper: {
    width: 460,
  },
  underline: {
    '&:after': {
      borderBottomColor: primary60,
    },
    '&&:hover::before': {
      borderBottomColor: primary60,
    },
  },
});

class DialogSelect extends Component {
  constructor(props) {
    super(props);

    const { value } = props;

    this.state = {
      value,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.valid = this.valid.bind(this);
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  // Dialog close
  handleClose() {
    const { handleClose: handleCloseProp } = this.props;

    this.setState({ value: null });

    handleCloseProp();
  }

  handleSaveClick() {
    const { handleSaveClick: handleSaveClickProp } = this.props;
    const { value } = this.state;

    handleSaveClickProp(value);

    this.setState({ value: null });
  }

  valid() {
    const { value } = this.state;

    return value !== null;
  }

  render() {
    const { classes, label, open, options, title } = this.props;
    const { value } = this.state;

    return (
      <DialogPaper open={open} onClose={this.handleClose} PaperProps={{ className: classes.paper }} size="large">
        <DialogTitle handleClose={this.handleClose} title={title} />
        <div className={classes.dialogContent}>
          <TextField
            autoFocus
            fullWidth
            InputProps={{ classes: { underline: classes.underline } }}
            label={capitalize(label)}
            onChange={this.handleChange}
            select
            value={value || ''}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <DialogActions className={classes.dialogActions}>
          <Button color="primary" disabled={!this.valid()} onClick={this.handleSaveClick} variant="contained">
            Save
          </Button>
        </DialogActions>
      </DialogPaper>
    );
  }
}

DialogSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
};

DialogSelect.defaultProps = {
  value: null,
};

export default withStyles(styles)(DialogSelect);
