import Collapse from '@material-ui/core/Collapse';
import grey from '@material-ui/core/colors/grey';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { noHover } from '../constants/styles';

const styles = (theme) => ({
  buttonExpand: {
    padding: '18px 23px',
  },
  noHover,
  icon: {
    color: grey[600],
  },
  iconExpanded: {
    transform: 'rotate(180deg)',
  },
  button: {
    display: 'flex',
    justifyContent: 'space-between',
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: theme.palette.action.hover,
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&:focus': {
      backgroundColor: theme.palette.action.hover,
    },
  },
});

class SearchExpansionPanel extends Component {
  constructor(props) {
    super(props);

    const { expanded } = this.props;
    this.state = { expanded };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { expanded } = this.state;

    this.setState({ expanded: !expanded });
  }

  render() {
    const { classes, details, summary } = this.props;
    const { expanded } = this.state;

    return (
      <>
        <div className={classes.button}>
          {summary}
          <IconButton
            className={clsx(classes.buttonExpand, classes.noHover)}
            disableRipple
            focusRipple={false}
            onClick={this.handleClick}
          >
            <ExpandMoreIcon className={clsx(classes.icon, expanded && [classes.iconExpanded])} />
          </IconButton>
        </div>
        <Collapse in={expanded}>
          <div role="region">{details}</div>
        </Collapse>
      </>
    );
  }
}

SearchExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  details: PropTypes.element.isRequired,
  expanded: PropTypes.bool,
  summary: PropTypes.element.isRequired,
};

SearchExpansionPanel.defaultProps = {
  expanded: false,
};

export default withStyles(styles)(SearchExpansionPanel);
