import add from 'date-fns/add';
import isSameDay from 'date-fns/isSameDay';
import { isBeforeToday, isEqualDuration } from './date';
import { getAge, isAgingOut } from './patientUtils';
import { isEqualRecommendation, newRecommendationNoScheduledTest, recommendationNextTestDue } from './recommendation';
import {
  callPatient,
  getLatestTest,
  getScreeningNotApplicable,
  getScreeningNotApplicableByPhysician,
  getScreeningVeryHighRisk,
  isReflexiveTest,
  referPatient,
  screeningIsEquivalentTestType,
  screeningMaxAge,
  screeningNextTestDueOptions,
  screeningNextTestTypeOptions,
  screeningPhysicianRecommendation,
  screeningRecommendation,
} from './screenings';

export default class Recommended {
  #latestTest;

  #screening;

  #patient;

  #screeningRecommendation;

  #physicianRecommendation;

  #recommendation;

  #nextTestDue;

  #nextTestAgingOut;

  #callPatient;

  #referPatient;

  constructor(screening, patient, newTest = null, physicianRecommendation = null) {
    this.#patient = patient;
    this.#screening = screening;
    this.#latestTest = newTest || getLatestTest(screening);
    this.#screeningRecommendation = screeningRecommendation(this.#latestTest, this.#screening, this.#patient);
    this.#physicianRecommendation = physicianRecommendation;
    this.#setRecommendation();
  }

  static copy = (recommended) =>
    new Recommended(
      recommended.#screening,
      recommended.#patient,
      recommended.#latestTest,
      recommended.#physicianRecommendation,
    );

  referral = () => this.#recommendation.referral;

  text = () => this.#recommendation.text;

  callPatient = () => this.#callPatient;

  referPatient = () => this.#referPatient;

  nextTestDue = () => this.#nextTestDue;

  nextTestType = () => this.#recommendation.type;

  nextTestOptional = () => this.#recommendation.optional;

  nextTestDuration = () => this.#recommendation.duration;

  nextTestFromDate = () => this.#recommendation.fromDate;

  nextTestAgingOut = () => this.#nextTestAgingOut;

  isTestScheduled = () => (this.nextTestFromDate() && this.nextTestDuration()) || false;

  isTestNotScheduled = () => !this.isTestScheduled();

  isTestOngoing = () =>
    isReflexiveTest(this.nextTestType(), this.#screening, this.#latestTest) ||
    this.referral() ||
    this.callPatient() ||
    this.referPatient();

  setPhysicianRecommendation(options) {
    this.#physicianRecommendation = screeningPhysicianRecommendation(
      options,
      this.#screeningRecommendation,
      this.#physicianRecommendation,
      this.#screening,
    );
    this.#setRecommendation();
  }

  // Will current recommendation change the screening test plan if applied
  willNextTestChange(other) {
    if (
      (this.nextTestDue() && !other.nextTestDue()) ||
      (!this.nextTestDue() && other.nextTestDue()) ||
      !screeningIsEquivalentTestType(this.nextTestType(), other.nextTestType(), this.#screening) ||
      this.nextTestAgingOut() !== other.nextTestAgingOut() ||
      this.text() !== other.text() ||
      this.referral() !== other.referral()
    ) {
      return true;
    }

    if (!this.nextTestDue() && !other.nextTestDue()) {
      return false;
    }

    return !isSameDay(this.nextTestDue(), other.nextTestDue());
  }

  // Is this recommendation equal to current screening test plan
  isEqualToScreening(screening) {
    const { nextTest } = screening;
    if (
      (this.nextTestDue() && !nextTest.due) ||
      (!this.nextTestDue() && nextTest.due) ||
      this.nextTestType() !== nextTest.type ||
      this.nextTestAgingOut() !== nextTest.agingOut
    ) {
      return false;
    }

    if (!this.nextTestDue() && !nextTest.due) {
      return true;
    }

    return isSameDay(this.nextTestDue(), nextTest.due);
  }

  // Are the underlying screening recommendations equal
  // Note: Strict check where mammogram and mammogramAwbu not equal
  isScreeningRecommendationsEqual = (other) =>
    isEqualRecommendation(this.#screeningRecommendation, other.#screeningRecommendation);

  #physicianOrScreeningRecommendation = () => this.#physicianRecommendation || this.#screeningRecommendation;

  #setRecommendation() {
    this.#callPatient = false;
    this.#referPatient = false;

    // Common to all screenings
    // Note: No test scheduled - referral, not applicable, aging out, very high risk

    const maxAge = screeningMaxAge(this.#screening);

    if (getScreeningNotApplicableByPhysician(this.#screening, this.#patient)) {
      this.#recommendation = newRecommendationNoScheduledTest('Screening does not apply to patient.');
      this.#nextTestDue = recommendationNextTestDue(this.#recommendation);
      this.#nextTestAgingOut = isAgingOut(maxAge, this.#patient, this.#nextTestDue);
      this.#physicianRecommendation = null;
      return;
    }

    if (getScreeningNotApplicable(this.#screening, this.#patient).value) {
      this.#recommendation = newRecommendationNoScheduledTest('Screening does not apply to patient.');
      this.#nextTestDue = recommendationNextTestDue(this.#recommendation);
      this.#nextTestAgingOut = isAgingOut(maxAge, this.#patient, this.#nextTestDue);
      this.#physicianRecommendation = null;
      return;
    }

    if (getScreeningVeryHighRisk(this.#screening, this.#patient).value) {
      this.#recommendation = newRecommendationNoScheduledTest('Patient risk is too high for typical screening.');
      this.#nextTestDue = recommendationNextTestDue(this.#recommendation);
      this.#nextTestAgingOut = isAgingOut(maxAge, this.#patient, this.#nextTestDue);
      this.#physicianRecommendation = null;
      return;
    }

    const recommendation = this.#physicianOrScreeningRecommendation();
    const nextTestDue = recommendationNextTestDue(recommendation);

    this.#recommendation = recommendation;
    this.#nextTestDue = nextTestDue;
    this.#callPatient = callPatient(this.#recommendation, this.#latestTest, this.#screening);
    this.#referPatient = referPatient(this.#recommendation, this.#screening);

    // Chek patient older than max screening age before recommended next test due
    //
    // Note: Make available the possibility for the physician to select an
    // sooner screening date and age the patient back in

    // Reflexive testing (i.e. breast imaging/biopsy or specialist recommended
    // colonoscopy), test still in progress so don't age out yet
    if (isReflexiveTest(recommendation.type, this.#screening, this.#latestTest)) {
      this.#nextTestAgingOut = false;
      return;
    }

    // Aging out determination from the age of the patient and
    // the recommended next test due of the screening recommendation
    // or physician recommendation to age out screening
    //
    // Note: There may be earlier screening date options the physician may
    // wish to schedule to age the patient back in or later screening date to
    // age-out early a patient

    this.#nextTestAgingOut = isAgingOut(maxAge, this.#patient, this.#nextTestDue);

    if (this.#nextTestAgingOut) {
      this.#recommendation = newRecommendationNoScheduledTest(
        `Patient ${getAge(this.#patient) > maxAge ? 'is' : 'will be'} over the screening age.`,
      );
      // Note: Still call patient (if needed) to discuss any recent results
      this.#referPatient = false;
    }
  }

  // Helpers

  static nextTestDueOptions(recommended) {
    // Remove any options which would result in due date in the past
    // Note: Based off screening recommendation

    const maxAge = screeningMaxAge(recommended.#screening);
    const options = screeningNextTestDueOptions(
      recommended.#screeningRecommendation,
      recommended.#latestTest,
      recommended.#screening,
      recommended.#patient,
    )
      .filter((option) => !isBeforeToday(add(recommended.#screeningRecommendation.fromDate, option.duration)))
      .map((option) => ({
        ...option,
        recommended: isEqualDuration(recommended.#screeningRecommendation.duration, option.duration),
        agingOut: isAgingOut(
          maxAge,
          recommended.#patient,
          add(recommended.#screeningRecommendation.fromDate, option.duration),
        ),
      }));

    // Check that recommendation is still an option. If not, add due now option
    // and make it the recommended option.
    if (options.find((option) => option.recommended) === undefined) {
      const duration = { months: 0 };
      options.push({
        name: 'Now',
        duration,
        recommended: true,
        agingOut: isAgingOut(
          maxAge,
          recommended.#patient,
          add(recommended.#screeningRecommendation.fromDate, duration),
        ),
      });
    }

    return options;
  }

  static nextTestTypeOptions = (recommended) =>
    screeningNextTestTypeOptions(recommended.#screeningRecommendation, recommended.#screening).map((option) => ({
      ...option,
      recommended:
        option.recommended !== undefined
          ? option.recommended
          : option.value === recommended.#screeningRecommendation.type,
    }));

  // Check if recommendation set to any one of the next test due options
  // and return that option. With custom dates, possible to set next test
  // due to something other than one of pre-set options.
  static getNextTestDueOption = (recommended) =>
    Recommended.nextTestDueOptions(recommended).reduce((accumulator, option) => {
      if (accumulator === null && isEqualDuration(option.duration, recommended.#recommendation.duration)) {
        return option;
      }
      return accumulator;
    }, null);
}
