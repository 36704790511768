import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { primary } from '../constants/colors';

const styles = (theme) => ({
  color: {
    color: primary,
  },
  icon: {
    marginLeft: theme.spacing(2),
  },
  paper: {
    borderRadius: 8,
    padding: theme.spacing(0.25, 1, 0.25, 1),
    width: 160,
  },
});

class AppBarUser extends Component {
  constructor(props) {
    super(props);

    this.anchorRef = createRef();
    this.handleMenuClose = this.handleMenuClose.bind(this);
  }

  handleMenuClose(event) {
    const { handleMenuClose: handleMenuCloseProp } = this.props;

    if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
      return;
    }

    handleMenuCloseProp(event);
  }

  render() {
    const { classes, handleAccountIconClick, handleProfileClick, handleSignOutClick, openMenu, user } = this.props;

    return (
      <>
        <Typography className={classes.color}>
          {user.fullName}
          <IconButton className={classes.icon} color="inherit" onClick={handleAccountIconClick} ref={this.anchorRef}>
            <AccountCircleIcon />
          </IconButton>
        </Typography>
        <Menu
          anchorEl={this.anchorRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          getContentAnchorEl={null}
          onClose={this.handleMenuClose}
          open={openMenu}
          PaperProps={{ className: classes.paper }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handleProfileClick}>My profile</MenuItem>
          <MenuItem onClick={handleSignOutClick}>Sign out</MenuItem>
        </Menu>
      </>
    );
  }
}

AppBarUser.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAccountIconClick: PropTypes.func.isRequired,
  handleMenuClose: PropTypes.func.isRequired,
  handleProfileClick: PropTypes.func.isRequired,
  handleSignOutClick: PropTypes.func.isRequired,
  openMenu: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppBarUser);
