import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Button from '../components/Button';
import ChartAddFamilyHistoryDialog from '../components/ChartAddFamilyHistoryDialog';
import ChartAddFamilyHistoryDialogBreast from '../components/ChartAddFamilyHistoryDialogBreast';
import ChartHistoryDialog from '../components/ChartHistoryDialog';
import ChartSocialHistoryDialogLung from '../components/ChartSocialHistoryDialogLung';
import Dialog from '../components/Dialog';
import ReferralDialog from '../components/ReferralDialog';
import ScreeningExpansionPanel from '../components/ScreeningExpansionPanel';
import { inversePrimary, inverseSurface, onInverseSurface } from '../constants/colors';
import {
  apiAcceptTest,
  apiAddTest,
  apiDeleteTest,
  apiIsUserPhysician,
  apiReferralTest,
  apiScreeningChartUpdateComplete,
  apiScreeningRequistionReleasedComplete,
  apiScreeningSetNextTestDue,
  apiScreeningSetNoNextTest,
  apiUpdateChartHistory,
  apiUpdateTest,
} from '../utils/api';
import { snackbarTextBilling } from '../utils/billing';
import EventListener from '../utils/eventListener';
import history from '../utils/history';
import { getEditChartHistoryDialogValuesLung } from '../utils/lung';
import notifier from '../utils/notifier';
import {
  chartHistoryTypeName,
  getAddChartHistoryDialogValue,
  getAddFamilyHistoryDialogValues,
  getEditChartHistoryDialogValue,
  hasLatestTestNotReviewed,
  isNewPatient,
  isPreNewOrNewPatient,
  isPreNewPatient,
  newDefaultTest,
  unsetChartHistoryItem,
} from '../utils/screenings';
import { uncapitalize } from '../utils/string';
import TestDialog from './TestDialog';
import { tabTitleSpacing } from './styles';

const styles = (theme) => ({
  dialogActions: {
    padding: theme.spacing(1, 1, 1.25),
  },
  expanded: {},
  list: {
    '& > *': {
      margin: '1px 0',
      transition: theme.transitions.create(['margin', 'marginTop', 'marginBottom']),
    },
    '& > $expanded': {
      borderRadius: 8,
      margin: theme.spacing(1, 0),
    },
    '& > :first-child': {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      marginTop: 0,
    },
    '& > :last-child': {
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      marginBottom: 0,
    },
    paddingBottom: theme.spacing(4),
  },
  paper: {
    width: 352,
  },
  paperWide: {
    width: 412,
  },
  snackbar: {
    backgroundColor: inverseSurface,
    color: onInverseSurface,
  },
  snackbarAction: {
    color: inversePrimary,
  },
  titleSpacing: {
    ...tabTitleSpacing(theme),
  },
});

class ChartScreenings extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      action: null,
      dialogTitle: null,
      historyType: null,
      screening: null,
      snackbar: null,
      test: null,
      testIndex: null,
      value: null,
    };

    this.capturedExpandTransitionsNotifier = null;

    this.handleAcceptClick = this.handleAcceptClick.bind(this);
    this.handleAddChartHistoryClick = this.handleAddChartHistoryClick.bind(this);
    this.handleAddTest = this.handleAddTest.bind(this);
    this.handleAddTestClick = this.handleAddTestClick.bind(this);
    this.handleChartUpdateCompleteClick = this.handleChartUpdateCompleteClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
    this.handleDeleteTestClick = this.handleDeleteTestClick.bind(this);
    this.handleEditChartHistoryClick = this.handleEditChartHistoryClick.bind(this);
    this.handleEditTest = this.handleEditTest.bind(this);
    this.handleEditTestClick = this.handleEditTestClick.bind(this);
    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.handleMenuItemChange = this.handleMenuItemChange.bind(this);
    this.handleReferralClick = this.handleReferralClick.bind(this);
    this.handleRemoveChartHistoryClick = this.handleRemoveChartHistoryClick.bind(this);
    this.handleRequistionReleasedComplete = this.handleRequistionReleasedComplete.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleSaveChartHistoryClick = this.handleSaveChartHistoryClick.bind(this);
    this.handleTestChange = this.handleTestChange.bind(this);
    this.findPreNewOrNewPatientScreening = this.findPreNewOrNewPatientScreening.bind(this);
    this.goToNextExpansion = this.goToNextExpansion.bind(this);
  }

  //
  // Accepting new test result or setting next test due date
  //

  handleAcceptClick(recommended, screening) {
    const { addWaitNotifier, patient } = this.props;

    this.capturedExpandTransitionsNotifier = notifier();
    addWaitNotifier(this.capturedExpandTransitionsNotifier);

    return (
      (recommended.referral() && apiReferralTest(recommended, screening, patient)) ||
      (recommended.isTestNotScheduled() && apiScreeningSetNoNextTest(recommended, screening, patient)) ||
      (hasLatestTestNotReviewed(screening) && apiAcceptTest(recommended, screening, patient)) ||
      apiScreeningSetNextTestDue(recommended, screening, patient)
    )
      .catch(() => {
        this.capturedExpandTransitionsNotifier.resolve();
        this.capturedExpandTransitionsNotifier = null;
      })
      .then(() => {
        this.goToNextExpansion(screening);
      });
  }

  handleAddChartHistoryClick(historyType, screening) {
    return () => {
      this.setState({
        action:
          historyType === 'familyHistory'
            ? `add familyHistory${screening.type === 'breast' ? 'Breast' : ''}`
            : 'add chartHistory',
        historyType,
        screening,
        value: null,
      });
    };
  }

  handleAddTest(newTest) {
    const { patient } = this.props;
    const { screening } = this.state;

    return apiAddTest(newTest, screening, patient).then(this.handleClose);
  }

  handleAddTestClick(screening) {
    const { patient } = this.props;

    return () => {
      this.setState({
        action: 'add test',
        screening,
        test: newDefaultTest(screening, patient),
      });
    };
  }

  handleChartUpdateCompleteClick(screening, option = null) {
    const { addWaitNotifier, patient } = this.props;

    this.capturedExpandTransitionsNotifier = notifier();
    addWaitNotifier(this.capturedExpandTransitionsNotifier);

    return apiScreeningChartUpdateComplete(screening, patient, option)
      .catch(() => {
        this.capturedExpandTransitionsNotifier.resolve();
        this.capturedExpandTransitionsNotifier = null;
      })
      .then(() => {
        this.goToNextExpansion(screening);
      });
  }

  handleClose() {
    this.setState({
      action: null,
      dialogTitle: null,
      historyType: null,
      screening: null,
      snackbar: null,
      test: null,
      testIndex: null,
      value: null,
    });
  }

  handleCloseSnackbar() {
    this.setState({ snackbar: null });
  }

  handleDeleteTestClick(testIndex, screening) {
    return () =>
      this.setState({
        action: 'delete test',
        screening,
        testIndex,
      });
  }

  handleEditChartHistoryClick(item, screening) {
    return () =>
      this.setState({
        action: screening.type === 'lung' ? 'edit socialHistoryLung' : 'edit chartHistory',
        historyType: item.type,
        screening,
        value: item,
      });
  }

  handleEditTest(test) {
    const { patient } = this.props;
    const { screening, testIndex } = this.state;

    return apiUpdateTest(test, testIndex, screening, patient).then(this.handleClose);
  }

  handleEditTestClick(test, testIndex, screening) {
    return () =>
      this.setState({
        action: 'edit test',
        screening,
        test,
        testIndex,
      });
  }

  handleExpandClick(screening) {
    const { expansions } = this.props;

    return () => {
      const { type } = screening;

      history.update({
        expansionsChart: expansions.includes(type)
          ? expansions.filter((item) => item !== type)
          : expansions.concat(type),
      });
    };
  }

  handleMenuItemChange(event) {
    this.setState({ value: event.target.value });
  }

  // Patient consult and preform referral if colonoscopy has come due
  handleReferralClick(recommended, screening) {
    return () =>
      this.setState({
        action: 'referral',
        screening,
        value: recommended,
      });
  }

  handleRemoveChartHistoryClick(item, screening) {
    return () =>
      this.setState({
        action: 'remove chartHistory',
        dialogTitle: `Remove ${uncapitalize(item.name)}?`,
        screening,
        value: item,
      });
  }

  handleRequistionReleasedComplete(screening) {
    const { addWaitNotifier, patient } = this.props;

    this.capturedExpandTransitionsNotifier = notifier();
    addWaitNotifier(this.capturedExpandTransitionsNotifier);

    return apiScreeningRequistionReleasedComplete(screening, patient)
      .catch(() => {
        this.capturedExpandTransitionsNotifier.resolve();
        this.capturedExpandTransitionsNotifier = null;
      })
      .then(() => {
        this.handleExpandClick(screening)();
        this.handleClose();
      });
  }

  handleSaveClick() {
    const { patient } = this.props;
    const { action, screening, testIndex, value } = this.state;

    this.setState({ action: null });

    if (action === 'remove chartHistory') {
      apiUpdateChartHistory(unsetChartHistoryItem(value, screening, patient), screening, patient).then(
        this.handleClose,
      );
      return;
    }

    if (action === 'delete test') {
      apiDeleteTest(testIndex, screening, patient).then(this.handleClose);
    }
  }

  handleSaveChartHistoryClick(items) {
    const { patient } = this.props;
    const { screening } = this.state;

    this.setState({ action: null });

    apiUpdateChartHistory(items, screening, patient).then(this.handleClose);

    this.handleClose();
  }

  handleTestChange(test) {
    this.setState({ test });
  }

  findPreNewOrNewPatientScreening(currentIndex) {
    const { patient } = this.props;

    // Find next pre-new patient screening greater than current one
    if (!apiIsUserPhysician()) {
      if (currentIndex !== -1) {
        const screening = patient.screenings.slice(currentIndex + 1).find((item) => isPreNewPatient(item));
        if (screening) {
          return screening;
        }
      }
      // Find any other pre-new patient screening
      const screening = patient.screenings
        .filter((_, index) => index !== currentIndex)
        .find((item) => isPreNewPatient(item));
      if (screening) {
        return screening;
      }

      return undefined;
    }

    // Find any other new patient screening
    if (currentIndex !== -1) {
      const screening = patient.screenings.slice(currentIndex + 1).find((item) => isNewPatient(item));
      if (screening) {
        return screening;
      }
    }
    // Find any new patient screening
    const screening = patient.screenings
      .filter((_, index) => index !== currentIndex)
      .find((item) => isNewPatient(item));

    if (screening) {
      return screening;
    }

    return undefined;
  }

  goToNextExpansion(screening) {
    const { patient } = this.props;

    const index = patient.screenings.findIndex((item) => item.type === screening.type);
    const preNewOrNewScreening = this.findPreNewOrNewPatientScreening(index);
    if (preNewOrNewScreening) {
      history.update({ expansionsChart: [preNewOrNewScreening.type] });
    } else {
      this.handleExpandClick(screening)();
    }
  }

  render() {
    const { classes, expansions, patient } = this.props;
    const { action, dialogTitle, historyType, screening, snackbar, test, testIndex, value } = this.state;

    // When upgrade patient to screening physician will not see upgrade screening
    // until post preNewPatient

    return (
      <>
        <EventListener onSnackbar={(event) => this.setState({ snackbar: event.detail.data })} target="document" />
        <div className={classes.titleSpacing} />
        <div className={classes.list}>
          {patient.screenings
            .filter((item) => !isPreNewPatient(item) || !apiIsUserPhysician())
            .map((item) => {
              let onTransitionEnd = () => {};
              const expanded = expansions.includes(item.type);
              if (!expanded) {
                onTransitionEnd = () => {
                  if (this.capturedExpandTransitionsNotifier) {
                    this.capturedExpandTransitionsNotifier.resolve();
                    this.capturedExpandTransitionsNotifier = null;
                  }
                };
              }
              return (
                <ScreeningExpansionPanel
                  className={clsx(expanded && classes.expanded)}
                  expanded={expanded}
                  handleAcceptClick={this.handleAcceptClick}
                  handleAddChartHistoryClick={this.handleAddChartHistoryClick}
                  handleAddTestClick={this.handleAddTestClick}
                  handleChartUpdateCompleteClick={this.handleChartUpdateCompleteClick}
                  handleDeleteTestClick={this.handleDeleteTestClick}
                  handleEditChartHistoryClick={this.handleEditChartHistoryClick}
                  handleEditTestClick={this.handleEditTestClick}
                  handleExpandClick={this.handleExpandClick(item)}
                  handleReferralClick={this.handleReferralClick}
                  handleRemoveChartHistoryClick={this.handleRemoveChartHistoryClick}
                  handleRequistionReleasedComplete={this.handleRequistionReleasedComplete}
                  key={item.type}
                  onTransitionEnd={onTransitionEnd}
                  patient={patient}
                  screening={item}
                />
              );
            })}
        </div>
        {screening && ['add test', 'edit test'].includes(action) && (
          <TestDialog
            handleClose={this.handleClose}
            handleSaveClick={action === 'add test' ? this.handleAddTest : this.handleEditTest}
            handleTestChange={this.handleTestChange}
            newPatient={isPreNewOrNewPatient(screening)}
            open
            patient={patient}
            screening={screening}
            test={test}
            testIndex={testIndex}
            title={action === 'add test' ? 'New test result' : 'Edit test result'}
          />
        )}
        {screening && action === 'add chartHistory' && (
          <ChartHistoryDialog
            getValue={getAddChartHistoryDialogValue}
            handleClose={this.handleClose}
            handleSaveClick={this.handleSaveChartHistoryClick}
            historyType={historyType}
            open
            patient={patient}
            screening={screening}
            title={`Add ${chartHistoryTypeName(historyType)}`}
          />
        )}
        {screening && action === 'add familyHistory' && (
          <ChartAddFamilyHistoryDialog
            getValues={getAddFamilyHistoryDialogValues}
            handleClose={this.handleClose}
            handleSaveClick={this.handleSaveChartHistoryClick}
            open
            patient={patient}
            screening={screening}
            title={`Add ${chartHistoryTypeName(historyType)}`}
          />
        )}
        {screening && action === 'add familyHistoryBreast' && (
          <ChartAddFamilyHistoryDialogBreast
            getValues={getAddFamilyHistoryDialogValues}
            handleClose={this.handleClose}
            handleSaveClick={this.handleSaveChartHistoryClick}
            open
            patient={patient}
            screening={screening}
            title={`Add ${chartHistoryTypeName(historyType)}`}
          />
        )}
        {screening && action === 'edit chartHistory' && (
          <ChartHistoryDialog
            getValue={getEditChartHistoryDialogValue}
            handleClose={this.handleClose}
            handleSaveClick={this.handleSaveChartHistoryClick}
            historyType={historyType}
            item={value}
            open
            patient={patient}
            screening={screening}
            title={`Edit ${chartHistoryTypeName(historyType)}`}
          />
        )}
        {screening && action === 'edit socialHistoryLung' && (
          <ChartSocialHistoryDialogLung
            getValues={getEditChartHistoryDialogValuesLung}
            handleClose={this.handleClose}
            handleSaveClick={this.handleSaveChartHistoryClick}
            open
            patient={patient}
            screening={screening}
            title={`Edit ${chartHistoryTypeName(historyType)}`}
          />
        )}
        <Dialog
          handleCancelClick={this.handleClose}
          handleOkClick={this.handleSaveClick}
          okLabel="OK, remove"
          open={action === 'remove chartHistory'}
          onClose={this.handleClose}
          PaperProps={{ className: classes.paperWide }}
          title={dialogTitle}
        />
        <Dialog
          handleCancelClick={this.handleClose}
          handleOkClick={this.handleSaveClick}
          okLabel="OK, delete"
          open={action === 'delete test'}
          onClose={this.handleClose}
          title="Delete patient test?"
        />
        {screening && action === 'referral' && (
          <ReferralDialog
            handleClose={this.handleClose}
            // Colonoscopy has come due which is a requisition release
            handleOkClick={() => this.handleRequistionReleasedComplete(screening)}
            open
          />
        )}
        {snackbar !== null && (
          <Snackbar
            ContentProps={{
              classes: { root: classes.snackbar },
            }}
            message={snackbarTextBilling(snackbar.activity)}
            onClose={this.handleCloseSnackbar}
            open
            action={
              <Button
                className={classes.snackbarAction}
                size="small"
                onClick={() =>
                  // Nav chart notes tab
                  history.update({ tabContext: 'openBilling', tabIndex: 2 })
                }
              >
                Change
              </Button>
            }
          />
        )}
      </>
    );
  }
}

ChartScreenings.propTypes = {
  addWaitNotifier: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  expansions: PropTypes.arrayOf(PropTypes.string),
  patient: PropTypes.object.isRequired,
};

ChartScreenings.defaultProps = {
  expansions: [],
};

export default withStyles(styles)(ChartScreenings);
