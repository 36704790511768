import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const styles = (theme) => ({
  exit: {
    opacity: 1,
  },
  exitActive: {
    opacity: 0,
    transition: theme.transitions.create('opacity'),
  },
  exitDone: {
    opacity: 0,
  },
  leftEnter: {
    opacity: 0,
    transform: 'translateX(56px)',
  },
  leftEnterActive: {
    opacity: 1,
    transform: 'translateX(0)',
    transition: theme.transitions.create(['opacity', 'transform']),
  },
  leftEnterDone: {
    opacity: 1,
    transform: 'translateX(0)',
  },
  rightEnter: {
    opacity: 0,
    transform: 'translateX(-56px)',
  },
  rightEnterActive: {
    opacity: 1,
    transform: 'translateX(0)',
    transition: theme.transitions.create(['opacity', 'transform']),
  },
  rightEnterDone: {
    opacity: 1,
    transform: 'translateX(0)',
  },
});

function FadeSlideOver(props) {
  const { children, classes, transitionKey, direction, ...other } = props;

  return (
    <TransitionGroup>
      <CSSTransition
        addEndListener={(node, done) => {
          const eventListener = (event) => {
            node.removeEventListener('transitionend', eventListener, false);
            done(event);
          };
          node.addEventListener('transitionend', eventListener, false);
        }}
        classNames={{
          enter: direction === 'left' ? classes.leftEnter : classes.rightEnter,
          enterActive: direction === 'left' ? classes.leftEnterActive : classes.rightEnterActive,
          enterDone: direction === 'left' ? classes.leftEnterDone : classes.rightEnterDone,
          exit: classes.exit,
          exitActive: classes.exitActive,
          exitDone: classes.exitDone,
        }}
        key={transitionKey}
        {...other} // eslint-disable-line react/jsx-props-no-spreading
      >
        {children}
      </CSSTransition>
    </TransitionGroup>
  );
}

FadeSlideOver.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  transitionKey: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['left', 'right']).isRequired,
};

export default withStyles(styles)(FadeSlideOver);
