import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider';
import InputBase from '@material-ui/core/InputBase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Component } from 'react';
import SearchExpansionPanel from '../components/SearchExpansionPanel';
import { flatShadow, shadow1 } from '../constants/styles';
import MedicalInformationIcon from '../icons/MedicalInformationOutlined';
import StickyNote2Icon from '../icons/StickyNote2Outlined';
import { apiPatientSearch } from '../utils/api';
import cancelable from '../utils/cancelable';
import history from '../utils/history';
import { getFullName } from '../utils/patient';

const styles = (theme) => ({
  button: {
    borderTopLeftRadius: 24,
    borderBottomLeftRadius: 24,
  },
  container: {
    width: 320,
    minHeight: 40,
    marginTop: 12,
    marginLeft: -110,
    borderRadius: 20,
    position: 'absolute',
    left: 476,
    zIndex: theme.zIndex.appBar + 1,
    transition:
      'width 200ms ease, height 200ms ease, min-height 200ms ease, margin-left 200ms ease, border-radius 100ms ease',
  },
  containerFocused: {
    width: 396,
    minHeight: 52,
    marginTop: 12,
    marginLeft: -138,
    borderRadius: 16,
    ...shadow1,
  },
  contentInput: {
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'height 200ms ease',
  },
  contentInputFocused: {
    height: 52,
  },
  details: {
    marginLeft: 52,
    '& > :last-child': {
      marginBottom: theme.spacing(1),
    },
  },
  flatShadow,
  icon: {
    marginRight: theme.spacing(2),
  },
  input: {
    height: 24,
    display: 'flex',
    alignItems: 'center',
    width: 276,
    transition: 'width 200ms ease',
  },
  inputFocused: {
    width: 352,
  },
  inputBase: {
    flex: 1,
    marginLeft: theme.spacing(1.5),
    fontSize: theme.typography.pxToRem(14),
  },
  inputBaseFocus: {
    fontSize: theme.typography.pxToRem(16),
  },
  list: {
    '& > :first-child': {
      marginTop: theme.spacing(1.5),
    },
    paddingBottom: theme.spacing(2.5),
  },
  summary: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(1, 0, 1, 3.5),
    minWidth: 0,
    flex: '1 1 0',
  },
  summaryColumn: {
    marginLeft: 38,
    minWidth: 0,
    textAlign: 'left',
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
  },
});

class SearchAppBar extends Component {
  constructor(props) {
    super(props);

    const { patientId, tabIndex } = history.getLocationState();

    this.state = {
      focused: false,
      query: '',
      results: [],
      patientId,
      tabIndex,
    };

    this.backdropClick = false;
    this.cancelable = undefined;

    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleLocationChanged = this.handleLocationChanged.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
  }

  componentDidMount() {
    this.historyUnlisten = history.addListener(this.handleLocationChanged);
  }

  componentWillUnmount() {
    if (this.historyUnlisten) {
      this.historyUnlisten();
    }
  }

  handleBlur() {
    const { focused } = this.state;

    if (focused) {
      this.setState(this.backdropClick ? { focused: false } : { focused: false, query: '', results: [] });
    }
  }

  handleChange(event) {
    const { value } = event.target;

    this.setState({ query: value });
    if (this.cancelable) this.cancelable.cancel();
    this.cancelable = cancelable(apiPatientSearch(value));
    this.cancelable.promise.then((results) => this.setState({ results })).catch(() => {});
  }

  handleFocus() {
    const { focused } = this.state;

    this.setState(!focused ? { focused: true } : null);
    this.backdropClick = false;
  }

  handleKeyDown(event) {
    if (['Escape', 'Esc'].includes(event.key)) {
      event.preventDefault();
      this.setState({ results: [] });
    }
  }

  handleLocationChanged(locationState) {
    const { patientId: prevPatientId, tabIndex: prevTabIndex } = this.state;

    const { patientId, tabIndex } = locationState || {};

    if (patientId !== prevPatientId || tabIndex !== prevTabIndex) {
      this.setState({ focused: false, query: '', patientId, results: [], tabIndex });
    } else {
      this.setState({
        patientId,
        tabIndex,
      });
    }
  }

  handleMouseDown() {
    this.backdropClick = true;
  }

  render() {
    const { classes, handleResetChartScrollHistory } = this.props;
    const { focused, query, patientId, results, tabIndex } = this.state;

    return (
      <Paper
        className={clsx(
          classes.container,
          focused || results.length !== 0 ? classes.containerFocused : classes.flatShadow,
        )}
      >
        <div className={clsx(classes.contentInput, (focused || results.length !== 0) && classes.contentInputFocused)}>
          <div className={clsx(classes.input, (focused || results.length !== 0) && classes.inputFocused)}>
            <SearchIcon
              className={classes.textSecondary}
              fontSize={focused || results.length !== 0 ? 'medium' : 'small'}
            />
            <InputBase
              className={clsx(
                classes.inputBase,
                (focused || results.length !== 0) && classes.inputBaseFocus,
                classes.textPrimary,
              )}
              inputProps={{ spellCheck: false }}
              onBlur={this.handleBlur}
              onChange={(event) => this.handleChange(event)}
              onFocus={this.handleFocus}
              onKeyDown={this.handleKeyDown}
              placeholder="Search for patients"
              value={query}
            />
          </div>
        </div>
        {results.length !== 0 && (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <div onMouseDown={this.handleMouseDown} role="document">
            <Divider />
            <div className={classes.list}>
              {results.slice(0, 5).map((item, index) => (
                <SearchExpansionPanel
                  details={
                    <List className={classes.details} disablePadding>
                      <ListItem
                        className={classes.button}
                        button
                        disableRipple
                        onClick={() => {
                          if (item.id !== patientId || tabIndex !== 0) {
                            handleResetChartScrollHistory();
                            history.replace({ expansionsChart: [], patientId: item.id, tabIndex: 0 });
                          } else {
                            this.setState({ focused: false, query: '', results: [] });
                          }
                        }}
                      >
                        <InfoIcon className={clsx(classes.icon, classes.textSecondary)} />
                        <Typography color="textPrimary">Patient info</Typography>
                      </ListItem>
                      {item.identity === 'verified' && (
                        <>
                          <ListItem
                            className={classes.button}
                            button
                            disableRipple
                            onClick={() => {
                              if (item.id !== patientId || tabIndex !== 1) {
                                handleResetChartScrollHistory();
                                history.replace({ expansionsChart: [], patientId: item.id, tabIndex: 1 });
                              } else {
                                this.setState({ focused: false, query: '', results: [] });
                              }
                            }}
                          >
                            <MedicalInformationIcon className={clsx(classes.icon, classes.textSecondary)} />
                            <Typography color="textPrimary">Screenings</Typography>
                          </ListItem>
                          <ListItem
                            className={classes.button}
                            button
                            disableRipple
                            onClick={() => {
                              if (item.id !== patientId || tabIndex !== 2) {
                                handleResetChartScrollHistory();
                                history.replace({ expansionsChart: [], patientId: item.id, tabIndex: 2 });
                              } else {
                                this.setState({ focused: false, query: '', results: [] });
                              }
                            }}
                          >
                            <StickyNote2Icon className={clsx(classes.icon, classes.textSecondary)} />
                            <Typography color="textPrimary">Chart notes</Typography>
                          </ListItem>
                        </>
                      )}
                    </List>
                  }
                  expanded={index === 0}
                  key={item.id}
                  summary={
                    <ButtonBase
                      className={classes.summary}
                      disableRipple
                      focusRipple={false}
                      onClick={() => {
                        if (item.id !== patientId) {
                          handleResetChartScrollHistory();
                          history.replace({
                            expansionsChart: [],
                            patientId: item.id,
                            tabIndex: 1,
                          });
                        } else {
                          this.setState({ focused: false, query: '', results: [] });
                        }
                      }}
                    >
                      <div className={classes.summaryColumn}>
                        <div className={clsx(classes.title)}>
                          <Typography noWrap>{getFullName(item)}</Typography>
                          <Typography color="textSecondary" variant="body2">
                            {item.phn}
                          </Typography>
                        </div>
                      </div>
                    </ButtonBase>
                  }
                />
              ))}
            </div>
          </div>
        )}
      </Paper>
    );
  }
}

SearchAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  handleResetChartScrollHistory: PropTypes.func.isRequired,
};

export default withStyles(styles)(SearchAppBar);
