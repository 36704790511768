import { createTheme } from '@material-ui/core/styles';

export const primary = '#255ea7';
export const primary80 = '#a8c8ff';
export const primary60 = '#6092de';
export const primaryContainer = '#d6e3ff';
export const primaryOnContainer = '#001b3c';
export const secondary = '#006877';
export const secondary99 = '#f7fdff';
export const secondary98 = '#effcff';
export const secondary95 = '#d5f7ff';
export const secondary20 = '00363f';
export const secondaryContainer = '#a4eeff';
export const secondaryOnContainer = '#001f25';
export const tertiary = '#ba1a1a';
export const tertiary95 = '#ffeedb';
export const tertiary20 = '#442c00';
export const tertiaryContainer = '#ffdad6';
export const tertiaryOnContainer = '#281800';

export const error95 = '#ffedea';
export const error20 = '#690005';
export const errorContainer = '#ffdad6';
export const errorOnContainer = '#410002';

export const neutral100 = '#ffffff';
export const neutral95 = '#f1f0f4';
export const neutral60 = '#909094';
export const neutral20 = '#2f3033';
export const neutral10 = '#1a1c1e';

export const neutralVariant100 = '#ffffff';
export const neutralVariant90 = '#e0e2ec';
export const neutralVariant80 = '#c4c6cf';
export const neutralVariant50 = '#74777f';
export const neutralVariant30 = '#43474e';

export const outline = neutralVariant50;
export const outlineVariant = neutralVariant80;

export const surface = neutral100;
export const onSurface = neutral10;

export const onSurface08Pct = '#1a1c1e08';
export const onSurface12Pct = '#1a1c1e0c';
export const onSurface38Pct = '#1a1c1e26';

export const surfaceVariant = neutralVariant90;
export const onSurfaceVariant = neutralVariant30;

export const inverseSurface = neutral20;
export const onInverseSurface = neutral95;

export const inversePrimary = primary80;

export const textPrimary = onSurface;
export const textSecondary = onSurfaceVariant;

export const createColorTheme = (baseTheme) =>
  createTheme({
    ...baseTheme,
    palette: {
      ...baseTheme.palette,
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
      },
    },
  });

export const createColorThemeBadge = (baseTheme) =>
  createTheme({
    ...baseTheme,
    palette: {
      ...baseTheme.palette,
      primary: {
        main: tertiary,
      },
      secondary: {
        main: onSurfaceVariant,
      },
    },
  });

const breast60 = '#d072a2';
const cervical60 = '#00a0ac';
const colon60 = '#5893dc';
const lung60 = '#939372';
const prostate60 = '#239bcc';

const colorCancers = {
  breast: breast60,
  cervical: cervical60,
  colon: colon60,
  lung: lung60,
  prostate: prostate60,
  default: surface,
};

// Billing code
export const lung40 = '#606043';

export const cancerColor = (screeningType) =>
  screeningType in colorCancers ? colorCancers[screeningType] : colorCancers.default;
