import { getCancerScreening } from './patientUtils';

export function hasReferralBeenRequested(screening, patient) {
  const cancerScreening = getCancerScreening(screening, patient);
  return (
    (Object.hasOwn(cancerScreening, 'referral') &&
      cancerScreening.referral.requested &&
      !cancerScreening.referral.sent) ||
    false
  );
}

export function hasReferralBeenSent(screening, patient) {
  const cancerScreening = getCancerScreening(screening, patient);
  return (Object.hasOwn(cancerScreening, 'referral') && cancerScreening.referral.sent) || false;
}
