import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Tooltip from './Tooltip';

const styles = (theme) => ({
  closeButton: {
    color: theme.palette.text.secondary,
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(24),
  },
});

function PopoverTitle(props) {
  const { classes, className: classNameProp, handleClose, title } = props;

  return (
    <MuiDialogTitle disableTypography>
      <Typography className={clsx(classes.title, classNameProp)} variant="h6">
        {title}
      </Typography>
      {handleClose && (
        <Tooltip title="Close">
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </MuiDialogTitle>
  );
}

PopoverTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  handleClose: PropTypes.func,
  title: PropTypes.string.isRequired,
};

PopoverTitle.defaultProps = {
  className: undefined,
  handleClose: null,
};

export default withStyles(styles)(PopoverTitle);
