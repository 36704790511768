import PropTypes from 'prop-types';
import { PureComponent } from 'react';

function defineProperty(object, property, attr) {
  return Object.defineProperty(object, property, attr);
}

const passiveOption = (() => {
  let cache = null;

  return (() => {
    if (cache !== null) {
      return cache;
    }

    let supportsPassiveOption = false;

    try {
      window.addEventListener(
        'test',
        null,
        defineProperty({}, 'passive', {
          get() {
            supportsPassiveOption = true;
          },
        }),
      );
    } catch (err) {
      //
    }

    cache = supportsPassiveOption;

    return supportsPassiveOption;
  })();
})();

const defaultEventOptions = {
  capture: false,
  passive: false,
};

function mergeDefaultEventOptions(options) {
  return { ...defaultEventOptions, ...options };
}

function getEventListenerArgs(eventName, callback, options) {
  const args = [eventName, callback];
  args.push(passiveOption ? options : options.capture);
  return args;
}

function on(target, eventName, callback, options) {
  target.addEventListener(...getEventListenerArgs(eventName, callback, options));
}

function off(target, eventName, callback, options) {
  target.removeEventListener(...getEventListenerArgs(eventName, callback, options));
}

function forEachListener(props, iteratee) {
  const { children, target, ...eventProps } = props;

  Object.keys(eventProps).forEach((name) => {
    if (name.substring(0, 2) !== 'on') {
      return;
    }

    const prop = eventProps[name];
    const type = typeof prop;
    const isObject = type === 'object';
    const isFunction = type === 'function';

    if (!isObject && !isFunction) {
      return;
    }

    const capture = name.slice(-7).toLowerCase() === 'capture';
    let eventName = name.substring(2).toLowerCase();
    eventName = capture ? eventName.substring(0, eventName.length - 7) : eventName;

    if (isObject) {
      iteratee(eventName, prop.handler, prop.options);
    } else {
      iteratee(eventName, prop, mergeDefaultEventOptions({ capture }));
    }
  });
}

class EventListener extends PureComponent {
  componentDidMount() {
    this.applyListeners(on);
  }

  componentDidUpdate(prevProps) {
    this.applyListeners(off, prevProps);
    this.applyListeners(on);
  }

  componentWillUnmount() {
    this.applyListeners(off);
  }

  applyListeners(onOrOff, props = this.props) {
    const { target } = props;

    if (target) {
      let element = target;

      if (typeof target === 'string') {
        element = window[target];
      }

      forEachListener(props, onOrOff.bind(null, element));
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

EventListener.propTypes = {
  children: PropTypes.node,
  // eslint-disable-next-line react/no-unused-prop-types
  target: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

EventListener.defaultProps = {
  children: null,
};

export default EventListener;
