import isAfter from 'date-fns/isAfter';
import subYears from 'date-fns/subYears';
import {
  getHistoryPreviousValue,
  historyItem as historyItemUtils,
  historyItemValue,
  historyValueChanged,
} from './chartHistoryUtils';
import { getAge } from './patientUtils';
import { newRecommendationAgingIn, newRecommendationReferral, newRecommendationScheduleTest } from './recommendation';
import { dueOptionsAddNowIfNeeded } from './screeningUtils';
import { capitalize } from './string';

const resultServerity = [
  'negative',
  'ascus',
  'lsil',
  'hsil',
  'asch',
  'ais',
  'agc',
  'invasiveCervicalCancer',
  'adenocarcinoma',
  'squamousCarcinoma',
  'otherMalignancy',
  'benignEndometrialCells',
];

export function testTypeNameCervical(type) {
  if (type === 'pap') return 'pap test';
  if (type === 'colposcopy') return 'colposcopy';
  if (type === 'dischargeLetter') return 'discharge letter';
  // Present to user as consult
  if (type === 'questionnaire') return 'patient consult';
  return type;
}

export function testReleaseTypeCervical(type) {
  if (type === 'pap') return 'instructions';
  if (type === 'colposcopy') return 'booking';
  if (['dischargeLetter', 'questionnaire'].includes(type)) return 'other';
  return null;
}

function valueNameCervical(value) {
  if (value === 'negative') return 'Negative';
  if (value === 'ascus') return 'ASCUS';
  if (value === 'lsil') return 'LSIL';
  if (value === 'hsil') return 'HSIL';
  if (value === 'asch') return 'ASC-H';
  if (value === 'ais') return 'AIS';
  if (value === 'agc') return 'AGC';
  if (value === 'invasiveCervicalCancer') return 'Invasive cervical cancer';
  if (value === 'adenocarcinoma') return 'Adenocarcinoma';
  if (value === 'squamousCarcinoma') return 'Squamous carcinoma';
  if (value === 'otherMalignancy') return 'Other malignancy';
  if (value === 'benignEndometrialCells') return 'Benign endometrial cells';
  if (value === 'unsatisfactory') return 'Unsatisfactory';
  return value;
}

export const testValueNameCervical = (test) => valueNameCervical(test.value);

export function testValue2NameCervical(value2) {
  if (value2 === 'indeterminate') return 'HPV test indeterminate';
  if (value2 === 'positive') return 'HPV test pos';
  if (value2 === 'negative') return 'HPV test neg';
  return '';
}

export const menuOptionsTestTypeCervical = () => [
  [
    { name: capitalize(testTypeNameCervical('pap')), value: 'pap' },
    { name: capitalize(testTypeNameCervical('colposcopy')), value: 'colposcopy' },
  ],
  [{ name: capitalize(testTypeNameCervical('dischargeLetter')), value: 'dischargeLetter' }],
];

const commonItemsResults = [
  [{ name: valueNameCervical('negative'), value: 'negative' }],
  [
    { name: valueNameCervical('ascus'), value: 'ascus' },
    { name: valueNameCervical('lsil'), value: 'lsil' },
  ],
  [
    { name: valueNameCervical('hsil'), value: 'hsil' },
    { name: valueNameCervical('asch'), value: 'asch' },
    { name: valueNameCervical('ais'), value: 'ais' },
    { name: valueNameCervical('agc'), value: 'agc' },
  ],
  [
    { name: valueNameCervical('invasiveCervicalCancer'), value: 'invasiveCervicalCancer' },
    { name: valueNameCervical('adenocarcinoma'), value: 'adenocarcinoma' },
    { name: valueNameCervical('squamousCarcinoma'), value: 'squamousCarcinoma' },
    { name: valueNameCervical('otherMalignancy'), value: 'otherMalignancy' },
  ],
  [{ name: valueNameCervical('benignEndometrialCells'), value: 'benignEndometrialCells' }],
  [{ name: valueNameCervical('unsatisfactory'), value: 'unsatisfactory' }],
];

export const menuOptionsTestResultCervical = (test) =>
  ['pap', 'colposcopy'].includes(test.type) ? commonItemsResults : [];

export const menuOptionsTestResult2Cervical = (test) =>
  test.type === 'pap'
    ? {
        label: 'HPV test (optional)',
        items: [
          { name: 'Negative', value: 'negative' },
          { name: 'Positive', value: 'positive' },
          { name: 'Indeterminate', value: 'indeterminate' },
        ],
      }
    : { label: '', items: [] };

export function newDefaultTestCervical(isReferral) {
  if (isReferral) {
    return {
      date: null,
      type: 'dischargeLetter',
    };
  }

  return {
    date: null,
    type: 'pap',
    value: null,
  };
}

export const getConsultWindowCervical = (nextTestType) =>
  ['pap', 'questionnaire'].includes(nextTestType) ? { days: 30 } : null;

export const isAutoConsultTestCervical = (screening) => screening.nextTest.type === 'pap';

const optionValue2 = (value) => ['ascus', 'lsil'].includes(value);

export function handleTestEditChangeCervical(key, newValue, test) {
  // Remove value and value2 for dischargeLetter
  if (key === 'type' && newValue === 'dischargeLetter') {
    const { value, value2, ...other } = test;
    return { ...other, [key]: newValue };
  }
  // Reset value and remove value2 for pap and colposcopy
  if (key === 'type' && ['pap', 'colposcopy'].includes(newValue)) {
    const { value2, ...other } = test;
    return { ...other, [key]: newValue, value: null };
  }
  if (key === 'value' && test.type === 'pap') {
    const { value2, ...other } = test;
    // Add HPV value option for value optionally triggering secondary result
    if (optionValue2(newValue)) {
      return { ...other, [key]: newValue, value2: null };
    }
    // Remove HPV value option from test
    return { ...other, [key]: newValue };
  }
  return { ...test, [key]: newValue };
}

const highRiskPathology = [
  'adenocarcinoma',
  'agc',
  'ais',
  'asch',
  'hsil',
  'invasiveCervicalCancer',
  'otherMalignancy',
  'squamousCarcinoma',
];

//
// Medical history items
//

// ** Display items of chart history

const sexuallyActiveHistoryItem = {
  type: 'socialHistory',
  field: 'sexuallyActive',
  name: 'Sexually active',
  questionnaire: true,
  showChart: true,
  onlyEdit: true,
  getChanged: (value, screening, patient) =>
    value && historyValueChanged({ type: 'socialHistory', field: 'sexuallyActive', value }, screening, patient),
};

export const historyItemsCervical = [
  {
    type: 'personalHistory',
    field: 'hysterectomy',
    name: 'Complete hysterectomy',
    firestore: true,
    showChart: true,
  },
  {
    type: 'personalHistory',
    field: 'higherPathology',
    highRiskPathology,
    name: 'HSIL or higher pathology',
    firestore: true,
    showChart: true,
  },
  {
    type: 'personalHistory',
    field: 'immunosuppressantIllness',
    name: 'History of immunosuppressant illness',
    firestore: true,
    showChart: true,
  },
  {
    type: 'personalHistory',
    field: 'immunosuppressantMedications',
    name: 'History of immunosuppressant medications',
    firestore: true,
    showChart: true,
  },
  sexuallyActiveHistoryItem,
  {
    // Not directly saved back to firestore
    type: 'socialHistory',
    field: 'neverSexuallyActive',
    name: 'Never been sexually active',
    showChart: true,
    onlyEdit: true,
    getValue: (screening, patient) => {
      const sexuallyActive = historyItemValue(sexuallyActiveHistoryItem, screening, patient);
      return sexuallyActive.value !== undefined ? !sexuallyActive.value : undefined;
    },
    getChanged: (value, screening, patient) => {
      if (!value) {
        return false;
      }
      const previousValue = getHistoryPreviousValue(sexuallyActiveHistoryItem, screening, patient);
      if (previousValue === undefined) {
        return true;
      }
      const sexuallyActive = historyItemValue(sexuallyActiveHistoryItem, screening, patient);
      return sexuallyActive.value !== previousValue;
    },
  },
];

const historyItem = (field) => historyItemUtils(field, historyItemsCervical);

//
// Screening rules
//

const screeningMinAgeCervical = 25;

export function getScreeningHighRiskCervical(screening, patient) {
  let pathology = false;
  let personalHistory = false;
  const indicators = new Set();

  // HSIL or higher pathology

  const higherPathology = historyItemValue(historyItem('higherPathology'), screening, patient);
  if (higherPathology.value) {
    pathology = higherPathology.pathology || false;
    personalHistory = !pathology;
    indicators.add(higherPathology.name);
  }

  // History of immunosuppressant illness, History of immunosuppressant medications

  ['immunosuppressantIllness', 'immunosuppressantMedications'].forEach((field) => {
    const item = historyItemValue(historyItem(field), screening, patient);
    if (item.value) {
      personalHistory = true;
      indicators.add(item.name);
    }
  });

  return {
    pathology,
    personalHistory,
    indicators: [...indicators],
    value: pathology || personalHistory,
  };
}

export function getScreeningNotApplicableCervical(screening, patient) {
  const indicators = new Set();
  let value = false;

  // Hysterectomy

  if (!getScreeningHighRiskCervical(screening, patient).value) {
    const hysterectomy = historyItemValue(historyItem('hysterectomy'), screening, patient);
    if (hysterectomy.value) {
      indicators.add(hysterectomy.name);
      value = true;
    }
  }

  return {
    indicators: [...indicators],
    value,
  };
}

export function getScreeningLowRiskCervical(screening, patient) {
  const indicators = new Set();
  let value = false;

  // Sexually active
  // Note: Use firestore item

  const sexuallyActive = historyItemValue(sexuallyActiveHistoryItem, screening, patient);

  if (!sexuallyActive.value) {
    indicators.add('Never been sexually active');
    value = true;
  }

  return {
    indicators: [...indicators],
    value,
  };
}

export function screeningRisksCervical(screening, patient) {
  const { pathology, personalHistory } = getScreeningHighRiskCervical(screening, patient);
  return {
    highRisk: {
      pathology,
      personalHistory,
    },
    notApplicable: getScreeningNotApplicableCervical(screening, patient).value,
  };
}

export const screeningMaxAgeCervical = 69;

//
// Recommendation
//

const defaultDueOptions = (latestTest) =>
  latestTest
    ? [
        { name: '3 years from test', duration: { months: 36 } },
        { name: '1 year from test', duration: { months: 12 } },
        { name: '6 months from test', duration: { months: 6 } },
        { name: '3 months from test', duration: { months: 3 } },
      ]
    : [];

export function screeningNextTestDueOptionsCervical(recommendation, latestTest) {
  if (['pap', 'questionnaire'].includes(recommendation.type)) {
    if (!recommendation.agedIn) {
      const minAge = screeningMinAgeCervical;
      return [{ name: `age ${minAge}`, duration: { months: minAge * 12 } }];
    }
    if (recommendation.type === 'questionnaire') {
      return [{ name: 'in 3 years', duration: { months: 36 } }];
    }
    return dueOptionsAddNowIfNeeded(recommendation, defaultDueOptions(latestTest));
  }
  return [];
}

export function screeningRecommendationCervical(latestTest, otherTests, screening, patient) {
  const { date, type, value, value2 } = latestTest || {};

  const isHighRisk = getScreeningHighRiskCervical(screening, patient).value;
  const isLowRisk = getScreeningLowRiskCervical(screening, patient).value;
  const age = getAge(patient, date);
  const minAge = screeningMinAgeCervical;

  if (
    latestTest &&
    (!isLowRisk ||
      isHighRisk ||
      value !== 'negative' ||
      (age >= 30 && value === 'ascus' && value2 !== 'negative') ||
      (age >= 50 && value === 'lsil' && value2 !== 'negative'))
  ) {
    const tests = otherTests.filter((item) => item.value !== 'unsatisfactory' && isAfter(item.date, subYears(date, 3)));
    const serverityAscus = resultServerity.indexOf('ascus');

    if (type === 'dischargeLetter') {
      return newRecommendationScheduleTest({ months: 12 }, 'pap', date);
    }

    if (type === 'colposcopy') {
      return newRecommendationReferral();
    }

    if (type === 'pap') {
      if (value === 'unsatisfactory') {
        return newRecommendationScheduleTest({ months: 3 }, 'pap', date);
      }
      if (age >= 50 && value === 'lsil' && value2 === 'negative') {
        return newRecommendationScheduleTest({ months: isHighRisk ? 12 : 36 }, 'pap', date);
      }
      if (age < 50 && resultServerity.indexOf(value) < serverityAscus) {
        const indexOfLsil = tests.findIndex((item, index) => item.value === 'lsil' && index > 0);
        const indexOfOther = tests.findIndex(
          (item, index) => index < indexOfLsil && resultServerity.indexOf(item.value) < serverityAscus,
        );
        if (indexOfLsil > -1 && indexOfOther > -1 && indexOfOther < indexOfLsil && indexOfOther === 0) {
          return newRecommendationScheduleTest({ months: isHighRisk ? 12 : 36 }, 'pap', date);
        }
        if (tests.some((item, index) => item.value === 'lsil' && index === 0)) {
          return newRecommendationScheduleTest({ months: 6 }, 'pap', date);
        }
      }
      if (age < 50 && value === 'lsil' && !tests.some((item) => item.value === 'lsil')) {
        return newRecommendationScheduleTest({ months: 6 }, 'pap', date);
      }
      if (age >= 30 && value === 'ascus' && value2 === 'negative') {
        return newRecommendationScheduleTest({ months: isHighRisk ? 12 : 36 }, 'pap', date);
      }
      if (age < 30 && resultServerity.indexOf(value) < serverityAscus) {
        const indexOfAscus = tests.findIndex((item, index) => item.value === 'ascus' && index > 0);
        const indexOfOther = tests.findIndex(
          (item, index) => index < indexOfAscus && resultServerity.indexOf(item.value) < serverityAscus,
        );
        if (indexOfAscus > -1 && indexOfOther > -1 && indexOfOther < indexOfAscus && indexOfOther === 0) {
          return newRecommendationScheduleTest({ months: isHighRisk ? 12 : 36 }, 'pap', date);
        }
        if (tests.some((item, index) => item.value === 'ascus' && index === 0)) {
          return newRecommendationScheduleTest({ months: 6 }, 'pap', date);
        }
      }
      if (age < 30 && value === 'ascus' && !tests.some((item) => item.value === 'ascus')) {
        return newRecommendationScheduleTest({ months: 6 }, 'pap', date);
      }
      if (value === 'negative') {
        return newRecommendationScheduleTest({ months: isHighRisk ? 12 : 36 }, 'pap', date);
      }

      // Any pap result at this point is positive and requires a call/referral
      return newRecommendationReferral();
    }
  }

  if (minAge <= age) {
    return isLowRisk
      ? newRecommendationScheduleTest({ months: 36 }, 'questionnaire')
      : newRecommendationScheduleTest({ months: 0 }, 'pap', date);
  }

  // Not agedIn
  return newRecommendationAgingIn({ months: minAge * 12 }, isLowRisk ? 'questionnaire' : 'pap', patient);
}

export const callPatientCervical = (recommendation, latestTest) =>
  recommendation.referral && (!latestTest || latestTest.type !== 'colposcopy');

export const referPatientCervical = (recommendation) => recommendation.referral;

//
// Chart medical history
//

export function setChartHistoryItemCervical(item, value = true) {
  if (item.field === sexuallyActiveHistoryItem.field) {
    return [
      {
        ...item,
        value: true,
      },
    ];
  }
  if (item.field === 'neverSexuallyActive') {
    return [
      {
        ...sexuallyActiveHistoryItem,
        value: false,
      },
    ];
  }

  return [{ ...item, value }];
}

export const menuOptionsEditSocialHistoryCervical = [sexuallyActiveHistoryItem, historyItem('neverSexuallyActive')];
