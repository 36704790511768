import env from '../utils/env';

const production = {
  apiKey: 'AIzaSyDCmlzblPoQKXbT7mbHbMoWRHiNiQ3tMww',
  authDomain: 'chekhealth-prod.firebaseapp.com',
  databaseURL: 'https://chekhealth-prod.firebaseio.com',
  projectId: 'chekhealth-prod',
  storageBucket: 'chekhealth-prod.appspot.com',
  messagingSenderId: '1024374547097',
  appId: '1:1024374547097:web:5b9011ec1f423cf12dce08',
  measurementId: 'G-K6MVQLJ62K',
};

const development = {
  apiKey: 'AIzaSyA2bMTDRizYzlY-Ltlrq07JHaf221Jg0yA',
  authDomain: 'chekhealth-dev.firebaseapp.com',
  databaseURL: 'https://chekhealth-dev.firebaseio.com',
  projectId: 'chekhealth-dev',
  storageBucket: 'chekhealth-dev.appspot.com',
  messagingSenderId: '563063334576',
  appId: '1:563063334576:web:a1afbce8b834ac2dc1bd75',
  measurementId: 'G-N6LHYTB8EF',
};

const config = {
  production,
  development,
};

export default config[env()];

// 2 hours
export const userInactivityTimeout = 2 * 60 * 60 * 1000;
