import DialogActions from '@material-ui/core/DialogActions';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { primary60 } from '../constants/colors';
import { chartHistoryItem, setChartHistoryItem } from '../utils/screenings';
import { capitalize } from '../utils/string';
import Button from './Button';
import DialogPaper from './DialogPaper';
import DialogTitle from './DialogTitle';
import TextField from './TextField';
import { dialogActions } from './styles';

const styles = (theme) => ({
  dialogActions: {
    ...dialogActions(theme),
    marginTop: theme.spacing(1.5),
  },
  dialogContent: {
    padding: theme.spacing(2, 3),
  },
  paper: {
    width: 460,
  },
  underline: {
    '&:after': {
      borderBottomColor: primary60,
    },
    '&&:hover::before': {
      borderBottomColor: primary60,
    },
  },
});

class ChartHistoryDialog extends Component {
  constructor(props) {
    super(props);

    const { item } = props;

    this.state = {
      value: item ? item.field : null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.valid = this.valid.bind(this);
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  // Dialog close
  handleClose() {
    const { handleClose: handleCloseProp } = this.props;

    this.setState({ value: null });

    handleCloseProp();
  }

  handleSaveClick() {
    const { handleSaveClick: handleSaveClickProp, screening, patient } = this.props;
    const { value } = this.state;

    // Value is firestore field
    const item = chartHistoryItem(value, screening);

    const items = setChartHistoryItem(item, screening, patient);

    handleSaveClickProp(items);

    this.setState({ value: null });
  }

  valid() {
    const { value } = this.state;

    return value !== null;
  }

  render() {
    const { classes, getValue, historyType, open, patient, screening, title } = this.props;
    const { value: valueState } = this.state;

    const value = getValue(historyType, screening, patient);

    return (
      <DialogPaper open={open} onClose={this.handleClose} PaperProps={{ className: classes.paper }} size="large">
        <DialogTitle handleClose={this.handleClose} title={title} />
        <div className={classes.dialogContent}>
          <TextField
            autoFocus
            fullWidth
            InputProps={{ classes: { underline: classes.underline } }}
            label={capitalize(value.label)}
            onChange={this.handleChange}
            select
            value={valueState || ''}
          >
            {value.options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <DialogActions className={classes.dialogActions}>
          <Button color="primary" disabled={!this.valid()} onClick={this.handleSaveClick} variant="contained">
            Save
          </Button>
        </DialogActions>
      </DialogPaper>
    );
  }
}

ChartHistoryDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  getValue: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  historyType: PropTypes.string.isRequired,
  item: PropTypes.object,
  open: PropTypes.bool.isRequired,
  patient: PropTypes.object.isRequired,
  screening: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

ChartHistoryDialog.defaultProps = {
  item: undefined,
};

export default withStyles(styles)(ChartHistoryDialog);
