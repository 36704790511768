import MuiPopover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { secondary99 } from '../constants/colors';
import { fontFamily1 } from '../constants/typography';

const styles = (theme) => ({
  paper: {
    backgroundColor: secondary99,
    borderRadius: 8,
    minWidth: 320,
    padding: theme.spacing(1.5, 3, 0.25, 3),
  },
  popover: {
    fontFamily: fontFamily1,
    transform: 'translateY(-12px)',
  },
});

function Popover(props) {
  const {
    anchorEl,
    anchorOrigin,
    children,
    classes,
    className: classNameProp,
    classNamePaper,
    handleClose,
    open,
    transformOrigin,
    ...other
  } = props;

  return (
    <MuiPopover
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      className={clsx(classes.popover, classNameProp)}
      elevation={1}
      id={open ? 'popover' : null}
      onClose={handleClose}
      open={open}
      PaperProps={{ className: clsx(classes.paper, classNamePaper) }}
      transitionDuration={350}
      transformOrigin={transformOrigin}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {children}
    </MuiPopover>
  );
}

Popover.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  anchorOrigin: PropTypes.object,
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  classNamePaper: PropTypes.string,
  handleClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  transformOrigin: PropTypes.object,
};

Popover.defaultProps = {
  anchorEl: () => document.createElement('div'),
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  children: undefined,
  className: undefined,
  classNamePaper: undefined,
  handleClose: null,
  transformOrigin: { vertical: 'bottom', horizontal: 'center' },
};

export default withStyles(styles)(Popover);
